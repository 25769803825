import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import Connector from '../../data/Connector';
import HelperClients from '../../helper/Clients';
import Cal from '../../helper/Calendar';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';
import HelperEmpty from '../../helper/Empty';
import Navigation from '../../components/Navigation';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import InputText from '../../components/Form/input-text';
import InputInvited from '../../components/Form/invited';
import InfoData from '../../components/Form/info';
import ModalClients from '../../components/Modals/clients';
import ModalItems from '../../components/Modals/items';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewLocationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: lang.t('admin:title.newlocation'),
      back: global.currentPage,
      name: '',
      value: '',
      invited: [],
      staff: [],
      staffids: [],
      error: [false, false],
      sessions: global.sessions,
      recurring: global.recurring,
      id: '',
      item: null,
      editing: false,
      exp1: false,
      exp2: false,
      h1: 20,
      h2: 20,
      showModalDelete: false,
      showModalClients: false,
      showModalStaff: false,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Location - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('recurringLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('staffLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationsLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      if(arr[arr.length-1] !== 'new') {
        var item = null
        for(var loc of global.locations) {
          if(loc.id === arr[arr.length-1]) {
            item = loc
          }
        }
        var back = global.currentPage
        if(global.linkCli !== '') {
          back = global.linkCli
        }
        this.setState({
          id: arr[arr.length-1],
          item: item,
          title: lang.t('admin:title.location'),
          back: back
        })
        this.setupExisting(arr[arr.length-1])
      }
    }
  }


  setupExisting(id) {
    var name = lang.t('admin:title.location')
    var ids1 = []
    var ids2 = []
    var tmp = []
    var sessions = []
    var rec = []
    for(var item of global.locations) {
      if(item.id === id) {
        name = item.data.name
        if(item.data.clients !== undefined) {
          for(var cl of item.data.clients) {
            ids1.push(cl)
          }
        }
        if(item.data.staff !== undefined) {
          for(var staff of item.data.staff) {
            ids2.push(staff)
            for(var st of global.userStaff) {
              if(st.id === staff) {
                tmp.push(st)
              }
            }
          }
        }
      }
    }
    for(var item2 of global.sessions) {
      if(item2.data.location !== undefined) {
        if(item2.data.location === id && Moment(item2.data.timestamp, 'X') < Moment().add(15, 'days') && Moment(item2.data.timestamp, 'X') > Moment()) {
          sessions.push(item2)
        }
      }
    }
    for(var item3 of global.recurring) {
      if(item3.data.location !== undefined) {
        if(item3.data.location === id) {
          rec.push(item3)
        }
      }
    }
    global.linkAdm = '/admin/locations/location/'+id
    this.setState({
      name: name,
      invited: ids1,
      staffids: ids2,
      staff: tmp,
      value: name,
      sessions: sessions,
      recurring: rec,
    })
    
  }


  toggleAcc1() {
    var height = 20
    if(!this.state.exp1) {
      height = this.el1.clientHeight+30
    }
    this.setState({
      h1: height,
      exp1: !this.state.exp1,
    })
  }


  toggleAcc2() {
    var height = 20
    if(!this.state.exp2) {
      height = this.el2.clientHeight+30
    }
    this.setState({
      h2: height,
      exp2: !this.state.exp2,
    })
  }



  // Client update stuff ------------------------------------------------------------



  checkCreate() {
    this.setState({error: [false, false]})
    var tmp = [false, false]
    if(this.state.value === '') {
      tmp[0] = true
    }
    this.setState({error: tmp})
    if(!tmp[0] && !tmp[1]) {
      this.createLocation()
    }
  }


  createLocation() {
    if(this.state.id === '') {
      // New
      Firebase.database().ref('/locations/'+global.uid).push({
        name: this.state.value,
        clients: this.state.invited,
        staff: this.state.staffids,
      }).then(() => {
        global.message = lang.t('messaging:message.locationcreated')
        this.props.history.push(ROUTES.LOCATIONS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      // Update
      for(var item1 of global.sessions) {
        if(item1.data.location !== undefined) {
          if(item1.data.location === this.state.id) {
            Firebase.database().ref('/sessions/'+global.uid+'/'+item1.id).update({
              locationName: this.state.value,
            })
          }
        }
      }
      for(var item2 of global.recurring) {
        if(item2.data.location !== undefined) {
          if(item2.data.location === this.state.id) {
            Firebase.database().ref('/recurring/'+global.uid+'/'+item2.id).update({
              locationName: this.state.value,
            })
          }
        }
      }
      for(var item3 of global.events) {
        if(item3.data.location !== undefined) {
          if(item3.data.location === this.state.id) {
            Firebase.database().ref('/events/'+global.uid+'/'+item3.id).update({
              locationName: this.state.value,
            })
          }
        }
      }
      Firebase.database().ref('/locations/'+global.uid+'/'+this.state.id).update({
        name: this.state.value,
        clients: this.state.invited,
        staff: this.state.staffids,
      }).then(() => {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.locationupdated'));
        this.setState({
          editing: false
        })
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  getClientName(id) {
    var label = lang.t('common:label.deletedclient')
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name+' ('+lang.t('admin:label.inactive2')+')'
      }
    }
    for(var item3 of global.userStaff) {
      if(item3.id === id) {
        label = item3.data.name
      }
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        client = item2
      }
    }
    return client
  }


  removeClient(item) {
    var tmp = this.state.invited
    for(var i=0; i<this.state.invited.length; i++) {
      if(tmp[i] === item) {
        tmp.splice(i, 1)
      }
    }
    this.setState({
      invited: tmp
    })
  }


  cancelEdit() {
    this.setState({
      editing: false,
      value: this.state.item.data.name,
      invited: this.state.item.data.clients
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalClients: false,
      showModalStaff: false,
    })
  }


  selectClients(items) {
    this.hideModals()
    this.setState({
      invited: items,
    })
  }


  selectStaff(items) {
    this.hideModals()
    var tmp = []
    for(var item of items) {
      tmp.push(item.id)
    }
    this.setState({
      staff: items,
      staffids: tmp,
    })
  }


  deleteLocation() {
    this.hideModals()
    Firebase.database().ref('/locations/'+global.uid+'/'+this.state.id).remove()
    for(var item1 of global.sessions) {
      if(item1.data.location !== undefined) {
        if(item1.data.location === this.state.id && Moment(item1.data.timestamp, 'X') > Moment()) {
          Firebase.database().ref('/sessions/'+global.uid+'/'+item1.id).update({
            location: '',
          })
        }
      }
    }
    for(var item2 of global.recurring) {
      if(item2.data.locations !== undefined) {
        if(item2.data.location === this.state.id) {
          Firebase.database().ref('/recurring/'+global.uid+'/'+item2.id).update({
            location: '',
          })
        }
      }
    }
    for(var item3 of global.events) {
      if(item3.data.locations !== undefined) {
        if(item3.data.location === this.state.id && Moment(item1.data.timestamp, 'X') > Moment()) {
          Firebase.database().ref('/events/'+global.uid+'/'+item3.id).update({
            location: '',
          })
        }
      }
    }
    global.message = lang.t('messaging:message.locationdeleted')
    this.props.history.push(ROUTES.LOCATIONS)
  }



  // Display stuff ------------------------------------------------------------



  getSessionIcon(item) {
    if(item.data.group) {
      if(item.data.availability) {
        return (
          <div className="icon secondary">
            <div className="inner session"></div>
          </div>
        )
      } else {
        return  (
          <div className="icon primary">
            <div className="inner group"></div>
          </div>
        )
      }
    } else {
      return  (
        <div className="icon secondary">
          <div className="inner session"></div>
        </div>
      )
    }
  }


  getSessionName(item) {
    var label = lang.t('admin:leads.groupsession')
    if(item.data.client !== '' && item.data.group) {
      label = item.data.client
    }
    if(item.data.availability !== undefined && item.data.group) {
      label = lang.t('admin:leads.available11session')
    }
    if(item.data.client !== '' && !item.data.group) {
      for(var cl of global.clients) {
        if(cl.id === item.data.client) {
          label = cl.data.name
        }
      }
    }
    return label
  }


  renderSessions() {
    if(this.state.sessions.length === 0) {
      return (
        <div className="empty small mt-20" style={{height: 140}} ref={(el1) => {this.el1 = el1}}>
          {HelperEmpty.renderImage('calendar')}
          <h4 style={{whiteSpace: 'pre-wrap'}}>{lang.t('admin:label.nosessions')}</h4>
        </div>
      )
    } else {
      var list = this.state.sessions
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div className="mt-10" ref={(el1) => {this.el1 = el1}}>
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/calendar/group-session/'+item.id}>
                {this.getSessionIcon(item)}
                <div className="main">
                  <h4>{this.getSessionName(item)}</h4>
                  <p>{Cal.getSpecialDate(item)}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderRecurring() {
    if(this.state.recurring.length === 0) {
      return (
        <div className="empty small mt-20" style={{height: 140}} ref={(el2) => {this.el2 = el2}}>
          {HelperEmpty.renderImage('calendar')}
          <h4>{lang.t('admin:label.norecurringsessionsyet')}</h4>
        </div>
      )
    } else {
      var list = this.state.recurring
      return (
        <div className="mt-10" ref={(el2) => {this.el2 = el2}}>
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/calendar/recurring-templates/group/'+item.id}>
                {this.getSessionIcon(item)}
                <div className="main">
                  <h4>{this.getSessionName(item)}</h4>
                  <p>{Moment(item.data.timestamp, 'X').format('dddd')}, {Moment(item.data.timestamp, 'X').locale(lang.language).format('LT')}</p>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderClients() {
    if(this.state.item.data.clients === undefined) {
      return (
        <div className="attendance clientlist data">
          <InfoData label={lang.t('admin:label.clients2')} value={lang.t('admin:label.noclientsassigned')}/>
        </div>
      )
    } else {
      return (
        <div className="attendance clientlist">
          <label>{lang.t('admin:label.clients2')}</label>
          {this.state.item.data.clients.map(item => (
            <div className="client small static" key={item}>
              {HelperClients.getImage(this.getClient(item))}
              <p>{this.getClientName(item)}</p>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderStaff() {
    if(global.userStaff.length > 0) {
      if(this.state.item.data.staff === undefined) {
        return (
          <div className="attendance clientlist data">
            <InfoData label={lang.t('admin:label.staff')} value={lang.t('admin:label.nostaffmembersassigned')}/>
          </div>
        )
      } else {
        return (
          <div className="attendance clientlist mb-20">
            <label>{lang.t('admin:label.staff')}</label>
            {this.state.item.data.staff.map(item => (
              <div className="client small static" key={item}>
                <div className="avatar">
                  <img src='/img/list-staff.svg' className='staff' alt="Staff member" style={{width: 32, height: 32}}/>
                </div>
                <p>{this.getClientName(item)}</p>
                <div className="clear"></div>
              </div>
            ))}
          </div>
        )
      }
    }
  }


  renderStaffEdit() {
    if(global.userStaff.length > 0) {
      return (
        <div>
          <InputInvited type={'staff'} clients={this.state.staffids} error={this.state.error[1]} clickClients={() => this.setState({showModalStaff: true})} clickRemove={(event) => this.removeStaff(event)}/>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.id !== '' && this.state.item !== null) {
      if(this.state.editing) {
        return (
          <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <InputText label={lang.t('admin:form.locationname')+"*"} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('admin:error.locationname')} onChange={(event) => this.setState({value: event})}/>
                {this.renderStaffEdit()}
                <InputInvited type={'location'} clients={this.state.invited} error={this.state.error[1]} clickClients={() => this.setState({showModalClients: true})} clickRemove={(event) => this.removeClient(event)}/>
                <div className="sv-30"></div>
                <button className="btn primary mb-20" onClick={() => this.checkCreate()}>{lang.t('admin:button.updatelocation')}</button>
                <button className="btn tertiary close width-12" onClick={() => this.cancelEdit()}>{lang.t('common:button.cancel')}</button>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='editlocation'/>
              </div>
            </div>
          </div>
          <Header title={lang.t('admin:title.location')} link={this.state.back}/>
          <Navigation active='clients' />
          <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} limit={false} selected={this.state.invited} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
          <ModalItems show={this.state.showModalStaff} type={'staff'} title={lang.t('modals:items.title.staff')} empty={lang.t('modals:items.empty.nostaffmembers')}  items={global.userStaff} selected={this.state.staff} multiple={true} button='Confirm selection' onHide={() => this.hideModals()} clickMainButton={(event) => this.selectStaff(event)}/>
        </div>
        )
      } else {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-sidebar">
              <div className="col-6 mt-20">
                <div className="box">
                  <InfoData label='Name' value={this.state.item.data.name}/>
                  {this.renderStaff()}
                  {this.renderClients()}
                  <div className="sv-30"></div>
                  <button className="btn primary mb-20" onClick={() => this.setState({editing: true})}>{lang.t('admin:button.editlocation')}</button>
                  <button className="btn tertiary close width-12" onClick={() => this.setState({showModalDelete: true})}>{lang.t('admin:button.deletethislocation')}</button>
                </div>
              </div>
              <div className="col-6 mt-20">
                <div className="sidebar">
                  <div className={this.state.exp1 ? 'accordion expanded mb-50' : 'accordion mb-50 a1'} style={{height: this.state.h1}}>
                    <h3 className="handle" onClick={() => this.toggleAcc1()}>{lang.t('admin:label.upcomingsessions')}</h3>
                    {this.renderSessions()}
                  </div>
                  <div className={this.state.exp2 ? 'accordion expanded mb-50' : 'accordion mb-50'} style={{height: this.state.h2}}>
                    <h3 className="handle" onClick={() => this.toggleAcc2()}>{lang.t('admin:label.recurringsessions')}</h3>
                    {this.renderRecurring()}
                  </div>
                  
                </div>
              </div>
              <div className="clear sv-80"></div>
            </div>
            <Header title={lang.t('admin:title.location')} link={this.state.back}/>
            <Navigation active='clients' />
            <ModalMessageSimple type='deletelocation' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteLocation()}/>
          </div>
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
                <div className="box">
                <InputText label={lang.t('admin:form.locationname')+"*"} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('admin:error.locationname')} onChange={(event) => this.setState({value: event})}/>
                {this.renderStaffEdit()}
                <InputInvited type={'location'} clients={this.state.invited} error={this.state.error[1]} clickClients={() => this.setState({showModalClients: true})} clickRemove={(event) => this.removeClient(event)}/>
                <div className="sv-30"></div>
                <button className="btn primary" onClick={() => this.checkCreate()}>{lang.t('admin:button.createlocation')}</button>
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='newlocation'/>
              </div>
            </div>
            <div className="clear sv-80"></div>
          </div>
          <Header title={lang.t('admin:title.newlocation')} link={this.state.back}/>
          <Navigation active='clients' />
          <ModalClients show={this.state.showModalClients} title={lang.t('modals:clients.title.selectclients')} button={lang.t('modals:clients.button.confirmselection')} multiple={true} selected={this.state.invited} limit={false} onHide={() => this.hideModals()} clickMainButton={(event) => this.selectClients(event)}/>
          <ModalItems show={this.state.showModalStaff} type={'staff'} title={lang.t('modals:items.title.staff')} empty={lang.t('modals:items.empty.nostaffmembers')} items={global.userStaff} selected={this.state.staff} multiple={true} button='Confirm selection' onHide={() => this.hideModals()} clickMainButton={(event) => this.selectStaff(event)}/>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div>
          {this.renderContent()}
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='clientgroups-locked' type='simple'/>
          </div>
          <Header title={lang.t('admin:title.location')} link={ROUTES.LOCATIONS}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(NewLocationPage)));