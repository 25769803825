import React, { Component } from 'react';
import 'firebase/functions';
import Connector from '../../data/Connector';

import Navigation from '../../components/Navigation';
import { Link } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



global.adminClients = []


class UsersClientsPage extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
    {authUser => (
      <UsersClients/>
    )}
  </AuthUserContext.Consumer>
    )
  }
}



class UsersClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      search: '',
      client: null,
      margin: 0,
      showOverlay: false,
      hidden: 'hidden',
      value: ''
    };
  }


  componentDidMount() {
    Connector.setUser()
    Connector.loadAdminClients()
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('adminClientsLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var tmp = []
    for(var item of global.adminClients) {
      if(item.data.name !== undefined) {
        tmp.push(item)
      }
    }
    this.setState({
      clients: tmp
    })
  }


  filterUsers = event => {
    this.setState({search: event.target.value });
    var tmp = []
    if(event.target.value !== '') {
      for(var item of global.adminClients) {
        if(item.data.name !== undefined) {
          if(item.data.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1) {
            tmp.push(item)
          }
        }
        
      }
    } else {
      for(var item2 of global.adminClients) {
        if(item2.data.name !== undefined) {
          tmp.push(item2)
        }
      }  
    }
    this.setState({clients: tmp})
  };



  // Reset overlay ------------------------------------------------------------



  showOverlay(item) {
    this.setState({
      showOverlay: true,
      client: item,
      value: item.data.email
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showOverlay: false,
      });
    }, 500);
  }


  renderOLTrainer() {
    if(this.state.client.trainers === null || this.state.client.trainers === undefined || this.state.client.trainers.length === 0) {
      return (
        <p className="mb-20">Name: {this.state.client.data.name}<br/>Current ID: {this.state.client.id}</p>
      )
    } else {
      return (
        <p className="mb-20">Name: {this.state.client.data.name}<br/>Current ID: {this.state.client.id}<br/>Trainer: {this.state.client.trainers[0].data.trainer}<br/>Client ID: {this.state.client.trainers[0].data.client}</p>
      )
    }
  }


  renderOLButton() {
    if(this.state.client.trainers === null || this.state.client.trainers === undefined || this.state.client.trainers.length === 0) {

    } else {
      return (
        <button className="btn primary" onClick={() => this.sendReset()}>Send reset</button>
      )
    }
  }


  renderOverlayInfo() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Send reset email</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>Close</button>
            <p className="clear mb-20">Send an email to the client to reset their account.</p>
            {this.renderOLTrainer()}
            <input type="email" value={this.state.value} onChange={this.onChange}/>
            <div className="sv-30"></div>
            {this.renderOLButton()}
          </div>
        </div>
      )
    }
  }


  onChange = event => {
    this.setState({
      value: event.target.value
    });
  };


  sendReset() {
    this.hideOverlay()
  }



  // Display stuff ------------------------------------------------------------



  renderFilters() {
    return (
      <div className="listheader clear">
        <input type="text" className="search light rgt" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterUsers(val)} />
        <div className="clear"></div>
      </div>
    )
  }


  renderClients() {
    if(this.state.clients.length === 0) {
      return (
        <div className="empty pt-60" style={{opacity: '.3'}}>
          <h2>Loading clients</h2>
        </div>
      )
    } else {
      var list = this.state.clients
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.showOverlay(item)}>
              <div className="col-4">
                <p><strong>{item.data.name === '' ? '<NO NAME>' : item.data.name}</strong></p>
              </div>
              <div className="col-4">
                <p>{item.data.email}</p>
              </div>
              <div className="col-2">
                <p>{item.data.mobile === undefined ? 'Old version' : item.data.mobile}<br/></p>
              </div>
              <div className="col-2">
                <button className="btn tertiary small rgt">Send reset</button>
              </div>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  render() {
    if(global.uidUser === 'L81v7rXOuTPWqVFfU89icsSOdLx1' || global.uidUser === 'EjXQG2v3hjcYr32l6oV2actRiBF2') {
      return (
        <div>
          <div className="content withtabs">
            <div className="col-12">
              {this.renderFilters()}
              {this.renderClients()}
            </div> 
          </div>
          <div className="header withtabs">
            <div className="content">
              <h2>Admin</h2>
              <Link to={'/admin/users'} className='tab'>
                <p>Business Users</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/admin/clients'} className='tab active'>
                <p>Clients</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/admin/movements'} className='tab'>
                <p>Movements</p>
                <div className="bg"></div>
              </Link>
            </div>
          </div>
          <Navigation active='admin' />
          {this.renderOverlayInfo()}
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}



const condition = authUser => !!authUser;

export default withAuthorization(condition)(UsersClientsPage);