import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactHtmlParser from 'react-html-parser';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperClients from '../../helper/Clients';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import Select from '../../components/Form/select';
import InputDate from '../../components/Form/input-date';
import InputRadio from '../../components/Form/radio';
import InfoData from '../../components/Form/info';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalMessageDouble from '../../components/Modals/message-double';
import ModalForm from '../../components/Modals/form';
import ModalFilter from '../../components/Modals/filter';
import ModalClients from '../../components/Modals/clients';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewEmailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: '',
      subject: '',
      selected: [],
      unsaved: true,
      saving: false,
      lock: false,
      test: '',
      blacklist: [],
      error: [false, false, false],
      clients: [],
      available: [],
      type: 'clients',
      time: global.spaceWelcomeTime,
      timeCustom: global.spaceWelcomeTime,
      timeEdit: false,
      add: false,
      editing: true,
      editorState: EditorState.createEmpty(),
      filter: 'both,all,all,false,'+Moment().add(-1, 'month').format('YYYY-MM-YY')+','+Moment().format('YYYY-MM-YY')+',both,both,both,both,both',
      filterFinal: 'both,all,all,false,'+Moment().add(-1, 'month').format('YYYY-MM-YY')+','+Moment().format('YYYY-MM-YY')+',both,both,both,both,both',
      date: Moment().format('YYYY-MM-DD'),
      min: '00',
      sec: '00',
      hiddenSchedule: 'hidden',
      showModalSchedule: false,
      showModalSave: false,
      showModalTest: false,
      showModalFilter: false,
      showModalSend: false,
      showModalDelete: false,
      showModalClients: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Email - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('emailsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('publicRequestsLoaded', (event) => this.configureData())
    Connector.loadEmailUsage()
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() { 
    if(this._isMounted) {
      var tmp = []
      var sel = []
      var type = 'clients'
      var tmpb = []
      if(global.publicData[0] !== undefined) {
        if(global.publicData[0].list !== undefined) {
          tmpb = global.publicData[0].list
        }
      }

      if(this.state.type === 'clients') {
        for(var item1 of global.clients) {
          if(item1.data.email !== undefined && item1.data.email !== '') {
            if(tmpb.indexOf(item1.data.email) === -1) {
              tmp.push(item1.id)
              sel.push(item1.id)
            }
          }
        }
      } else {
        for(var item2 of global.publicRequests) {
          if(item2.data.email !== undefined && item2.data.email !== '') {
            if(tmpb.indexOf(item2.data.email) === -1) {
              tmp.push(item2.id)
              sel.push(item2.id)
            }
          }
        }
      }
      
      this.setState({
        clients: tmp,
        selected: sel,
        blacklist: tmpb,
      })

      var time = global.spaceWelcomeTime
      if(time !== 0 && time !== 1 && time !== 2 && time !== 6 && time !== 12 && time !== 24) {
        time = 99999
      }

      var arr = this.props.location.pathname.split('/')
      if(arr[arr.length-1] === 'new' || arr[arr.length-1] === 'welcome') {
        var unblock = this.props.history.block(() => {
          if(this.state.unsaved) {
            unblock()
            this.setState({showModalSave: true})
            return false
          } else {
            return true
          }
        });
      } else {
        var id = arr[arr.length-1]
        var item = null
        var name = ''
        var sub = ''
        var con = ''
        sel = []
        for(var email of global.emails) {
          if(email.id === id) {
            item = email
            name = email.data.name
            sub = email.data.subject
            con = email.data.content
            if(item.data.clients !== undefined) {
              sel = item.data.clients
            }
            if(item.data.type !== undefined) {
              type = item.data.type
            }
          }
        }
        var blocksFromHtml = htmlToDraft(con);
        var { contentBlocks, entityMap } = blocksFromHtml;
        var contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        var editorState = EditorState.createWithContent(contentState);
        this.setState({
          editing: false,
          id: id,
          item: item,
          name: name,
          subject: sub,
          editorState: editorState,
          selected: sel,
          type: type,
          time: time,
          timeCustom: global.spaceWelcomeTime,
        })
      }
      var avail = [{name: '- '+lang.t('admin:label.pleaseselect')+' -', value: ''}]
      for(var cl1 of global.clients) {
        if(sel.indexOf(cl1.id) === -1) {
          avail.push({name: cl1.data.name, value: cl1.id})
        }
      }
      for(var cl2 of global.clientsInactive) {
        if(sel.indexOf(cl2.id) === -1) {
          avail.push({name: cl2.data.name, value: cl2.id})
        }
      }
      this.setState({
        available: avail,
      })
    }
  }


  getStatus() {
    var label = ''
    if(this.state.item.data.status === 'draft') {
      label = lang.t('admin:label.draft')
    } else if(this.state.item.data.status === 'sent') {
      label = lang.t('admin:label.sent')+' '+Moment(this.state.item.data.date, 'X').locale(lang.language).format('llll')
      if(Moment(this.state.item.data.date, 'X') > Moment()) {
        label = lang.t('admin:label.scheduled')+' '+Moment(this.state.item.data.date, 'X').locale(lang.language).format('llll')
      }
    }
    return label
  }


  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
  };


  addClient(item) {
    var tmp = this.state.selected
    tmp.push(item)
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp
    })
  }


  removeClient(item) {
    var tmp = this.state.selected
    if(tmp.length > 0) {
      for(var i=0; i<tmp.length; i++) {
        if(tmp[i] === item) {
          tmp.splice(i, 1)
        }
      }
    }
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).update({
      clients: tmp
    })
  }


  updateTime(time) {
    this.setState({
      timeEdit: true,
      time: time,
    })
  }


  saveTime() {
    if(this.state.time === 99999) {
      Firebase.database().ref('/spaces/'+global.uid).update({
        welcomeTime: parseInt(this.state.timeCustom)
      })
    } else {
      Firebase.database().ref('/spaces/'+global.uid).update({
        welcomeTime: parseInt(this.state.time)
      })
    }
    this.setState({
      timeEdit: false,
    })
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.timeupdated'))
  }


  checkCreateEmail() {
    var tmp = [false, false, false]
    var passed = true
    if(this.state.name === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.subject === '') {
      tmp[1] = true
      passed = false
    }
    if(this.state.content === '') {
      tmp[2] = true
      passed = false
    }
    if(passed) {
      this.setState({
        unsaved: false,
        saving: false
      }, () => {
        if(this.state.id === '') {
          this.createEmail()
        } else {
          this.updateEmail()
        }
      })
    }
    this.setState({
      error: tmp,
      saving: true
    })
  }


  createEmail() {
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] === 'welcome') {
      var obj = Firebase.database().ref('/emails/'+global.uid).push()
      Firebase.database().ref('/spaces/'+global.uid).update({
        welcome: obj.key,
        welcomeTime: this.state.time,
      })
      Firebase.database().ref('/emails/'+global.uid+'/'+obj.key).update({
        name: this.state.name,
        subject: this.state.subject,
        content: ""+draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        clients: this.state.selected,
        status: 'welcome',
      }).then((data)=>{
        global.message = lang.t('messaging:message.welcomeemailcreated')
        this.props.history.push(ROUTES.ADMINEMAILS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      Firebase.database().ref('/emails/'+global.uid).push({
        name: this.state.name,
        subject: this.state.subject,
        content: ""+draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
        clients: this.state.selected,
        status: 'draft',
      }).then((data)=>{
        global.message = lang.t('messaging:message.emailcreated')
        this.props.history.push(ROUTES.ADMINEMAILS)
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    }
  }


  updateEmail() {
    var status = 'draft'
    if(this.state.item !== null) {
      if(this.state.item.data.status === 'welcome') {
        status = 'welcome'
      }
    }
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).update({
      name: this.state.name,
      subject: this.state.subject,
      content: ""+draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),
      status: status,
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailupdated'));
      this.setState({
        editing: false,
      })
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  duplicateEmail() {
    Firebase.database().ref('/emails/'+global.uid).push({
      name: this.state.item.data.name+' ('+lang.t('messaging:label.copy')+')',
      subject: this.state.item.data.subject,
      content: this.state.item.data.content,
      clients: this.state.item.data.clients,
      status: 'draft',
    })
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailduplicated'));
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalSave: false,
      showModalTest: false,
      showModalFilter: false,
      showModalSend: false,
      showModalDelete: false,
      showModalClients: false,
    })
  }


  attemptSave() {
    this.hideModals()
    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
    this.checkCreateForm()
  }


  cancelSave() {
    this.hideModals()
    this.props.history.push(ROUTES.ADMINEMAILS)
  }


  closeSave() {
    this.hideModals()
    var unblock = this.props.history.block(() => {
      if(this.state.unsaved) {
        unblock()
        this.setState({showModalSave: true})
        return false
      } else {
        return true
      }
    });
  }


  saveFilter() {
    this.hideModals()
    var tmp = []
    var tmpg = []
    var tmpd = []
    var tmps = []
    var tmpc = []
    var tmpdis = []
    var tmpp = []
    var tmpn = []
    var final = []
    var ar = this.state.filter.split(',')
    if(ar[0] === 'active' || ar[0] === 'both') {
      for(var item1 of global.clients) {
        tmp.push(item1.id)
      }
    }
    if(ar[0] === 'inactive' || ar[0] === 'both') {
      for(var item2 of global.clientsInactive) {
        tmp.push(item2.id)
      }
    }
    // Groups
    if(ar[1] !== 'all') {
      for(var g of global.clientGroups) {
        if(g.id === ar[1]) {
          tmpg = g.data.clients
        }
      }
    } else {
      tmpg = tmp
    }
    // Debits
    if(ar[2] !== 'all') {
      var pt = '---'
      for(var p of global.products) {
        if(p.id === ar[2]) {
          pt = p.id
        }
      }
      for(var c1 of global.clients) {
        for(var cs1 of c1.subscriptions) {
          if(cs1.id === pt) {
            tmpd.push(c1.id)
          }
        }
      }
    } else {
      tmpd = tmp
    }
    // Show time
    if(ar[3] === 'true') {
      // Sessions
      if(ar[6] === 'both') {
        tmps = tmp
      } else if(ar[6] === 'yes') {
        var found1 = []
        for(var s1 of global.sessions) {
          if(s1.data.timestamp >= parseInt(Moment(ar[4], 'YYYY-MM-DD').format('X')) && s1.data.timestamp <= parseInt(Moment(ar[5], 'YYYY-MM-DD').format('X'))) {
            if(s1.data.group) {
              if(s1.data.clients !== undefined) {
                for(var sc of s1.data.clients) {
                  found1.push(sc)
                }
              }
            } else {
              found1.push(s1.data.client)
            }
          }
        }
        for(var s11 of global.archive) {
          if(s11.data.timestamp >= parseInt(Moment(ar[4], 'YYYY-MM-DD').format('X')) && s1.data.timestamp <= parseInt(Moment(ar[5], 'YYYY-MM-DD').format('X'))) {
            if(s11.data.group) {
              if(s11.data.clients !== undefined) {
                for(var sc11 of s11.data.clients) {
                  found1.push(sc11)
                }
              }
            } else {
              found1.push(s11.data.client)
            }
          }
        }
      }
      // Connected
      if(ar[7] === 'both') {
        tmpc = tmp
      } else {
        for(var cc1 of global.clients) {
          var found2 = false
          for(var cc1a of cc1.history) {
            if(Moment(cc1a.data.date, 'DD/MM/YYYY HH:mm') >= Moment(ar[4], 'YYYY-MM-DD') && Moment(cc1a.data.date, 'DD/MM/YYYY HH:mm') <= Moment(ar[5], 'YYYY-MM-DD') && cc1a.data.title === 'Connected') {
              found2 = true
            }
          }
          if(found2 && ar[7] === 'yes') {
            tmpc.push(cc1)
          } else if(!found2 && ar[7] === 'no') {
            tmpc.push(cc1)
          }
        }
        for(var cc11 of global.clientsInactive) {
          var found21 = false
          for(var cc11a of cc1.history) {
            if(Moment(cc11a.data.date, 'DD/MM/YYYY HH:mm') >= Moment(ar[4], 'YYYY-MM-DD') && Moment(cc11a.data.date, 'DD/MM/YYYY HH:mm') <= Moment(ar[5], 'YYYY-MM-DD') && cc11a.data.title === 'Connected') {
              found21 = true
            }
          }
          if(found21 && ar[7] === 'yes') {
            tmpc.push(cc11)
          } else if(!found21 && ar[7] === 'no') {
            tmpc.push(cc11)
          }
        }
      }
      // Disconnected
      if(ar[8] === 'both') {
        tmpdis = tmp
      } else {
        for(var cc2 of global.clients) {
          var found3 = false
          for(var cc2a of cc2.history) {
            if(Moment(cc2a.data.date, 'DD/MM/YYYY HH:mm') >= Moment(ar[4], 'YYYY-MM-DD') && Moment(cc2a.data.date, 'DD/MM/YYYY HH:mm') <= Moment(ar[5], 'YYYY-MM-DD') && cc2a.data.title === 'Disconnected') {
              found3 = true
            }
          }
          if(found3 && ar[8] === 'yes') {
            tmpdis.push(cc2)
          } else if(!found3 && ar[8] === 'no') {
            tmpdis.push(cc2)
          }
        }
        for(var cc21 of global.clientsInactive) {
          var found31 = false
          for(var cc21a of cc2.history) {
            if(Moment(cc21a.data.date, 'DD/MM/YYYY HH:mm') >= Moment(ar[4], 'YYYY-MM-DD') && Moment(cc21a.data.date, 'DD/MM/YYYY HH:mm') <= Moment(ar[5], 'YYYY-MM-DD') && cc21a.data.title === 'Disconnected') {
              found31 = true
            }
          }
          if(found31 && ar[8] === 'yes') {
            tmpdis.push(cc21)
          } else if(!found31 && ar[8] === 'no') {
            tmpdis.push(cc21)
          }
        }
      }
      // Payment
      if(ar[9] === 'both') {
        tmpp = tmp
      } else {
        var found4 = []
        for(var pay of global.payments) {
          if(Moment(pay.data.date, 'DD/MM/YYYY HH:mm') >= Moment(ar[4], 'YYYY-MM-DD') && Moment(pay.data.date, 'DD/MM/YYYY HH:mm') <= Moment(ar[5], 'YYYY-MM-DD')) {
            found4.push(pay.data.client)
          }
        }
        if(ar[9] === 'yes') {
          tmpp = found4
        } else {
          for(var cc4 of global.clients) {
            if(found4.indexOf(cc4.id) === -1) {
              tmpp.push(cc4.id)
            }
          }
          for(var cc41 of global.clientsInactive) {
            if(found4.indexOf(cc41.id) === -1) {
              tmpp.push(cc41.id)
            }
          }
        }
      }
      // Nutrition
      if(ar[10] === 'both') {
        tmpn = tmp
      } else {
        var yes = []
        var no = []
        for(var cl5 of global.clients) {
          if(cl5.data.nutritionId !== undefined && cl5.data.nutritionId !== '') {
            var n1 = parseInt(cl5.data.nutritionStart)
            var d1 = parseInt(Moment(ar[4], 'YYYY-MM-DD').format('X'))
            var n2 = parseInt(cl5.data.nutritionEnd)
            var d2 = parseInt(Moment(ar[5], 'YYYY-MM-DD').format('X'))
            if((n1 < d1 && n2 > d1) || (n1 >= d1 && n1 < d2)) {
              yes.push(cl5)
            }
          } else {
            no.push(cl5)
          }
        }
        for(var cl51 of global.clientsInactive) {
          if(cl51.data.nutritionId !== undefined && cl51.data.nutritionId !== '') {
            var n11 = parseInt(cl51.data.nutritionStart)
            var d11 = parseInt(Moment(ar[4], 'YYYY-MM-DD').format('X'))
            var n21 = parseInt(cl51.data.nutritionEnd)
            var d21 = parseInt(Moment(ar[5], 'YYYY-MM-DD').format('X'))
            if((n11 < d11 && n21 > d11) || (n11 >= d11 && n11 < d21)) {
              yes.push(cl51)
            }
          } else {
            no.push(cl51)
          }
        }
        if(ar[10] === 'yes') {
          tmpn = yes
        } else {
          tmpn = no
        }
      }
    } else {
      tmpg = tmp
      tmpd = tmp
      tmps = tmp
      tmpc = tmp
      tmpdis = tmp
      tmpp = tmp
      tmpn = tmp
    }
    for(var t of tmp) {
      if(tmpg.indexOf(t) !== -1 && tmpd.indexOf(t) !== -1 && tmps.indexOf(t) !== -1 && tmpc.indexOf(t) !== -1 && tmpdis.indexOf(t) !== -1 && tmpp.indexOf(t) !== -1 && tmpn.indexOf(t) !== -1) {
        if(this.state.clients.indexOf(t) !== -1) {
          final.push(t)
        }
      }
    }

    if(this.state.type === 'leads') {
      final = []
      for(var itemr of global.publicRequests) {
        if(itemr.data.email !== undefined && itemr !== '') {
          final.push(itemr.id)
        }
      }
    }

    this.setState({
      selected: final,
      filterFinal: this.state.filter
    })
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).update({
      clients: final,
      type: this.state.type
    })
  }


  sendTest() {
    this.hideModals()
    if(this.state.test !== '') {
      var disclaimer = '<p style="font-size: 11px; padding-top: 50px"><a href="https://ptmate.app/unsubscribe/'+global.uid+'">Unsubscribe</a></p>'
      var content = this.state.item.data.content
      if(this.state.editing) {
        content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
      }
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailsent'));
      var callFunction = Firebase.functions().httpsCallable('sendMarketing');
      callFunction({sender: global.userName, email: [this.state.test], subject: '[TEST] '+this.state.subject, content: content+disclaimer}).then(function(result) {});
    }
  }


  sendEmail() {
    this.hideModals()
    var disclaimer = '<p style="font-size: 11px; padding-top: 50px"><a href="https://ptmate.app/unsubscribe/'+global.uid+'">Unsubscribe</a></p>'
    var base = 0
    var flist = []
    if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
      base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
    }
    var emails = []
    if(this.state.type === 'clients') {
      for(var item1 of global.clients) {
        if(this.state.selected.indexOf(item1.id) !== -1 && this.state.blacklist.indexOf(item1.data.email) === -1) {
          emails.push(item1.data.email)
          flist.push(item1.id)
        }
      }
      for(var item2 of global.clientsInactive) {
        if(this.state.selected.indexOf(item2.id) !== -1 && this.state.blacklist.indexOf(item2.data.email) === -1) {
          emails.push(item2.data.email)
          flist.push(item2.id)
        }
      }
    } else {
      for(var item3 of global.publicRequests) {
        if(this.state.selected.indexOf(item3.id) !== -1 && this.state.blacklist.indexOf(item3.data.email) === -1) {
          emails.push(item3.data.email)
          flist.push(item3.id)
        }
      }
    }
    
    var content = this.state.item.data.content
    if(this.state.editing) {
      content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    }
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailsent'));
    var callFunction = Firebase.functions().httpsCallable('sendMarketing');
    callFunction({sender: global.userName, email: emails, subject: this.state.subject, content: content+disclaimer}).then(function(result) {});
  
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).update({
      status: 'sent',
      clients: flist,
      date: parseInt(Moment().format('X'))
    })
    Firebase.database().ref('/admin/emails/'+global.uid).update({
      [Moment().format('MM-YYYY')]: base+emails.length
    })
  }


  sendClient(item) {
    if(item.data.email !== '' && item.data.email !== undefined) {
      var disclaimer = '<p style="font-size: 11px; padding-top: 50px"><a href="https://ptmate.app/unsubscribe/'+global.uid+'">Unsubscribe</a></p>'
      var content = this.state.item.data.content
      if(this.state.editing) {
        content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
      }
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.emailsent'));
      var callFunction = Firebase.functions().httpsCallable('sendMarketing');
      callFunction({sender: global.userName, email: [item.data.email], subject: this.state.subject, content: content+disclaimer}).then(function(result) {});
    } else {
      EventEmitter.dispatch('showMessageError', lang.t('messaging:message.emailerror'));
    }
  }


  deleteEmail() {
    this.hideModals()
    if(this.state.item.data.status === 'welcome') {
      Firebase.database().ref('/spaces/'+global.uid+'/').update({
        welcome: ''
      })
    }
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).remove()
    .then((data)=>{
      global.message = lang.t('messaging:message.emaildeleted')
      this.props.history.push(ROUTES.ADMINEMAILS)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Schedule modal ------------------------------------------------------------



  showModalSchedule() {
    var date = Moment().format('X')
    this.setState({
      showModalSchedule: true,
      date: Moment(date, 'X').format('YYYY-MM-DD'),
      min: Moment(date, 'X').format('HH'),
      sec: Moment(date, 'X').format('mm')
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenSchedule: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalSchedule() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenSchedule: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModalSchedule: false,
      });
    }, 500);
  }


  renderModalSchedule() {
    if(this.state.showModalSchedule) {
      return (
        <div className={'overlay '+this.state.hiddenSchedule}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Schedule email</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalSchedule()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-20">Set a time when to send this email. Note: Once confirmed, you won't be able to cancel the email or edit it.</p>
            <div className="col-6">
              <InputDate label={lang.t('modals:form.data9.date')} value={this.state.date} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} onChange={(event) => this.setState({date: Moment(event).format('YYYY-MM-DD')})}/>
            </div>
            <div className="col-3">
              <Select label={lang.t('modals:form.data9.hour')} value={this.state.min} values={[{name: '00', value: '00'}, {name: '01', value: '01'}, {name: '02', value: '02'}, {name: '03', value: '03'}, {name: '04', value: '04'}, {name: '05', value: '05'}, {name: '06', value: '06'}, {name: '07', value: '07'}, {name: '08', value: '08'}, {name: '09', value: '09'}, {name: '10', value: '10'}, {name: '11', value: '11'}, {name: '12', value: '12'}, {name: '13', value: '13'}, {name: '14', value: '14'}, {name: '15', value: '15'}, {name: '16', value: '16'}, {name: '17', value: '17'}, {name: '18', value: '18'}, {name: '19', value: '19'}, {name: '20', value: '20'}, {name: '21', value: '21'}, {name: '22', value: '22'}, {name: '23', value: '23'}]} onChange={(event) => this.setState({min: event})}/>
            </div>
            <div className="col-3">
              <Select label={lang.t('modals:form.data9.min')} value={this.state.sec} values={[{name: '00', value: '00'}, {name: '15', value: '15'}, {name: '30', value: '30'}, {name: '45', value: '45'}]} onChange={(event) => this.setState({sec: event})}/>
            </div>
            <div className="clear sv-10"></div>
            <button className="btn primary" onClick={() => this.scheduleEmail()}>Schedule email</button>
          </div>
        </div>
      )
    }
  }


  scheduleEmail() {
    this.hideModalSchedule()
    var disclaimer = '<p style="font-size: 11px; padding-top: 50px"><a href="https://ptmate.app/unsubscribe/'+global.uid+'">Unsubscribe</a></p>'
    var base = 0
    var flist = []
    if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
      base = global.spaceEmailUsage[Moment().format('MM-YYYY')]
    }
    var emails = []
    if(this.state.type === 'clients') {
      for(var item1 of global.clients) {
        if(this.state.selected.indexOf(item1.id) !== -1 && this.state.blacklist.indexOf(item1.data.email) === -1) {
          emails.push(item1.data.email)
          flist.push(item1.id)
        }
      }
      for(var item2 of global.clientsInactive) {
        if(this.state.selected.indexOf(item2.id) !== -1 && this.state.blacklist.indexOf(item2.data.email) === -1) {
          emails.push(item2.data.email)
          flist.push(item2.id)
        }
      }
    } else {
      for(var item3 of global.publicRequests) {
        if(this.state.selected.indexOf(item3.id) !== -1 && this.state.blacklist.indexOf(item3.data.email) === -1) {
          emails.push(item3.data.email)
          flist.push(item3.id)
        }
      }
    }
    
    var content = this.state.item.data.content
    if(this.state.editing) {
      content = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    }
    EventEmitter.dispatch('showMessage', "Email scheduled");
    
    Firebase.database().ref('/emails/'+global.uid+'/'+this.state.item.id).update({
      status: 'sent',
      clients: flist,
      date: parseInt(Moment(this.state.date+' '+this.state.min+':'+this.state.sec+':00', 'YYYY-MM-DD HH:mm:ss').format('X'))
    })
    Firebase.database().ref('/schedule/'+this.state.item.id).update({
      sender: global.userName,
      subject: this.state.subject,
      content: content+disclaimer,
      email: emails,
      timestamp: parseInt(Moment(this.state.date+' '+this.state.min+':'+this.state.sec+':00', 'YYYY-MM-DD HH:mm:ss').format('X'))
    })
    Firebase.database().ref('/admin/emails/'+global.uid).update({
      [Moment().format('MM-YYYY')]: base+emails.length
    })
  }



  // Display stuff ------------------------------------------------------------



  renderAdd() {
    if(this.state.add) {
      return <Select label={lang.t('admin:button.addclient')} value={this.state.client} values={this.state.available} onChange={(event) => this.addClient(event)}/>
    }
  }


  renderAddClient() {
    if(this.state.type === 'clients') {
      return (
        <div>
          <button className="btn tertiary width-12 add small" onClick={() => this.setState({add: !this.state.add})}>{this.state.add ? lang.t('common:button.cancel') : lang.t('admin:button.addclient')}</button>
          {this.renderAdd()}
        </div>
      )
    }
  }


  renderRemove(item) {
    if(this.state.item.data.status === 'draft') {
      return <button className="btn tertiary small rgt close" style={{fontSize: 10, marginTop: 7}} onClick={() => this.removeClient(item.id)}>{lang.t('common:button.remove')}</button>
    }
  }


  renderList() {
    if(this.state.selected.length === 0) {
      return (
        <div className="empty small">
          <div className="sv-30"></div>
          <h4>{this.state.type === 'clients' ? lang.t('admin:label.noclientsselected') : lang.t('admin:label.noleadsselected')}</h4>
          <div className="sv-30"></div>
        </div>
      )
    } else {
      var list = []
      if(this.state.type === 'clients') {
        for(var item1 of global.clients) {
          if(this.state.selected.indexOf(item1.id) !== -1) {
            list.push(item1)
          }
        }
        for(var item2 of global.clientsInactive) {
          if(this.state.selected.indexOf(item2.id) !== -1) {
            list.push(item2)
          }
        }
      } else {
        for(var item3 of global.publicRequests) {
          if(this.state.selected.indexOf(item3.id) !== -1) {
            list.push({
              id: item3.id,
              data: {
                name: item3.data.name,
                email: item3.data.email,
                image: '',
              }
            })
          }
        }
      }
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="client small static" key={item.id}>
              {HelperClients.getImage(item)}
              <p className="lft">{item.data.name}</p>
              {this.renderRemove(item)}
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderButtons() {
    if(this.state.item.data.status === 'draft') {
      return (
        <div>
          <div className="col-2">
            <button className="btn secondary" onClick={() => this.showModalSchedule()}>{lang.t('messaging:button.schedule')}</button>
          </div>
          <div className="col-2">
            <button className="btn secondary" onClick={() => this.setState({showModalTest: true})}>{lang.t('admin:button.sendtest')}</button>
          </div>
          <div className="col-4">
            <button className="btn primary" onClick={() => this.setState({showModalSend: true})}>{lang.t('admin:button.sendnow')}</button>
          </div>
          <div className="col-2">
            <button className="btn secondary" onClick={() => this.setState({editing: true})}>{lang.t('admin:button.editemail')}</button>
          </div>
          <div className="col-2">
            <button className="btn secondary" onClick={() => this.duplicateEmail()}>{lang.t('common:button.duplicate')}</button>
          </div>
          <div className="clear sv-30"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-40"></div>
        </div>
      )
    } else if(this.state.item.data.status === 'welcome') {
      return (
        <div>
          <div className="col-4">
            <button className="btn primary" onClick={() => this.setState({showModalClients: true})}>{lang.t('admin:button:sendtoaclient')}</button>
          </div>
          <div className="col-4">
            <button className="btn secondary" onClick={() => this.setState({showModalTest: true})}>{lang.t('admin:button:sendtest')}</button>
          </div>
          <div className="col-4">
            <button className="btn secondary" onClick={() => this.setState({editing: true})}>{lang.t('admin:button:editemail')}</button>
          </div>
          <div className="clear sv-30"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-40"></div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="col-3">&nbsp;</div>
          <div className="col-3">
            <button className="btn secondary" onClick={() => this.setState({showModalTest: true})}>{lang.t('admin:button.sendtest')}</button>
          </div>
          <div className="col-3">
            <button className="btn secondary" onClick={() => this.duplicateEmail()}>{lang.t('common:button.duplicate')}</button>
          </div>
          <div className="clear sv-30"></div>
          <button className="btn tertiary width-12 close" onClick={() => this.setState({showModalDelete: true})}>{lang.t('common:button.delete')}</button>
          <div className="clear sv-40"></div>
        </div>
      )
    }
  }


  renderCancel() {
    if(this.state.id !== '') {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.setState({editing: false})}>{lang.t('common:button.cancel')}</button>
        </div>
      )
    }
  }


  renderInfo() {
    if(this.state.id === '') {
      return (
        <div className="infobox primary mb-30">
          <p>{lang.t('admin:label.editrecipientlist')}</p>
        </div>
      )
    }
  }


  renderClients() {
    if(this.state.item.data.status === 'draft') {
      var title = lang.t('admin:label.recipients')
      if(this.state.type === 'clients' && this.state.item.data.clients !== undefined) {
        title = lang.t('admin:label.clientstobesentto')+' ('+this.state.item.data.clients.length+')'
      } else if(this.state.type === 'leads' && this.state.item.data.clients !== undefined) {
        title = lang.t('admin:label.leadstobesentto')+' ('+this.state.item.data.clients.length+')'
      }
      return (
        <div>
          <label className="lft mb-10">{title}</label>
          <button className="btn tertiary small rgt" onClick={() => this.setState({showModalFilter: true})}>{lang.t('admin:button.filter')}</button>
          <div className="clear"></div>
          {this.renderList()}
          {this.renderAddClient()}
        </div>
      )
    } else {
      return (
        <div>
          <label className="lft">{lang.t('admin:label.sentto')} {this.state.item.data.clients.length} {lang.t('admin:label.clients')}</label>
          <div className="attendance clear">
            {this.renderList()}
          </div>
        </div>
      )
    }
  }


  renderTimeButton() {
    if(this.state.timeEdit) {
      return <button className="btn primary" onClick={() => this.saveTime()}>{lang.t('common:button.savechanges')}</button>
    }
  }


  renderTimeCustom() {
    if(this.state.time === 99999) {
      return <InputNumber label={lang.t('admin:form.custom')} value={this.state.timeCustom} onChange={(event) => this.setState({timeCustom: event})}/>
    }
  }


  renderSidebar() {
    if(this.state.item.data.status === 'welcome') {
      return (
        <div className="sidebar">
          <h2 className="mb-30">{this.state.item.data.name}</h2>
          <InputRadio
            label={lang.t('admin:form.sendconnection')}
            value={this.state.time}
            info={lang.t('admin:form.sendconnection.text')}
            clickElement={(event) => this.updateTime(event)}
            values={[{name: '0', value: 0}, {name: '1', value: 1}, {name: '2', value: 2}, {name: '6', value: 6}, {name: '12', value: 12}, {name: '24', value: 24}, {name: 'Other', value: 99999}]}
          />
          {this.renderTimeCustom()}
          {this.renderTimeButton()}
        </div>
      )
    } else {
      return (
        <div className="sidebar">
          <h2 className="mb-30">{this.state.item.data.name}</h2>
          <InfoData label={lang.t('admin:label.subject')} value={this.state.item === null ? '' : this.state.item.data.subject} small={true}/>
          <InfoData label={lang.t('admin:label.status')} value={this.getStatus()} small={true}/>
          {this.renderClients()}
        </div>
      )
    }
  }


  renderContent() {
    if(this.state.editing) {
      return (
        <div className="content form-sidebar">
          <div className="col-12">
            {this.renderInfo()}
            <InputText label={lang.t('admin:form.name')+"*"} value={this.state.name} error={this.state.error[0]} errormsg={lang.t('admin:error.name2')} onChange={(event) => this.setState({name: event})}/>
            <InputText label={lang.t('admin:label.subject')+"*"} value={this.state.subject} error={this.state.error[1]} errormsg={lang.t('admin:error.subject')} onChange={(event) => this.setState({subject: event})}/>
            <div className="clear sv-20"></div>
            <Editor
              editorState={this.state.editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onEditorStateChange}
            />
            <div className="clear sv-30"></div>
            <div className="col-3">&nbsp;</div>
            <div className="col-6">
              <button className="btn primary" onClick={() => this.checkCreateEmail()}>{this.state.id === '' ? lang.t('admin:button.createemail') : lang.t('common:button.savechanges')}</button>
              {this.renderCancel()}
            </div>
            <div className="clear sv-40"></div>
          </div>
        </div>
      )
    } else {
      if(this.state.item !== null) {
        return (
          <div className="content mt-20">
            <div className="col-8 withsidebar">
              <div className="box email-content" style={{color: 'initial'}}>
                {ReactHtmlParser (this.state.item === null ? '' : this.state.item.data.content)}
              </div>
            </div>
            <div className="col-4">
              {this.renderSidebar()}
            </div>
            <div className="clear sv-40"></div>
            {this.renderButtons()}
          </div>
        )
      }
    }
  }


  renderTitle() {
    if(this.state.id === '') {
      return lang.t('admin:title.newemail')
    } else {
      if(this.state.item !== null) {
        if(this.state.item.data.status === 'welcome') {
          return lang.t('admin:title.welcomeemail')
        } else {
          return lang.t('admin:title.email')
        }
      } else {
        return lang.t('admin:title.email')
      }
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          {this.renderContent()}
          <Header title={this.renderTitle()} link={ROUTES.ADMINEMAILS}/>
          <Navigation active='admin' />
          {this.renderModalSchedule()}
          <ModalMessageDouble type='saveform' show={this.state.showModalSave} onHide={() => this.closeSave()} clickMainButton={() => this.attemptSave()} clickSecondaryButton={() => this.cancelSave()}/>
          <ModalForm type='testemail' show={this.state.showModalTest} onHide={() => this.hideModals()} value1={this.state.test} updateValue1={(event) => this.setState({test: event})} clickMainButton={() => this.sendTest()}/>
          <ModalMessageSimple type='sendemail' var={this.state.selected.length+(this.state.type === 'clients' ? ' clients' : ' leads')} show={this.state.showModalSend} onHide={() => this.hideModals()} clickMainButton={() => this.sendEmail()}/>
          <ModalMessageSimple type='deleteemail' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteEmail()}/>
          <ModalFilter show={this.state.showModalFilter} value={this.state.filter} stype={this.state.type} onHide={() => this.hideModals()} updateFilter={(event) => this.setState({filter: event})} updateType={(event) => this.setState({type: event})} clickMainButton={() => this.saveFilter()}/>
          <ModalClients clients={global.clients} title={lang.t('modals:clients.title.sendwelcomeemail')} show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.sendClient(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='forms-locked' type='simple'/>
          </div>
          <Header title={lang.t('admin:title.newemail')} link={'/overview'}/>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(NewEmailPage)));