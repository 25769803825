import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperBusiness from '../../helper/Business';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class HeaderFormTabs extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      title: props.title ?? '',
      link: props.link ?? '/overview',
      type: props.type,
      active: props.active ?? ''
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      title: props.title ?? '',
      link: props.link ?? '/overview',
      type: props.type,
      active: props.active ?? ''
    }
  }


  renderRecLink() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <Link to={ROUTES.NEWRECEVENT} className={this.state.active === 'event' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:form.events')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }
  
  
  render() {
    if(this.state.type === 'clients') {
      return (
        <div className="header withtabs">
          <div className="content">
            <Link to={this.state.link} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.state.title}</h2>
            <Link to={ROUTES.NEWCLIENT} className={this.state.active === 'add' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:form.addclient')}</p>
              <div className="bg"></div>
            </Link>
            <Link to={ROUTES.NEWCLIENTIMPORT} className={this.state.active === 'import' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:form.importclients')}</p>
              <div className="bg"></div>
            </Link>
          </div>
        </div>
      )
    } else if(this.state.type === 'sessions') {
      return (
        <div className="header withtabs">
          <div className="content">
            <Link to={this.state.link} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.state.title}</h2>
            <div className="tabs">
            <Link to={ROUTES.NEWGROUP} className={this.state.active === 'group' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:form.groupsession')}</p>
              <div className="bg"></div>
            </Link>
            <Link to={ROUTES.NEWSESSION} className={this.state.active === '11' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:form.11session')}</p>
              <div className="bg"></div>
            </Link>
            <Link to={ROUTES.NEWEVENT} className={this.state.active === 'event' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:form.event')}</p>
              <div className="bg"></div>
            </Link>
            </div>
          </div>
        </div>
      )
    } else if(this.state.type === 'recurring') {
      return (
        <div className="header withtabs">
          <div className="content">
            <Link to={this.state.link} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.state.title}</h2>
            <div className="tabs">
              <Link to={ROUTES.NEWRECGROUP} className={this.state.active === 'group' ? 'tab active' : 'tab'}>
                <p>{lang.t('nav:form.groupsessions')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={ROUTES.NEWRECSESSION} className={this.state.active === '11' ? 'tab active' : 'tab'}>
                <p>{lang.t('nav:form.11sessions')}</p>
                <div className="bg"></div>
              </Link>
              {this.renderRecLink()}
            </div>
          </div>
        </div>
      )
    } else if(this.state.type === 'simple') {
      return (
        <div className="header">
          <div className="content">
            <Link to={this.state.link} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2>{this.state.title}</h2>
          </div>
        </div>
      )
    }
  }
}


export default withTranslation(['nav'])(HeaderFormTabs);