import Moment from 'moment';
import Firebase from 'firebase';
import Connector from '../data/Connector';
import lang from 'i18next';
import EventEmitter from '../helper/Emitter';



class HelperPage {

  initialize(title, page) {
    Connector.setUser()
    document.title = title
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = ''
    global.currentPage = page
    global.linkCli = ''
    global.linkCal = ''
    global.linkPro = ''
    global.linkBil = ''
    global.linkAdm = ''

    global.currentDate = Moment().format('DD/MM/YYYY')
    global.currentTime = '05'
  }


  initializeForm(title) {
    Connector.setUser()
    document.title = title
    window.Intercom("update")
    window.scrollTo(0, 0)
    setTimeout(() => {
      document.title = title
    }, 1000);
  }


  checkMessage() {
    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }
  }


  signOut() {
    global.uid = "";
    global.uidUser = "";
    global.userName = '';
    global.userBusiness = '';
    global.userEmail = '';
    global.userBday = Moment();
    global.userPhone = '';
    global.userStripe = '';
    global.userMessage = 0;
    global.userCard = '';
    global.userSubPlanId = '';
    global.userSubPlan = 'spark';
    global.userSubId = '';
    global.userSubStatus = 'trialing';
    global.userSubBilling = '';
    global.userSubCancel = false;
    global.userSubEnd = 0;
    global.userSubItemId = '';
    global.userStripeConnect = '';
    global.userLimitBooking = false;
    global.userStripeBank = '';
    global.userStripeAddress = '';
    global.timerStart = 5;
    global.userImageDate = Moment();
    global.userTemplates = [];
    global.userImage = '';
    global.userEmailsSent = [];
    global.userVerified = false;
    global.userOnboarding = ['', ''];
    global.userBadges = [];
    global.userComments = true;
    global.userShowBooked = true;
    global.userSubdomain = '';

    global.cards = [];
    global.coupon = [];
    global.userSignup = false;
    global.spaceTheme = 'blue';

    global.currentMonth = 1;
    global.currentDay = parseInt(Moment().format('D'));
    global.currentWeek = parseInt(Moment().format('w'));
    global.currentCalendarView = 'week';
    global.currentDate = Moment().format('DD/MM/YYYY');
    global.currentTime = '05';

    global.userRole = '';
    global.userStaff = [];
    global.spaces = [];
    global.clientPlans = [];

    global.publicData = [];
    global.publicRequests = [];
    global.showOnboarding = false;
    global.userConnectClient = null;
    global.clientToken = '';
    global.clientTokens = [];

    // Data arrays

    global.clients = [];
    global.clientGroups = [];
    global.clientsImages = [];
    global.clientsInactive = [];
    global.sessions = [];
    global.archive = [];
    global.events = []
    global.clientBest = [];
    global.clientBestGroup = [];
    global.chats = [];
    global.chatsGroup = [];
    global.activity = [];

    global.sessionsTraining = [];
    global.archiveTraining = [];
    global.recurring = [];

    global.programs = [];
    global.programsClient = [];
    global.plans = [];
    global.exercises = [];

    global.payments = [];
    global.invoices = [];
    global.products = [];

    global.userLog = [];
    global.syncDate = 0;
    global.maxClients = 0;
    global.maxPrograms = 0;
    global.maxPlans = 0;
    global.maxTrainers = 0;

    global.userDataLoaded = false;
    Firebase.auth().signOut() 
  }

}


const b = new HelperPage();
export default b;