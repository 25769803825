import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Moment from 'moment';
import Firebase from 'firebase';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminExercisePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      exercises: [],
      title: 'Exercise',
      desc: '',
      cat: 0,
      type: 0,
      user: '',
      muscles: [],
      values: ['', ''],
      variations: [],
      musclesEdit: [],
      files: [null],
      error: false,
      errorText: '',
      editing: false,
      showOverlay: false,
      hidden: 'hidden',
      custImages: []
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Movement Type Details - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';
    Connector.loadExercisesAdmin()
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    var id = ''
    var user = ''
    var title = 'Exercise'
    var desc = ''
    var cat = 0
    var type = 0
    var muscles = []
    var tmp = []
    var tmp2 = []
    var tags = []

    var arr = this.props.location.pathname.split('/')
    id = arr[arr.length-1]

    for(var item of global.exercises) {
      if(item.id === id) {
        title = item.data.name
        desc = item.data.desc
        muscles = item.data.muscles
        cat = item.data.cat
        type = item.data.type
        user = item.data.user
        tags = []
        if(item.data.subTags !== undefined) {
          tags = item.data.subTags
        }
        for(var i=0; i<item.data.subTitles.length; i++) {
          var tag = ''
          if(tags.length > i) {
            tag = tags[i]
          }
          tmp.push({
            id: item.data.subIds[i],
            name: item.data.subTitles[i],
            desc: item.data.subDescs[i],
            tool: parseInt(item.data.subTools[i]),
            image: item.data.subImages[i],
            video: item.data.subVideos[i],
            tags: tag,
          })
          tmp2.push(null)
        }
      }
    }

    this.setState({
      id: id,
      user: user,
      title: title,
      desc: desc,
      muscles: muscles,
      musclesEdit: muscles,
      exercises: tmp,
      cat: cat,
      type: type,
      values: [title, desc],
      variations: tmp,
      files: tmp2
    })
  }


  setImage(file, index) {
    var tmp = this.state.files
    var tmp2 = this.state.variations
    tmp[index] = file
    tmp2[index].image = this.state.id+'-'+Moment().format('DDMMYYYYHHmmssSSS')+index+'.jpg'
    this.setState({
      files: tmp,
      variations: tmp2
    })
  }


  displayImage(index) {
    if(this.state.files[index] === null) {
      if(this.state.variations[index].image === '') {
        return (
          <div className="ex-image"></div>
        )
      } else if(this.state.variations[index].image.indexOf('adm-') !== -1) {
        return (
          <div className="ex-image" style={{backgroundImage: 'url(/img/exercises/'+this.state.variations[index].image+'.jpg)'}}></div>
        )
      } else {
        return (
          <div className="ex-image" style={{backgroundImage: 'url('+this.state.variations[index].image+')'}}></div>
        )
      }
    } else {
      return (
        <div className="ex-image" style={{backgroundImage: 'url('+this.state.variations[index].image+')'}}></div>
      )
    }
  }



   // Interactive stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  setCat(event) {
    this.setState({
      cat: event.target.value
    })
  }


  setType(event) {
    this.setState({
      type: event.target.value
    })
  }


  addVariation() {
    var tmp = this.state.variations
    var tmp2 = this.state.files
    tmp.push({
      name: '',
      desc: '',
      tool: 0,
      image: '',
      video: '',
      tags: '',
    })
    tmp2.push(null)
    this.setState({
      variations: tmp,
      files: tmp2,
    })
  }


  deleteVariation(index) {
    var tmp = this.state.variations
    var tmp2 = this.state.files
    tmp.splice(index, 1)
    tmp2.splice(index, 1)
    this.setState({
      variations: tmp,
      files: tmp2,
    })
  }


  onChangeName(event, index) {
    var tmp = this.state.variations
    tmp[index].name = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeDesc(event, index) {
    var tmp = this.state.variations
    tmp[index].desc = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeTags(event, index) {
    var tmp = this.state.variations
    tmp[index].tags = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeVideo(event, index) {
    var tmp = this.state.variations
    tmp[index].video = event.target.value
    this.setState({
      variations: tmp
    })
  }


  setTool(event, index) {
    var tmp = this.state.variations
    tmp[index].tool = event.target.value
    this.setState({
      variations: tmp
    })
  }


  getMuscles(index) {
    if(this.state.musclesEdit.indexOf(index) !== -1) {
      return "ex-muscle active"
    } else {
      return "ex-muscle"
    }
  }


  setMuscles(index) {
    var add = true
    var tmp = this.state.musclesEdit
    for(var i=tmp.length-1; i>=0; i--) {
      if(tmp[i] === index) {
        add = false
        tmp.splice(i, 1)
      }
    }
    if(add) {
      tmp.push(index)
    }
    this.setState({
      musclesEdit: tmp
    })
  }


  revertEditing() {
    this.configureData()
    this.setState({
      editing: false
    })
  }


  deleteExercise() {
    this.hideOverlay()
    Firebase.database().ref('/exercises/'+this.state.id).remove()
    .then((data)=>{
      global.message = 'Movement type successfully deleted'
      this.props.history.push(ROUTES.EXERCISES)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Delete overlay ------------------------------------------------------------



  showOverlay() {
    this.setState({showOverlay: true})
    setTimeout(() => {
      this.setState({hidden: ''});
    }, 100);
  }


  hideOverlay() {
    this.setState({hidden: 'hidden'})
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderOverlay() {
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box text">
            <h3>Delete exercise</h3>
            <button className="btn tertiary small close" onClick={() => this.hideOverlay()}>Close</button>
            <p className="clear">Do you want delete this movement type with all its movements? Programs using it won't be affected.</p>
            <button className="btn primary" onClick={() => this.deleteExercise()}>Delete movement type</button>
          </div>
        </div>
      )
    }
  }



  // Create exercises ------------------------------------------------------------



  checkUpdateExercise() {
    var passed = true
    if(this.state.values[0] === '' || this.state.values[1] === '' || this.state.muscles.length === 0) {
      passed = false
    }
    for(var item of this.state.variations) {
      if(item.name === '') {
        passed = false
      }
    }
    if(passed) {
      this.updateExercise()
    } else {
      this.showError()
    }
  }


  updateExercise() {
    var ids = []
    var names = []
    var tools = []
    var descs = []
    var images = []
    var videos = []
    var tags = []
    for(let item of this.state.variations) {
      if(item.id === '' || item.id === undefined) {
        var key = Firebase.database().ref('/exercises/'+this.state.id).push()
        ids.push(key.key)
      } else {
        ids.push(item.id)
      }
      names.push(item.name)
      tools.push(parseInt(item.tool))
      descs.push(item.desc)
      images.push(item.image)
      videos.push(item.video)
      tags.push(item.tags)
    }
    this.uploadImages(this.state.id, images)
    Firebase.database().ref('/exercises/'+this.state.id).update({
      name: this.state.values[0],
      desc: this.state.values[1],
      muscles: this.state.muscles,
      cat: parseInt(this.state.cat),
      type: parseInt(this.state.type),
      subIds: ids,
      subTitles: names,
      subDescs: descs,
      subTools: tools,
      subImages: images,
      subVideos: videos,
      subTags: tags,
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', 'Movement type successfully updated')
      setTimeout(() => {
        this.revertEditing()
      }, 500);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  showError() {
    var txt = ''
    if(this.state.values[0] === '') {
      txt = 'movement type name'
    }
    if(this.state.values[1] === '') {
      if(txt !== '') { txt += ', ' }
      txt += 'movement type description'
    }
    if(this.state.muscles.length === 0) {
      if(txt !== '') { txt += ', ' }
      txt += 'Select at least 1 muscle'
    }
    for(var item of this.state.variations) {
      if(item.name === '') {
        if(txt !== '') { txt += ', ' }
        txt += 'Name all your movements'
      }
    }
    this.setState({
      error: true,
      errorText: txt
    })
  }


  uploadImages(key, ids) {
    for(var i=0; i<ids.length; i++) {
      if(this.state.files[i] !== null) {
        const sr = Firebase.storage().ref()
        const fr = sr.child('images/exercises/'+ids)
        fr.put(this.state.files[i]).then(() => {
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    } 
  }


  updateUser() {
    Firebase.database().ref('/exercises/'+this.state.id).update({
      user: "admin"
    })
    EventEmitter.dispatch('showMessage', 'Movement is now public');
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    if(this.state.error) {
      return (
        <div className="inline-message red">
          <strong>Please review the following</strong><br/>{this.state.errorText}
        </div>
      )
    }
  }


  renderDelete(index) {
    if(index !== 0) {
      return (
        <button className="btn tertiary close rgt" onClick={() => this.deleteVariation(index)}>Delete</button>
      )
    }
  }


  renderExercisesEdit() {
    return (
      <div>
        {this.state.variations.map((item, index) => (
          <div key={index}>
            <div className="box exercise">
              <div className="col-3">
                {this.displayImage(index)}
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], index);}}/>
              </div>
              <div className="col-4 form">
                <label>Name*</label>
                <input type="text" value={this.state.variations[index].name} onChange={(val) => this.onChangeName(val, index)}/>
                <label>Video</label>
                <input type="text" value={this.state.variations[index].video} onChange={(val) => this.onChangeVideo(val, index)}/>
                <label>Equipment</label>
                <select value={this.state.variations[index].tool} onChange={event => this.setTool(event, index)}>
                  {global.exTools.map((item, index) => (
                    <option key={index} value={index}>{item} ({global.exToolsCount[index]})</option>
                  ))}
                </select>
                <label>Tags (Write numbers with a comma, e.g. "0,1," etc)</label>
                <input type="text" value={item.tags} placeholder = 'Write numbers with a comma, e.g. 0,1, etc' onChange={(val) => this.onChangeTags(val, index)}/>
                <p style={{fontSize:10, marginTop: 5, marginBottom: 0}}>0 - Mobility<br/>1 - Activation<br/>2 - Strength<br/>3 - Cardiovascular<br/>4 - Skill<br/>5 - Warm up<br/>6 - Accessory<br/>7 - Stability<br/>8 - MetCon<br/>9 - Stretch</p>
              </div>
              <div className="col-4 form">
                <label>Description</label>
                <textarea className="big" value={this.state.variations[index].desc} onChange={(val) => this.onChangeDesc(val, index)}></textarea>
              </div>
              <div className="col-1">
                {this.renderDelete(index)}
              </div>
              <div className="clear"></div>
            </div>
            <div className="space-20"></div>
          </div>
        ))}
      </div>
    )
  }


  getImg(image) {
    var label = ''
    for(var item of this.state.custImages) {
      if(item.image === image) {
        label = item.url
      }
    }
    if(label === '') {
      Firebase.storage().ref().child(image).getDownloadURL().then((url) => {
        var tmp = this.state.custImages
        tmp.push({
          image: image,
          url: url
        })
        this.setState({
          custImages: tmp
        })
      }).catch((error) => {})
    }
    return label
  }


  renderImage(img) {
    if(img === '') {
      return (
        <div className="image"></div>
      )
    } else if(img.indexOf('adm-') !== -1) {
      return (
        <div className="image" style={{backgroundImage: 'url(/img/exercises/'+img+'.jpg)'}}></div>
      )
    } else {
      return (
        <div className="image" style={{backgroundImage: 'url('+this.getImg('images/exercises/'+img)+')'}}></div>
      )
    }
  }


  renderVideo(item) {
    if(item.video !== '') {
      return (
        <div style={{paddingTop: 20}}>
          <a href={item.video} target="_blank" rel="noopener noreferrer" className="btn tertiary">Watch video</a>
        </div>
      )
    }
  }


  formatDesc(desc) {
    var label = desc.split('\n')
    return (
      <p>
        {label.map(item => (
          <span key={item}>{item}<br/></span>
        ))}
      </p>
    )
    
  }

  renderExercises() {
    if(this.state.exercises.length !== 0) {
      var list = this.state.exercises
      list.sort((a, b) => a.name.localeCompare(b.name))
      return (
        <div>
        {list.map(item => (
          <div className="box list exercise" key={item.id}>
            <div className="col-4">
              {this.renderImage(item.image)}
            </div>
            <div className="col-8">
              <h3>{item.name}</h3>
              <div className="blue"> 
                <p>{global.exTools[item.tool]}</p>
              </div>
              <p>{this.formatDesc(item.desc)}</p>
              {this.renderVideo(item)}
            </div>
            <div className="clear"></div>
          </div>
        ))}
        </div>
      )
    }
  }

   
  renderEditing() {
    var list1 = global.exCats
    var list2 = global.exTypes
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content">
          {this.renderError()}
          <div className="box">
            <div className="col-6">
              <div className="form">
                <label>Name*</label>
                <input type="text" value={this.state.values[0]} name="0" onChange={this.onChange}/>
                <label>Description*</label>
                <input type="text" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              </div>
            </div>
            <div className="col-6">
              <div className="form">
                <label>Category</label>
                <select value={this.state.cat} onChange={event => this.setCat(event)}>
                  {list1.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
                <label>Type</label>
                <select value={this.state.type} onChange={event => this.setType(event)}>
                  {list2.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clear space-20"></div>
            <div className="col-12">
              <label>Muscles*</label>
              <div className="ex-muscles clear">
                {global.exMuscles.map((item, index) => (
                  <div className={this.getMuscles(index)} key={index} onClick={() => this.setMuscles(index)}>
                    <img src={global.exMusclesImages[index]} alt={item}/>
                    <p>{item} </p>
                  </div>
                ))}
                <div className="clear"></div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="filters">
            <h2>Movements</h2>
            <div className="clear"> </div>
          </div>
          {this.renderExercisesEdit()}
          <div className="space-10"></div>
          <button className="btn tertiary add width-12" onClick={() => this.addVariation()}>Add movement</button>
          <div className="space-60"></div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.checkUpdateExercise()}>Update movement type</button>
          </div>
          <div className="space-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.showOverlay()}>Delete movement type</button>
          <div className="space-40"></div>
           <button className="btn tertiary close width-12" onClick={() => this.revertEditing()}>Cancel</button>
          <div className="space-20"></div>
        </div>
        <div className="header">
          <Link to={'/admin/movements'} className="back"></Link>
            <h1>{this.state.title}</h1>
        </div>
        <Navigation active='admin' />
        {this.renderOverlay()}
      </div>
    )
  }


  renderButton() {
    if(this.state.user === 'admin') {
      return (
        <div>
          <button className="btn tertiary width-12 edit" onClick={() => this.setState({editing: true})}>Edit movement type</button>
          <div className="space-20"></div>
        </div>
      )
    } else {
      return (
        <div>
          <button className="btn primary" onClick={() => this.setState({editing: true})}>Edit movement type</button>
          <div className="space-20"></div>
          <button className="btn tertiary width-12 edit" onClick={() => this.updateUser()}>Convert to common movement type</button>
          <div className="space-20"></div>
        </div>
      )
    }
  }


  renderBase() {
    if(this.state.editing) {
      return (
        this.renderEditing()
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <div className="exercise-info">
              <div className="clear"></div>
              <p>{this.state.desc}</p>
              <div className="muscles">
                {this.state.muscles.map(item => (
                  <div className="muscle" key={item}>
                    <img src={global.exMusclesImages[item]} alt={global.exMuscles[item]}/>
                    <p>{global.exMuscles[item]}</p>
                  </div>
                ))}
              </div>
            </div>
            {this.renderExercises()}
            <div className="space-20"></div>
            {this.renderButton()}
          </div>
          <div className="header">
            <Link to={'/admin/movements'} className="back"></Link>
            <h1>{this.state.title}</h1>
          </div>
          <Navigation active='admin' />
        </div>
      )
    }
  }


  render() {
    if(global.uidUser === 'L81v7rXOuTPWqVFfU89icsSOdLx1' || global.uidUser === 'EjXQG2v3hjcYr32l6oV2actRiBF2') {
      return (
        <AuthUserContext.Consumer>
          {authUser => (
            this.renderBase()
          )}
        </AuthUserContext.Consumer>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(AdminExercisePage));


