import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import InputText from '../../components/Form/input-text';
import InputFile from '../../components/Form/input-file';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import InputRadio from '../../components/Form/radio';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalDocument extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      doc: props.doc ?? '',
      client: '',
      type: 'client',
      link: '',
      value: '',
      date: Moment().format('X'),
      error: [false, false, false],
      documents: props.documents ?? [],
      file: null,
      pdate: props.date ?? Moment().format('YYYY-MM-DD'),
      min: '00',
      sec: '00',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    var date = Moment().format('YYYY-MM-DD')
    if(props.date !== undefined) {
      date = Moment(props.date, 'X').format('YYYY-MM-DD')
    }
    var min = Moment().format('HH')
    var s = parseInt(Moment().format('m'))
    var sc = Math.ceil(s/60*12)
    var sec = sc*5
    if(sec < 10) {
      sec = '0'+sec
    }
    if(sec === '60') {
      sec = '00'
    }

    this.setState({
      show: props.show,
      doc: props.doc ?? '',
      documents: props.documents ?? [],
      pdate: date,
      min: min,
      sec: sec,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    var link = ''
    var val = ''
    var dat = Moment().format('X')
    var cl = ''
    if(this.state.doc !== '') {
      for(var item of this.state.documents) {
        if(item.id === this.state.doc) {
          val = item.data.name
          dat = item.data.date
          cl = item.client
          link = 'documents/'+global.uid+'/'+item.client+'/'+item.id+'.'+item.data.ext
          if(item.client === undefined) {
            cl = ''
            link = 'documents/'+global.uid+'/'+item.id+'.'+item.data.ext
          }
        }
      }
    }
    this.setState({
      show: true,
      date: dat,
      value: val,
      client: cl,
      link: link,
      error: [false, false, false]
    })
    if(link !== "") {
      Firebase.storage().ref().child(link).getDownloadURL().then((url) => {
        this.setState({
          link: url
        })  
      })
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  setDocument(file) {
    var tmp = this.state.error
    tmp[1] = false
    if(file.size > 1000000) {
      tmp[1] = true
    }
    this.setState({
      file: file,
      date: Moment().format('X'),
      error: tmp
    })
  }


  uploadDocument() {
    var tmp = this.state.error
    tmp[0] = false
    tmp[2] = false
    if(this.state.value === '') {
      tmp[0] = true
    }
    if(this.state.client === '' && this.state.type === 'client') {
      tmp[2] = true
    }
    this.setState({
      error: tmp
    })
    if(!tmp[0] && !tmp[1] && !tmp[2]) {
      this.hide()
      this.props.clickUpload([this.state.type, this.state.value, this.state.file, this.state.client, this.state.pdate+' '+this.state.min+':'+this.state.sec])
    }
  }

  updateDocument() {
    var tmp = this.state.error
    tmp[0] = false
    if(this.state.value === '') {
      tmp[0] = true
    }
    this.setState({
      error: tmp
    })
    if(!tmp[0]) {
      this.hide()
      this.props.clickUpdate([this.state.value, this.state.client])
    }
  }


  deleteDocument() {
    this.hide()
    this.props.clickDelete(this.state.client)
  }


  renderClientName(id, type) {
    var label = ''
    for(var item1 of global.clients) {
      if(item1.id === id) {
        label = item1.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    if(type === 'public') {
      label = lang.t('modals:docuemnt.publiclibraryfile')
    }
    return label
  }


  renderDocType() {
    if(this.state.client !== '') {
      return (
        <div>
          <label>Client</label>
          <p>{this.renderClientName(this.state.client)}</p>
        </div>
      )
    }
  }


  renderDate() {
    if(this.state.type !== 'client') {
      return (
        <div>
          <div className="col-6" style={{paddingLeft: 0}}>
            <InputDate label={'modals:form.data9.makeavailable'} value={this.state.pdate} format='YYYY-MM-DD' min={Moment().format('YYYY-MM-DD')} onChange={(event) => this.setState({date: Moment(event).format('YYYY-MM-DD')})}/>
          </div>
          <div className="col-3">
            <Select label={lang.t('modals:form.data9.hour')} value={this.state.min} values={[{name: '00', value: '00'}, {name: '01', value: '01'}, {name: '02', value: '02'}, {name: '03', value: '03'}, {name: '04', value: '04'}, {name: '05', value: '05'}, {name: '06', value: '06'}, {name: '07', value: '07'}, {name: '08', value: '08'}, {name: '09', value: '09'}, {name: '10', value: '10'}, {name: '11', value: '11'}, {name: '12', value: '12'}, {name: '13', value: '13'}, {name: '14', value: '14'}, {name: '15', value: '15'}, {name: '16', value: '16'}, {name: '17', value: '17'}, {name: '18', value: '18'}, {name: '19', value: '19'}, {name: '20', value: '20'}, {name: '21', value: '21'}, {name: '22', value: '22'}, {name: '23', value: '23'}]} onChange={(event) => this.setState({min: event})}/>
          </div>
          <div className="col-3" style={{paddingRight: 0}}>
            <Select label={lang.t('modals:form.data9.min')} value={this.state.sec} values={[{name: '00', value: '00'}, {name: '05', value: '05'}, {name: '10', value: '10'}, {name: '15', value: '15'}, {name: '20', value: '20'}, {name: '25', value: '25'}, {name: '30', value: '30'}, {name: '30', value: '30'}, {name: '35', value: '35'}, {name: '45', value: '45'}, {name: '50', value: '50'}, {name: '55', value: '55'}]} onChange={(event) => this.setState({sec: event})}/>
          </div>
        </div>
      )
    }
  }


  renderDocumentClient() {
    if(this.state.type === 'client') {
      var list = [{value: '', name: '- '+lang.t('modals:document.selectaclient')+' -'}]
      for(var item of global.clients) {
        list.push({value: item.id, name: item.data.name})
      }
      return <Select label={lang.t('modals:document.client.label')} value={this.state.client} values={list} error={this.state.error[2]} errormsg={lang.t('modals:document.selectaclient')} onChange={(event) => this.setState({client: event})}/>
    } else {
      return (
        <div className="form-item">
          <p className="small" style={{padding: 0}}>{lang.t('modals:document.info2')}</p>
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      if(this.state.doc === '') {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:document.title.upload')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <p className="clear mb-30">{lang.t('modals:document.info1')}</p>
              <InputRadio
                label=''
                value={this.state.type}
                clickElement={(event) => this.setState({type: event})}
                values={[{name: lang.t('modals:document.radio.label1'), value: 'client'}, {name: lang.t('modals:document.radio.label2'), value: 'public'}]}
              />
              {this.renderDocumentClient()}
              <InputText label={lang.t('modals:document.name.label')} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('modals:document.name.error')} onChange={(event) => this.setState({value: event})}/>
              <InputFile label='File' error={this.state.error[1]} errormsg={lang.t('modals:document.file.error')} onChange={(event) => this.setDocument(event)}/>
              {this.renderDate()}
              <div className="clear sv-20"></div>
              <button className="btn primary" onClick={() => this.uploadDocument()}>{lang.t('modals:document.button.upload')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div className={'overlay '+this.state.hidden}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
              <h2 className="mb-20 lft">{lang.t('modals:document.title')}</h2>
              <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
              <div className="clear"></div>
              {this.renderDocType()}
              <InputText label={lang.t('modals:document.name.label')} value={this.state.value} error={this.state.error[0]} errormsg={lang.t('modals:document.name.error')} onChange={(event) => this.setState({value: event})}/>
              <button className="btn tertiary small" onClick={() => this.updateDocument()} style={{marginTop: 5}}>{lang.t('modals:document.button.update')}</button>
              <div className="clear sv-40"></div>
              <a href={this.state.link} target="_blank" rel="noopener noreferrer" className="btn primary">{lang.t('modals:document.button.viewdocument')}</a>
              <div className="sv-20"></div>
              <button className="btn tertiary width-12" onClick={() => this.deleteDocument()}>{lang.t('modals:document.button.delete')}</button>
            </div>
          </div>
        )
      }
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalDocument);