import React from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import HelperBusiness from './Business';
import { Link } from 'react-router-dom';
import lang from 'i18next';



class HelperClients {


  // Create templates

  createTemplate(type) {
    var sections = []

    if(type === 'consent') {
      sections.push({
        label: 'I understand that I am entering into a personal training agreement with [BUSINESS NAME].',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'My training is intended to enhance my physical and mental wellbeing including body composition, cardiovascular strength and endurance, muscle power and strength, flexibility, and other intended purposes such as;',
        type: 'text',
        mandatory: false
      })
      sections.push({
        label: 'I have informed my trainer of all known physical conditions, medical conditions and medications, and will keep them updated of any changes to my health that may occur in the future.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'You agree that by participating in these physical exercise sessions or personal training activities, you do so entirely at your own risk. This includes, without limitation;',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'A. your use of all amenities and equipment in the facility and any offsite location and your participation in any activity, class, program, personal training or instruction.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'B. The sudden and unforeseen malfunctioning of any equipment',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'C. Our instruction, training, supervision, or recommendations. You agree that you are voluntarily participating in these activities and use of these facilities and premises and assume all risks of injury. You expressly agree to release and discharge your personal trainer or instructor, and from any and all claims or causes of action except in the case of negligence.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'D. This waiver and release of liability includes, without limitation, all injuries to you which may occur, except in the case of negligence.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'E. By agreeing to this document you acknowledge that you have carefully read this waiver and release and fully understand that it is a release of liability of any personal injury or property damage or loss action except in the case of proven negligence.',
        type: 'yesno',
        answer1: '',
        answer2: 'yes',
        mandatory: false
      })
      sections.push({
        label: 'DATE:',
        type: 'text',
        mandatory: false
      })
    }

    if(type === 'payment') {
      sections.push({
        label: "This allows my trainer [TRAINER'S NAME] to charge my Credit or Debit Card for the purchase of packs of training sessions and to set up direct debits. Direct debits can be set weekly, fortnightly or monthly, depending on the specific product.",
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Card Details',
        type: 'header',
        mandatory: false
      })
      sections.push({
        label: 'Name on card',
        type: 'text',
        mandatory: false
      })
      sections.push({
        label: 'Card number',
        type: 'text',
        mandatory: false
      })
      sections.push({
        label: 'Expiry',
        type: 'text',
        mandatory: false
      })
      sections.push({
        label: 'CVC',
        type: 'text',
        mandatory: false
      })
      sections.push({
        label: 'I have received and agree to the terms on the PT Mate Personal Training payment and Cancellation Policy document.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: "I have provided my card details and agree to my understanding to give [TRAINER'S NAME] at [BUSINESS NAME] to process my training fees",
        type: 'yesno',
        answer1: 'yes',
        answer2: 'yes',
        mandatory: false
      })
      sections.push({
        label: 'Select all points to recognize your understanding',
        type: 'selection',
        options: [
          'Credit Card fees / Default fees / Transaction fees apply',
          'For any changes to direct debit account ensure 7 days notice via email to',
          'No refunds for purchase of Personal training unless error on trainers behalf',
          'I have received and read the Cancellation policy document and all following information',
          'By signing you are acknowledging that you have received and agreed with the policies on the cancellation document.'
        ],
        multiple: true,
        mandatory: false
      })
      sections.push({
        label: 'DATE:',
        type: 'text',
        mandatory: false
      })
      sections.push({
        label: 'For any changes to direct debit account ensure 7 days notice via email to [EMAIL ADDRESS].',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Fitness Business Payment and Cancellation Policy',
        type: 'header',
        mandatory: false
      })
      sections.push({
        label: 'I hope that you are enjoying your training so far and that I am providing you with the knowledge and tools to help you in achieving your training goals. To better assist you with your personal training experience can I ask that you take note of the following information to ensure you get the best out of your training.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Personal training can be purchased and paid for via Bundle Packs via PT Mate.app with Stripe By choosing to purchase a bundle pack it gives you the flexibility to change, add or decrease your training schedule each week.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Membership',
        type: 'header',
        mandatory: false
      })
      sections.push({
        label: 'By choosing the DD option you no longer have to remember to bring your wallet/ purse into the gym. Once you have filled in the Direct Debit form payments can be debited straight from your account on a fortnightly basis. I understand that you may miss sessions or go away, if so please let me know in advance so I can amend / suspend your debit accordingly. This method is easily adjustable and you aren’t locked into anything that we haven’t agreed upon.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Cancellation policy',
        type: 'header',
        mandatory: false
      })
      sections.push({
        label: 'Please understand that as well as being unfair on my business, this is to get the best result out of your training and to ensure your own consistency and accountability. I require 24 hours’ notice for cancellations or to reschedule if I have the availabilities. I understand that unexpected things happen and I can definitely be accommodating for that. However, if cancellations become a regular occurrence then you will be charged for the session(s) that have been missed without the required notice.',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'The rules apply to me too! A minimum of 24 hours’ notice must be given if I need to cancel the sessions or your next session is on me!',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Remember I am here to help and provide a service that you can rely on. I am always available should you require and assistance towards your health and training goals. I wish you the very best on your health and fitness journey!',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Kind regards,',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: "[TRAINER'S NAME]",
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Personal Trainer & Coach',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Email:',
        type: 'paragraph',
        mandatory: false
      })
      sections.push({
        label: 'Phone:',
        type: 'paragraph',
        mandatory: false
      })
    }

    return sections
  }

}


const b = new HelperClients();
export default b;