import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import LandingPage from '../../pages/Landing';
import PasswordPage from '../../pages/Landing/password';
import SignUpPage from '../../pages/SignUp';
import SignUp2Page from '../../pages/SignUp/page2';
import SignUpStaffPage from '../../pages/SignUp/staff';
import StaffSetupPage from '../../pages/SignUp/setup';
import InvitePage from '../../pages/SignUp/invite';
import StaffInactivePage from '../../pages/SignUp/inactive';
import StaffSearchPage from '../../pages/SignUp/search';

import OverviewPage from '../../pages/Overview';
import CommunityPage from '../../pages/Overview/community';
import ClientsPage from '../../pages/Clients';
import ClientsGroupsPage from '../../pages/Clients/groups';
import ClientsNewPage from '../../pages/Clients/new';
import ClientsNewImportPage from '../../pages/Clients/newimport';
import ClientsGroupsNewPage from '../../pages/Clients/groupsnew';
import ClientsOverviewPage from '../../pages/Clients/overview';
import ClientsSessionsPage from '../../pages/Clients/sessions';
import ClientsBillingPage from '../../pages/Clients/billing';
import ClientsPlansPage from '../../pages/Clients/plans';
import ClientsHealthPage from '../../pages/Clients/health';
import ClientsPlanPage from '../../pages/Clients/plan';
import ClientsProgressPage from '../../pages/Clients/progress';
import ClientsDetailsPage from '../../pages/Clients/details';
import ClientsNewAssessmentPage from '../../pages/Clients/assessment-new';
import ClientsAssessmentPage from '../../pages/Clients/assessment';
import BenchmarkPage from '../../pages/Clients/benchmark';
import ClientsQRPage from '../../pages/Clients/qr';
import ClientsReportPage from '../../pages/Clients/report';

import CalendarPage from '../../pages/Calendar';
import RecurringPage from '../../pages/Calendar/recurring';
import SessionPage from '../../pages/Calendar/session';
import GroupPage from '../../pages/Calendar/group';
import EventPage from '../../pages/Calendar/event';
import NewSessionPage from '../../pages/Calendar/new-session';
import NewGroupPage from '../../pages/Calendar/new-group';
import NewEventPage from '../../pages/Calendar/new-event';
import NewRecSessionPage from '../../pages/Calendar/new-rec-session';
import NewRecGroupPage from '../../pages/Calendar/new-rec-group';
import NewRecEventPage from '../../pages/Calendar/new-rec-event';
import ActiveSessionPage from '../../pages/Calendar/active';
import CheckinPage from '../../pages/Calendar/checkin';
import CalendarInsightsPage from '../../pages/Calendar/insights';
import CalendarReportPage from '../../pages/Calendar/report';
import ArchivePage from '../../pages/Calendar/archive';
import RosterPage from '../../pages/Calendar/roster';

import ProgrammingPage from '../../pages/Programming';
import NewProgramPage from '../../pages/Programming/newprogram';
import ProgramPage from '../../pages/Programming/program';
import PrintProgramPage from '../../pages/Programming/print-program';
import ResultsPage from '../../pages/Programming/results';
import PlansPage from '../../pages/Programming/plans';
import PlanPage from '../../pages/Programming/plan';
import EditPlanPage from '../../pages/Programming/editplan';
import ExercisesPage from '../../pages/Programming/exercises';
import ExercisePage from '../../pages/Programming/exercise';
import NewExercisePage from '../../pages/Programming/newexercise';

import NutritionPage from '../../pages/Health';
import NewNutritionPage from '../../pages/Health/new';
import HabitsPage from '../../pages/Health/habits';
import NewHabitPage from '../../pages/Health/new-habit';
import HabitPage from '../../pages/Health/habit';

import BillingPage from '../../pages/Billing';
import DebitsPage from '../../pages/Billing/debits';
import ProductsPage from '../../pages/Billing/products';
import DirectDebitPage from '../../pages/Billing/directdebit';
import DebitNewPage from '../../pages/Billing/debitnew';
import InvoicesPage from '../../pages/Billing/invoices';
import ProductPage from '../../pages/Billing/product';
import PaymentPage from '../../pages/Billing/payment';
import NewInvoicePage from '../../pages/Billing/newinvoice';
import InvoicePage from '../../pages/Billing/invoice';
import ExpensesPage from '../../pages/Billing/expenses';
import NewExpensePage from '../../pages/Billing/newexpense';
import ReportPage from '../../pages/Billing/report';
import BillingInsightsPage from '../../pages/Billing/insights';
import ReceiptPage from '../../pages/Billing/receipt';

import AccountPage from '../../pages/Account';
import SubscriptionPage from '../../pages/Account/subscription';
import AccountBillingPage from '../../pages/Account/billing';
import AccountSpacesPage from '../../pages/Account/spaces';
import AccountBadgesPage from '../../pages/Account/badges';
import TrialPage from '../../pages/Account/trial';
import AffiliatesPage from '../../pages/Account/affiliates';

import AdminPage from '../../pages/Admin';
import AdminStaffPage from '../../pages/Admin/staff';
import AdminNewStaffPage from '../../pages/Admin/newstaff';
import DocumentsPage from '../../pages/Admin/documents';
import DocumentPage from '../../pages/Admin/document';
import NewDocumentPage from '../../pages/Admin/newdocument';
import FillDocumentPage from '../../pages/Admin/filldocument';
import PublicPage from '../../pages/Admin/public';
import MemberTrialPage from '../../pages/Public/';
import EmailsPage from '../../pages/Admin/emails';
import NewEmailPage from '../../pages/Admin/newemail';
import LocationsPage from '../../pages/Admin/locations';
import NewLocationPage from '../../pages/Admin/newlocation';
import SettingsPage from '../../pages/Admin/settings';
import UsersPage from '../../pages/Admin/users';
import UsersClientsPage from '../../pages/Admin/usersclients';
import AdminExercisesPage from '../../pages/Admin/movements';
import AdminExercisePage from '../../pages/Admin/movement';
import AdminNewExercisePage from '../../pages/Admin/newmovement';
import EmailUsagePage from '../../pages/Account/email';
import UnsubscribePage from '../../pages/Public/unsubscribe';

import MemberPage from '../../pages/Member/';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';



const App = () => {
  return (
  <Router>
    <div style={{height: '100%'}}>
      <Route exact path={ROUTES.LANDING} component={LandingPage} />
      <Route exact path={ROUTES.PASSWORD} component={PasswordPage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.SIGN_UP2} component={SignUp2Page} />
      <Route exact path={'/affiliate/:id'} component={SignUpPage} />
      <Route exact path={'/staff/:id/signup/:id2'} component={SignUpStaffPage} />
      <Route exact path={'/staff/setup/:id'} component={StaffSetupPage} />
      <Route exact path={'/staff/setup/'} component={StaffSetupPage} />
      <Route exact path={'/invite/:id'} component={InvitePage} />
      <Route exact path={ROUTES.STAFFINACTIVE} component={StaffInactivePage} />
      <Route exact path={ROUTES.STAFFSEARCH} component={StaffSearchPage} />

      <Route exact path={ROUTES.OVERVIEW} component={OverviewPage} />
      <Route exact path={ROUTES.COMMUNITY} component={CommunityPage} />
      <Route exact path={ROUTES.CLIENTS} component={ClientsPage} />
      <Route exact path={ROUTES.CLIENTSGROUPS} component={ClientsGroupsPage} />
      <Route exact path={ROUTES.NEWCLIENT} component={ClientsNewPage} />
      <Route exact path={ROUTES.NEWCLIENTIMPORT} component={ClientsNewImportPage} />
      <Route exact path={ROUTES.CLIENTSGROUPSNEW} component={ClientsGroupsNewPage} />
      <Route exact path={'/clients/groups/group/:id/'} component={ClientsGroupsNewPage} />
      <Route path={'/clients/:id/overview'} component={ClientsOverviewPage} />
      <Route path={'/clients/:id/sessions'} component={ClientsSessionsPage} />
      <Route path={'/clients/:id/billing'} component={ClientsBillingPage} />
      <Route path={'/clients/:id/plans'} component={ClientsPlansPage} />
      <Route path={'/clients/:id/plan/:id2'} component={ClientsPlanPage} />
      <Route path={'/clients/:id/plan-program/:id2/:id3'} component={ProgramPage} />
      <Route path={'/clients/:id/health'} component={ClientsHealthPage} />
      <Route path={'/clients/:id/progress'} component={ClientsProgressPage} />
      <Route path={'/clients/:id/details'} component={ClientsDetailsPage} />
      <Route path={'/clients/:id/new-assessment'} component={ClientsNewAssessmentPage} />
      <Route path={'/clients/:id/assessments/:id2'} component={ClientsAssessmentPage} />
      <Route path={'/clients/:id/edit-assessment/:id2'} component={ClientsNewAssessmentPage} />
      <Route path={'/clients/:id/benchmark/:id2'} component={BenchmarkPage} />
      <Route exact path={'/clients/:id/form/:id2'} component={FillDocumentPage} />
      <Route path={'/clients/qrcode'} component={ClientsQRPage} />
      <Route exact path={ROUTES.CLIENTSREPORT} component={ClientsReportPage} />

      <Route exact path={ROUTES.CALENDAR} component={CalendarPage} />
      <Route exact path={ROUTES.RECURRING} component={RecurringPage} />
      <Route exact path={ROUTES.NEWSESSION} component={NewSessionPage} />
      <Route exact path={ROUTES.NEWGROUP} component={NewGroupPage} />
      <Route exact path={ROUTES.NEWAVAILABILITY} component={NewGroupPage} />
      <Route exact path={ROUTES.NEWEVENT} component={NewEventPage} />
      <Route path={'/calendar/:id/new-session'} component={NewSessionPage} />
      <Route exact path={'/calendar/session/:id'} component={SessionPage} />
      <Route exact path={'/calendar/edit-session/:id'} component={NewSessionPage} />
      <Route path={'/calendar/session/:id/program/:id2'} component={ProgramPage} />
      <Route path={'/calendar/session/:id/edit-program/:id2'} component={NewProgramPage} />
      <Route path={'/calendar/group-session/:id/program/:id2'} component={ProgramPage} />
      <Route path={'/calendar/group-session/:id/edit-program/:id2'} component={NewProgramPage} />
      <Route exact path={'/calendar/session/:id/new-program'} component={NewProgramPage} />
      <Route exact path={'/calendar/group-session/:id/new-program'} component={NewProgramPage} />
      <Route path={'/calendar/results/:id'} component={ResultsPage} />
      <Route exact path={'/calendar/group-session/:id'} component={GroupPage} />
      <Route exact path={'/calendar/edit-group-session/:id'} component={NewGroupPage} />
      <Route exact path={'/calendar/availability/:id'} component={GroupPage} />
      <Route exact path={'/calendar/availability/:id/new-program'} component={NewProgramPage} />
      <Route exact path={'/calendar/availability/:id/program/:id2'} component={ProgramPage} />
      <Route path={'/calendar/run-session/:id'} component={ActiveSessionPage} />
      <Route path={'/calendar/check-in/:id'} component={CheckinPage} />
      <Route path={'/calendar/event/:id'} component={EventPage} />
      <Route path={'/calendar/edit-event/:id'} component={NewEventPage} />
      <Route exact path={ROUTES.NEWRECSESSION} component={NewRecSessionPage} />
      <Route exact path={ROUTES.NEWRECGROUP} component={NewRecGroupPage} />
      <Route exact path={ROUTES.NEWRECAVAILABILITY} component={NewRecGroupPage} />
      <Route exact path={ROUTES.NEWRECEVENT} component={NewRecEventPage} />
      <Route path={'/calendar/recurring-templates/sessions/:id'} component={NewRecSessionPage} />
      <Route path={'/calendar/recurring-templates/group/:id'} component={NewRecGroupPage} />
      <Route path={'/calendar/recurring-templates/availability/:id'} component={NewRecGroupPage} />
      <Route path={'/calendar/recurring-templates/events/:id'} component={NewRecEventPage} />
      <Route exact path={ROUTES.CALINSIGHTS} component={CalendarInsightsPage} />
      <Route exact path={ROUTES.CALREPORT} component={CalendarReportPage} />
      <Route exact path={ROUTES.CALARCHIVE} component={ArchivePage} />
      <Route exact path={ROUTES.ROSTER} component={RosterPage} />

      <Route exact path={ROUTES.PROGRAMMING} component={ProgrammingPage} />
      <Route exact path={ROUTES.NEWPROGRAM} component={NewProgramPage} />
      <Route exact path={'/programming/program/:id'} component={ProgramPage} />
      <Route exact path={'/programming/edit-program/:id'} component={NewProgramPage} />
      <Route exact path={'/programming/print-program/:id'} component={PrintProgramPage} />
      <Route exact path={ROUTES.PLANS} component={PlansPage} />
      <Route exact path={'/programming/plan/:id'} component={PlanPage} />
      <Route exact path={'/programming/edit-plan/:id'} component={EditPlanPage} />
      <Route exact path={'/programming/plan/:id/program/:id2'} component={ProgramPage} />
      <Route exact path={'/programming/plan/:id/new-program'} component={NewProgramPage} />
      <Route path={'/programming/plan/:id/edit-program/:id2'} component={NewProgramPage} />
      <Route exact path={ROUTES.EXERCISES} component={ExercisesPage} />
      <Route exact path={'/programming/exercise/:id'} component={ExercisePage} />
      <Route exact path={ROUTES.NEWEXERCISE} component={NewExercisePage} />

      <Route exact path={ROUTES.NUTRITION} component={NutritionPage} />
      <Route exact path={ROUTES.NEWNUTRITION} component={NewNutritionPage} />
      <Route exact path={'/nutrition/new-plan/:id'} component={NewNutritionPage} />
      <Route exact path={ROUTES.HABITS} component={HabitsPage} />
      <Route exact path={ROUTES.NEWHABIT} component={NewHabitPage} />
      <Route path={'/health/habit/:id/'} component={HabitPage} />
      <Route path={'/health/edit-habit/:id/'} component={NewHabitPage} />
      <Route path={'/clients/:id/habit/:id2/'} component={HabitPage} />
      <Route path={'/clients/:id/edit-habit/:id2/'} component={NewHabitPage} />
      <Route path={'/clients/:id/new-habit/'} component={NewHabitPage} />

      <Route exact path={ROUTES.BILLING} component={BillingPage} />
      <Route exact path={ROUTES.DEBITS} component={DebitsPage} />
      <Route exact path={ROUTES.PRODUCTS} component={ProductsPage} />
      <Route exact path={ROUTES.INVOICES} component={InvoicesPage} />
      <Route exact path={ROUTES.PAYMENT} component={PaymentPage} />
      <Route path={'/billing/payment/:id/:id2'} component={PaymentPage} />
      <Route path={'/billing/pay-invoice/:id'} component={PaymentPage} />
      <Route path={'/billing/direct-debits/:id/:id2'} component={DirectDebitPage} />
      <Route path={ROUTES.DEBITNEW} component={DebitNewPage} />
      <Route exact path={ROUTES.INVOICENEW} component={NewInvoicePage} />
      <Route path={'/billing/products/:id'} component={ProductPage} />
      <Route path={'/billing/invoice/:id'} component={InvoicePage} />
      <Route exact path={ROUTES.EXPENSES} component={ExpensesPage} />
      <Route exact path={ROUTES.NEWEXPENSE} component={NewExpensePage} />
      <Route exact path={'/billing/edit-expense/:id'} component={NewExpensePage} />
      <Route path={ROUTES.REPORT} component={ReportPage} />
      <Route exact path={ROUTES.BILINSIGHTS} component={BillingInsightsPage} />
      <Route path={'/billing/receipt/:id'} component={ReceiptPage} />

      <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route exact path={ROUTES.SUBSCRIPTION} component={SubscriptionPage} /> 
      <Route exact path={ROUTES.ACCOUNTBILLING} component={AccountBillingPage} />
      <Route exact path={ROUTES.ACCOUNTSPACES} component={AccountSpacesPage} />
      <Route exact path={ROUTES.ACCOUNTBADGES} component={AccountBadgesPage} />
      <Route exact path={ROUTES.ACCOUNTTRIAL} component={TrialPage} />
      <Route exact path={ROUTES.AFFILIATES} component={AffiliatesPage} />

      <Route exact path={ROUTES.ADMINASSISTANT} component={AdminPage} />
      <Route exact path={ROUTES.ADMINSTAFF} component={AdminStaffPage} />
      <Route exact path={ROUTES.ADMINSTAFFNEW} component={AdminNewStaffPage} />
      <Route exact path={ROUTES.ADMINDOC} component={DocumentsPage} />
      <Route exact path={ROUTES.ADMINNEWDOC} component={NewDocumentPage} />
      <Route exact path={'/admin/documents/form/:id'} component={DocumentPage} />
      <Route exact path={'/admin/documents/form/:id/edit'} component={NewDocumentPage} />
      <Route exact path={ROUTES.ADMINPUBLIC} component={PublicPage} />
      <Route exact path={ROUTES.ADMINEMAILS} component={EmailsPage} />
      <Route exact path={ROUTES.ADMINNEWEMAIL} component={NewEmailPage} />
      <Route exact path={ROUTES.ADMINWELCOMEEMAIL} component={NewEmailPage} />
      <Route exact path={'/admin/emails/email/:id'} component={NewEmailPage} />
      <Route exact path={ROUTES.LOCATIONS} component={LocationsPage} />
      <Route exact path={ROUTES.NEWLOCATION} component={NewLocationPage} />
      <Route exact path={'/admin/locations/location/:id'} component={NewLocationPage} />
      <Route exact path={ROUTES.ADMINSETTINGS} component={SettingsPage} />
      <Route exact path={'/trainer/:id'} component={MemberTrialPage} />
      <Route exact path={'/admin/users'} component={UsersPage} />
      <Route exact path={'/admin/clients'} component={UsersClientsPage} />
      <Route exact path={'/admin/movements'} component={AdminExercisesPage} />
      <Route exact path={'/admin/movements/:id'} component={AdminExercisePage} />
      <Route exact path={'/admin/new-movement'} component={AdminNewExercisePage} />
      <Route exact path={ROUTES.EMAILUSAGE} component={EmailUsagePage} />
      <Route exact path={'/unsubscribe/:id'} component={UnsubscribePage} />

      
      <Route exact path={ROUTES.MEMBERLOGIN} component={MemberPage} />
      <Route exact path={'/member/:id/login'} component={MemberPage} />
      <Route exact path={ROUTES.MEMBERPASSWORD} component={MemberPage} />
      <Route exact path={ROUTES.MEMBERSIGNUP} component={MemberPage} />
      <Route exact path={'/member/:id/signup'} component={MemberPage} />
      <Route exact path={'/member/complete-signup'} component={MemberPage} />
      <Route exact path={'/member/:id/complete-signup'} component={MemberPage} />
      <Route exact path={ROUTES.MEMBERCONFIRM} component={MemberPage} />
      <Route exact path={'/member/:id/confirm'} component={MemberPage} />
      <Route exact path={'/member/:id/pre-exercise'} component={MemberPage} />
      <Route exact path={ROUTES.MEMBERLOADING} component={MemberPage} />
      <Route exact path={'/member/:id/home'} component={MemberPage} />
      <Route exact path={'/member/:id/community'} component={MemberPage} />
      <Route exact path={'/member/:id/calendar'} component={MemberPage} />
      <Route exact path={'/member/:id/session/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/session/:id2/program/:id3'} component={MemberPage} />
      <Route exact path={'/member/:id/results/:id2/'} component={MemberPage} />
      <Route exact path={'/member/:id/running/:id2/'} component={MemberPage} />
      <Route exact path={'/member/:id/past-session/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/event/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/training/sessions'} component={MemberPage} />
      <Route exact path={'/member/:id/training/programs'} component={MemberPage} />
      <Route exact path={'/member/:id/training/plans'} component={MemberPage} />
      <Route exact path={'/member/:id/training/progress'} component={MemberPage} />
      <Route exact path={'/member/:id/training/program/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/training/plan-program/:id2/:id3'} component={MemberPage} />
      <Route exact path={'/member/:id/training/plan/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/health/nutrition'} component={MemberPage} />
      <Route exact path={'/member/:id/health/meal/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/health/preferences'} component={MemberPage} />
      <Route exact path={'/member/:id/health/log'} component={MemberPage} />
      <Route exact path={'/member/:id/health/habits'} component={MemberPage} />
      <Route exact path={'/member/:id/health/habit/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/health/new-log'} component={MemberPage} />
      <Route exact path={'/member/:id/health/log-entry/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/admin/forms'} component={MemberPage} />
      <Route exact path={'/member/:id/form/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/admin/payments'} component={MemberPage} />
      <Route exact path={'/member/:id/admin/payment'} component={MemberPage} />
      <Route exact path={'/member/:id/admin/pay-invoice/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/admin/invoice/:id2'} component={MemberPage} />
      <Route exact path={'/member/:id/admin/card'} component={MemberPage} />
      <Route exact path={'/member/:id/account'} component={MemberPage} />
      <Route exact path={'/member/:id/spaces'} component={MemberPage} />
      <Route exact path={'/member/:id/thankyou'} component={MemberPage} />
    </div>
  </Router>
  )
};


export default withAuthentication(App);