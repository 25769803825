import React, { Component } from 'react';



class InputToggle extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      value: props.value ?? false,
      text: props.text,
      align: props.align ?? '',
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      value: props.value ?? false,
      text: props.text,
      align: props.align ?? '',
    }
  }


  render() {
    return (
      <div className={'form-item '+this.state.align}>
        <label>{this.state.label}</label>
        <div className={this.state.value ? 'toggle active' : 'toggle'} onClick={() => this.props.onChange(!this.state.value)}>
          <div className="mover"></div>
        </div>
        <p className="toggle-label">{this.state.text}</p>
        <div className="clear"></div>
      </div>
    )
  }
}


export default InputToggle;