import React, { Component } from 'react';
import FormCard from '../../components/Form/card';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      card: props.card ?? '',
      cards: props.cards ?? [],
      valuesCard: ['', '', '', '', ''],
      errorCard: [false, false, false, false, false],
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
      card: props.card ?? '',
      cards: props.cards ?? []
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  createCard() {
    this.setState({errorCard: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.valuesCard[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.valuesCard[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.valuesCard[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.valuesCard[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.valuesCard[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({errorCard: tmp})
    if(passed) {
      this.hide()
      this.props.clickMainButton(this.state.valuesCard)
    }
  }


  renderCardImage(name) {
    var label = '/img/card-generic.svg'
    if(name === 'Visa') {
      label = '/img/card-visa.svg'
    } else if(name === 'MasterCard') {
      label = '/img/card-mastercard.svg'
    } else if(name === 'American Express') {
      label = '/img/card-amex.svg'
    }
    return <div className='avatar' style={{backgroundImage: 'url('+label+')'}}></div>
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Manage cards</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <div className="clear small-list">
              {this.state.cards.map(item => (
                <div className="client small" key={item.id}>
                  {this.renderCardImage(item.data.brand)}
                  <p className="lft" style={{paddingTop: 6}}><strong>{item.data.brand} ending {item.data.last4}</strong></p>
                  <button className="btn tertiary small close rgt" onClick={() => this.props.deleteCard(item.id)} style={{paddingTop: 7}}>{lang.t('common:button.remove')}</button>
                  <div className="clear"></div>
                </div>
              ))}
            </div>
            <div className="sv-20"></div>
            <p className="mb-30">{this.state.card === '' ? lang.t('modals:card.info1') : lang.t('modals:card.info2')}</p>
            <FormCard values={this.state.valuesCard} error={this.state.errorCard} onChange={(event) => this.setState({valuesCard: event})}/>
            <div className="clear sv-40"></div>
            <button className="btn primary" onClick={() => this.createCard()}>{lang.t('modals:card.button')}</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalCard);