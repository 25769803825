import HelperCal from '../helper/Calendar';
import lang from 'i18next';



class HelperProgramming {


  // Block type

  getBlockType(item) {
    var label = ''
    var s = ''
    var time1 = (parseInt(item.min)*60)+parseInt(item.sec)
    var time2 = (parseInt(item.min2)*60)+parseInt(item.sec2)
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(time1)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(time1)
      if(item.emom) {
        label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(time1)+' (All movements at the same time)'
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' rounds'
      if(item.rounds === 1) {
        label = ' - 1 round'
      }
      if(item.type === 2 && item.emom) { 
        if(time2 === 0) {
          label += ' of '+HelperCal.getDurationMinAlt(time1)+' work & no rest'
        } else {
          label += ' of '+HelperCal.getDurationMinAlt(time1)+' work & '+HelperCal.getDurationMinAlt(time2)+' rest'
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' sets'
      if(item.rounds === 1) {
        label = ' - 1 set'
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(time1)
      if(item.rounds > 1) {
        label = ' - '+item.rounds+' rounds - Cap: '+HelperCal.getDurationMin(time1)
      }
    }
    return label
  }


  getBlockTypeEmail(item) {
    var label = ''
    var s = ''
    var time1 = (parseInt(item.min)*60)+parseInt(item.sec)
    var time2 = (parseInt(item.min2)*60)+parseInt(item.sec2)
    if(isNaN(time1)) {
      time1 = (parseInt(item.exWork[0]))
      if(item.type === 4 || item.type === 5) {
        time1 = (parseInt(item.rounds))
      }
    }
    if(isNaN(time2)) {
      time2 = (parseInt(item.exRest[0]))
    }
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(time1)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(time1)
      if(item.emom) {
        label = ' - '+item.rounds+' round'+s+' of '+HelperCal.getDurationMinAlt(time1)+' (All movements at the same time)'
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' rounds'
      if(item.rounds === 1) {
        label = ' - 1 round'
      }
      if(item.type === 2 && item.emom) { 
        if(time2 === 0) {
          label += ' of '+HelperCal.getDurationMinAlt(time1)+' work & no rest'
        } else {
          label += ' of '+HelperCal.getDurationMinAlt(time1)+' work & '+HelperCal.getDurationMinAlt(time2)+' rest'
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' sets'
      if(item.rounds === 1) {
        label = ' - 1 set'
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(time1)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' rounds - Cap: '+HelperCal.getDurationMin(time1)
      }
    }
    return label
  }


  // Display weight

  showWeight(weight) {
    var label = weight
    if(global.userLbs) {
      if(!isNaN(+weight)) {
        label = (weight*global.lbsUp).toFixed(0)
      } else {
        var ar = weight.split('-')
        label = ''
        for(var a of ar) {
          if(!isNaN(+a)) {
            label += (a*global.lbsUp).toFixed(1)+'-'
          }
        }
        if(ar.length > 0) {
          label = label.slice(0, -1)
        }
      }
    }
    return label
  }


  // Calculate weight to save

  calculateWeight(weight) {
    var label = weight
    if(global.userLbs) {
      if(!isNaN(+weight)) {
        label = (weight*global.lbsDown).toFixed(2)
      } else {
        var ar = weight.split('-')
        label = ''
        for(var a of ar) {
          if(!isNaN(+a) && a !== '') {
            label += (a*global.lbsDown).toFixed(2)+'-'
          }
        }
        if(ar.length > 0) {
          label = label.slice(0, -1)
        }
      }
    }
    return label
  }


  // Set preset

  setPreset(block, type) {
    var reps = ''
    var time = false
    var time11 = 0
    var time12 = 0
    var time21 = 0
    var time22 = 0
    if(type === 'amrap1') {
      reps = '1-2-3-4-5-6-8-9-10'
      block.min = 12
    } else if(type === 'amrap2') {
      reps = '2-4-6-8-10-12-14'
      block.min = 15
    } else if(type === 'amrap3') {
      reps = '3-6-9-12-15-18-21'
      block.min = 20
    } else if(type === 'amrap4') {
      reps = '10-9-8-7-6-5-4-3-2-1'
      block.min = 12
    } else if(type === 'amrap5') {
      reps = '20-18-16-14-12-10-8'
      block.min = 15
    } else if(type === 'amrap6') {
      reps = '30-27-24-21-18-15-12-9'
      block.min = 20
    } else if(type === 'fortime1') {
      reps = '21-18-15-12-9-6-3'
      block.min = 15
      block.rounds = 7
    } else if(type === 'fortime2') {
      reps = '21-15-9'
      block.min = 10
      block.rounds = 3
    } else if(type === 'fortime3') {
      reps = '1-2-3-4-5-6-7-8-9-10'
      block.min = 15
      block.rounds = 10
    } else if(type === 'fortime4') {
      reps = '2-4-6-8-10-12-14-16-18-20'
      block.min = 15
      block.rounds = 10
    } else if(type === 'fortime5') {
      reps = '3-6-9-12-15-18-21-24-27-30'
      block.min = 20
      block.rounds = 10
    } else if(type === 'fortime6') {
      reps = '5-10-15'
      block.min = 6
      block.rounds = 3
    } else if(type === 'fortime7') {
      reps = '5-10-15-20'
      block.min = 8
      block.rounds = 4
    } else if(type === 'fortime8') {
      reps = '5-10-15-20-25'
      block.min = 11
      block.rounds = 5
    } else if(type === 'fortime9') {
      reps = '5-10-15-20-25-30'
      block.min = 16
      block.rounds = 6
    } else if(type === 'fortime10') {
      reps = '10-9-8-7-6-5-4-3-2-1'
      block.min = 12
      block.rounds = 10
    } else if(type === 'fortime11') {
      reps = '20-18-16-14-12-10-8-6-4-2'
      block.min = 15
      block.rounds = 10
    } else if(type === 'fortime12') {
      reps = '30-27-24-21-18-15-12-9-6-3'
      block.min = 20
      block.rounds = 10
    } else if(type === 'fortime13') {
      reps = '15-10-5'
      block.min = 6
      block.rounds = 3
    } else if(type === 'fortime14') {
      reps = '20-15-10-5'
      block.min = 8
      block.rounds = 4
    } else if(type === 'fortime15') {
      reps = '25-20-15-10-5'
      block.min = 11
      block.rounds = 5
    } else if(type === 'fortime16') {
      reps = '30-25-20-15-10-5'
      block.min = 16
      block.rounds = 6
    } else if(type === 'notime1') {
      reps = '1'
      block.rounds = 1
    } else if(type === 'notime2') {
      reps = '2'
      block.rounds = 1
    } else if(type === 'notime3') {
      reps = '3'
      block.rounds = 1
    } else if(type === 'notime4') {
      reps = '4'
      block.rounds = 1
    } else if(type === 'notime5') {
      reps = '5'
      block.rounds = 1
    } else if(type === 'notime6') {
      reps = '1'
      block.rounds = 2
    } else if(type === 'notime7') {
      reps = '2'
      block.rounds = 2
    } else if(type === 'notime8') {
      reps = '3'
      block.rounds = 2
    } else if(type === 'notime9') {
      reps = '4'
      block.rounds = 2
    } else if(type === 'notime10') {
      reps = '5'
      block.rounds = 2
    } else if(type === 'notime11') {
      reps = '6'
      block.rounds = 2
    } else if(type === 'notime12') {
      reps = '1'
      block.rounds = 3
    } else if(type === 'notime13') {
      reps = '3'
      block.rounds = 3
    } else if(type === 'notime14') {
      reps = '4'
      block.rounds = 3
    } else if(type === 'notime15') {
      reps = '5'
      block.rounds = 3
    } else if(type === 'notime16') {
      reps = '6'
      block.rounds = 3
    } else if(type === 'notime17') {
      reps = '8'
      block.rounds = 3
    } else if(type === 'notime18') {
      reps = '10'
      block.rounds = 3
    } else if(type === 'notime19') {
      reps = '12'
      block.rounds = 3
    } else if(type === 'notime20') {
      reps = '3'
      block.rounds = 4
    } else if(type === 'notime21') {
      reps = '4'
      block.rounds = 4
    } else if(type === 'notime22') {
      reps = '5'
      block.rounds = 4
    } else if(type === 'notime23') {
      reps = '6'
      block.rounds = 4
    } else if(type === 'notime24') {
      reps = '8'
      block.rounds = 4
    } else if(type === 'notime25') {
      reps = '9'
      block.rounds = 4
    } else if(type === 'notime26') {
      reps = '10'
      block.rounds = 4
    } else if(type === 'notime27') {
      reps = '12'
      block.rounds = 4
    } else if(type === 'notime28') {
      reps = '3'
      block.rounds = 5
    } else if(type === 'notime29') {
      reps = '4'
      block.rounds = 5
    } else if(type === 'notime30') {
      reps = '5'
      block.rounds = 5
    } else if(type === 'notime31') {
      reps = '6'
      block.rounds = 5
    } else if(type === 'notime32') {
      reps = '7'
      block.rounds = 5
    } else if(type === 'notime33') {
      reps = '8'
      block.rounds = 5
    } else if(type === 'notime34') {
      reps = '10'
      block.rounds = 5
    } else if(type === 'notime35') {
      reps = '12'
      block.rounds = 5
    } else if(type === 'notime36') {
      reps = '4'
      block.rounds = 6
    } else if(type === 'notime37') {
      reps = '6'
      block.rounds = 6
    } else if(type === 'notime38') {
      reps = '5'
      block.rounds = 7
    } else if(type === 'notime39') {
      reps = '4'
      block.rounds = 8
    } else if(type === 'notime40') {
      reps = '3'
      block.rounds = 10
    } else if(type === 'intervals1') {
      time = true
      time12 = 30
      time22 = 30
    } else if(type === 'intervals2') {
      time = true
      time12 = 45
      time22 = 30
    } else if(type === 'intervals3') {
      time = true
      time11 = 1
      time22 = 30
    } else if(type === 'intervals4') {
      time = true
      time11 = 1
      time12 = 15
      time22 = 30
    } else if(type === 'intervals5') {
      time = true
      time11 = 1
      time12 = 30
      time22 = 30
    } else if(type === 'intervals6') {
      time = true
      time11 = 2
      time22 = 30
    } else if(type === 'intervals7') {
      time = true
      time11 = 3
      time21 = 1
    } else if(type === 'intervals8') {
      time = true
      time11 = 5
      time21 = 1
    }
    for(var item of block.exercises) {
      item.reps = reps
      if(time) {
        item.wmin = time11
        item.wsec = time12
        item.rmin = time21
        item.rsec = time22
        block.min = time11
        block.sec = time12
        block.min2 = time21
        block.sec2 = time22
      }
    }
    return block
  }


  // Set preset for new movement

  setPresetMovement(type) {
    var reps = ''
    if(type === 'amrap1') {
      reps = '1-2-3-4-5-6-8-9-10'
    } else if(type === 'amrap2') {
      reps = '2-4-6-8-10-12-14'
    } else if(type === 'amrap3') {
      reps = '3-6-9-12-15-18-21'
    } else if(type === 'amrap4') {
      reps = '10-9-8-7-6-5-4-3-2-1'
    } else if(type === 'amrap5') {
      reps = '20-18-16-14-12-10-8'
    } else if(type === 'amrap6') {
      reps = '30-27-24-21-18-15-12-9'
    } else if(type === 'fortime1') {
      reps = '21-18-15-12-9-6-3'
    } else if(type === 'fortime2') {
      reps = '21-15-9'
    } else if(type === 'fortime3') {
      reps = '1-2-3-4-5-6-8-10'
    } else if(type === 'fortime4') {
      reps = '2-4-6-8-10-12-14-16-18-20'
    } else if(type === 'fortime5') {
      reps = '3-6-9-12-15-18-21-24-27-30'
    } else if(type === 'fortime6') {
      reps = '5-10-15'
    } else if(type === 'fortime7') {
      reps = '5-10-15-20'
    } else if(type === 'fortime8') {
      reps = '5-10-15-20-25'
    } else if(type === 'fortime9') {
      reps = '5-10-15-20-25-30'
    } else if(type === 'fortime10') {
      reps = '10-9-8-7-6-5-4-3-2-1'
    } else if(type === 'fortime11') {
      reps = '20-18-16-14-12-10-8-6-4-2'
    } else if(type === 'fortime12') {
      reps = '30-27-24-21-18-15-12-9-6-3'
    } else if(type === 'fortime13') {
      reps = '15-10-5'
    } else if(type === 'fortime14') {
      reps = '20-15-10-5'
    } else if(type === 'fortime15') {
      reps = '25-20-15-10-5'
    } else if(type === 'fortime16') {
      reps = '30-25-20-15-10-5'
    } else if(type === 'notime1') {
      reps = '1'
    } else if(type === 'notime2') {
      reps = '2'
    } else if(type === 'notime3') {
      reps = '3'
    } else if(type === 'notime4') {
      reps = '4'
    } else if(type === 'notime5') {
      reps = '5'
    } else if(type === 'notime6') {
      reps = '1'
    } else if(type === 'notime7') {
      reps = '2'
    } else if(type === 'notime8') {
      reps = '3'
    } else if(type === 'notime9') {
      reps = '4'
    } else if(type === 'notime10') {
      reps = '5'
    } else if(type === 'notime11') {
      reps = '6'
    } else if(type === 'notime12') {
      reps = '1'
    } else if(type === 'notime13') {
      reps = '3'
    } else if(type === 'notime14') {
      reps = '4'
    } else if(type === 'notime15') {
      reps = '5'
    } else if(type === 'notime16') {
      reps = '6'
    } else if(type === 'notime17') {
      reps = '8'
    } else if(type === 'notime18') {
      reps = '10'
    } else if(type === 'notime19') {
      reps = '12'
    } else if(type === 'notime20') {
      reps = '3'
    } else if(type === 'notime21') {
      reps = '4'
    } else if(type === 'notime22') {
      reps = '5'
    } else if(type === 'notime23') {
      reps = '6'
    } else if(type === 'notime24') {
      reps = '8'
    } else if(type === 'notime25') {
      reps = '9'
    } else if(type === 'notime26') {
      reps = '10'
    } else if(type === 'notime27') {
      reps = '12'
    } else if(type === 'notime28') {
      reps = '3'
    } else if(type === 'notime29') {
      reps = '4'
    } else if(type === 'notime30') {
      reps = '5'
    } else if(type === 'notime31') {
      reps = '6'
    } else if(type === 'notime32') {
      reps = '7'
    } else if(type === 'notime33') {
      reps = '8'
    } else if(type === 'notime34') {
      reps = '10'
    } else if(type === 'notime35') {
      reps = '12'
    } else if(type === 'notime36') {
      reps = '4'
    } else if(type === 'notime37') {
      reps = '6'
    } else if(type === 'notime38') {
      reps = '5'
    } else if(type === 'notime39') {
      reps = '4'
    } else if(type === 'notime40') {
      reps = '3'
    }
    return reps
  }


  // Set preset for interval movement

  setPresetInterval(ex, type) {
    ex.wmin = 0
    ex.wsec = 0
    ex.rmin = 0
    ex.rsec = 0
    if(type === 'intervals1') {
      ex.wsec = 30
      ex.rsec = 30
    } else if(type === 'intervals2') {
      ex.wsec = 45
      ex.rsec = 30
    } else if(type === 'intervals3') {
      ex.wmin = 1
      ex.rsec = 30
    } else if(type === 'intervals4') {
      ex.wmin = 1
      ex.wsec = 15
      ex.rsec = 30
    } else if(type === 'intervals5') {
      ex.wmin = 1
      ex.wsec = 30
      ex.rsec = 30
    } else if(type === 'intervals6') {
      ex.wmin = 2
      ex.rsec = 30
    } else if(type === 'intervals7') {
      ex.wmin = 3
      ex.rmin = 1
    } else if(type === 'intervals8') {
      ex.wmin = 5
      ex.rmin = 1
    }
    return ex
  }


  // Create email

  createEmail(program) {
    var label = '<h2>'+program.data.name+'</h2>'
    if(program.data.desc !== '') {
      label += "<p>Coach's notes:<br/>"+program.data.desc+"</p>"
    }
    if(program.data.video !== '') {
      label += '<p><a href="'+program.data.video+'">Watch video</a></p>'
    }
    label += '<p style="height: 30px">&nbsp;</p>'
    for(var block of program.blocks) {
      if(block.data.name !== '' && block.data.name !== undefined) {
        label += '<h3>'+block.data.name+'<br/>'
      } else {
        label += '<h3>'+lang.t(global.exCats[block.data.cat])+'<br/>'
      }
      label += lang.t(global.proTypes[block.data.type])+this.getBlockTypeEmail(block.data)+'</h3>'
      console.log(block)
      if(block.data.simple) {
        label += '<p style="margin-bottom: 40px;white-space: pre-wrap">'+block.data.notes+'</p>'
      } else {
        for(var i=0; i<block.data.exName.length; i++) {
          if(block.data.exRepsRounds[i] === '' || block.data.exRepsRounds[i] === undefined) {
            label += block.data.exReps[i]+'x '+block.data.exName[i]
          } else {
            label += block.data.exRepsRounds[i]+'x '+block.data.exName[i]
          }
          
          var unit = block.data.exWeightType[i]
            if(unit === 'per') {
              unit = '%' 
            }
          if(block.data.exWorkRounds !== undefined) {
            if(block.data.exWeightRounds[i] !== '' && block.data.exWeightRounds[i] !== undefined && block.data.exWeightRounds[i] !== '0') {
              label += ' ('+block.data.exWeightRounds[i]+unit+')'
            } else {
              if(block.data.exWork.length > i) {
                if(block.data.exWeight[i] !== '' && block.data.exWeight[i] !== undefined && block.data.exWeight[i] !== '0') {
                  label += ' ('+block.data.exWeight[i]+unit+')'
                }
              }
            }
          } else {
            if(block.data.exWork.length > i) {
              if(block.data.exWeight[i] !== '' && block.data.exWeight[i] !== undefined && block.data.exWeight[i] !== '0') {
                label += ' ('+block.data.exWeight[i]+' '+unit+')'
              }
            }
          }
          label += '<br/>'
        }
        label += '<p style="height: 30px">&nbsp;</p>'
      }
    }
    return label
  }


  // Template info

  getTemplateInfo(type) {
    var title = ''
    var desc = ''
    if(type === 'functional1') {
      title = 'Functional Strength'
      desc = 'Functional movements used to perform day-to-day activities, i.e; squatting, hinging, pulling & pushing. Functional training improves mobility, strength and cardiovascular fitness with the use of functional movements performed at a moderate to high intensity.'
    }
    if(type === 'functional2') {
      title = 'Functional Strength Stamina'
      desc = 'Functional movements used to perform day-to-day activities, i.e; squatting, hinging, pulling & pushing. Functional training improves mobility, strength and cardiovascular fitness with the use of functional movements performed at a moderate to high intensity.'
    }
    if(type === 'functional3') {
      title = 'Functional Strength & Fitness'
      desc = 'Functional movements used to perform day-to-day activities, i.e; squatting, hinging, pulling & pushing. Functional training improves mobility, strength and cardiovascular fitness with the use of functional movements performed at a moderate to high intensity.'
    }
    if(type === 'hiit1') {
      title = 'Tabata HIIT Program'
      desc = "HIIT or High Intensity Interval Training incorporates several rounds that alternate between several minutes of high intensity movements to significantly increase the heart rate to at least 80% of one's maximum heart rate, followed by short periods of lower intensity movements."
    }
    if(type === 'hiit2') {
      title = 'Stamina Builder Program'
      desc = "HIIT or High Intensity Interval Training incorporates several rounds that alternate between several minutes of high intensity movements to significantly increase the heart rate to at least 80% of one's maximum heart rate, followed by short periods of lower intensity movements."
    }
    if(type === 'hiit3') {
      title = 'Stamina Strength & Endurance Program'
      desc = "HIIT or High Intensity Interval Training incorporates several rounds that alternate between several minutes of high intensity movements to significantly increase the heart rate to at least 80% of one's maximum heart rate, followed by short periods of lower intensity movements."
    }
    if(type === 'weight1') {
      title = 'Weightlifting Strength'
      desc = 'Weightlifting is recognized as an essential activity to build and tone muscles as well as improve endurance and explosive strength. Olympic Weightlifting is designed to build the functional movement patterns, strength & power while developing Olympic Weightlifting skill sets.'
    }
    if(type === 'weight2') {
      title = 'Weightlifting Strength Endurance'
      desc = 'Weightlifting is recognized as an essential activity to build and tone muscles as well as improve endurance and explosive strength. Olympic Weightlifting is designed to build the functional movement patterns, strength & power while developing Olympic Weightlifting skill sets.'
    }
    if(type === 'weight3') {
      title = 'Weightlifting Strength Stamina'
      desc = 'Weightlifting is recognized as an essential activity to build and tone muscles as well as improve endurance and explosive strength. Olympic Weightlifting is designed to build the functional movement patterns, strength & power while developing Olympic Weightlifting skill sets.'
    }
    if(type === 'body1') {
      title = 'Strength Training Structure'
      desc = 'A program structure designed to enhance muscular development and strength.'
    }
    if(type === 'body2') {
      title = 'Hypertrophy Training Structure'
      desc = 'A program structure designed to enhance muscular development, strength & stamina.'
    }
    if(type === 'body3') {
      title = 'Strength Stamina Structure'
      desc = 'A program structure designed to enhance muscular development, strength & stamina.'
    }
    return [title, desc]
  }


  // Create templates

  createTemplate(type) {
    var blocks = []

    if(type === 'functional1') {
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          }
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Stretch and lengthen muscles to increase blood flow and circulation\n10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch.\nSelect 5 stretches / movements'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Run, Row or Bike',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Wall Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Ankle Taps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Walks',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscled joint function\nSelect a cardiovascular exercise: run, row or bike\nThen 3 - 4 activation / warm up movements'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Strength Compound',
        cat: 2,
        rounds: 6,
        emom: true,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats / Deadlifts',
          },
        ],
        min: 2,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n6 x E2MOM (Every 2 minutes on the minute)\n1 Compound movement @ 3 - 6 reps (between 60 - 80%)'
      })
      blocks.push({
        preset: '',
        type: 0,
        name: 'Metabolic Conditioning',
        cat: 6,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Row',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Push Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Sit Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Air Squats',
          },
        ],
        min: 12,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Conditioning to increase cardiovascular fitness and stamina\nEither AMRAP or Rounds for time (10 - 15 minutes of working time)\nAdd between 2 to 5 movements'
      })
    }

    if(type === 'functional2') {
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Run, Row or Bike',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Pass Throughs',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Walks',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscled joint function\n2 x no time (no rest)\nShort cardiovascular exercise\nThen 3 - 4 activation / warm up movements.'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Strength Stamina',
        cat: 8,
        rounds: 5,
        emom: true,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'Barbell complex (Rest remaining time)',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'Strength movement + Accessory movement (Rest remaining time)',
          },
        ],
        min: 1,
        sec: 30,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscles and increase heart rate improving resting heart rate\n5 x E90S (Every 90 seconds)\nE.g, Barbell Complex, Strength movement + Accessory Movement or Cardiovascular Movement + Accessory Movement (Resting remaining time)'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Metabolic Conditioning',
        cat: 6,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Row',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Push Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Sit Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Air Squats',
          },
        ],
        min: 15,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'A combination of interval or EMOM style workouts designed to improve your cardiovascular strength and endurance\n10 - 15 minutes of working time\nAdd between 3 to 5 movements'
      })
    }

    if(type === 'functional3') {
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Run, Row or Bike',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Pass Throughs',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Walks',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscled joint function\n1 set x no time\n4 - 6 activation / warm up movements'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Strength Compound',
        cat: 2,
        rounds: 6,
        emom: true,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats / Deadlifts',
          },
        ],
        min: 2,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n6 x E2MOM (Every 2 minutes on the minute)\n1 Compound movement @ 3 - 6 reps (between 60 - 80%)'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 5,
        emom: true,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Barbell Complex, Strength movement + Accessory Movement or Cardiovascular Movement + Accessory Movement (Resting remaining time)',
          },
        ],
        min: 1,
        sec: 30,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscles and increase heart rate improving resting heart rate\n5 x E90S (Every 90 seconds)\nE.g, Barbell Complex, Strength movement + Accessory Movement or Cardiovascular Movement + Accessory Movement (Resting remaining time)'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Metabolic Conditioning',
        cat: 6,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Power Snatch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Box Jump Overs',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Overhead Walking Lunge',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Burpees',
          },
        ],
        min: 15,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Conditioning to increase cardiovascular fitness and stamina\nRounds for time or AMRAP\n10 - 15 minutes of working time\nAdd between 2 to 5 movements'
      })
    }
    if(type === 'hiit1') {
      blocks.push({
        preset: '',
        type: 3,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Plank',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Shoulder Taps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Ankle Taps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Plank to Push Up',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Air Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kneel to Squat',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\nTabata warm up (4 minutes)\nSelect 8 warm up / activation movements focused on increasing the heart and warming up muscle groups'
      })
      blocks.push({
        preset: '',
        type: 3,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hand Release Push Up',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Suspended Row',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Push Press',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Pull Aparts',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Tabata style HIIT component. E.g, Upper body.\nWork through 4 - 8 movements for 20 seconds, resting 10 seconds between each movement. '
      })
      blocks.push({
        preset: '',
        type: 3,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Goblet Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Lunge',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Weighted Step Ups',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Tabata style HIIT component. E.g, Lower body.\nWork through 4 - 8 movements for 20 seconds, resting 10 seconds between each movement.'
      })
      blocks.push({
        preset: '',
        type: 3,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Ab Bicycle',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Supine Ankle Taps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Tuck Crunches ',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Weighted Sit Ups',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Tabata style HIIT component. E.g, Tabata CORE\nWork through 4 - 8 movements for 20 seconds, resting 10 seconds between each movement.'
      })
      blocks.push({
        preset: '',
        type: 3,
        name: 'Tabata Cool Down',
        cat: 5,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Downward Dog',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Bear Crawl Position Shoulder Rotations',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Static Ab Stretch',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\nTabata Cool Down (4 minutes)'
      })
    }
    if(type === 'hiit2') {
      blocks.push({
        preset: '',
        type: 2,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Ankle Taps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Wall Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Plank to Push Up',
          },
        ],
        min: 0,
        sec: 30,
        min2: 0,
        sec2: 30,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n3 Rounds of 30 seconds work / 30 seconds rest\nSelect 3 - 4 activation movements'
      })
      blocks.push({
        preset: '',
        type: 2,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Floor Press',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Renegade Row',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hand Release Push Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Overhead Sit Ups',
          },
        ],
        min: 0,
        sec: 30,
        min2: 0,
        sec2: 30,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target muscle groups at a lighter weight and a higher rep range, to increase stamina strength and cardiovascular endurance\n4 Rounds of 30 seconds work / 30 seconds rest'
      })
      blocks.push({
        preset: '',
        type: 2,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Goblet Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Lunge',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Weighted Step Ups',
          },
        ],
        min: 0,
        sec: 30,
        min2: 0,
        sec2: 30,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target muscle groups at a lighter weight and a higher rep range, to increase stamina strength and cardiovascular endurance\n4 Rounds of 30 seconds work / 30 seconds rest'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Cool Down',
        cat: 5,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Hamstring Stretch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Pigeon Stretch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Lats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Overhead Lat Stretch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Roll Chest Attachments',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Stretch Chest',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Static Ab Stretch',
          },
        ],
        min: 0,
        sec: 30,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\n2 rounds of every 30 seconds - rotate between movements'
      })
    }
    if(type === 'hiit3') {
      blocks.push({
        preset: '',
        type: 2,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Ankle Taps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Wall Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Plank to Push Up',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 30,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n3 Rounds of 60 seconds work / 30 seconds rest\nSelect 3 - 4 activation movements'
      })
      blocks.push({
        preset: '',
        type: 2,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Floor Press',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Renegade Row',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hand Release Push Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Overhead Sit Ups',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 30,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target muscle groups at a lighter weight and a higher rep range, to increase stamina fitness and muscular endurance\n4 Rounds of 60 seconds work / 30 seconds rest'
      })
      blocks.push({
        preset: '',
        type: 2,
        name: 'Strength Stamina',
        cat: 10,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Goblet Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, DB Lunge',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating Weighted Step Ups',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 30,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target muscle groups at a lighter weight and a higher rep range, to increase stamina fitness and muscular endurance\n4 Rounds of 60 seconds work / 30 seconds rest'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Cool Down',
        cat: 5,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Hamstring Stretch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Pigeon Stretch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Lats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Overhead Lat Stretch',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Roll Chest Attachments',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Stretch Chest',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Static Ab Stretch',
          },
        ],
        min: 0,
        sec: 30,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\n2 rounds of every 30 seconds - rotate between movements'
      })
    }
    if(type === 'weight1') {
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g Pass Throughs',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded pull Aparts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Drinking Bird Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Glute Walks',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n2-3 sets - no time (no rest)\nSelect 3 - 5 exercises targeting the muscle groups of the program'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Specific Warm up',
        cat: 4,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g Deadlift',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hang Power Clean',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, High Hang Squat Clean',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Front Squat',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Full (Squat) Clean',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Warm up specific muscles and movement patterns to improve muscle & joint function\n1 - 3 Supersets (barbell technique) - no time (rest as needed between each set)\nSelect 3 - 5 movements @ 3 - 5 reps per movement specifically focusing on technique'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Priority Movement',
        cat: 9,
        rounds: 6,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Full (Squat) Clean / Full (Squat) Snatch',
          },
        ],
        min: 20,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on the specific Olympic Weightlifting movement. E.g, Clean & Jerk or Snatch\nFor time timer set to 20 minutes - Select 5 to 7 sets (Rounds)\nWork up until the timer (rest as needed between sets)\nSet 1 - 4 reps @ 60%\nSet 2 - 3 reps @ 65%\nSet 3 - 2 reps @ 70%\nSet 4 - 2 reps @ 75%\nSet 5 - 1 rep @ 80%\nSet 6 - 1 rep @ 80%\nSet 7 - 1 rep @ 80%'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Heavy Pulls at a high percentage',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on building strength specific to the priority movement of the program\n3 - 5 sets @ 90 - 110% No time timer - (rest as needed)\nSelect accessory movement to support the main priority movement'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Power Snatch or Clean & Jerk, positional drills (3 position Cleans or Snatches)',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on building skills specific to the priority movement of the program\n3 - 5 Sets @ 60 - 70% No time timer - (rest as needed)\nSelect accessory movement to support skills of the priority weightlifting movements (Snatch and Clean & Jerk) '
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats or Front Squats',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focused on building stronger, denser muscle fibers\n3 - 5 Sets - No time timer - Rest 1 - 2 minutes after each set\n1 Compound movement @ 2 - 5 reps (between 60 - 80%)'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 2,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\n10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements'
      })
    }
    if(type === 'weight2') {
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Stretch and lengthen muscles to increase blood flow and circulation\n10 minute EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Pass Throughs',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Pull Aparts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Drinking Bird Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Glute Walks',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n2-3 sets - no time (no rest)\nSelect 3 - 5 exercises targeting the muscle groups of the program'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Specific Warm up',
        cat: 4,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Deadlift',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hang Power Clean',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, High Hang Squat Cleans',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Front Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Full Clean',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Warm up specific muscles and movements patterns to improve muscle & joint function\n1 - 3 Supersets (barbell technique) - no time (rest as needed between each set)\nSelect 3 - 5 movements at 3 - 5 reps per movement specifically focusing on technique'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Priority Movement',
        cat: 9,
        rounds: 6,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Full (Squat) Clean / Full (Squat) Snatch',
          },
        ],
        min: 20,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on the specific Olympic Weightlifting movement. E.g, Clean & Jerk or Snatch\nFor time timer set to 20 minutes - Select 5 to 7 sets (Rounds)\nWork up until the timer (rest as needed between sets)\nSet 1 - 4 reps @ 60%\nSet 2 - 3 reps @ 65%\nSet 3 - 2 reps @ 70%\nSet 4 - 2 reps @ 75%\nSet 5 - 1 rep @ 80%\nSet 6 - 1 rep @ 80%\nSet 7 - 1 rep @ 80%'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Heavy Pulls at a high percentage',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on building strength specific to the priority movement of the program\n3 - 5 sets @ 90 - 110% No time timer - (rest as needed)\nSelect accessory movement to support the main priority movement'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Snatch Balance, Jerk Balance, Muscle Snatch or Muscle Cleans',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: ' Focusing on building skills specific to the priority movement of the program\n3 - 5 Sets @ 30 - 50% No time timer - (rest as needed)\nSelect accessory movement to support skills of the priority weightlifting movements (Snatch and Clean & Jerk)'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Power Snatch, Power Clean & Jerk, Positional Drills (3 position Cleans or Snatches)',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on building skills specific to the priority movement of the program\n3 - 5 Sets @ 60 - 70% No time timer - (rest as needed)\nSelect accessory movement to support skills of the priority weightlifting movements (Snatch and Clean & Jerk)'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats or Front Squats',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focused on building stronger, denser muscle fibers\n3 - 5 Sets - No time timer - Rest 1 - 2 minutes after each set\n1 Compound movement @ 2 - 5 reps (between 60 - 80%)'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\n10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements'
      })
    }
    if(type === 'weight3') {
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Stretch and lengthen muscles to increase blood flow and circulation\n10 minute EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Pass Throughs',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Pull Aparts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Drinking Bird Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Glute Walks',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n2-3 sets - no time (no rest)\nSelect 3 - 5 exercises targeting the muscle groups of the program'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Specific Warm up',
        cat: 4,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Deadlift',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hang Power Clean',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, High hang Squat Cleans',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Front Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Full Clean',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Warm up specific muscles and movements patterns to improve muscle & joint function\n1 - 3 Supersets (barbell technique) - no time (rest as needed between each set)\nSelect 3 - 5 movements at 3 - 5 reps per movement specifically focusing on technique'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Priority Movement',
        cat: 9,
        rounds: 6,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'Full (Squat) Clean / Full (Squat) Snatch',
          }
        ],
        min: 20,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on the specific Olympic Weightlifting movement. E.g, Clean & Jerk or Snatch\nFor time timer set to 20 minutes - Select 5 to 7 sets (Rounds)\nWork up until the timer (rest as needed between sets)\nSet 1 - 4 reps @ 60%\nSet 2 - 3 reps @ 65%\nSet 3 - 2 reps @ 70%\nSet 4 - 2 reps @ 75%\nSet 5 - 1 rep @ 80%\nSet 6 - 1 rep @ 80%\nSet 7 - 1 rep @ 80%'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Heavy Pulls at a high percentage',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on building strength specific to the priority movement of the program\n3 - 5 sets @ 90 - 110% No time timer - (rest as needed)\nSelect accessory movement to support the main priority movement '
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Power Snatch, Power Clean & Jerk, positional drills (3 position Cleans or Snatches)',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focusing on building skills specific to the priority movement of the program\n3 - 5 Sets @ 60 - 70% No time timer - (rest as needed)\nSelect accessory movement to support skills of the priority weightlifting movements (Snatch and Clean & Jerk)'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory Movement',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Bridges, Box Jumps, Push Ups, Plank Holds, etc.',
          }
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: '3 - 5 Sets @60 - 70% effort - No time timer - (rest as needed)\nSelect accessory movement to support skills of the priority weightlifting movements (Snatch and Clean & Jerk)'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats / Deadlifts',
          }
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Focused on building stronger, denser muscle fibers\n3 - 5 Sets - No time timer - Rest 1 - 2 minutes after each set\n1 Compound movement @ 2 - 5 reps (between 60 - 80%)'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          }
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\n10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements'
      })
    }
    if(type === 'body1') {
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Drinking Bird Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Walks',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n2-3 sets - no time (no rest)\nSelect 3 - 5 exercises targeting the muscle groups of the program\nE.g, Lunge with rotation, Kang squats, Banded good mornings, Drinking bird deadlifts, Glute walks'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 5,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats / Deadlifts / Bench Press',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n4 - 6 sets - No time (rest as needed)\n1 Compound movement (60 - 90%) @ 3 - 6 reps\nNote: Aim to add the larger movements earlier in the program.'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 5,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Split Squats / Hip Thrusts / Chest Fly',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n4 - 6 sets - No time (rest as needed)\n1 Compound movement (60 - 90%) @ 3 - 6 reps'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 5,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Leg Press / Hamstring Curls / Incline Press',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n4 - 6 sets - No time (rest as needed)\n1 Compound movement (60 - 90%) @ 3 - 6 reps'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Seated Leg Extension / Weighted Step Ups / Dips',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target supporting muscle groups under fatigue at a lighter weight to increase strength, stamina and endurance\n3 - 5 sets - No time (rest as needed)\n1 Accessory strength movement (40 - 60%) @ 8 - 12 reps'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Bridge / Nordic Hamstring Curls / Cable Fly',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target supporting muscle groups under fatigue at a lighter weight to increase strength, stamina and endurance\n3 - 5 sets - No time (rest as needed)\n1 Accessory strength movement (40 - 60%) @ 8 - 12 reps'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Cool Down',
        cat: 5,
        rounds: 10,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Cardiovascular Exercise - Walk/ Run, Row or Bike',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Slowing down the heart rate as an active recovery to help flush lactic acid build up\n5 - 10 minutes\nCardiovascular exercise'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Stretch / Mobility',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'After working muscle groups to fatigue. Maintaining muscle health, mobility, length and function\n10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch.\nSelect 5 stretches/ movements\nE.g, Foam roll Quads & Glutes and Stretch Quads, Hamstrings & Glutes'
      })
    }

    if(type === 'body2') {
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 3,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Drinking Bird Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Walks',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n2-3 sets - no time (no rest)\nSelect 3 - 5 exercises targeting the muscle groups of the program \nE.g, Lunge with rotation, Kang squats, Banded good mornings, Drinking bird deadlifts, Glute walks'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 5,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Compound Movement - E.g, Back Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Strength Accessory Movement - E.g, Split Squats',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n4 - 6 Supersets - No working time - 60 - 90 seconds rest after each set\n1 Compound movement (40 - 60%) @ 8 - 12 reps\n+ 1 Strength accessory movement (40 - 60%) 8 - 12 reps '
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Compound',
        cat: 2,
        rounds: 5,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Compound Movement - E.g, Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Strength Accessory Movement - E.g, Weighted Step Ups',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target and build strength through muscle groups increasing muscle mass\n4 - 6 Supersets - No working time - 60 - 90 seconds rest after each set\n1 Compound movement (40 - 60%) @ 8 - 12 reps\n+ 1 Strength accessory movement (40 - 60%) 8 - 12 reps '
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Accessory',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Leg Press',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hamstring Curls',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Hip Thrusts',
          },
        ],
        min: 0,
        sec: 45,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target supporting muscle groups under fatigue at a lighter weight to increase strength, stamina and endurance\n3 - 5 sets - No time - 45 - 60 seconds rest after each set\n3 Accessory movements (40 - 60%) @ 8 - 12 reps'
      })
      blocks.push({
        preset: '',
        type: 2,
        name: 'Accessory',
        cat: 12,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Weighted Wall Sit',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Alternating DB Lunges',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Bridge Hold',
          },
        ],
        min: 0,
        sec: 45,
        min2: 0,
        sec2: 15,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target supporting muscle groups under fatigue at a lighter weight to increase strength, stamina and endurance\n3 - 5 sets - Interval timer - 45 - 60 seconds work / 15 - 30 seconds rest after each set\n3 Accessory movements'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Cool Down',
        cat: 5,
        rounds: 1,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Walk / Run, Row or Bike',
          },
        ],
        min: 5,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: '5 minute active recovery'
      })
      blocks.push({
        preset: '',
        type: 5,
        name: 'Stretch / Mobility',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },{
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: '10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements\nE.g, Foam roll Quads & Glutes and Stretch Quads, Hamstrings & Glutes'
      })
    }

    if(type === 'body3') {
      blocks.push({
        preset: '',
        type: 4,
        name: 'Activation / Warm up',
        cat: 4,
        rounds: 4,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Walk / Run, Row or Bike',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Lunge with Thoracic Rotation',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Kang Squats',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Banded Good Mornings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Drinking Bird Deadlifts',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Glute Walks',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: false,
        showInfo: false,
        instructions: 'Increase heart rate, warm up and lubricate joints to improve muscle & joint function\n2 - 5 minutes cardiovascular exercise\n2-3 sets - no time (no rest)\nSelect 3 - 5 exercises targeting the muscle groups of the program'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Strength Compound',
        cat: 2,
        rounds: 6,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Back Squats x 6 reps @ 60%',
          },
        ],
        min: 2,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target muscle groups after fatigue at a lighter weight to increase strength, stamina and endurance\n6 x E2M Timer (Every 2 minutes)\n1 compound movement @ 50 - 60% x 6 - 8 reps\nRest remaining time'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Stamina Strength',
        cat: 10,
        rounds: 5,
        emom: true,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Compound Movement E.g, Power Cleans @ 6 - 8 reps',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Dynamic (Explosive) Movement E.g, Wall Ball Shots @ 8 - 12 reps',
          },
        ],
        min: 2,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'arget muscle groups after fatigue at a lighter weight to increase strength, stamina and endurance\n5 x E2M Timer (Every 2 minutes)\n1 Compound movement @ 40 - 60% x 6 - 8 reps + 1 Dynamic (explosive) movement @ 8 - 12 reps'
      })
      blocks.push({
        preset: '',
        type: 4,
        name: 'Strength Accessory',
        cat: 12,
        rounds: 5,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, 10 x Weighted Step Ups',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, 20 x Bent over Row',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, 10 x Overhead Sit Up',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, 20 x Lateral Plank Twists',
          },
        ],
        min: 0,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'Target muscle groups after fatigue at a lighter weight to increase strength, stamina and endurance\n4 - 6 sets - Not time (rest as needed)\nSelect 2 - 4 movements to superset'
      })
      blocks.push({
        preset: '',
        type: 1,
        name: 'Mobility / Stretch',
        cat: 3,
        rounds: 2,
        emom: false,
        notes: '',
        exercises: [
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Foam Roll Glutes',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Quads',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Hamstrings',
          },
          {
            id: 'placeholder',
            name: 'Placeholder',
            notes: 'E.g, Stretch Glutes',
          },
        ],
        min: 1,
        sec: 0,
        min2: 0,
        sec2: 0,
        show: false,
        logResults: true,
        showInfo: false,
        instructions: 'After working muscle groups to fatigue. Maintaining muscle health, mobility, length and function\n10 x EMOM (Every minute on the minute) - Spend 60 seconds on each stretch\nSelect 5 stretches/ movements\nE.g, Foam roll Quads & Glutes and Stretch Quads, Hamstrings & Glutes'
      })
    }

    return blocks
  }


}


const b = new HelperProgramming();
export default b;