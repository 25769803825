import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

import actions_en from "./locale/en/actions.json";
import common_en from "./locale/en/common.json";
import admin_en from "./locale/en/admin.json";
import clients_en from "./locale/en/clients.json";
import billing_en from "./locale/en/billing.json";
import nav_en from "./locale/en/nav.json";
import auth_en from "./locale/en/auth.json";
import calendar_en from "./locale/en/calendar.json";
import overview_en from "./locale/en/overview.json";
import home_en from "./locale/en/home.json";
import empty_en from "./locale/en/empty.json";
import forminfo_en from "./locale/en/forminfo.json";
import header_en from "./locale/en/header.json";
import health_en from "./locale/en/health.json";
import messaging_en from "./locale/en/messaging.json";
import modals_en from "./locale/en/modals.json";
import program_en from "./locale/en/program.json";
import widgets_en from "./locale/en/widgets.json";
import account_en from "./locale/en/account.json";


i18n
  .use(Backend)
  .use(LanguageDetector)    
  .use(initReactI18next)
  .init({

    resources: {
      en: {
        common: common_en,
        nav: nav_en,
        admin: admin_en,
        actions: actions_en,
        clients: clients_en,
        overview: overview_en,
        billing: billing_en,
        calendar: calendar_en,
        auth: auth_en,
        home: home_en,
        empty: empty_en,
        forminfo: forminfo_en,
        header: header_en,
        health: health_en,
        messaging: messaging_en,
        modals: modals_en,
        program: program_en,
        widgets: widgets_en,
        account: account_en,
      },
      /*de: {
        common: common_es,
        nav: nav_es,
        actions: actions_es,
        clients: clients_es
      },*/
  },


    lng: "en",   //default language
    //lng: window.navigator.language,
    //lng: "es",
    fallbackLng: "en", //when specified language translations not present 

//then fallbacklang translations loaded.

    debug: true,
      backend: {
      /* translation file path */
      //loadPath: "/assets/{{ns}}/{{lng}}.json",
      //loadPath: "/locale//{{lng}}/{{ns}}.json",
    },
 

/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */


    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },

    react: {
      //wait: true,
      useSuspense: false
    },

    

  });


export default i18n;