import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import HelperEmpty from '../../helper/EmptyLarge';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataEmpty  = [
  {
    id: 'activity',
    title: 'empty:activity.title',
    text: "empty:activity.text",
    icon: 'activity',
  },
  {
    id: 'clientactivity',
    title: "empty:clientactivity.title",
    text: "empty:clientactivity.text",
    icon: 'activity',
  },
  {
    id: 'community',
    title: 'empty:community.title',
    text: 'empty:community.text',
    icon: 'community'
  },
  {
    id: 'communityoff',
    title: "empty:communityoff.title",
    text: "empty:communityoff.text",
    icon: 'community',
    button: 'empty:communityoff.button',
    link: ROUTES.ADMINSETTINGS
  },
  {
    id: 'clients',
    title: 'empty:clients.title',
    text: 'empty:clients.text',
    icon: 'clients',
    button: 'empty:clients.button',
    link: ROUTES.NEWCLIENT,
    video: 'yes',
  },
  {
    id: 'noclients',
    title: 'empty:noclients.title',
    text: 'empty:noclients.text',
    icon: 'clients',
    video: 'yes',
  },
  {
    id: 'clientgroups',
    title: 'empty:clientgroups.title',
    text: 'empty:clientgroups.text',
    icon: 'groups',
    button: 'empty:clientgroups.button',
    link: ROUTES.CLIENTSGROUPSNEW
  },
  {
    id: 'programs',
    title: 'empty:programs.title',
    text: 'empty:programs.text',
    icon: 'programs',
    button: 'empty:programs.button',
    link: ROUTES.NEWPROGRAM
  },
  {
    id: 'noprograms',
    title: 'empty:noprograms.title',
    text: '',
    icon: 'programs',
  },
  {
    id: 'noprogramsyet1',
    title: 'empty:noprogramsyet1.title',
    text: 'empty:noprogramsyet1.text',
    icon: 'programs',
  },
  {
    id: 'noprogramsyet2',
    title: 'empty:noprogramsyet2.title',
    text: 'empty:noprogramsyet2.text',
    icon: 'programs',
    button: 'empty:noprogramsyet2.button',
    link: '-func-'
  },
  {
    id: 'nonutrition',
    title: 'empty:nonutrition.title',
    text: 'empty:nonutrition.text',
    icon: 'nutrition',
    button: 'empty:nonutrition.button',
    link: ROUTES.NEWNUTRITION
  },
  {
    id: 'nutenabled',
    title: 'empty:nutenabled.title',
    text: 'empty:nutenabled.text',
    icon: 'nutrition',
  },
  {
    id: 'nonutrition2',
    title: 'empty:nonutrition2.title',
    text: '',
    icon: 'nutrition',
  },
  {
    id: 'nohabits',
    title: "empty:nohabits.title",
    text: "empty:nohabits.text",
    icon: 'habits',
    button: 'empty:nohabits.button',
    link: ROUTES.NEWHABIT
  },
  {
    id: 'nobenchmark',
    title: "empty:nobenchmark.title",
    text: "empty:nobenchmark.text",
    icon: 'programs',
  },
  {
    id: 'nobenchmark2',
    title: "empty:nobenchmark2.title",
    text: "empty:nobenchmark2.text",
    icon: 'programs',
  },
  {
    id: 'nopayments',
    title: "empty:nopayments.title",
    text: "empty:nopayments.text",
    icon: 'payments',
  },
  {
    id: 'nopayments2',
    title: "empty:nopayments2.title",
    text: "empty:nopayments2.text",
    icon: 'payments',
  },
  {
    id: 'nopaymentsclient',
    title: "empty:nopaymentsclient.title",
    text: "empty:nopaymentsclient.text",
    icon: 'payments',
  },
  {
    id: 'noproducts',
    title: "empty:noproducts.title",
    text: "empty:noproducts.text",
    icon: 'products',
    button: 'empty:noproducts.button',
    link: '/billing/products/new'
  },
  {
    id: 'noinvoices',
    title: "empty:noinvoices.title",
    text: "empty:noinvoices.text",
    icon: 'invoices',
  },
  {
    id: 'noinvoices2',
    title: "empty:noinvoices2.title",
    text: "empty:noinvoices2.text",
    icon: 'invoices',
  },
  {
    id: 'noexpenses',
    title: "empty:noexpenses.title",
    text: "empty:noexpenses.text",
    icon: 'expenses',
  },
  {
    id: 'noexpenses2',
    title: "empty:noexpenses2.title",
    text: "empty:noexpenses2.text",
    icon: 'expenses',
  },
  {
    id: 'finreports',
    title: "empty:finreports.title",
    text: "empty:finreports.text",
    icon: 'reports',
    button: 'empty:finreports.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'clientreports',
    title: "empty:clientreports.title",
    text: "empty:clientreports.text",
    icon: 'reports',
    button: 'empty:clientreports.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'admin',
    title: "empty:admin.title",
    text: "empty:admin.text",
    icon: 'insights',
    button: 'empty:admin.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'staff',
    title: "empty:staff.title",
    text: "empty:staff.text",
    icon: 'clients',
  },
  {
    id: 'noforms',
    title: "empty:noforms.title",
    text: "empty:noforms.text",
    icon: 'documents',
    button: 'empty:noforms.button',
    link: ROUTES.ADMINNEWDOC
  },
  {
    id: 'noleads',
    title: "empty:noleads.title",
    text: "empty:noleads.text",
    icon: 'leads',
  },
  {
    id: 'noemails',
    title: "empty:noemails.title",
    text: "empty:noemails.text",
    icon: 'emails',
  },
  {
    id: 'recurring',
    title: "empty:recurring.title",
    text: "empty:recurring.text",
    icon: 'recurring',
    button: 'empty:recurring.button',
    link: ROUTES.NEWRECSESSION
  },
  {
    id: 'noarchive',
    title: "empty:noarchive.title",
    text: "empty:noarchive.text",
    icon: 'newsession',
  },
  {
    id: 'clientgroups-locked',
    title: 'empty:clientgroups-locked.title',
    text: "empty:clientgroups-locked.text",
    icon: 'groups',
  },
  {
    id: 'newclient-locked',
    title: 'empty:newclient-locked.title',
    text: "empty:newclient-locked.text",
    icon: 'newsession',
  },
  {
    id: 'newimport-locked',
    title: 'empty:newimport-locked.title',
    text: "empty:newimport-locked.text",
    icon: 'newsession',
  },
  {
    id: 'programs-locked',
    title: 'empty:programs-locked.title',
    text: "empty:programs-locked.text",
    icon: 'programs',
  },
  {
    id: 'trainingplans-locked',
    title: 'empty:trainingplans-locked.title',
    text: "empty:trainingplans-locked.text",
    icon: 'plans',
  },
  {
    id: 'nutrition-locked',
    title: 'empty:nutrition-locked.title',
    text: "empty:nutrition-locked.text",
    icon: 'nutrition',
  },
  {
    id: 'habits-locked',
    title: "empty:habits-locked.title",
    text: "empty:habits-locked.text",
    icon: 'habits',
    button: 'empty:habits-locked.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'habits-locked2',
    title: 'empty:habits-locked2.title',
    text: "empty:habits-locked2.text",
    icon: 'habits',
  },
  {
    id: 'billing-locked',
    title: 'empty:billing-locked.title',
    text: "empty:billing-locked.text",
    icon: 'newsession',
  },
  {
    id: 'debits-locked',
    title: 'empty:debits-locked.title',
    text: "empty:debits-locked.text",
    icon: 'debits',
  },
  {
    id: 'products-locked',
    title: 'empty:products-locked.title',
    text: "empty:products-locked.text",
    icon: 'products',
  },
  {
    id: 'expenses-locked',
    title: 'empty:expenses-locked.title',
    text: "empty:expenses-locked.text",
    icon: 'expenses',
  },
  {
    id: 'invoices-locked',
    title: "empty:invoices-locked.title",
    text: "empty:invoices-locked.text",
    icon: 'invoices',
    button: 'empty:invoices-locked.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'invoices-locked2',
    title: 'empty:invoices-locked2.title',
    text: "empty:invoices-locked2.text",
    icon: 'invoices',
  },
  {
    id: 'payment-locked',
    title: 'empty:payment-locked.title',
    text: "empty:payment-locked.text",
    icon: 'payments',
  },
  {
    id: 'admin-locked',
    title: 'empty:admin-locked.title',
    text: "empty:admin-locked.text",
    icon: 'newsession',
  },
  {
    id: 'stats-locked',
    title: 'empty:stats-locked.title',
    text: "empty:stats-locked.text",
    icon: 'insights',
  },
  {
    id: 'staff-locked',
    title: 'empty:staff-locked.title',
    text: "empty:staff-locked.text",
    icon: 'clients',
  },
  {
    id: 'forms-locked',
    title: 'empty:forms-locked.title',
    text: "empty:forms-locked.text",
    icon: 'documents',
  },
  {
    id: 'emails-locked',
    title: 'empty:emails-locked.title',
    text: "empty:emails-locked.text",
    icon: 'emails',
  },
  {
    id: 'documents-locked',
    title: "empty:documents-locked.title",
    text: "empty:documents-locked.text",
    icon: 'documents',
    button: 'empty:documents-locked.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'leads-locked',
    title: 'empty:leads-locked.title',
    text: "empty:leads-locked.text",
    icon: 'leads',
  },
  {
    id: 'leads-locked2',
    title: 'empty:leads-locked2.title',
    text: "empty:leads-locked2.text",
    icon: 'leads',
    button: 'empty:leads-locked2.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'settings-locked',
    title: 'empty:settings-locked.title',
    text: "empty:settings-locked.text",
    icon: 'settings',
  },
  {
    id: 'recurring-locked',
    title: 'empty:recurring-locked.title',
    text: "empty:recurring-locked.text",
    icon: 'recurring',
  },
  {
    id: 'roster-locked',
    title: 'empty:roster-locked.title',
    text: "empty:roster-locked.text",
    icon: 'roster',
  },
  {
    id: 'roster-locked2',
    title: 'empty:roster-locked2.title',
    text: "empty:roster-locked2.text",
    icon: 'roster',
    button: 'empty:roster-locked2.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'insights-locked',
    title: 'empty:insights-locked.title',
    text: "empty:insights-locked",
    icon: 'insights',
  },
  {
    id: 'insights-locked2',
    title: 'empty:insights-locked2.title',
    text: "empty:insights-locked2.text",
    icon: 'insights',
    button: 'empty:insights-locked2.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'reports-locked',
    title: 'empty:reports-locked.title',
    text: "empty:reports-locked.text",
    icon: 'reports',
  },
  {
    id: 'reports-locked2',
    title: 'empty:reports-locked2.title',
    text: "empty:reports-locked2.text",
    icon: 'reports',
    button: 'empty:reports-locked2.button',
    link: ROUTES.SUBSCRIPTION
  },
  {
    id: 'calendar-locked',
    title: 'empty:calendar-locked.title',
    text: "empty:calendar-locked.text",
    icon: 'newsession',
  },
  {
    id: 'calendar-locked2',
    title: 'empty:calendar-locked2.title',
    text: "empty:calendar-locked2.text",
    icon: 'newsession',
  },
  {
    id: 'sessions-locked',
    title: 'empty:sessions-locked.title',
    text: "empty:sessions-locked.text",
    icon: 'newsession',
  },
  {
    id: 'family-locked',
    title: 'empty:family-locked.title',
    text: "empty:family-locked.text",
    icon: 'newsession',
  },
  {
    id: 'healthlog-locked',
    title: 'empty:healthlog-locked.title',
    text: "empty:healthlog-locked.text",
    icon: 'newsession',
  },
  {
    id: 'results-locked',
    title: 'empty:results-locked.title',
    text: "empty:results-locked.text",
    icon: 'newsession',
  },
  {
    id: 'running-locked',
    title: 'empty:running-locked.title',
    text: "empty:running-locked.text",
    icon: 'newsession',
  },
  {
    id: 'benchmark-locked',
    title: 'empty:benchmark-locked.title',
    text: "empty:benchmark-locked.text",
    icon: 'programs',
  },
  {
    id: 'health-locked',
    title: 'empty:health-locked.title',
    text: "empty:health-locked.text",
    icon: 'nutrition',
  },
  {
    id: 'programming-locked',
    title: 'empty:programming-locked.title',
    text: "empty:programming-locked.text",
    icon: 'programs',
  },
  {
    id: 'noplansclient1',
    title: 'empty:noplansclient1.title',
    text: "empty:noplansclient1.text",
    icon: 'plans',
  },
  {
    id: 'noplansclient2',
    title: 'empty:noplansclient2.title',
    text: "empty:noplansclient2.text",
    icon: 'plans',
    button: 'empty:noplansclient2.button',
    link: '-func-'
  },
  {
    id: 'chat',
    title: 'empty:chat.title',
    text: "empty:chat.text",
    icon: 'community',
  },
  {
    id: 'affiliates',
    title: 'empty:affiliates.title',
    text: "empty:affiliates.text",
    icon: 'clients',
  },
  {
    id: 'nolocations',
    title: 'empty:nolocations.title',
    text: "empty:nolocations.text",
    icon: 'locations',
  },
  {
    id: 'locations-locked',
    title: 'empty:locations-locked.title',
    text: "empty:locations-locked.text",
    icon: 'locations',
  },
]



class ListEmpty extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      type: props.type ?? '',
      var: props.var
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
      type: props.type ?? '',
      var: props.var
    }
  }


  renderVideo(data) {
    if(data !== undefined && data !== '') {
      return (
        <div className="video mb-20" onClick={() => this.props.clickVideo()}>
          <img src="/img/peetie.svg" alt="Video" className="mr-10"/>
          <p>Click to watch a short overview video to get started</p>
        </div>
      )
    } else {
      return <div></div>
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataEmpty) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      text = lang.t(data.text).replace('$var', this.state.var)
      if(this.state.type === 'simple') {
        return (
          <div className="empty large pt-60 clear">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmpty.renderImage(data.icon)}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t(data.title)}</h3>
              <p className="mb-20">{text}</p>
              {this.renderVideo(data.video)}
            </div>
          </div>
        )
      } else {
        if(data.link === '-func-') {
          return (
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmpty.renderImage(data.icon)}
              </div>
              <div className="data">
                <h3 className="mb-20">{lang.t(data.title)}</h3>
                <p className="mb-20">{lang.t(data.text)}</p>
                {this.renderVideo(data.video)}
                <button className="btn tertiary width-12 add" onClick={() => this.props.clickMainButton()}>{lang.t(data.button)}</button>
              </div>
            </div>
          )
        } else {
          return (
            <div className="empty large pt-60 clear">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmpty.renderImage(data.icon)}
              </div>
              <div className="data">
                <h3 className="mb-20">{lang.t(data.title)}</h3>
                <p className="mb-20">{lang.t(data.text)}</p>
                {this.renderVideo(data.video)}
                <Link to={data.link} className="btn tertiary width-12 add">{lang.t(data.button)}</Link>
              </div>
            </div>
          )
        }
      }
    }
  }
}


export default withTranslation(['empty'])(ListEmpty);