import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import Header from '../../components/Headers/form';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewExercisePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat: 0,
      type: 0,
      muscles: [],
      values: ['', ''],
      variations: [{
        name: '',
        desc: '',
        tool: 0,
        image: '',
        video: ''
      }],
      files: [null],
      error: false,
      errorText: '',
      tracks: 'reps'
    };
  }


  componentDidMount() {
    Connector.setUser()  
    document.title = 'New Movement Types - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    
  }


  setImage(file, index) {
    var tmp = this.state.files
    var tmp2 = this.state.variations
    tmp[index] = file
    tmp2[index].image = URL.createObjectURL(file)
    this.setState({
      files: tmp,
      variations: tmp2
    })
  }


  displayImage(index) {
    if(this.state.files[index] === null) {
      return (
       <div className="ex-image"></div>
      )
    } else {
      return (
        <div className="ex-image" style={{backgroundImage: 'url('+this.state.variations[index].image+')'}}></div>
      )
    }
  }



  // Interactive stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  setCat(event) {
    this.setState({
      cat: event.target.value
    })
  }


  setType(event) {
    this.setState({
      type: event.target.value
    })
  }


  onChangeName(event, index) {
    var tmp = this.state.variations
    tmp[index].name = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeDesc(event, index) {
    var tmp = this.state.variations
    tmp[index].desc = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeVideo(event, index) {
    var tmp = this.state.variations
    tmp[index].video = event.target.value
    this.setState({
      variations: tmp
    })
  }


  setTool(event, index) {
    var tmp = this.state.variations
    tmp[index].tool = event.target.value
    this.setState({
      variations: tmp
    })
  }


  getMuscles(index) {
    if(this.state.muscles.indexOf(index) !== -1) {
      return "ex-muscle active"
    } else {
      return "ex-muscle"
    }
  }


  setMuscles(index) {
    var add = true
    var tmp = this.state.muscles
    for(var i=tmp.length-1; i>=0; i--) {
      if(tmp[i] === index) {
        add = false
        tmp.splice(i, 1)
      }
    }
    if(add) {
      tmp.push(index)
    }
    this.setState({
      muscles: tmp
    })
  }



  // Create exercises ------------------------------------------------------------



  checkCreateExercise() {
    var passed = true
    /*if(this.state.values[0] === '' || this.state.values[1] === '' || this.state.muscles.length === 0) {
      passed = false
    }*/
    for(var item of this.state.variations) {
      if(item.name === '') {
        passed = false
      }
    }
    if(passed) {
      this.createExercise()
    } else {
      this.showError()
    }
  }


  createExercise() {
    global.badgeLocked = true
    var user = global.uid
    var ids = []
    var names = []
    var tools = []
    var descs = []
    var images = []
    var videos = []
    var muscles = ""
    var obj = Firebase.database().ref('/exercises/').push()
    for(var msl of this.state.muscles) {
      muscles += ","+msl
    }
    for(let item of this.state.variations) {
      var img = item.image
      var key = Firebase.database().ref('/exercises/'+obj.key).push()
      if(item.image !== '') {
        img = obj.key+'-'+key.key+'.jpg'
      }
      ids.push(key.key)
      names.push(item.name)
      tools.push(parseInt(item.tool))
      descs.push(item.desc)
      images.push(img)
      videos.push(item.video)
    }
    this.uploadImages(obj.key, ids)
    obj.set({
      name: names[0],
      desc: "-",
      muscles: [0],
      cat: 0,
      type: 0,
      user: user,
      subIds: ids,
      subTitles: names,
      subDescs: descs,
      subTools: tools,
      subImages: images,
      subVideos: videos,
      subMuscles: [muscles],
      subUnits: [this.state.tracks]
    }).then((data)=>{
      global.message = lang.t('messaging:message.movementcreated')
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'exercise');
      }, 1000);
      this.props.history.push(ROUTES.EXERCISES)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  showError() {
    var txt = ''
    if(this.state.values[0] === '') {
      txt = lang.t('program:error.movementtype')
    }
    if(this.state.values[1] === '') {
      if(txt !== '') { txt += ', ' }
      txt += lang.t('program:error.movementdescription')
    }
    if(this.state.muscles.length === 0) {
      if(txt !== '') { txt += ', ' }
      txt += lang.t('program:error.muscle')
    }
    for(var item of this.state.variations) {
      if(item.name === '') {
        if(txt !== '') { txt += ', ' }
        txt += lang.t('program:error.movementname')
      }
    }
    this.setState({
      error: true,
      errorText: txt
    })
  }


  uploadImages(key, ids) {
    for(var i=0; i<ids.length; i++) {
      if(this.state.files[i] !== null) {
        const sr = Firebase.storage().ref()
        const fr = sr.child('images/exercises/'+key+'-'+ids[i]+'.jpg')
        fr.put(this.state.files[i]).then(() => {
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    } 
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    if(this.state.error) {
      return (
        <div className="inline-message red">
          <strong>{lang.t('program:error.pleasereview')}</strong><br/>{this.state.errorText}
        </div>
      )
    }
  }


  renderDelete(index) {
    if(index !== 0) {
      return (
        <button className="btn tertiary close rgt" onClick={() => this.deleteVariation(index)}>{lang.t('common:button.delete')}</button>
      )
    }
  }


  renderExercises() {
    return (
      <div>
        {this.state.variations.map((item, index) => (
          <div key={index}>
            <div className="box exercise">
              <div className="col-3">
                {this.displayImage(index)}
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], index);}}/>
              </div>
              <div className="col-4 form">
                <label>{lang.t('program:form.name')}*</label>
                <input type="text" value={this.state.variations[index].name} onChange={(val) => this.onChangeName(val, index)}/>
                <label>{lang.t('program:form.video2')}</label>
                <input type="text" value={this.state.variations[index].video} onChange={(val) => this.onChangeVideo(val, index)}/>
                <label>{lang.t('program:form.equipment')}</label>
                <select value={this.state.variations[index].tool} onChange={event => this.setTool(event, index)}>
                  {global.exTools.map((item, index) => (
                    <option key={index} value={index}>{item} ({lang.t(global.exToolsCount[index])})</option>
                  ))}
                </select>
              </div>
              <div className="col-4 form">
                <label>{lang.t('program:form.description')}</label>
                <textarea className="big" value={this.state.variations[index].desc} onChange={(val) => this.onChangeDesc(val, index)}></textarea>
              </div>
              <div className="col-1">
                {this.renderDelete(index)}
              </div>
              <div className="clear"></div>
            </div>
            <div className="space-20"></div>
          </div>
        ))}
      </div>
    )
  }


  renderTracks() {
    var co = false
    for(var item of global.exToolsWeight) {
      if(String(item) === String(this.state.variations[0].tool)) {co = true}
    }
    if(co) {
      return (
        <div>
          <div style={{height: 5}}></div>
          <label>{lang.t('program:label.alsotracksweight')}</label>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content form-container withtabs">
          {this.renderError()}
          <div className="mt-20">
          <div className="box">
            <div className="clear none">
              {this.displayImage(0)}
              <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], 0);}}/>
            </div>
            <div className="form-item">
              <label>{lang.t('program:form.name')}*</label>
              <input type="text" value={this.state.variations[0].name} onChange={(val) => this.onChangeName(val, 0)}/>
            </div>
            <div className="form-item">
              <label>{lang.t('program:form.video2')}</label>
              <input type="text" value={this.state.variations[0].video} onChange={(val) => this.onChangeVideo(val, 0)}/>
            </div>
            <div className="form-item">
              <label>{lang.t('program:form.equipment')}</label>
              <select value={this.state.variations[0].tool} onChange={event => this.setTool(event, 0)}>
                {global.exTools.map((item, index) => (
                  <option key={index} value={index}>{lang.t(item)}</option>
                ))}
              </select>
            </div>
            <div className="form-item">
              <label>{lang.t('program:form.tracks')}</label>
              <div className="radios">
                <button className={this.state.tracks === 'reps' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'reps'})}>{lang.t('program:form.tracks.label1')}</button>
                <button className={this.state.tracks === 'dist' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'dist'})}>{lang.t('program:form.tracks.label2')}</button>
                <button className={this.state.tracks === 'cals' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'cals'})}>{lang.t('program:form.tracks.label3')}</button>
                <button className={this.state.tracks === 'time' ? 'radio col-3 active' : 'radio col-3'} onClick={() => this.setState({tracks: 'time'})}>{lang.t('program:form.tracks.label4')}</button>
              </div>
              {this.renderTracks()}
            </div>
            <div className="form-item">
              <label>{lang.t('program:form.description')}</label>
              <textarea value={this.state.variations[0].desc} onChange={(val) => this.onChangeDesc(val, 0)}></textarea>
            </div>
          </div>
          </div>

          <div className="col-6 sidebar none">
            <div className="muscles none">
              {global.exMuscles.map((item, index) => (
                <div className={'muscle col-3 '+this.getMuscles(index)} key={index} onClick={() => this.setMuscles(index)}>
                  <img src={global.exMusclesImages[index]} alt={item}/>
                  <p>{item}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="clear sv-40"></div>
          <button className="btn primary" onClick={() => this.checkCreateExercise()}>{lang.t('program:button.createmovement')}</button>
          <div className="clear sv-40"></div>
        </div>

        <Header title={lang.t('program:title.newmovement')} link={ROUTES.EXERCISES}/>
        <Navigation active='programming' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['program','common','header','messaging'])(withRouter(withAuthorization(condition)(NewExercisePage)));