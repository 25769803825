import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import 'firebase/functions';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import HelperEmptyImg from '../../helper/EmptyLarge';
import ModalBilling from '../../components/Modals/billing';
import HelperPage from '../../helper/Page';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import InputRadio from '../../components/Form/radio';
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      payments: global.payments,
      paymentsArchive: global.paymentsArchive,
      paymentsList: [],
      expenses: global.expenses,
      products: global.products,
      start: Moment('01/01/2020', 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end: Moment().format('YYYY-MM-DD'),
      currentClient: '',
      currentType: '',
      currentTypeTmp: '',
      utc: false,
      amount: 0,
      amounte: 0,
      net: 0,
      fee: 0,
      cgroup: '',
      created: false,
      totalp: 0,
      totale: 0,
      totali: 0,
      tax: 0,
      gst: 0,
      invoices: [],
      types: [],
      selclients: [],
      product: '',
      category: '',
      location: global.loc,
      showModalInfo: false,
      // CSV stuff
      csvheaders: [
        { label: "Date", key: "date" },
        { label: "Amount", key: "amount" },
        { label: "Payee", key: "payee" },
        { label: "Description", key: "description" },
        { label: "Reference", key: "reference" },
        { label: "Cheque number", key: "cheque" },
        { label: "Gross", key: "gross" },
        { label: "Fees", key: "fees" },
        { label: "Payment method", key: "method" },
      ],
      csvdata: [],
    };
  }


  componentDidMount() {
    HelperPage.initialize('Financial Reports - PT Mate', ROUTES.BILLING)
    HelperPage.checkMessage()
    this._isMounted = true

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.getClients())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsArchiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('expensesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    this.getClients()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getClients() {
    if(this._isMounted) {
      var tmp = []
      for(var item of global.clients) {
        tmp.push(item)
      }
      this.setState({
        clients: tmp
      }, () => {
        this.configureData()
      });
    }
  }


  configureData() {
    if(this._isMounted) {
      if(global.paymentsArchive.length === 0 && global.uidUser !== '') {
        Connector.loadPaymentsArchive((result) => {})
      }
      var tmp1 = [{value: '', name: lang.t('billing:report.cardandother')}, {value: 'Card', name: lang.t('billing:report.cardpayments')}, {value: 'Cash', name: lang.t('billing:report.otherpayments')}, {value: 'exp', name: lang.t('billing:report.expenses')}, {value: 'all', name: lang.t('billing:report.allpaymentsexpenses')}]
      var tmp2 = [{value: '', name: lang.t('billing:label.allclients')}]
      if(global.showHabits) {
        tmp1.push({value: 'inv', name: lang.t('billing:report.invoices')})
      }
      var list = this.state.clients
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var cl of list) {
        tmp2.push({value: cl.id, name: cl.data.name})
      }
      this.setState({
        payments: global.payments,
        paymentsArchive: global.paymentsArchive,
        expenses: global.expenses,
        products: global.products,
        stripe: global.userStripeConnect,
        bank: global.userStripeBank,
        types: tmp1,
        selclients: tmp2,
      })
    }
  }


  configureList() {
    var tmp = []
    var csv = []
    var tmp2 = ""
    var tmpi = []
    var amount = 0
    var amounte = 0
    var net = 0
    var fee = 0
    var totalp = 0
    var totale = 0
    var totali = 0
    var tax = 0
    var gst = 0
    var startStr = this.state.start+' 00:00'
    var endStr = this.state.end+' 23:59'
    var start = Moment(startStr, 'YYYY-MM-DD HH:mm').format('X')
    var end = Moment(endStr, 'YYYY-MM-DD HH:mm').format('X')
    if(this.state.utc) {
      start = Moment(this.state.start, 'YYYY-MM-DD').utc(true).format('X')
      end = Moment(endStr, 'YYYY-MM-DD HH:mm').utc(true).format('X')
    }

    var list = []
    if(global.loc === '') {
      for(var cl of global.clients) {
        list.push(cl.id)
      }
      for(var cl2 of global.clientsInactive) {
        list.push(cl2.id)
      }
    } else {
      for(var loc of global.locations) {
        if(loc.id === global.loc && loc.data.clients !== undefined) {
          list = loc.data.clients
        }
      }
    }
    
    for(var client of this.state.clients) {
      if(this.state.currentClient !== '') {
        if(client.id === this.state.currentClient) {
          tmp2 += ','+client.id
        }
      } else {
        if(list.indexOf(client.id) !== -1) {
          tmp2+=","+client.id
        }
        
      }
    }
    
    var addType = false
    for(var item of this.state.payments) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      addType = false
      if(item.data.timestamp !== undefined) {
        date = Moment(item.data.timestamp, 'X').format('X') 
      }
      if(item.data.type === 'Cash' && (this.state.currentType === 'Cash' || this.state.currentType === '' || this.state.currentType === 'all')) {
        addType = true
      }
      if(item.data.type !== 'Cash' && (this.state.currentType === 'Card' || this.state.currentType === '' || this.state.currentType === 'all')) {
        addType = true
      }
      if(date >= start && date <= end && tmp2.indexOf(item.data.client) !== -1 && addType) {
        tmp.push(item)
        amount += item.data.amount/100
        totalp++
        if(item.data.fee === undefined || item.data.fee !== 0) {
          net += (item.data.amount/100)*0.9775-0.30
          fee += (item.data.amount/100)*0.0225+0.30
        } else {
          net += item.data.amount/100
          fee += 0
        }
        // CSV data
        csv.push({date: Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY'), amount: this.renderAmount(item), payee: this.renderClientName(item.data.client), description: this.renderPaymentName(item), reference: '', cheque: '', gross: (item.data.amount/100), fees: this.renderFeeNum(item), method: (item.data.type === 'Cash' ? 'Other' : 'Card')})
      } 
    }
    for(var iteme of this.state.expenses) {
      var datee = Moment(iteme.data.date, 'X').format('X')
      if(datee >= start && datee <= end && (this.state.currentType === 'all' || this.state.currentType === 'exp')) {
        amounte += iteme.data.amount
        totale++
        if(iteme.data.tax !== undefined) {
          tax += iteme.data.tax
        }
        if(iteme.data.gst !== undefined) {
          gst += iteme.data.gst
        }
        tmp.push({
          id: iteme.id,
          data: {
            id: iteme.id,
            name: iteme.data.name,
            amount: iteme.data.amount,
            type: 'expense',
            timestamp: iteme.data.date,
            date: Moment(iteme.data.date, 'X').format('DD/MM/YYYY HH:mm'),
            client: iteme.data.cat,
            tax: iteme.data.tax,
            gst: iteme.data.gst
          }
        })
        // CSV data
        csv.push({date: Moment(iteme.data.date, 'X').format('DD/MM/YYYY'), amount: "("+iteme.data.amount+")", payee: this.renderClientName(iteme.data.cat), description: iteme.data.name, reference: '', cheque: '', gross: "("+iteme.data.amount+")", fees: 0, method: ''})
      }
    }
    for(var item2 of this.state.paymentsArchive) {
      var date2 = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')
      addType = false
      if(item2.data.timestamp !== undefined) {
        date2 = Moment(item2.data.timestamp, 'X').format('X') 
      }
      if(item2.data.type === 'Cash' && (this.state.currentType === 'Cash' || this.state.currentType === '' || this.state.currentType === 'all')) {
        addType = true
      }
      if(item2.data.type !== 'Cash' && (this.state.currentType === 'Card' || this.state.currentType === '' || this.state.currentType === 'all')) {
        addType = true
      }
      if(date2 >= start && date2 <= end && tmp2.indexOf(item2.data.client) !== -1 && item2.data.client !== '' && addType) {
        tmp.push(item2)
        amount += item2.data.amount/100
        totalp++
        if(item2.data.fee === undefined || item2.data.fee !== 0) {
          net += (item2.data.amount/100)*0.9775-0.30
          fee += (item2.data.amount/100)*0.0225+0.30
        } else {
          net += item2.data.amount/100
          fee += 0
        }
      }
    }

    if(tmp.length > 0) {
      for(var i=tmp.length-1; i>-1; i--) {
        if(this.state.product !== 'sub') {
          if((tmp[i].data.type !== 'expense') && tmp[i].data.name !== this.state.product && this.state.product !== '') {
            tmp.splice(i, 1)
          }
        } else {
          if((tmp[i].data.type !== 'expense') && tmp[i].data.name.indexOf('Invoice') === -1 && tmp[i].data.name.indexOf('Subscription') === -1 && this.state.product !== '') {
            tmp.splice(i, 1)
          }
        }
        if(tmp[i] !== undefined) {
          if((tmp[i].data.type === 'expense') && tmp[i].data.client !== this.state.category && this.state.category !== '') {
            tmp.splice(i, 1)
          }
        }
      }
    }

    for(var inv of global.invoices) {
      var date3 = Moment(inv.data.date, 'X').format('X')
      if(date3 >= start && date3 <= end && tmp2.indexOf(inv.data.client) !== -1) {
        tmpi.push(inv)
        totali++
      } 
    }

    this.setState({
      paymentsList: tmp,
      amount: amount,
      amounte: amounte,
      net: net,
      fee: fee,
      totalp: totalp,
      totale: totale,
      tax: tax,
      gst: gst,
      csvdata: csv,
      invoices: tmpi,
      totali: totali,
    })
  }


  setGroup(event) {
    this.setState({
      cgroup: event,
      currentClient: '',
    }, () => {
      this.setClients()
    })
  }


  setLocation(event) {
    this.setState({
      location: event,
      currentClient: '',
    }, () => {
      this.setClients()
    })
  }


  setClients() {
    var tmp = []
    var list1 = []
    var list2 = []
    var cl = []
    var ids = []
    for(var c1 of global.clients) {
      ids.push(c1.id)
    }

    if(this.state.cgroup === '') {
      list1 = ids
    } else {
      for(var gr of global.clientGroups) {
        if(gr.id === this.state.cgroup && gr.data.clients !== undefined) {
          list1 = gr.data.clients
        }
      }
    }
    
    if(this.state.location === '') {
      list2 = list1
    } else {
      for(var loc of global.locations) {
        if(loc.id === this.state.location && loc.data.clients !== undefined) {
          list2 = loc.data.clients
        }
      }
    }
    
    var tmp2 = [{value: '', name: lang.t('billing:label.all clients')}]
    for(var client of global.clients) {
      if(list1.indexOf(client.id) !== -1 && list2.indexOf(client.id) !== -1) {
        tmp.push(client)
        tmp2.push({value: client.id, name: client.data.name})
      }
    }

    this.setState({
      clients: tmp,
      selclients: tmp2,
    })
  }


  renderPaymentName(item) {
    var label = item.data.name
    if(item.data.subscription !== undefined && item.data.subscription !== '' && this.state.item !== null) {
      for(var sub of this.state.products) {
        if(sub.id === item.data.subscription) {
          label = sub.data.name
        }
      }
    }
    if(item.data.name.indexOf('Invoice ') !== -1 || item.data.name.indexOf('Subscription ') !== -1) {
      label = lang.t('billing:label.membership')
    }
    return label
  }


  renderClientName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    for(var item2 of global.clientsInactive) {
      if(item2.id === id) {
        label = item2.data.name
      }
    }
    return label
  }


  renderAmount(item) {
    var net = item.data.amount/100
    if(item.data.type !== 'Cash') {
      net = (item.data.amount/100)*0.9775-0.30
    }
    return net.toFixed(2)
  }


  renderFeeNum(item) {
    var fee = 0
    if(item.data.type !== 'Cash') {
      fee = (item.data.amount/100)*0.0225+0.30
    }
    return fee.toFixed(2)
  }


  getPayout() {
    var label = global.curSym+'0'
    var payout = Moment().startOf('week').add(7, 'days').format('D MMM YYYY')
    var start = Moment().utc().startOf('week').utc(true).add(-3, 'days').format('X')
    var end = Moment().utc().startOf('week').utc(true).add(4, 'days').format('X')
    var total = 0
    for(var item of this.state.payments) {
      var date = Moment(item.data.timestamp, 'X').format('X')
      var fee = 0.9775
      if(date > start && date < end && item.data.type !== 'Cash') {
        total += (item.data.amount/100)*fee-0.30
      }
    }
    label = global.curSym+total.toFixed(2)+' '+lang.t('billing:label.monday')+', '+payout
    return label
  }



  // Interactive stuff ------------------------------------------------------------



  setDateStart(event) {
    this.setState({
        start: event.target.value
    });
  }


  setDateEnd(event) {
    this.setState({
        end: event.target.value
    });
  }


  createReport() {
    if(this.state.start !== '' && this.state.end !== '') {
      this.setState({
        created: true,
        currentType: this.state.currentTypeTmp
      }, () => {
        this.configureList()
      })
      
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'report');
      }, 1000);
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalInfo: false,
      showModalLocked: false,
      showModalPayment: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderInvoiceStatus(item) {
    var label = lang.t('billing:label.open')
    if(item.data.status === 'paid') {
      label = lang.t('billing:label.paid')
    }
    if(item.data.status === 'open' && Moment(item.data.due, 'X') < Moment().add(-1, 'day')) {
      label = lang.t('billing:label.overdue')
    }
    return label
  }


  renderInvoices() {
    if(this.state.invoices.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1 static"></div>
            <div className="img2 static"></div>
            <div className="img3 static"></div>
            <div className="img4 static"></div>
            {HelperEmptyImg.renderImage('reports')}
          </div>
          <h3 className="mb-20">{lang.t('billing:report.nothing.title')}</h3>
          <p className="mb-20">{lang.t('billing:report.nothing.text2')}</p>
        </div>
      )
    } else {
      var list = this.state.invoices
      list.sort((a,b) => Moment(b.data.date, 'X') - Moment(a.data.date, 'X'));
      return (
        <div>
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <Link to={'/billing/invoice/'+item.id}>
              <div className="col-3">
                <div className="icon lft primary">
                  <div className="inner invoice"></div>
                </div>
                <p className="lft"><strong>{lang.t('billing:label.invoice')} {item.data.number}</strong><br/>{global.curSym}{item.data.price.toFixed(2)}</p>
              </div>
              <div className="col-3">
                <p>{this.renderPaymentClient(item)}</p>
              </div>
              <div className="col-2">
                <p>{Moment(item.data.date, 'X').locale(lang.language).format('D MMM YYYY')}</p>
              </div>
              <div className="col-2">
                <p>{Moment(item.data.due, 'X').locale(lang.language).format('D MMM YYYY')}</p>
              </div>
              <div className="col-2">
              <p>{this.renderInvoiceStatus(item)}</p>
              </div>
              </Link>
            </div>
          ))}
        </div>
      )
    }
  }


  renderPaymentClient(item) {
    var label = '-'
    if(global.userSubStatus === 'trialing' || (global.userSubPlan === 'promonthly' || global.userSubPlan === 'proyearly' || global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly')) {
      for(var client of global.clients) {
        if(client.id === item.data.client) {
          label = client.data.name
        }
      }
      for(var client2 of global.clientsInactive) {
        if(client2.id === item.data.client) {
          label = client2.data.name
        }
      }
    }
    if(item.data.type === 'expense') {
      for(var cat of global.expensesCat) {
        if(cat.id === item.data.client) {
          label = cat.data.name
        }
      }
    }
    return label
  }


  renderFee(item) {
    if(item.data.type === 'expense') {
      var label = '-'
      if(item.data.tax === undefined || item.data.tax === 0) {
        // do nothing
      } else {
        label = global.curSym+item.data.tax.toFixed(2)
      }
      if(this.state.currentType === 'exp') {
        var l1 = '-'
        var l2 = '-'
        if(item.data.tax === undefined || item.data.tax === 0) {
          // do nothing
        } else {
          l1 = global.curSym+item.data.tax.toFixed(2)
        }
        if(item.data.gst === undefined || item.data.gst === 0) {
          // do nothing
        } else {
          l2 = global.curSym+item.data.gst.toFixed(2)
        }
        label = l1+' / '+l2
      }
      return label
    } else {
      var label = '-'
      if(item.data.fee === undefined || item.data.fee !== 0) {
        label = global.curSym+this.renderFeeNum(item)+'* ('+global.stripeFee+')'
      }
      if(global.spaceGST !== 0 && this.state.currentType !== 'all') {
        var mul = (item.data.amount/100)/11
        var num = mul/10*global.spaceGST
        var gst = global.curSym+num.toFixed(2)
        return <span>{label}<br/>{gst}</span>
      } else {
        return label
      }
      
    }
  }


  renderPaymentDate(item) {
    var label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')
    if(item.data.timestamp !== undefined || item.data.type === 'expense') {
      label = Moment(item.data.timestamp, 'X').locale(lang.language).format('D MMM YYYY')
    }
    return label
  }


  renderIcon(item) {
    if(item.data.type === 'expense') {
      return (
        <div className="icon tertiary">
          <div className="inner dollar"></div>
        </div>
      )
    } else {
      if(item.data.fee === undefined || item.data.fee !== 0) {
        return (
          <div className="icon secondary">
            <div className="inner card"></div>
          </div>
        )
      } else {
        return (
          <div className="icon secondary">
            <div className="inner dollar"></div>
          </div>
        )
      }
    }
  }


  renderType(item) {
    if(item.data.type === 'expense') {
      return lang.t('billing:title.expense')
    } else {
      if(item.data.fee === undefined || item.data.fee !== 0) {
        return lang.t('billing:form.cardpayment')
      } else {
        return lang.t('billing:form.othermethod')
      }
    }
  }


  renderPayments() {
    if(this.state.paymentsList.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1 static"></div>
            <div className="img2 static"></div>
            <div className="img3 static"></div>
            <div className="img4 static"></div>
            {HelperEmptyImg.renderImage('reports')}
          </div>
          <h3 className="mb-20">{lang.t('billing:report.nothing.title')}</h3>
          <p className="mb-20">{lang.t('billing:report.nothing.text1')}</p>
        </div>
      )
    } else {
      var list = this.state.paymentsList
      list.sort((a,b) => Moment(b.data.date, 'DD/MM/YYYY HH:mm') - Moment(a.data.date, 'DD/MM/YYYY HH:mm'));
      return (
        <div>
          {list.map(item => (
            <div className="list bare" key={item.id}>
              <div className="col-2">
                {this.renderIcon(item)}
                <p className="lft"><strong>{item.data.type === 'expense' ? '('+global.curSym+item.data.amount.toFixed(2)+')' : global.curSym+(item.data.amount/100).toFixed(2)}</strong><br/>{this.renderType(item)}</p>
              </div>
              <div className="col-3">
                <p>{this.renderPaymentName(item)}</p>
              </div>
              <div className="col-3">
                <p>{this.renderPaymentClient(item)}</p>
              </div>
              <div className="col-3">
                <p>{this.renderFee(item)}</p>
              </div>
              <div className="col-1">
              <p>{this.renderPaymentDate(item)}</p>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderCSV() {
    if(this.state.created && this.state.currentType !== 'inv') {
      return (
        <CSVLink className="btn secondary" data={this.state.csvdata} headers={this.state.csvheaders} filename={"ptmate-"+Moment().format('YYYY-MM-DD')+".csv"}>{lang.t('billing:button.exportascsv')}</CSVLink>
      )
    }
  }


  renderPrint() {
    if(this.state.created) {
      return (
        <button className="btn secondary" onClick={() => window.print()}>{lang.t('billing:button.print')}</button>
      )
    }
  }


  renderProducts() {
    if(this.state.currentTypeTmp !== 'exp') {
      var list = []
      for(var item of global.products) {
        if(item.data.type !== 'subscription' && item.data.name !== undefined) {
          list.push({id: item.data.name, name: item.data.name})
        }
      }
      list.push({value: '', name: ' '+lang.t('billing:report.allproducts')})
      list.push({value: 'sub', name: ' '+lang.t('billing:report.membershipany')})
      list.sort((a, b) => a.name.localeCompare(b.name))
      return (
        <div className="col-2">
          <Select label={lang.t('billing:form.product')} value={this.state.product} values={list} onChange={(event) => this.setState({product: event})}/>
        </div>
        
      )
    }
  }


  renderCategories() {
    if(this.state.currentTypeTmp === 'exp' || this.state.currentTypeTmp === 'all') {
      var list = [{value: '', name: ' '+lang.t('billing:report.allcategories')}]
      for(var item of global.expensesCat) {
        list.push({value: item.id, name: item.data.name})
      }
      list.sort((a, b) => a.name.localeCompare(b.name))
      return (
        <div className="col-2">
          <Select label={lang.t('billing:report.expensecategory')} value={this.state.category} values={list} onChange={(event) => this.setState({category: event})}/>
        </div>
      )
    }
  }


  renderLocations() {
    if(global.locations.length > 0) {
      var list = [{value: '', name: lang.t('billing:report.all')}]
      global.locations.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var loc of global.locations) {
        list.push({value: loc.id, name: loc.data.name})
      }
      return (
        <div className="col-2">
          <Select label={lang.t('billing:report.location')} value={this.state.location} values={list} onChange={(event) => this.setLocation(event)}/>
        </div>
      )
    }
  }


  renderGroups() {
    if(global.clientGroups.length > 0 && this.state.currentTypeTmp !== 'exp') {
      var list = [{value: '', name: 'All'}]
      global.clientGroups.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var clg of global.clientGroups) {
        list.push({value: clg.id, name: clg.data.name})
      }
      return (
        <div className="col-2">
          <Select label={lang.t('billing:report.clientgroups')} value={this.state.cgroup} values={list} onChange={(event) => this.setGroup(event)}/>
        </div>
      )
    }
  }


  renderClients() {
    if(this.state.clients.length > 0 && this.state.currentTypeTmp !== 'exp') {
      return (
        <div className="col-2">
          <Select label={lang.t('billing:report.clients')} value={this.state.currentClient} values={this.state.selclients} onChange={(event) => this.setState({currentClient: event})}/>
        </div>
      )
    }
  }


  renderTime() {
    if(global.userStripeConnect === '' || global.userStripeConnect === undefined) {
      return (
        <div className="col-2">&nbsp;</div>
      )
    } else {
      return (
        <div className="col-2">
          <InputRadio
            label={lang.t('billing:report.timezone')+"**"}
            value={this.state.utc}
            clickElement={(event) => this.setState({utc: event})}
            values={[{name: lang.t('billing:report.yourtime'), value: false}, {name: 'GMT', value: true}]}
          />
        </div>
      )
    }
  }


  renderConsolidated(amount) {
    if(amount === 0 || amount > 0) {
      return global.curSym+amount.toFixed(2)
    } else {
      return '('+global.curSym+(amount.toFixed(2)*-1)+')'
    }
  }


  renderReportHeader() {
    if(this.state.currentType === 'exp') {
      return (
        <div className="print-box mb-40">
          <h3 className="mb-30">{lang.t('header:title.summary')}</h3>
          <div className="info large col-3 clear">
            <label>{lang.t('billing:report.spent')}</label>
            <p>{global.curSym}{this.state.amounte.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:report.tax')}</label>
            <p>{global.curSym}{this.state.tax.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:form.gst')}</label>
            <p>{global.curSym}{this.state.gst.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:label.expenseslogged')}</label>
            <p>{this.state.totale}</p>
          </div>
        </div>  
      )
    } else if(this.state.currentType === 'inv') {
      return (
        <div className="print-box mb-40">
          <h3 className="mb-30">{lang.t('header:title.summary')}</h3>
          <div className="info large col-12 clear">
            <label>{lang.t('billing:report.invoices')}</label>
            <p>{this.state.totali}</p>
          </div>
        </div>  
      )
    } else if(this.state.currentType === 'all') {
      return (
        <div className="print-box mb-40">
          <h3 className="mb-30">{lang.t('header:title.summary')}</h3>
          <div className="info large col-4 clear">
            <label>{lang.t('billing:report.charged2')}*</label>
            <p>{global.curSym}{this.state.net.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:report.spent')}</label>
            <p>{global.curSym}{this.state.amounte.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:report.consolidated')}</label>
            <p>{this.renderConsolidated(this.state.net-this.state.amounte)}</p>
          </div>
          <div className="info large col-1">
            <label>{lang.t('billing:label.payments')}</label>
            <p>{this.state.totalp}</p>
          </div>
          <div className="info large col-1">
            <label>{lang.t('header:title.expenses')}</label>
            <p>{this.state.totale}</p>
          </div>
        </div>  
      )
    } else {
      return (
        <div className="print-box mb-40">
          <h3 className="mb-30">{lang.t('header:title.summary')}</h3>
          <div className="info large col-3 clear">
            <label>{lang.t('billing:report.charged')}</label>
            <p>{global.curSym}{this.state.amount.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:label.stripefees')}*</label>
            <p>{global.curSym}{this.state.fee.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:label.afterfees')}*</label>
            <p>{global.curSym}{this.state.net.toFixed(2)}</p>
          </div>
          <div className="info large col-3">
            <label>{lang.t('billing:label.payments')}</label>
            <p>{this.state.totalp}</p>
          </div>
        </div>  
      )
    }
  }


  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }


  renderReport() {
    if(this.state.created) {
      if(this.state.currentType !== 'inv') {
        return (
          <div>
            <div className="col-12">
              <div className="print-header">
                {this.renderLogo()}
                <h2>{global.userBusiness}</h2>
                <h3>{lang.t('billing:report.title.financialreport')} {Moment(this.state.start, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')} - {Moment(this.state.end, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')}</h3>
              </div>
              {this.renderReportHeader()}
            </div>
            <div className="col-12 clear print-data mt-20">
              <h3 className="mb-10">{lang.t('billing:label.payments')}</h3>
              <div className="list bare" style={{background: 'none', cursor: 'default'}}>
                <div className="col-2"><h5>{lang.t('billing:report.title.amount')}</h5></div>
                <div className="col-3"><h5>{lang.t('billing:report.title.name')}</h5></div>
                <div className="col-3"><h5>{(this.state.currentType !== 'exp' && this.state.currentType !== 'all') ? lang.t('billing:report.title.client') : (this.state.currentType === 'exp' ? lang.t('billing:report.title.category') : lang.t('billing:report.title.clientcategory'))}</h5></div>
                <div className="col-3"><h5>{(this.state.currentType !== 'exp' && this.state.currentType !== 'all') ? lang.t('billing:report.title.fees') : (this.state.currentType === 'exp' ? lang.t('billing:report.title.tax') : lang.t('billing:report.title.feestax'))}</h5></div>
                <div className="col-1"><h5>{lang.t('billing:report.title.date')}</h5></div>
                <div className="clear"></div>
              </div>
              {this.renderPayments()}
            </div>
            <div className="sv-20 clear"></div>
            <div className="print-disclaimer clear">* {lang.t('billing:report.data1')} {global.stripeFee}<span className="noprint"><br/>{lang.t('billing:report.data2')}</span></div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="col-12">
              <div className="print-header">
                {this.renderLogo()}
                <h2>{global.userBusiness}</h2>
                <h3>{lang.t('billing:report.title.financialreport')} {Moment(this.state.start, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')} - {Moment(this.state.end, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')}</h3>
              </div>
              {this.renderReportHeader()}
            </div>
            <div className="col-12 clear print-data mt-20">
              <h3 className="mb-10">{lang.t('billing:report.invoices')}</h3>
              <div className="list bare" style={{background: 'none', cursor: 'default'}}>
                <div className="col-3"><h5>{lang.t('billing:report.title.amount')}</h5></div>
                <div className="col-3"><h5>{lang.t('billing:report.title.client')}</h5></div>
                <div className="col-2"><h5>{lang.t('billing:report.title.created')}</h5></div>
                <div className="col-2"><h5>{lang.t('billing:label.due')}</h5></div>
                <div className="col-2"><h5>{lang.t('billing:label.status')}</h5></div>
                <div className="clear"></div>
              </div>
              {this.renderInvoices()}
            </div>
            <div className="sv-20 clear"></div>
          </div>
        )
      }
    }
  }


  renderPayoutInfo() {
    if(global.uid !== '7B6I1C4rIggMDrRW909CU377jEo2' && global.uid !== 'sdT75ccfyOacM4z1zDxpmxirdRm1' && global.uid !== 'iWFKT7bIQ1VDxH7T9ZjdKY8rFgi1' && global.uid !== 'XiRDsN8lYwhkdqHOSFmaDBjjkE62' && HelperBusiness.getRoleIncl('')) {
      return (
        <div className="infobox large green mb-30">
          <p>{lang.t('billing:label.estimatedpayout')}: {this.getPayout()} <button className="btn tertiary small rgt" onClick={() => this.setState({showModalInfo: true})}>{lang.t('billing:button.learnmore')}</button></p>
        </div>
      )
    } else {
      return <div className="sv-20"></div>
    }
  }


  renderUTC() {
    if(global.userStripeConnect !== '' && global.userStripeConnect !== undefined && HelperBusiness.getRoleIncl('')) {
      return (
        <div className="print-disclaimer clear" style={{paddingTop: 0}}><span className="noprint">** {lang.t('billing:label.reportinfo1')}<br/>{lang.t('billing:label.reportinfo2')} <span className="print-link" onClick={() => this.setState({showModalInfo: true})}>{lang.t('billing:button.learnmore')}</span>.</span></div>
      )
    }
  }


  renderContent() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-12 noprint">
            {this.renderPayoutInfo()}
            <div className="box">
              <h2 className="mb-20">{lang.t('billing:report.title')}</h2>
              <div className="col-2 clear">
                <InputDate label="From" value={Moment(this.state.start, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(event) => this.setState({start: event})}/>
              </div>
              <div className="col-2">
                <InputDate label="To" value={Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(event) => this.setState({end: event})}/>
              </div>
              <div className="col-2">
                <Select label='Type' value={this.state.currentTypeTmp} values={this.state.types} onChange={(event) => this.setState({currentTypeTmp: event})}/>
              </div>
              {this.renderProducts()}
              {this.renderCategories()}
              {this.renderLocations()}
              {this.renderTime()}
              <div className="clear"></div>
              {this.renderGroups()}
              {this.renderClients()}
              <div className="col-2 clear mt-10">
                <button className="btn primary" onClick={() => this.createReport()}>{lang.t('billing:button.createreport')}</button>
              </div>
              <div className="col-2 mt-10">
                {this.renderPrint()}
              </div>
              <div className="col-2 mt-10">
                {this.renderCSV()}
              </div>
              <div className="clear"></div>
            </div>
            <div className="sv-40"></div>
          </div>
          {this.renderReport()}
          {this.renderUTC()}
          <div className="sv-30"></div>
        </div>
        <div className="noprint">
          <Header type='billing' active='reports' onClickInfo={() => this.setState({showModalInfo: true})}/>
          <Navigation active='billing' />
        </div>
        <ModalBilling type='info' show={this.state.showModalInfo} onHide={() => this.hideModals()}/>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(global.maxClients !== 0) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <ListEmpty id='finreports'/>
            </div>
            <Header type='billing' active='reports'/>
            <Navigation active='billing' />
          </div>
        )
      } else {
        return (
          this.renderContent()
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='billing-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.billing')}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','nav'])(withRouter(withAuthorization(condition)(ReportPage)));