import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import EventEmitter from '../../helper/Emitter';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';



var badgeData = [
  // Level 1
  {
    id: 'l1-account',
    title: 'Your first badge',
    desc: 'PT Mate celebrates the small wins for your business, and each badge helps you to get set up. Get the first six badges and get your first paid month free.',
    badge: '/img/badges/account.svg'
  },
  {
    id: 'l1-import',
    title: 'Your client list is growing',
    desc: 'Great job! Next create a recurring session and a program to get a free first month on any paid subscription.',
    badge: '/img/badges/import-clients.svg'
  },
  {
    id: 'l1-session',
    title: 'Your first session',
    desc: 'Nice! You just created your first client session. Next create a program and upload your client list to get a free first month on any paid subscription.',
    badge: '/img/badges/first-session.svg'
  },
  {
    id: 'l1-program',
    title: 'Your first program',
    desc: 'Great, you just created your first program! Next create a recurring session and upload your client list to get a free first month on any paid subscription.',
    badge: '/img/badges/create-program.svg'
  },
  // Level 2
  {
    id: 'l2-billing',
    title: 'You enabled billing',
    desc: 'Great news! You can now charge your clients directly through the app. Next create your first product so you can start processing payments.',
    badge: '/img/badges/enable-billing.svg'
  },
  {
    id: 'l2-attendance',
    title: 'Track session attendance',
    desc: "You've just tracked your first session attendance. This allows you to stay on top of your clients' packs and payments. Keep up the good work!",
    badge: '/img/badges/run-session.svg'
  },
  {
    id: 'l2-product',
    title: 'Your first product',
    desc: "Congratulations! You've just created your first product. You can now start charging your clients through the app.",
    badge: '/img/badges/create-product.svg'
  },
  {
    id: 'l2-payment',
    title: 'Your first payment',
    desc: 'Your first payout is on its way! Remember: You can keep track of your finances and outstanding payments in the Billing section.',
    badge: '/img/badges/payment.svg'
  },
  {
    id: 'l2-report',
    title: 'Your first report',
    desc: 'Stay on top of things! Reports allow you to get an overview of your business at any given moment. Well done!',
    badge: '/img/badges/create-report.svg'
  },
  // Level 3
  {
    id: 'l3-exercise',
    title: 'Custom movement',
    desc: 'You just created your first custom movement. You can use it in your training programs.',
    badge: '/img/badges/exercise.svg'
  },
  {
    id: 'l3-plan',
    title: 'Training plan',
    desc: 'Awesome! You just created your first training plan. Send it to your clients so they can start training.',
    badge: '/img/badges/plan.svg'
  },
  {
    id: 'l3-sessions',
    title: '100 sessions',
    desc: "Congratulations! You've just created your 100th session. Keep up the great work!",
    badge: '/img/badges/sessions-100.svg'
  },
  {
    id: 'l3-programs',
    title: '10 programs',
    desc: "Congratulations! You've just created your 10th program. You're really starting to get the hang of things!",
    badge: '/img/badges/programs-10.svg'
  },
  {
    id: 'l3-run',
    title: '20 sessions run',
    desc: "Congratulations! You've just run your 20th session. Keep up the good work!",
    badge: '/img/badges/run-sessions-20.svg'
  },
  {
    id: 'l3-nutrition',
    title: '10 nutrition plans',
    desc: "Congratulations! You've just created your 10th nutrition plan. Keep up the good work!",
    badge: '/img/badges/nutrition-10.svg'
  },
  // Level 3
  {
    id: 'l4-sessions',
    title: '500 sessions',
    desc: "Congratulations! You've just created your 500th session. Well Done! Keep up the great work!",
    badge: '/img/badges/sessions-500.svg'
  },
  {
    id: 'l4-payments',
    title: '500 payments',
    desc: "Congratulations! You've just made your 500th payment. Business is booming!",
    badge: '/img/badges/clients-charged-500.svg'
  },
  {
    id: 'l4-programs',
    title: '60 programs',
    desc: "Congratulations! You've just created your 60th program. You know what you're doing!",
    badge: '/img/badges/programs-60.svg'
  },
  {
    id: 'l4-plans',
    title: '20 training plans',
    desc: "Congratulations! You've just created your 20th training plan. Online coaching is your jam!",
    badge: '/img/badges/plans-20.svg'
  },
  {
    id: 'l4-run',
    title: '200 sessions run',
    desc: "Congratulations! You've just run your 200th session. You are doing awesome!",
    badge: '/img/badges/run-sessions-200.svg'
  },
  {
    id: 'l4-public',
    title: 'Leads page',
    desc: 'You just enabled your leads page. Your potential clients now have an easy way to book into trial sessions.',
    badge: '/img/badges/public.svg'
  },
]



class Badges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: '',
      hidden: 'hidden',
      display: '',
      title: '',
      desc: '',
      badge: '',
      earned: '',
      badges: global.userBadges,
    };
  }


  componentDidMount() {
    EventEmitter.subscribe('updateBadges', (event) => this.checkAchievement(event))
  }


  componentWillUnmount() {
    global.badgeLocked = false
  }


  checkAchievement(data) {
    global.badgeLocked = true
    var show = ''
    var type = 'overlay'

    var totSess = global.sessions.length+global.archive.length
    var c1 = 0
    var c2 = 0
    var c3 = 0
    var c4 = 0
    for(var item of global.sessions) {
      if(item.data.attendance !== 2) {
        c1++
      }
      if(item.data.attendance === 3 && item.program !== undefined) {
        if(item.program.length > 0) {
          c2++
          c3++
        }
      }
    }
    for(var item2 of global.archive) {
      if(item2.data.attendance === 3 && item2.program !== undefined) {
        if(item2.program.length > 0) {
          c2++
        }
      }
    }
    global.programs.forEach(() => {
      c3++
    })
    for(var item3 of global.clients) {
      if(item3.data.nutritionId !== undefined && item3.data.nutritionId !== '')
      c4++
    }

    // Level 1
    if((data === 'client' && global.clients.length === 5) || data === 'import') {
      if(global.userBadges.indexOf('l1-import') === -1) {
        show = 'l1-import'
        if(global.userBadges.indexOf('l1-client') === -1) {
          global.userBadges.push('li-client')
        }
      }
    }
    if(data === 'session' && global.sessions.length > 0 && global.userBadges.indexOf('l1-session') === -1) {
      show = 'l1-session'
    }
    if(data === 'program' && global.programs.length === 1 && global.userBadges.indexOf('l1-program') === -1) {
      show = 'l1-program'
    }
    // Level 2
    if(data === 'billing' && global.userBadges.indexOf('l2-billing') === -1) {
      show = 'l2-billing'
    }
    if(data === 'attendance' && c1 === 1 && global.userBadges.indexOf('l2-attendance') === -1) {
      show = 'l2-attendance'
    }
    if(data === 'product' && global.userBadges.indexOf('l2-product') === -1) {
      show = 'l2-product'
    }
    if(data === 'payment' && global.payments.length === 1 && global.userBadges.indexOf('l2-payment') === -1) {
      show = 'l2-payment'
    }
    if(data === 'report' && global.userBadges.indexOf('l2-report') === -1) {
      show = 'l2-report'
    }
    // Level 3
    if(data === 'exercise' && global.userBadges.indexOf('l3-exercise') === -1) {
      show = 'l3-exercise'
    }
    if(data === 'plan' && global.payments.length === 1 && global.userBadges.indexOf('l3-plan') === -1) {
      show = 'l3-plan'
    }
    if(data === 'session' && totSess > 100 && global.userBadges.indexOf('l3-sessions') === -1) {
      show = 'l3-sessions'
      type = 'toast'
    }
    if(data === 'program' && c3 === 10 && global.userBadges.indexOf('l3-programs') === -1) {
      show = 'l3-programs'
      type = 'toast'
    }
    if(data === 'attendance' && c2 === 20 && global.userBadges.indexOf('l3-run') === -1) {
      show = 'l3-run'
      type = 'toast'
    }
    if(data === 'nutrition' && c4 === 10 && global.userBadges.indexOf('l3-nutrition') === -1) {
      show = 'l3-nutrition'
    }
    // Level 4
    if(data === 'session' && totSess > 500 && global.userBadges.indexOf('l4-sessions') === -1) {
      show = 'l4-sessions'
      type = 'toast'
    }
    if(data === 'payment' && global.payments.length === 500 && global.userBadges.indexOf('l4-payments') === -1) {
      show = 'l4-payments'
      type = 'toast'
    }
    if(data === 'program' && c3 === 60 && global.userBadges.indexOf('l4-programs') === -1) {
      show = 'l4-programs'
      type = 'toast'
    }
    if(data === 'plan' && global.plans.length === 20 && global.userBadges.indexOf('l4-plans') === -1) {
      show = 'l4-plans'
      type = 'toast'
    }
    if(data === 'attendance' && c2 === 200 && global.userBadges.indexOf('l4-run') === -1) {
      show = 'l4-run'
      type = 'toast'
    }
    if(data === 'public' && global.userBadges.indexOf('l4-public') === -1) {
      show = 'l4-public'
    }

    if(data === 'account' && global.userBadges.indexOf('l1-account') === -1) {
      show = 'l1-account'
    }

    if(show !== '') {
      Firebase.database().ref('/activity/'+global.uidUser+'/'+show).update({
        type: 'badge',
        data: show,
        date: Moment().format('DD/MM/YYYY HH:mm')
      })
      Firebase.database().ref('/users/'+global.uidUser).update({
        badges: global.userBadges
      })
      var title = ''
      var desc = ''
      var badge = ''
      for(var item11 of badgeData) {
        if(item11.id === show) {
          title = item11.title
          desc = item11.desc
          badge = item11.badge
        }
      }
      this.showOverlay(type, title, desc, badge, show)
    }
    
  }



  // Overlay ------------------------------------------------------------



  showOverlay(type, title, desc, badge, earned) {
    this.setState({
      //show: 'overlay',
      hidden: 'hidden',
      show: type,
      title: title,
      desc: desc,
      badge: badge,
      earned: earned
    });

    setTimeout(() => {
      //const height = this.divElement.clientHeight
      const height = 400
      this.setState({
        hidden: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100,
    })
    setTimeout(() => {
      this.updateBadges()
    }, 500);
  }


  updateBadges() {
    global.userBadges.push(this.state.earned)
    Firebase.database().ref('/users/'+global.uidUser).update({
      badges: global.userBadges
    })
    this.setState({show: ''});
  }


  hideToast() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.updateBadges()
    }, 500);
  }



  // Display ------------------------------------------------------------



  render() {
  	if(this.state.show === 'toast') {
      return (
        <div className={'milestone '+this.state.hidden}>
          <img src={this.state.badge} alt="New Achievement!"/>
          <div className="text">
            <strong>New Achievement!</strong><br/>
            {this.state.desc}<br/><Link to={ROUTES.ACCOUNTBADGES} className="btn tertiary small" onClick={() => this.hideToast()}>See all your achievements</Link>
          </div>
          <div className="close" onClick={() => this.hideToast()}>X</div>
        </div>
      )
    } else if(this.state.show === 'overlay') {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">New Achievement!</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>Close</button>
            <div className="badge-ol-img clear mb-20">
              <img src={this.state.badge} alt="New Achievement!"/>
            </div>
            <h4 className="mb-20">{this.state.title}</h4>
            <p className="mb-20">{this.state.desc}</p>
            <Link to={ROUTES.ACCOUNTBADGES} className="btn primary" onClick={() => this.hideOverlay()}>See all your achievements</Link>
          </div>
        </div>
      )
    } else if(this.state.show === 'level1') {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">Congratulations!</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>Close</button>
            <div className="badge-ol-img clear mb-20">
              <img src="/img/badges/unlocked.svg" alt="New Achievement!"/>
            </div>
            <h4 className="mb-20">You earned your first six badges!</h4>
            <p className="mb-30">Get your first month free! Upgrade to a paid subscription plan now and receive your first month free.</p>
            <Link to={ROUTES.SUBSCRIPTION} className="btn primary" onClick={() => this.hideOverlay()}>Get your free month now</Link>
          </div>
        </div>
      )
  	} else {
  		return (<div/>)
  	}
    
  }
}


export default Badges;