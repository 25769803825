import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminExercisesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: global.exercises,
      exercisesList: global.exercises,
      search: '',
      // Overlay
      showOverlay: false,
      hidden: 'hidden',
      ext: '',
      exd: '',
      exi: '',
      exv: '',
      showOverlayMessage: false,
      margin: 0,
      hiddenMessage: 'hidden',
      maxPrograms: global.maxPrograms,
      custImages: []
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Movements - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    if(global.message !== '') {
      setTimeout(() => {
        EventEmitter.dispatch('showMessage', global.message);
        global.message = ''
      }, 300);
      
    }

    global.currentClient = '';
    global.currentPage = ROUTES.EXERCISES;
    global.linkCli = '';
    global.linkCal = '';
    global.linkPro = '';
    global.linkBil = '';
    global.linkAdm = '';

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
    this.loadExercises()
  }


  configureData() {
    var tmp = []
    for(var item of global.exercises) {
      if(item.data.user === 'admin') {
        tmp.push(item)
      }
    }
    this.setState({
      exercises: tmp,
      exercisesList: tmp,
      maxPrograms: global.maxPrograms
    })
  }


  loadExercises() {
    setTimeout(() => {
      Connector.loadExercisesAdmin()
    }, 1000);
  }



  // Interactive stuff ------------------------------------------------------------



  filterExercises = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList()
    });
  }


  configureList() {
    var tmp = []
    for(var item of global.exercises) {
      if(item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 && item.data.user === 'admin') {
        tmp.push(item)
      }
      /*for(var i=0; i<item.data.subTitles.length; i++) {
        if((item.data.subTitles[i]).toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
          tmp.push({
            id: item.data.subIds[i],
            name: item.data.subTitles[i],
            tool: item.data.subTools[i],
            image: item.data.subImages[i],
            video: item.data.subVideos[i],
            parent: item.data.name,
            cat: item.data.cat,
            type: item.data.type
          })
        }
      }*/
    }
    this.setState({
      exercisesList: tmp
    })
  }



  // Exercise overlay ------------------------------------------------------------



  showOverlayEx(id) {
    var ext = ''
    var exd = ''
    var exi = ''
    var exv = ''
    for(var item of global.exercises) {
      for(var i=0; i<item.data.subTitles.length; i++) {
        if(item.data.subIds[i] === id) {
          ext = item.data.subTitles[i]
          exd = item.data.subDescs[i]
          exi = item.data.subImages[i]
          exv = item.data.subVideos[i]
        }
      }
      
    }

    this.setState({
      showOverlay: true,
      ext: ext,
      exd: exd,
      exi: exi,
      exv: exv,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideOverlayEx() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderExText() {
    if(this.state.exd !== '') {
      return (
        <p>{this.state.exd}</p>
      )
    }
  }


  renderExVideo() {
    if(this.state.exv !== '') {
      return (
        <a href="sadf" target="_blank" className="btn tertiary width-12">Watch video</a>
      )
    }
  }


  renderExImage(img) {
    if(img === '') {
      return (
        <div className="form-image clear"></div>
      )
    } else if(img.indexOf('adm-') !== -1) {
      return (
        <div className="form-image clear" style={{backgroundImage: 'url(/img/exercises/'+img+'.jpg)'}}></div>
      )
    } else {
      return (
        <div className="form-image clear" style={{backgroundImage: 'url('+img+')'}}></div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderExercises() {
    var list1 = this.state.exercisesList
    list1.sort((a, b) => a.data.name.localeCompare(b.data.name))
    return (
      <div>
        {list1.map(item => (
          <div className="box list highlight" key={item.id}>
            <Link to={'/admin/movements/'+item.id}>
            <div className="col-5 col-12-768"> 
              <p className="lft"><strong>{item.data.name}</strong></p>
            </div>
            <div className="col-3">
              <p>{item.data.user === 'admin' ? 'PT Mate' : 'Trainer created'}</p>
            </div>
            <div className="col-2 hide768">
              <p>{item.data.subTitles.length} movement{item.data.subTitles.length === 1 ? '' : 's'}</p>
            </div>
            <div className="col-2">
              <button className="btn tertiary small">View details</button>
            </div>
            </Link>
          </div>
        ))}
        <div className="space-20"></div>
      </div>
    )
  }


  renderBottom() {
    return (
      <div className="col-12">
        <div className="filters" style={{paddingTop: 0}}>
          <h2>Movement types</h2>
          <input type="text" className="search" placeholder="Filter by name" value={this.state.search} onChange={(val) => this.filterExercises(val)} />
          <div className="clear"></div>
        </div>
        {this.renderExercises()}
        <Link to={'/admin/new-movement'} className="btn tertiary add">Create a movement type</Link>
        <div className="space-40"></div>
      </div>
    )
  }


  renderBase() {
    if(global.uidUser === 'L81v7rXOuTPWqVFfU89icsSOdLx1' || global.uidUser === 'EjXQG2v3hjcYr32l6oV2actRiBF2') {
      return (
        <div>
          <div className="content withtabs">
            {this.renderBottom()}
          </div>
          <div className="header withtabs">
            <h1>Admin</h1>
            <div className="tabs">
              <Link to={ROUTES.ADMINASSISTANT} className="tab">Assistant Trainers</Link>
              <Link to={ROUTES.ADMINDOC} className="tab">Documents</Link>
              <Link to={ROUTES.ADMINPUBLIC} className="tab">Trial Session Page</Link>
              <Link to={'/admin/users'} className="tab">Users</Link>
              <Link to={'/admin/clients'} className="tab">Clients</Link>
              <Link to={'/admin/movements'} className="tab active">Movements</Link>
            </div>
          </div>
          <Navigation active='admin' />
        </div>
      )
    } else {
      return (
        <div style={{paddingTop: 40}}><Link to={ROUTES.OVERVIEW} className="btn tertiary">Go to overview</Link></div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(AdminExercisesPage));

