import React, { Component } from 'react';
import Moment from 'moment';
import Firebase from 'firebase';
import 'firebase/functions';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperCal from '../../helper/Calendar';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminStaffPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: global.userStaff,
      sessions: global.sessions,
      archive: global.archive,
      pending: [],
      invites: [],
      showModal: false,
      hidden: 'hidden',
      margin: 0,
      id: '',
      name: '',
      email: '',
      role: 0,
      urole: 'assistant',
      selected: null,
      showModalUpdate: false,
      hiddenUpdate: 'hidden',
      showModalDelete: false,
      hiddenDelete: 'hidden',
      showModalStats: false,
      hiddenStats: 'hidden',
      img: '',
      dateRange: global.dateRange,
      full: false,
      status: 'active',
      showModalLocked: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Staff - PT Mate', ROUTES.ADMINSTAFF)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('staffLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      if(global.loc === '') {
        tmp = global.userStaff
      } else {
        for(var loc of global.locations) {
          if(loc.id === global.loc && loc.data.staff !== undefined) {
            for(var staff of global.userStaff) {
              if(loc.data.staff.indexOf(staff.id) !== -1) {
                tmp.push(staff)
              }
            }
          }
        }
      }
      this.setState({
        staff: tmp,
      })
    }
  }


  getStaff(active) {
    var num = 0
    for(var item of this.state.staff) {
      if(item.data.status === 'active' && active) {
        num++
      }
      if(item.data.status !== 'active' && !active) {
        num++
      }
    }
    return num
  }


  clickLink() {
    var show = true
    if(HelperBusiness.getRole('') && global.userSubStatus !== 'trialing') {
      if(global.userSubPlan === 'spark' || global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        show = false
      }
      if((global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') && global.userStaff.length > 0) {
        show = false
      }
    }
    if(show) {
      this.showAdd()
    } else {
      this.setState({
        showModalLocked: true
      })
    }
  }



  // Add Trainer Modal ------------------------------------------------------------



  showAdd() {
    if(global.maxTrainers === 0 || (global.maxTrainers === 1 && global.userStaff.length === 1)) {
      if(HelperBusiness.getRoleIncl('')) {
        this.setState({showModalLocked: true})
      } else {
        this.showModalAdd()
      }
      
    } else {
      this.showModalAdd()
    }
  }


  showModalAdd() {
    if(global.spaces.length === 0) {
      Connector.loadTrainers()
    }
    this.setState({
      showModal: true,
      role: 'assistant'
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
        email: '',
        type: 'form',
        selected: null
      });
    }, 100);
  }


  hideModalAdd() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModal: false});
    }, 500);
  }


  renderAddButton() {
    var allowed = true
    if(global.userSubPlan !== 'businessmonthly' && global.userSubPlan !== 'businessyearly' && global.userSubStatus !== 'trialing' && this.state.role > 0) {
      allowed = false
    }
    if(allowed) {
      return (
        <button className="btn primary" onClick={() => this.checkTrainer()}>{lang.t('modals:staffinvite.button')}</button>
      )
    } else {
      return (
        <div className="info-small warning">
          <p style={{margin: 0}}>{lang.t('modals:staffupdate.text.subscription')}<br/><Link to={ROUTES.SUBSCRIPTION}>{lang.t('modals:staffupdate.button.subscription')}</Link></p>
        </div>
      )
    }
  }


  renderAddContent() {
    return (
      <div>
        <p className="mb-20">{lang.t('modals:staffinvite.text')}</p>
        <label>{lang.t('modals:staffinvite.emailaddress')}</label>
        <input type="text" value={this.state.email} name="0" onChange={this.onChange}/>
        <div className="sv-20"></div>
        <label>{lang.t('modals:staffupdate.role')}</label>
        <select value={this.state.role} onChange={event => this.setRole(event)}>
          <option value='0'>{lang.t('modals:role.assistanttrainer')} ({lang.t('modals:staffupdate.limitedaccess')})</option>
          <option value='1'>{lang.t('modals:role.trainer')}</option>
          <option value='2'>{lang.t('modals:role.admin')}</option>
          <option value='3'>{lang.t('modals:role.finance')}</option>
          <option value='4'>{lang.t('modals:role.cs')}</option>
          <option value='5'>{lang.t('modals:role.manager')}</option>
        </select>
        <div className="sv-30"></div>
        {this.renderAddButton()}
      </div>
    )
  }


  setRole(event) {
    this.setState({
      role: parseInt(event.target.value)
    })
  }


  setURole(event) {
    this.setState({
      urole: event.target.value
    })
  }


  getImage(item) {
    if(item.data.image === '' && item.data.image !== null) {
      var inits = ''
      let arr = item.data.name.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    } else {
      Firebase.storage().ref().child(item.data.image).getDownloadURL().then((url) => {
        this.setState({
          img: url
        })
      }).catch((error) => {
        // Handle any errors
      })
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.img+')'}}> </div>
      )
    }
  }


  renderModalAdd() {
    if(this.state.showModal) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto response" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:staffinvite.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalAdd()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            {this.renderAddContent()}
          </div>
        </div>
      )
    }
  }


  onChange = event => {
    this.setState({
      email: event.target.value,
    });
  };


  checkTrainer() {
    if(this.state.email !== '') {
      this.sendConnect()
    }
  }


  sendConnect() {
    this.hideModalAdd();
    var link = 'https://ptmate.app/staff/'+global.uid+'/signup/'+this.state.role
    var invite = Firebase.functions().httpsCallable('sendStaffJoin');
    invite({type: 'invite', email: this.state.email, name: global.userBusiness, user: global.userName, link: link}).then(function(result) {
      // nothing
    })
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.invitesent'));
  }



  // Modal update ------------------------------------------------------------



  showModalUpdate(item) {
    var role = 'assistant'
    var full= false
    if(item.data.role !== undefined) {
      role = item.data.role
      if(role === 'assistant2' || role === 'trainer2') {
        full = true
      }
    }
    
    this.setState({
      showModalUpdate: true,
      selected: item,
      urole: role,
      full: full,
      status: item.data.status
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenUpdate: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalUpdate() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenUpdate: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalUpdate: false});
    }, 500);
  }


  renderUpdateButton() {
    var allowed = true
    if(global.userSubPlan !== 'businessmonthly' && global.userSubPlan !== 'businessyearly' && global.userSubStatus !== 'trialing' && this.state.urole !== 'assistant' && this.state.urole !== 'assistant2') {
      allowed = false
    }
    if(allowed) {
      return (
        <button className="btn primary" onClick={() => this.updateStaff()}>{lang.t('modals:staffupdate.button')}</button>
      )
    } else {
      return (
        <div className="info-small warning">
          <p style={{margin: 0}}>{lang.t('modals:staffupdate.text.subscription')}<br/><Link to={ROUTES.SUBSCRIPTION}>{lang.t('modals:staffupdate.button.subscription')}</Link></p>
        </div>
      )
    }
  }


  renderSelect() {
    if(this.state.full) {
      return (
        <select value={this.state.urole} onChange={event => this.setURole(event)}>
          <option value='assistant2'>{lang.t('modals:role.assistanttrainer')} ({lang.t('admin:form.limitedaccess')})</option>
          <option value='trainer'>{lang.t('modals:role.trainer')}</option>
          <option value='admin'>{lang.t('modals:role.admin')}</option>
          <option value='finance'>{lang.t('modals:role.finance')}</option>
          <option value='cs'>{lang.t('modals:role.cs')}</option>
          <option value='manager'>{lang.t('modals:role.manager')}</option>
        </select>
      )
    } else {
      return (
        <select value={this.state.urole} onChange={event => this.setURole(event)}>
          <option value='assistant'>{lang.t('modals:role.assistanttrainer')} ({lang.t('admin:form.limitedaccess')})</option>
          <option value='trainer'>{lang.t('modals:role.trainer')}</option>
          <option value='admin'>{lang.t('modals:role.admin')}</option>
          <option value='finance'>{lang.t('modals:role.finance')}</option>
          <option value='cs'>{lang.t('modals:role.cs')}</option>
          <option value='manager'>{lang.t('modals:role.manager')}</option>
        </select>
      )
    }
  }


  renderModalUpdate() {
    if(this.state.showModalUpdate) {
      return (
        <div className={'overlay '+this.state.hiddenUpdate}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:staffupdate.title')} {this.state.selected.data.name}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalUpdate()}>{lang.t('common:button.close')}</button>
            <label className="clear">{lang.t('modals:staffupdate.role')}</label>
            {this.renderSelect()}
            <div className="sv-20"></div>
            <label>{lang.t('modals:staffupdate.activestaffmember')}</label>
            <div className={this.state.status === 'active' ? 'toggle active' : 'toggle'} onClick={() => this.toggleActive()}>
              <div className="mover"></div>
            </div>
            <p className="toggle-label">{lang.t('modals:staffupdate.activestaffmember.text')}</p>
            <div className="clear"></div>
            <div className="sv-30"></div>
            {this.renderUpdateButton()}
            <div className="sv-20"></div>
            <button className="btn tertiary width-12 small" onClick={() => this.showDelete()}>{this.state.full ? lang.t('modals:staffdelete.button.disconnect') : lang.t('common:button.delete')} {this.state.selected.data.name}</button>
          </div>
        </div>
      )
    }
  }


  toggleActive() {
    var tmp = this.state.status
    if(tmp === 'active') {
      tmp = 'inactive'
    } else {
      tmp = 'active'
    }
    this.setState({
      status: tmp
    })
  }


  updateStaff() {
    this.hideModalUpdate()
    if(this.state.full) {
      Firebase.database().ref('/users/'+this.state.selected.id+'/spaces/'+global.uid).update({
        role: this.state.urole,
        status: this.state.status
      })
    } else {
      Firebase.database().ref('/usersStaff/'+this.state.selected.id).update({
        role: this.state.urole,
        status: this.state.status
      })
    }
    Firebase.database().ref('/spaces/'+global.uidUser+'/staff/'+this.state.selected.id).update({
      role: this.state.urole,
      status: this.state.status
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.staffmemberupdated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  showDelete() {
    this.hideModalUpdate()
    setTimeout(() => {
      this.showModalDelete();
    }, 500);
  }



  // Modal delete ------------------------------------------------------------



  showModalDelete() {
    this.setState({
      showModalDelete: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDelete: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalDelete() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDelete: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalDelete: false});
    }, 500);
  }


  renderDeleteText() {
    if(this.state.full) {
      return (
        <p className="clear">{lang.t('modals:staffdelete.text1.disconnect')} {this.state.selected.data.name}? {lang.t('modals:staffdelete.text2.disconnect')}</p>
      )
    } else {
      return (
        <p className="clear">{lang.t('modals:staffdelete.text1')} {this.state.selected.data.name}? {lang.t('modals:staffdelete.text2')}</p>
      )
    }
  }


  renderModalDelete() {
    if(this.state.showModalDelete) {
      return (
        <div className={'overlay '+this.state.hiddenDelete}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.full ? lang.t('modals:staffdelete.title.disconnect') : lang.t('modals:staffdelete.title')} {this.state.selected.data.name}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalDelete()}>{lang.t('common:button.close')}</button>
            {this.renderDeleteText()}
            <div className="clear sv-30"></div>
            <button className="btn primary" onClick={() => this.removeStaff()}>{this.state.full ? lang.t('modals:staffdelete.button.disconnect') : lang.t('modals:staffdelete.button')} {this.state.selected.data.name}</button>
          </div>
        </div>
      )
    }
  }


  removeStaff() {
    this.hideModalDelete()

    if(this.state.full) {
      Firebase.database().ref('/users/'+this.state.selected.id+'/spaces/'+global.uid).remove()
      Firebase.database().ref('/users/'+this.state.selected.id).update({
        user: '',
      })
    } else {
      var callFunction = Firebase.functions().httpsCallable('testDelete');
      callFunction({uid: this.state.selected.id}).then(function(result) {});

      Firebase.database().ref('/usersStaff/'+this.state.selected.id).remove()
    }

    Firebase.database().ref('/spaces/'+global.uid+'/staff/'+this.state.selected.id).remove()
      .then(() => {
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.staffmemberremoved'));
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
      
      if(this.state.selected.data.role === 'assistant' || this.state.selected.data.role === 'trainer' || this.state.selected.data.role === 'assistant2' || this.state.selected.data.role === 'trainer2') {
        // Check sessions
        for(var sess of global.sessions) {
          if(sess.data.trainerId !== undefined && Moment(sess.data.date, 'DD/MM/YYYY HH:mm') > Moment()) {
            if(sess.data.trainerId === this.state.selected.id) {
              Firebase.database().ref('/sessions/'+global.uidUser+'/'+sess.id).update({
                trainerId: '',
                trainerName: ''
              })
            }
          }
        }
        for(var rec of global.recurring) {
          if(rec.data.trainerId !== undefined) {
            if(rec.data.trainerId === this.state.selected.id) {
              Firebase.database().ref('/recurring/'+global.uidUser+'/'+rec.id).update({
                trainerId: '',
                trainerName: ''
              })
            }
          }
        }
      }
  }



  // Stats modal ------------------------------------------------------------



  showModalStats(item) {
    this.setState({
      showModalStats: true,
      selected: item,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenStats: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalStats() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenStats: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalStats: false});
    }, 500);
  }


  renderModalStats() {
    if(this.state.showModalStats) {
      return (
        <div className={'overlay '+this.state.hiddenStats}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{this.state.selected.data.name}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalStats()}>{lang.t('common:button.close')}</button>
            <div className="form-item clear">
              <label>{lang.t('modals:staffstats.title')}</label>
              <select onChange={event => this.setDateRange(event)} value={this.state.dateRange}>
                <option value='week'>{lang.t('nav:stats.week')}</option>
                <option value='7days'>{lang.t('nav:stats.7days')}</option>
                <option value='30days'>{lang.t('nav:stats.30days')}</option>
                <option value='month'>{lang.t('nav:stats.month')}</option>
                <option value='60days'>{lang.t('nav:stats.60days')}</option>
              </select>
            </div>
            <div className="clear "></div>
            <h4 className="mb-10">{lang.t('modals:items.11sessions')}</h4>
            <p className="mb-20">{this.getDates(false, 'attended', 'total')} {lang.t('modals:staffstats.attended')} {this.getDates(false, 'attended', 'time')}<br/>{this.getDates(false, 'unattended', 'total')} {lang.t('modals:staffstats.notattended')} {this.getDates(false, 'unattended', 'time')}</p>
            <h4 className="mb-10">{lang.t('modals:staffstats.groupsessions')}</h4>
            <p>{this.getDates(true, 'attended', 'total')} {lang.t('modals:staffstats.withconfirmedattendance')} {this.getDates(true, 'attended', 'time')}<br/>{this.getDates(true, 'unattended', 'total')} {lang.t('modals:staffstats.notconfirmed')} {this.getDates(true, 'unattended', 'time')}</p>
          </div>
        </div>
      )
    }
  }


  setDateRange(event) {
    global.dateRange = event.target.value
    this.setState({dateRange: event.target.value})
  }


  getDates(group, attendance, type) {
    var label = ''
    var num1 = 0
    var num2 = 0
    var today = Moment().format('X')
    var minDate = Moment().add(-31, 'days').format('X')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days').format('X')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days').format('X')
    }
    if(this.state.dateRange === 'week') {
      var minDate1 = Moment().startOf('week')
      minDate =  minDate1.add(-1, 'days').format('X')
    }
    for(var item of global.sessions) {
      var add = false
      var idate = Moment(item.data.timestamp, 'X').format('X')
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(idate > minDate && idate < today) {
          add = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.timestamp, 'X').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.timestamp, 'X') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'week') {
        idate = Moment(item.data.timestamp, 'X').format('X')
        if(idate > minDate && idate < today) {
          add = true
        }
      }
      if(item.data.group === group && item.data.trainerId === this.state.selected.id && add) {
        if(item.data.attendance === 3 && attendance === 'attended') {
          num1++
          num2 += item.data.duration
        } else if(item.data.attendance !== 3 && attendance !== 'attended') {
          if(item.data.group && (item.data.availability === undefined || item.data.availability === false)) {
            num1++
            num2 += item.data.duration
          } else if(!item.data.group) {
            num1++
            num2 += item.data.duration
          }
          
        }
        
      }
    }
    if(type === 'total') {
      label = num1
    } else if(type === 'time' && num2 > 0) {
      label = '('+HelperCal.getDuration(num2)+')'
    }
    return label
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalLocked: false,
      showModalDelete: false
    })
  }


  modalClick() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }



  // Display stuff ------------------------------------------------------------



  renderListRole(item) {
    var label = lang.t('admin:staff.assistanttrainer')
    if(item.data.role === 'trainer' || item.data.role === 'trainer2') {
      label = lang.t('admin:staff.trainer')
    }
    if(item.data.role === 'finance') {
      label = lang.t('admin:staff.finance')
    }
    if(item.data.role === 'marketing') {
      label = 'Marketing'
    }
    if(item.data.role === 'admin') {
      label = lang.t('admin:staff.admin')
    }
    if(item.data.role === 'cs') {
      label = lang.t('admin:staff.cs')
    }
    if(item.data.role === 'manager') {
      label = lang.t('admin:staff.manager')
    }
    return label
  }


  renderButtonStats(item) {
    if(item.data.role === 'assistant' || item.data.role === 'trainer' || item.data.role === 'assistant2' || item.data.role === 'trainer2') {
      return (
        <button className="btn tertiary small rgt" onClick={() => this.showModalStats(item)}>{lang.t('admin:button.viewstats')}</button>
      )
    } else {
      return <span>&nbsp;</span>
    }
  }


  renderStatus(item) {
    if(item.data.status !== 'active') {
      return (
        <span className="pill yellow">Inactive</span>
      )
    }
  }

  
  renderListUpdate(item) {
    if(global.uidUser !== item.id) {
      return (
        <button className="btn tertiary edit small rgt" onClick={() => this.showModalUpdate(item)}>{lang.t('common:button.update')}</button>
      )
    }
  }


  renderList() {
    var list = this.state.staff
    for(var tr of list) {
      if(tr.data.name === undefined) {
        tr.data.name = lang.t('admin:label.unknowntrainer')
      }
    }
    list.sort((a,b) => a.data.name.localeCompare(b.data.name))
    return (
      <div>
        {list.map(item => (
          <div className="box list simple highlight mb-10" key={item.id}>
            <div className="col-4">
              <div className="avatar"><img src='/img/list-staff.svg' className='staff' alt={item.data.name}/></div>
              <p><strong>{item.data.name}</strong></p>
            </div>
            <div className="col-4">
              <p>{this.renderListRole(item)} {this.renderStatus(item)}</p>
            </div>
            <div className="col-2">
              {this.renderButtonStats(item)}
            </div>
            <div className="col-2">
              {this.renderListUpdate(item)}
            </div>
          </div>
        ))}
        <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.ADMINSTAFFNEW)}>{lang.t('admin:button.addanotherstaffmember')}</button>
      </div>
    )
  }


  renderStaff() {
    if(this.state.staff.length > 0) {
      return (
        <div className="col-12">
          <div className="listheader">
            <ListHeader id='staff' number={this.state.staff.length}/>
            <div className="clear"></div>
          </div>
          {this.renderList()}
        </div>
      )
    } else {
      return (
        <div className="col-12">
          <div className="listheader">
            <h3>{lang.t('admin:label.yourstaff')}</h3>
            <div className="clear"></div>
          </div>
          <ListEmpty id='staff' type='simple'/>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newstaff', link: ROUTES.ADMINSTAFFNEW}
    var item2 = {id: 'staffinvite', link: ''}
    if(HelperBusiness.getRole('') && global.userSubStatus !== 'trialing') {
      if(global.userSubPlan === 'spark' || global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        item1 = {id: 'newstaff', link: ''}
      }
      if((global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') && global.userStaff.length > 0) {
        item1 = {id: 'newstaff', link: ''}
      }
    }
    return (
      <WidgetActions
        item1={item1} clickItem1={() => this.setState({showModalLocked: true})}
        item2={item2} clickItem2={() => this.clickLink()}
        item3={{id: '', link: ''}}
      />
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'stafftotal', value: this.state.staff.length, prev: ''}}
                item2={{id: 'staffactive', value: this.getStaff(true), prev: ''}}
                item3={{id: 'staffinactive', value: this.getStaff(false), prev: ''}}
                item4={{id: '', value: ''}}
              />
            </div>
            {this.renderActions()}
            {this.renderStaff()}
            <div className="clear sv-80"></div>
          </div>
          <Header type='admin' active='staff'/>
          <Navigation active='admin' />
          {this.renderModalAdd()}
          {this.renderModalUpdate()}
          {this.renderModalDelete()}
          {this.renderModalStats()}
          <ModalMessageSimple type={global.maxTrainers === 0 ? 'stafflocked' : 'stafflocked2'} show={this.state.showModalLocked} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='staff-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.admin')}/>
          <Navigation active='admin' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['admin','common','header','nav','modals','messaging'])(withRouter(withAuthorization(condition)(AdminStaffPage)));