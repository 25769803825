//import Var from '../helper/Calendar';
import React from 'react';



class HelperBusiness {


  renderLogo() {
    if(global.spaceImage !== '' && global.spaceImage !== global.userImage) {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+global.spaceImage+')'}}> </div>
      )
    }
  }


  getRole(string) {
    var label = false
    if(string.includes(global.userRole) && global.userRole !== '') {
      label = true
    }
    return label
  }


  getRoleIncl(string) {
    var label = false
    if(string.includes(global.userRole) || global.userRole === '') {
      label = true
    }
    if(global.userRole.includes('manager')) {
      label = true
    }
    return label
  }


}



const b = new HelperBusiness();
export default b;