import React, { Component } from 'react';
import InfoBar from '../../components/Form/info-bar';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalStats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      show: props.show,
      hidden: 'hidden',
      item: props.item,
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.id,
      show: props.show,
      item: props.item,
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      this.setState({
        hidden: '',
      });
    }, 100);
  }


  hide() {
    this.setState({
      hidden: 'hidden',
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  getClientResponseText(client) {
    for(var form of client.forms) {
      if(form.id === this.state.id) {
        for(var sec of form.sections) {
          if(sec.id === this.state.item.id) {
            if(sec.data.response !== '') {
              return (
                <p className="mb-20"><span style={{fontSize:11}}>{client.data.name}<br/></span>{sec.data.response}</p>
              )
            }
          }
        }
      }
    }
  }


  getClientResponseYesNo(value, type) {
    var label = ''
    var num = 0
    var total = 0
    for(var client of global.clients) {
      for(var form of client.forms) {
        if(form.id === this.state.id) {
          for(var sec of form.sections) {
            if(sec.id === this.state.item.id) {
              total++
              if(sec.data.response.indexOf(value) !== -1) {
                num++
              }
            }
          }
        }
      }
    }
    if(type === 'per') {
      label = parseInt(num/total*100)+"%"
      if(total === 0) {
        label = "0"
      } else {
        label = parseInt(num/total*100)+"%"
      }
    } else {
      if(total === 0) {
        label = "0% ("+num+")"
      } else {
        label = parseInt(num/total*100)+"% ("+num+")"
      }
    }
    return label
  }


  renderContent() {
    if(this.state.item.data.type === 'text') {
      var list = global.clients
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map((item, index) => (
            this.getClientResponseText(item)
          ))}
        </div>
      )
    } else if(this.state.item.data.type === 'yesno') {
      return (
        <div>
          <InfoBar label={lang.t('modals:filter.yes')} value={this.getClientResponseYesNo('0', 'label')} color='primary' width={this.getClientResponseYesNo('0', 'per')}/>
          <InfoBar label={lang.t('modals:filter.no')} value={this.getClientResponseYesNo('1', 'label')} color='primary' width={this.getClientResponseYesNo('1', 'per')}/>
        </div>
      )
    } else if(this.state.item.data.type === 'selection') {
      var list2 = []
      for(var option of this.state.item.data.options) {
        list2.push(option)
      }
      return (
        <div>
          {list2.map((item, index) => (
            <InfoBar label={item} value={this.getClientResponseYesNo(String(index), 'label')} color='primary' width={this.getClientResponseYesNo(String(index), 'per')}/>
          ))}
        </div>
      )
    } else if(this.state.item.data.type === 'rating') {
      var list3 = []
      for(var i=0; i<this.state.item.data.num; i++) {
        list3.push(String(i+1))
      }
      return (
        <div>
          {list3.map((item, index) => (
            <InfoBar label={index+1} value={this.getClientResponseYesNo(item, 'label')} color='primary' width={this.getClientResponseYesNo(item, 'per')}/>
          ))}
        </div>
      )
    }
  }


  render() {
    if(this.state.show) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box clients">
            <h2 className="mb-20 lft">{lang.t('modals:stats.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            <p className="mb-20"><strong>{this.state.item.data.label}</strong></p>
            <div className="clear"></div>
            <div className="scroll">
              {this.renderContent()}
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalStats);