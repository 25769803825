import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import Message from '../../components/Message';
import Moment from 'moment';
import Resizer from "react-image-file-resizer";
import Connector from '../../data/Connector';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import * as ROUTES from '../../constants/routes';
import EventEmitter from '../../helper/Emitter';



class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'form',
      email: '',
      password: '',
      error: [false, false, false],
      checked: false,
      showOverlayStaff: false,
      hiddenStaff: 'hidden',
      margin: 0,

      name: '',
      phone: '',
      birth: '',
      code: '',
      btype: 0,
      error2: [false, false, false],
      file: null,
      fileDisplay: null,
      types: ['', '', '', '', ''],
      allowClick: true,
      move1: '',
      move2: 'in',
      move3: 'in',
      excus: '',
      exsub: '',
      country: 'au',
      lbs: false,
      referrer: '',
      t2: ' lout',
      t3: 'rout ',
    };
  }


  componentDidMount() {
    document.title = 'Business Portal - PT Mate'
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    var ctry = 'us'
    var lbs = true
    var referrer = ''
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-2]
    global.showOverview = false
    if(id === 'referral' || id === 'affiliate') {
      referrer = arr[arr.length-1]
    }
    if(tz.indexOf('Australia') !== -1) {
      ctry = 'au'
      lbs = false
    }
    this.setState({
      country: ctry,
      lbs: lbs,
      referrer: referrer
    })
    setTimeout(() => {
      this.setState({t2: ''})
    }, 1350);
    setTimeout(() => {
      this.setState({t3: ''})
    }, 1450);
  }


  componentWillUnmount() {
    this.setState({
      active: false
    })
  }


  onChange2 = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  toggleChange = () => {
    this.setState({
      checked: !this.state.checked,
    });
  }


  createUser() {
    if(this.state.allowClick) {
      var tmp = [false, false, false]
      this.setState({
        error: [false, false, false],
        allowClick: false,
        //move1: 'out'
      })
      if(this.state.email !== '' && this.state.password !== '' && this.state.checked) {
        this.setState({
          error: [false, false, false],
          allowClick: false,
          //move1: 'out'
        })

        Firebase.auth().createUserWithEmailAndPassword(
          this.state.email,
          this.state.password
        ).then(authUser => {
          //this.createEntry()
          this.setState({
            move1: 'out'
          })
          setTimeout(() => {
            this.createEntry()
          }, 250);
          if(this.state.referrer !== '') {
            var callFunction1 = Firebase.functions().httpsCallable('sendReferral');
            callFunction1({email: this.state.email, uid: Firebase.auth().currentUser.uid, referrer: this.state.referrer}).then(function(result) {})
          }
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({
            allowClick: true,
          })
        })
        
      } else {
        if(this.state.email === '') {
          tmp[0] = true
        }
        if(this.state.password === '') {
          tmp[1] = true
        }
        if(!this.state.checked) {
          tmp[2] = true
        }
        this.setState({
          error: tmp,
          allowClick: true
        })
      }
    }
    
  }


  createEntry() {
    global.uid = Firebase.auth().currentUser.uid;
    global.uidUser = Firebase.auth().currentUser.uid;
    Firebase.database().ref('/users/'+global.uid).set({
      uid: global.uid,
      name: '',
      email: this.state.email,
      phone: '',
      timer: 5,
      lbs: false,
      inches: false,
      birth: '01/01/1900',
      country: 'au',
      message: 0,
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      pushToken: '',
    }).then(() => {
      this.setState({
        type: 'form2',
        allowClick: true
      })
      setTimeout(() => {
        this.setState({
          move2: '',
        })
      }, 50);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/spaces/'+global.uid).set({
      uid: global.uid,
      owner: '',
      name: '',
      email: this.state.email,
      phone: '',
      pin: '',
      subPlanId: '',
      card: '',
      stripe: '',
      subId: '',
      subStatus: 'trialing',
      subBilling: '',
      subCancel: false,
      subEnd: parseInt(Moment().add(14, 'days').format('X')),
      subItemId: '',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      community: true,
      communityPost: true,
      comments: true,
      showBooked: true,
      theme: 'blue',
      country: 'au',
      lbs: false,
    })
  }



  // Page 2 stuff ------------------------------------------------------------



  setCountry(event) {
    var lbs = false
    if(event.target.value === 'us') {
      lbs = true
    }
    this.setState({
      country: event.target.value,
      lbs: lbs
    });
  }


  checkUpdate() {
    var tmp = [false, false, false]
    this.setState({error2: [false, false, false]})
    if(this.state.name !== '' && this.state.phone !== '' && this.state.birth !== '') {
      this.updateUser()
    } else {
      if(this.state.name === '') {
        tmp[0] = true
      }
      if(this.state.phone === '') {
        tmp[1] = true
      }
      if(this.state.birth === '') {
        tmp[2] = true
      }
      this.setState({error2: tmp})
    }
  }


  updateUser() {

    var bus = this.state.code
    if(bus === '') {
      bus = this.state.name
    }

    Connector.loadSubscriptions((result) => {
      for(var item of global.allSubs) {
        if(item.id === Firebase.auth().currentUser.uid) {
          this.setState({
            exsub: item.sub,
            excus: item.stripe,
          })
        }
      }
    })
    Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
      owner: this.state.name,
      phone: '',
      name: bus,
      country: this.state.country,
      lbs: this.state.lbs
    })
    Firebase.database().ref('/public/'+Firebase.auth().currentUser.uid).update({
      color: 'blue',
      name: bus,
      logo: ''
    })
    var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
    createStripe({email: this.state.email, name: this.state.name, code: '', user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
      //window.location.pathname = '/overview'
    })
    Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
      name: this.state.name,
      phone: '',
      birth: this.state.birth,
      business: bus,
      country: this.state.country,
      lbs: this.state.lbs
    }).then(() => {
      //this.setState({type: 'form3'})
      this.setState({
        allowClick: false,
        move2: 'out'
      })
      setTimeout(() => {
        this.setState({
          type: 'form3',
          allowClick: true
        })
      }, 250);
      setTimeout(() => {
        this.setState({
          move3: '',
        })
      }, 300);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      //this.setState({type: 'form2'})
      this.setState({
        type: 'form2',
        move2: '',
        move3: 'in',
      })
    })
  }


  uploadImage() {
    var user = Firebase.auth().currentUser
    const sr = Firebase.storage().ref()
    const fr = sr.child('images/users/'+Firebase.auth().currentUser.uid+'.jpg')
    user.updateProfile({
      photoURL: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg'
    })
    global.userCurrency = 'aud'
    if(this.state.country === 'us') {
      global.userCurrency = 'usd'
    }
    Resizer.imageFileResizer(
      this.state.file,
      800,
      800,
      "JPEG",
      70,
      0,
      (uri) => {
        var ar = uri.split(',')
        fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
          Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
            image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          })
          Firebase.database().ref('/public/'+Firebase.auth().currentUser.uid).update({
            logo: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
          })
          Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
            image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          }).then(() => {
            if(this.state.exsub === '' && this.state.excus === '') {
              /*var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
              createStripe({email: this.state.email, name: this.state.name, code: '', user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
                //window.location.pathname = '/overview'
              })*/
              setTimeout(() => {
                this.props.history.push(ROUTES.OVERVIEW)
              }, 1200);
            } else {
              this.updateUserSub()
            }
            
            global.uid = ''
            global.uidUser = ''
            //this.setState({type: 'form3'})
          })
        }).catch((error)=>{
          //EventEmitter.dispatch('showMessageError', error.message);
        })
      },
      "base64",
      200,
      200
    );
  }


  updateUserSub() {
    /*var callFunction = Firebase.functions().httpsCallable('retrieveSubscription');
    callFunction({uid: Firebase.auth().currentUser.uid, sub: this.state.exsub, customer: this.state.excus}).then(function(result) {
      //window.location.pathname = '/overview'
    });*/
    setTimeout(() => {
      this.props.history.push(ROUTES.OVERVIEW)
    }, 1200);
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onChangeBirth = event => { 
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      this.setState({birth: Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')});
    }
  };


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        <div className="avatar"><img src="/img/data-client.svg" alt="Select your avatar"/></div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}></div>
      )
    }
  }


  updateToggle(num, value) {
    var tmp = this.state.types
    if(tmp[num] === '') {
      tmp[num] = value
    } else {
      tmp[num] = ''
    }
    this.setState({
      types: tmp
    })
  }


  setBtype(value) {
    this.setState({
      btype: value
    })
  }


  sendToggle() {
    global.userCurrency = 'aud'
    if(this.state.country === 'us') {
      global.userCurrency = 'usd'
    }
    var user = Firebase.auth().currentUser
    global.signup = true
    if(this.state.btype === 0) {
      this.setState({type: 'loading'})
      var types = ''
      for(var item of this.state.types) {
        if(item !== '') {
          types += ','+item
        }
      }
      this.initIntercom(this.state.name, types)
      user.updateProfile({
        displayName: this.state.name,
      })
      Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).update({
        phone: this.state.phone,
        pin: this.state.phone,
      })
      Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).update({
        types: types,
        experience: 0,
        phone: this.state.phone,
      }).then(() => {
        if(this.state.file !== null) {
          this.uploadImage()
        } else {
          if(this.state.exsub === '' && this.state.excus === '') {
            /*var createStripe = Firebase.functions().httpsCallable('createStripeCustomerWeb');
            createStripe({email: this.state.email, name: this.state.name, code: '', user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
              //window.location.pathname = '/overview'
            })*/
            setTimeout(() => {
              this.props.history.push(ROUTES.OVERVIEW)
            }, 1200);
          } else {
            this.updateUserSub()
          }
          global.uid = ''
          global.uidUser = ''
        }
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
        this.setState({type: 'form3'})
      })
    } else {
      this.showOverlayStaff()
    }
  }


  continueStaff() {
    this.hideOverlayStaff()
    var user = Firebase.auth().currentUser
    user.updateProfile({
      displayName: 'staff',
    })
    var createStripe = Firebase.functions().httpsCallable('sendStaffInternal');
    createStripe({name: this.state.name, user: Firebase.auth().currentUser.uid}).then(function(result) {})

    Firebase.database().ref('/spaces/'+Firebase.auth().currentUser.uid).remove()
    Firebase.database().ref('/users/'+Firebase.auth().currentUser.uid).remove()
    Firebase.database().ref('/usersStaff/'+Firebase.auth().currentUser.uid).update({
      phone: this.state.phone,
      name: this.state.name,
      birth: this.state.birth,
      uid: Firebase.auth().currentUser.uid,
      role: 'assistant',
      email: this.state.email,
      pushToken: '',
      space: '',
      country: this.state.country,
      status: 'active',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      lbs: this.state.lbs
    }).then(() => {
      window.location.pathname = '/staff/search'
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      this.setState({type: 'form3'})
    })
  }


  initIntercom(name, types) {
    if(Firebase.auth().currentUser.displayName !== 'staff' && Firebase.auth().currentUser.displayName !== 'client') {
      window.Intercom("boot", {
        app_id: "gwe4xp9u",
        name: name, // Full name
        email: this.state.email, // Email address
        phone: this.state.phone,
        user_id: Firebase.auth().currentUser.uid,
        created_at: Moment().format('X'),
        "Experience": 0,
        "Types": types
      });
      window.Intercom("update");
    }
  }



  // Staff overlay ------------------------------------------------------------



  showOverlayStaff() {
    this.setState({
      showOverlayStaff: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenStaff: '',
        margin: -height/2,
      });
    }, 100);
  }


  hideOverlayStaff() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenStaff: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlayStaff: false});
    }, 500);
  }


  renderOverlayStaff() {
    if(this.state.showOverlayStaff) {
      return (
        <div className={'overlay '+this.state.hiddenStaff}>
            <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h3>{lang.t('modals:staffsignup.title')}</h3>
            <button className="btn tertiary small close" onClick={() => this.hideOverlayStaff()}>{lang.t('common:button.close')}</button>
            <p className="clear">{lang.t('modals:staffsignup.text')}</p>
            <button className="btn primary" onClick={() => this.continueStaff()}>{lang.t('modals:staffsignup.button')}</button>
            <div className="clear space-20"></div>
            <button className="btn tertiary width-12" onClick={() => this.hideOverlayStaff()}>{lang.t('common:button.cancel')}</button>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    var label = ''
    if(this.state.error[0]) {
      label += ' '+lang.t('auth:error.email')
    }
    if(this.state.error[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:error.password')
    }
    if(this.state.error[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:error.tc')
    }
    if(this.state.error[0] || this.state.error[1] || this.state.error[2]) {
      return (
        <p className="error pb-30">{lang.t('auth:error.pleasereview')}: {label}</p>
      )
    }
  }


  renderError2() {
    var label = ''
    if(this.state.error2[0]) {
      label += ' '+lang.t('auth:new.fullname')
    }
    if(this.state.error2[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:new.phone')
    }
    if(this.state.error2[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:new.dob')
    }
    if(this.state.error2[0] || this.state.error2[1] || this.state.error2[2]) {
      return (
        <p className="error pb-30">{lang.t('auth:error.pleasereview')}: {label}</p>
      )
    }
  }


  renderForm3() {
    if(this.state.type === 'form3') {
      return (
        <div className={'inner '+this.state.move3}>
          <h1 style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:new.page3.title')}</h1>
          <p>{lang.t('auth:new.page3.text')}</p>
          <div className="sv-30 clear"></div>
          <div className={this.state.types[0] === '' ? 'toggle clear' : 'toggle active'} onClick={() => this.updateToggle(0, 'pt')}>
            <div className="mover"></div>
          </div>
          <p style={{textAlign: 'left'}}>{lang.t('auth:new.type1')}</p>
          <div className="sv-30"></div>
          <div className={this.state.types[1] === '' ? 'toggle clear' : 'toggle active'} onClick={() => this.updateToggle(1, 'group')}>
            <div className="mover"></div>
          </div>
          <p style={{textAlign: 'left'}}>{lang.t('auth:new.type2')}</p>
          <div className="sv-30"></div>
          <div className={this.state.types[2] === '' ? 'toggle' : 'toggle active'} onClick={() => this.updateToggle(2, 'functional')}>
            <div className="mover"></div>
          </div>
          <p style={{textAlign: 'left'}}>{lang.t('auth:new.type3')}</p>
          <div className="sv-30"></div>
          <div className={this.state.types[3] === '' ? 'toggle' : 'toggle active'} onClick={() => this.updateToggle(3, 'virtual')}>
            <div className="mover"></div>
          </div>
          <p style={{textAlign: 'left'}}>{lang.t('auth:new.type4')}</p>
          <div className="sv-30"></div>
          <div className={this.state.types[4] === '' ? 'toggle' : 'toggle active'} onClick={() => this.updateToggle(4, 'online')}>
            <div className="mover"></div>
          </div>
          <p style={{textAlign: 'left'}}>{lang.t('auth:new.type5')}</p>
          <div className="clear sv-20"></div>
          <label>{lang.t('auth:new.youare')}</label>
          <div className="radios">
            <button style={{marginTop:0}} className={this.state.btype === 0 ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setBtype(0)}>{lang.t('auth:new.owner')}</button>
            <button style={{marginTop:0}} className={this.state.btype === 1 ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setBtype(1)}>{lang.t('auth:new.staff')}</button>
          </div>

          <div className="sv-50 clear"></div>
          <button className="btn primary" onClick={() => this.sendToggle()}>{lang.t('auth:button.alldone')}</button>
        </div>
      )
    }
  }


  renderForm2() {
    if(this.state.type === 'form2') {
      return (
        <div className={'inner '+this.state.move2}>
          <h1 style={{marginBottom: 10}}>{lang.t('auth:new.page2.title')}</h1>
          <p className="mb-30" style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:new.page2.text')}</p>
          <div className="none">{this.displayImage()}
          <div className="sv-20"></div>
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>  </div>    
          <div className="sv-20"></div>
          <label>{lang.t('auth:new.fullname')}*</label>
          <input type="text" placeholder={lang.t('auth:new.fullname.placeholder')} name="name" value={this.state.name} onChange={this.onChange2}/>
          <label>{lang.t('auth:new.phone')}*</label>
          <input type="number" placeholder={lang.t('auth:new.phone.placeholder')} name="phone" value={this.state.phone} onChange={this.onChange2}/>
          <label>{lang.t('auth:new.dob')}*</label>
          <input type="date" placeholder="Your date of birth" value={Moment(this.state.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeBirth}/>
          <label>{lang.t('auth:new.businessname')}</label>
          <input type="text" placeholder={lang.t('auth:new.businessname.placeholder')} name="code" value={this.state.code} onChange={this.onChange2}/>
          <label>{lang.t('auth:new.country')}</label>
          <select value={this.state.country} onChange={event => this.setCountry(event)}>
            <option value='au'>{lang.t('auth:new.country.au')}</option>
            <option value='us'>{lang.t('auth:new.country.us')}</option>
          </select>
          {this.renderError2()}
          <div className="clear sv-50"></div>   
          <button className="btn primary" onClick={() => this.checkUpdate()}>{lang.t('auth:button.continue')}</button>
        </div>
      )
    }
  }


  renderLoader() {
    if(this.state.type === 'loading') {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p>{lang.t('auth:new.settingyouup')}</p>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.type === 'form') {
      return (
        <div className={'inner '+this.state.move1}>
          <img src="/img/logo.svg" alt="PT Mate Business Portal"/>
          <h1 style={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:new.title')}</h1>
          <p className="mb-30">{lang.t('auth:new.text')}</p>
          <label>{lang.t('auth:login.email')}</label>
          <input type="text" placeholder={lang.t('auth:login.email.placeholder')} name="email" value={this.state.email} onChange={this.onChange}/>
          <label>{lang.t('auth:login.password')}</label>
          <input type="password" placeholder={lang.t('auth:new.password.placeholder')} name="password" value={this.state.password} onChange={this.onChange}/>
          <div className="terms">
            <input type="checkbox" value={this.state.checked} onChange={this.toggleChange}/>
            <p>{lang.t('auth:new.tc1')} <a href="http://www.ptmate.net/terms-conditions" target="_blank" rel="noopener noreferrer">{lang.t('auth:new.tc2')}</a> {lang.t('auth:new.tc3')} <a href="http://www.ptmate.net/privacy-policy" target="_blank" rel="noopener noreferrer">{lang.t('auth:new.tc4')}</a></p>
            <div className="clear"></div>
          </div>
          {this.renderError()}
          <button className="btn primary" onClick={() => this.createUser()}>{lang.t('auth:button.createaccount')}</button>
        </div>
      )
    }
  }


  renderBottom() {
    if(this.state.type === 'form') {
      return (
        <Link to={ROUTES.LANDING} className={'link'+this.state.t2}>{lang.t('auth:button.already')}</Link>
      )
    }
  }


  renderPromo() {
    if(this.state.type === 'form') {
      return <div className={'promo '+this.state.t3+this.state.move1}>{lang.t('auth:new.message1.title')}<br/><span>{lang.t('auth:new.message1.text')}</span></div>
    } else if(this.state.type === 'form2') {
      return <div className={'promo '+this.state.move2}>{lang.t('auth:new.message2.title')}<br/><span>{lang.t('auth:new.message2.text')}</span></div>
    } else if(this.state.type === 'form3') {
      return <div className={'promo '+this.state.move3}>{lang.t('auth:new.message3.title')}<br/><span>{lang.t('auth:new.message3.text')}</span></div>
    }
  }


  render() {
    return (
      <div className="register">
        <div className="content hero1">
          {this.renderForm()}
          {this.renderLoader()}
          {this.renderForm2()}
          {this.renderForm3()}
        </div>
        <div className="hero">
          <div className="reg-hero hero2">
            <div className="gradient"></div>
          </div>
          {this.renderPromo()}
        </div>
        {this.renderBottom()}
        {this.renderOverlayStaff()}
        <Message/>
      </div>
    )
  }
}

const SignUpForm = compose(
  withRouter,
)(SignUpPage);

export default withTranslation(['auth','common','modals'])(SignUpForm);
