import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Connector from '../../data/Connector';
import Moment from 'moment';
import HelperBusiness from '../../helper/Business';
import { withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputClient from '../../components/Form/client';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import InfoPrice from '../../components/Form/price';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import ModalClients from '../../components/Modals/clients';
import ModalMessageBasic from '../../components/Modals/message-basic';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class NewInvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      invoices: global.invoices,
      clients: global.clients,
      clientsList: global.clients,
      products: [],
      product: null,
      productId: '',
      client: null,
      type: 'sessions',
      due: 'today',
      gst: global.spaceGST,
      date: Moment().format('YYYY-MM-DD'),
      showGST: false,
      showModalClients: false,
      showModalBasic: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('New Invoice - PT Mate')
    this._isMounted = true

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    this.configureData()
    var arr = this.props.location.pathname.split('/')
    if(arr[arr.length-1] !== 'new') {
      this.setState({id: arr[arr.length-1]})
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp2 = []
      for(var item of global.products) {
        if(item.data.type === this.state.type) {
          tmp.push(item)
        }
      }
      for(var item2 of global.clients) {
        if(item2.data.parent === undefined || item2.data.parent === '') {
          tmp2.push(item2)
        }
      }
      var show = false
      if(global.spaceGST !== 0) {
        show = true
      }
      this.setState({
        invoices: global.invoices,
        clients: global.clients,
        clientsList: tmp2,
        products: tmp,
        product: null,
        productId: '',
        showGST: show
      })
    }
  }



  // Interactive ------------------------------------------------------------



  updateValues(val, key) {
    this.setState({
      [key]: val
    });
  }


  toggleType(value) {
    this.setState({
      type: value,
    }, () => {
      var tmp = []
      for(var item of global.products) {
        if(item.data.type === value) {
          tmp.push(item)
        }
      }
      this.setState({
        products: tmp,
        product: null,
        productId: '',
      })
    })
  }


  selectProduct(event) {
    if(event.target.value !== '') {
      var tmp = null
      for(var item of this.state.products) {
        if(item.id === event.target.value) {
          tmp = item
        }
      }
      this.setState({
        productId: event.target.value,
        product: tmp,
        group: tmp.data.group
      });
    }
  }


  getGST() {
    if(this.state.gst !== 0) {
      var mul = this.state.product.data.price/11
      var num = mul/10*this.state.gst
      return global.curSym+num.toFixed(2)
    }
  }


  checkCreateInvoice() {
    var valid = false
    valid = Moment(this.state.date, 'YYYY-MM-DD').isValid()
    if(this.state.due !== 'other') {
      valid = true
    }
    if(valid) {
      this.createInvoice()
    }
  }


  createInvoice() {
    var gst = null
    var gststr1 = ''
    var gststr2 = ''
    var date = Moment(this.state.date, 'YYYY-MM-DD').format('X')
    if(this.state.due === 'today') {
      date = Moment().format('X')
    } else if(this.state.due === '7days') {
      date = Moment().add(7, 'days').format('X')
    } else if(this.state.due === '30days') {
      date = Moment().add(30, 'days').format('X')
    }
    var number = ''+global.spaceInvoice
    if(global.spaceInvoice < 10) {
      number = '00'+global.spaceInvoice
    }
    if(global.spaceInvoice < 100 && global.spaceInvoice > 9) {
      number = '0'+global.spaceInvoice
    }
    if(this.state.showGST) {
      if(this.state.gst !== '' && this.state.gst !== 0) {
        var mul = this.state.product.data.price/11
        var num = mul/10*this.state.gst
        gst = num
        gststr1 = global.curSym+num.toFixed(2)
        gststr2 = lang.t('messaging:label.gstincluded')
      }
    }

    var token = this.state.client.data.pushToken
    if(token !== undefined && token !== '') {
      Connector.sendPushNotification(token, lang.t('messaging:push.newinvoice.title'), global.userBusiness+lang.t('messaging:push.newinvoice.text'), 'invoice', "")
    }

    var obj = Firebase.database().ref('/invoices/'+global.uid).push()
    obj.update({
      client: this.state.client.id,
      product: this.state.productId,
      price: this.state.product.data.price,
      gst: gst,
      date: parseInt(Moment().format('X')),
      due: parseInt(date),
      number: number,
      status: 'open',
    }).then(() => {
      global.message = lang.t('messaging:message.invoicecreated')
      this.props.history.push(global.currentPage)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })

    if(this.state.email !== '') {
      var arr = this.state.emails.split(',')
      for(var ar of arr) {
        var email = ar.replace(' ', '')
        var arr2 = []
        var address = ''
        if(global.spaceAddress !== undefined) {
          arr2 = global.spaceAddress.split('||')
          if(arr2.length > 3) {
            address = arr2[0]+'\n'+arr2[1]+'\n'+arr2[2]+', '+arr2[3]
          }
        }
        if(global.spaceInvoice1 !== undefined && global.spaceInvoice1 !== '') {
          address = global.spaceInvoice1
        }
        var desc = ''
        if(this.state.product.data.desc !== undefined) {
          desc = this.state.product.data.desc
        }
        
        var callFunction = Firebase.functions().httpsCallable('sendInvoiceV2');
        callFunction({name: global.userBusiness, email: email, address: address, number: number, date: Moment().format('D MMM YYYY'), client: this.state.client.data.name, phone: this.state.client.data.phone, product: this.state.product.data.name, price: global.curSym+this.state.product.data.price.toFixed(2), link: 'https://ptmate.app/member/'+global.uid+'/admin/pay-invoice/'+obj.key, footer: global.spaceInvoice2, due: Moment(date, 'X').format('D MMM YYYY'), gst1: gststr1, gst2: gststr2, desc: desc}).then(function(result) {});
      }
    }

    Firebase.database().ref('/spaces/'+global.uid).update({
      invoice: (global.spaceInvoice+1)
    })
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false,
      showModalBasic: false,
    })
  }


  modalClick(item) {
    this.hideModals()
    this.setState({
      client: item,
      emails: item.data.email
    })
    if(item.data.uid !== '') {
      Connector.getClientToken(item.data.uid)
    }
  }



  // Display stuff ------------------------------------------------------------



  renderProductGroup(item) {
    if(this.state.type === 'sessions' && item.data.stype === '11') {
      return lang.t('billing:label.form11')
    } else if(this.state.type === 'sessions' && item.data.stype === 'group') {
      return lang.t('billing:label.formgroup')
    } else if(this.state.type === 'sessions' && item.data.stype === 'both') {
      return lang.t('billing:label.formgroup11')
    }
  }


  renderDesc() {
    var show = false
    if(this.state.productId !== '') {
      for(var item of global.products) {
        if(item.id === this.state.productId && item.data.desc !== undefined && item.data.desc !== '') {
          show = true
        }
      }
    }
    if(show) {
      return <button className="btn tertiary small rgt" style={{fontSize:'11px'}} onClick={() => this.setState({showModalBasic: true})}>{lang.t('common:button.viewdetails')}</button>
    }
  }


  renderDate() {
    if(this.state.due === 'other') {
      return <InputDate label={lang.t('billing:form.due.label')+"*"} value={Moment(this.state.date, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' placeholder="Select a date" onChange={(event) => this.updateValues(event, 'date')}/>
    }
  }


  renderGST() {
    if(this.state.showGST) {
      return (
        <InputNumber label={lang.t('billing:form.includegst.label')} value={this.state.gst} onChange={(event) => this.setState({gst: parseFloat(event)})} info={this.getGST()}/>
      )
    }
  }


  renderBottom() {
    if(this.state.productId !== '' && this.state.client !== null) {
      return (
        <div>
          <InputToggle label={lang.t('billing:form.includegst')} value={this.state.showGST} onChange={() => this.setState({showGST: !this.state.showGST})} text={lang.t('billing:form.includegst.text')}/>
          {this.renderGST()}
          <InputRadio
            label={lang.t('billing:label.due')}
            value={this.state.due}
            clickElement={(event) => this.setState({due: event})}
            values={[{name: lang.t('billing:form.due.today'), value: 'today'}, {name: lang.t('billing:form.due.7days'), value: '7days'}, {name: lang.t('billing:form.due.30days'), value: '30days'}, {name: lang.t('billing:form.due.other'), value: 'other'}]}
          />
          {this.renderDate()}
          <InputText label={lang.t('billing:form.sendto')} value={this.state.emails} onChange={(event) => this.updateValues(event, 'emails')} info={lang.t('billing:form.sendto.info')}/>
          <button className="btn primary mt-20" onClick={() => this.checkCreateInvoice()}>{lang.t('billing:button.createinvoice')}</button>
        </div>
      )
    }
  }


  renderForm() {
    return (
      <div>
        <InputClient id='invoice' client={this.state.client} clickElement={() => this.setState({showModalClients: true})}/>
        <div className="sv-20"></div>
        <InputRadio
          label={lang.t('billing:form.producttype')}
          value={this.state.type}
          clickElement={(event) => this.toggleType(event)}
          values={[{name: lang.t('billing:form.packofsessions'), value: 'sessions'}, {name: lang.t('billing:form.other'), value: 'other'}]}
        />
        <label style={{float:'left'}}>{lang.t('billing:form.product')}</label>{this.renderDesc()}
        <select onChange={event => this.selectProduct(event)} value={this.state.productId}>
          <option value={''}>- {lang.t('billing:form.pleaseselect')} -</option>
          {this.state.products.map(item => (
            <option value={item.id} key={item.id}>{item.data.name} {this.renderProductGroup(item)}</option>
          ))}
        </select>
        <div className="sv-20"></div>
        <InfoPrice id={this.state.productId} product={this.state.product}/>
        {this.renderBottom()}
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                {this.renderForm()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id='newinvoice'/>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header title={lang.t('billing:title.newinvoice')} link={global.currentPage}/>
          <Navigation active='billing' />
          <ModalMessageBasic show={this.state.showModalBasic} onHide={() => this.hideModals()} text={this.state.product === null ? '' : this.state.product.data.desc}/>
          <ModalClients clients={this.state.clientsList} show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='invoices-locked2' type='simple'/>
          </div>
          <Header title={lang.t('billing:title.newinvoice')} link={global.currentPage}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','messaging'])(withRouter(withAuthorization(condition)(NewInvoicePage)));