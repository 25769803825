import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import Message from '../../components/Message';
import Moment from 'moment';
import Resizer from "react-image-file-resizer";
import Connector from '../../data/Connector';
import ConnectorMember from '../../data/ConnectorMember';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import * as ROUTES from '../../constants/routes';
import EventEmitter from '../../helper/Emitter';



class SignUpStaffPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'form',
      email: '',
      password: '',
      error: [false, false, false],
      checked: false,
      logo: '',
      sname: 'PT Mate',
      atypes: ['assistant', 'trainer', 'admin', 'finance','cs','manager'],
      dtypes: [lang.t('auth:staff.type1'), lang.t('auth:staff.type2'), lang.t('auth:staff.type3'), lang.t('auth:staff.type4'), lang.t('auth:staff.type5')],
      atype: 4,

      name: '',
      phone: '',
      birth: '',
      code: '',
      exp: 0,
      error2: [false, false, false],
      file: null,
      fileDisplay: null,
      allowClick: true,
      move1: '',
      move2: 'in',
    };
  }


  componentDidMount() { 
    document.title = 'Staff Signup - PT Mate'
    global.uid = ''
    global.showOverview = false
    var arr = this.props.location.pathname.split('/')
    var id = arr[arr.length-3]
    var type = parseInt(arr[arr.length-1])
    if(type === undefined || isNaN(type) || type > this.state.atypes.length) {
      type = 0
    }
    if(id !== 'signup' && id !== 'staff') {
      global.uid = id
      ConnectorMember.loadPublic()
    }
    EventEmitter.subscribe('publicLoaded', (event) => {
      this.setAppearance()
    })
    this.setState({
      atype: parseInt(type),
    })
  }


  componentWillUnmount() {
    this.setState({
      active: false
    })
  }


  setAppearance() {
    var tmp = 'PT Mate'
    if(global.userBusiness !== '') {
      tmp = global.userBusiness
      document.title = 'Staff Sign Up - '+global.userBusiness
    }
    this.setState({
      logo: global.spaceImage,
      sname: tmp
    })
  }


  onChange2 = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  toggleChange = () => {
    this.setState({
      checked: !this.state.checked,
    });
  }


  createUser() {
    if(this.state.allowClick) {
      var tmp = [false, false, false]
      this.setState({
        error: [false, false, false],
        allowClick: false,
        //move1: 'out'
      })
      if(this.state.email !== '' && this.state.password !== '' && this.state.checked && global.uid !== '') {
        this.setState({
          error: [false, false, false],
          allowClick: false,
          //move1: 'out'
        })

        Firebase.auth().createUserWithEmailAndPassword(
          this.state.email,
          this.state.password
        ).then(authUser => {
          //this.createEntry()
          this.setState({
            move1: 'out'
          })
          setTimeout(() => {
            this.createEntry()
          }, 250);
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
          this.setState({
            allowClick: true,
          })
        })
        
      } else {
        if(this.state.email === '') {
          tmp[0] = true
        }
        if(this.state.password === '') {
          tmp[1] = true
        }
        if(!this.state.checked) {
          tmp[2] = true
        }
        this.setState({
          error: tmp,
          allowClick: true
        })
      }
    }
    
  }


  createEntry() {
    global.uidUser = Firebase.auth().currentUser.uid;
    global.userName = 'staff'
    Firebase.auth().currentUser.updateProfile({
      displayName: 'staff'
    })
    Firebase.database().ref('/usersStaff/'+global.uidUser).set({
      uid: global.uidUser,
      name: 'Staff Member',
      email: this.state.email,
      phone: '0',
      birth: '01/01/1900',
      image: '',
      imageDate: Moment().format('DD/MM/YYYY HH:mm'),
      pushToken: '',
      role: 'assistant',
      space: global.uid,
      country: 'au',
      status: 'active'
    }).then(() => {
      this.setState({
        type: 'form2',
        allowClick: true
      })
      setTimeout(() => {
        this.setState({
          move2: '',
        })
      }, 50);
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    Firebase.database().ref('/spaces/'+global.uid+'/staff/'+global.uidUser).set({
      id: global.uidUser,
      role: 'assistant',
      status: 'active',
      name: 'Staff Member',
    })
  }



  // Page 2 stuff ------------------------------------------------------------



  checkUpdate() {
    var tmp = [false, false, false]
    this.setState({error2: [false, false, false]})
    if(this.state.name !== '' && this.state.phone !== '' && this.state.birth !== '') {
      this.updateUser()
    } else {
      if(this.state.name === '') {
        tmp[0] = true
      }
      if(this.state.phone === '') {
        tmp[1] = true
      }
      if(this.state.birth === '') {
        tmp[2] = true
      }
      this.setState({error2: tmp})
    }
  }


  updateUser() {
    Firebase.database().ref('/spaces/'+global.uid+'/staff/'+Firebase.auth().currentUser.uid).update({
      name: this.state.name,
    })
    Firebase.database().ref('/usersStaff/'+Firebase.auth().currentUser.uid).update({
      name: this.state.name,
      phone: this.state.phone,
      birth: this.state.birth,
    }).then(() => {
      this.setState({
        allowClick: false,
        move2: 'out'
      })
      if(this.state.file !== null) {
        this.uploadImage()
      } else {
        //global.uid = ''
        //global.uidUser = ''
        Connector.loadUserStaff((result) => {})
        setTimeout(() => {
          window.location.pathname = '/overview'
        }, 300);
      }
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
      //this.setState({type: 'form2'})
      this.setState({
        type: 'form2',
        move2: '',
        move3: 'in',
      })
    })
  }


  uploadImage() {
    var user = Firebase.auth().currentUser
    const sr = Firebase.storage().ref()
    const fr = sr.child('images/users/'+Firebase.auth().currentUser.uid+'.jpg')
    user.updateProfile({
      photoURL: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg'
    })
    Resizer.imageFileResizer(
      this.state.file,
      800,
      800,
      "JPEG",
      70,
      0,
      (uri) => {
        var ar = uri.split(',')
        fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
          Firebase.database().ref('/usersStaff/'+Firebase.auth().currentUser.uid).update({
            image: 'images/users/'+Firebase.auth().currentUser.uid+'.jpg',
            imageDate: Moment().format('DD/MM/YYYY HH:mm')
          }).then(() => {
            //global.uid = ''
            //global.uidUser = ''
            Connector.loadUserStaff((result) => {})
            setTimeout(() => {
              window.location.pathname = '/overview'
            }, 300);
          })
        }).catch((error)=>{
          //EventEmitter.dispatch('showMessageError', error.message);
        })
      },
      "base64",
      200,
      200
    );
  }


  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };


  onChangeBirth = event => { 
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    if(valid) {
      this.setState({birth: Moment(event.target.value, 'YYYY-MM-DD').format('DD/MM/YYYY')});
    }
  };


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        <div className="avatar"><img src="/img/data-client.svg" alt="Avatar"/></div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}></div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    var label = ''
    if(this.state.error[0]) {
      label += ' '+lang.t('auth:error.email')
    }
    if(this.state.error[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:error.password')
    }
    if(this.state.error[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:error.tc')
    }
    if(this.state.error[0] || this.state.error[1] || this.state.error[2]) {
      return (
        <p className="error">{lang.t('auth:error.pleasereview')}: {label}</p>
      )
    }
  }


  renderError2() {
    var label = ''
    if(this.state.error2[0]) {
      label += ' '+lang.t('auth:new.fullname')
    }
    if(this.state.error2[1]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:new.phone')
    }
    if(this.state.error2[2]) {
      if(label !== '') {
        label += ','
      }
      label += ' '+lang.t('auth:new.dob')
    }
    if(this.state.error2[0] || this.state.error2[1] || this.state.error2[2]) {
      return (
        <p className="error">{lang.t('auth:error.pleasereview')}: {label}</p>
      )
    }
  }


  renderForm2() {
    if(this.state.type === 'form2') {
      return (
        <div className={'inner '+this.state.move2}>
          <h1 style={{marginBottom: 10}}>{lang.t('auth:new.page2.title')}</h1>
          <p className="mb-40" className={{whiteSpace: 'pre-wrap'}}>{lang.t('auth:new.page2.text')}</p>
          {this.displayImage()}
          <div className="sv-10"></div>
          <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
          <div className="sv-20"></div>
          <label>{lang.t('auth:new.fullname')}*</label>
          <input type="text" placeholder={lang.t('auth:new.fullname.placeholder')} name="name" value={this.state.name} onChange={this.onChange2}/>
          <label>{lang.t('auth:new.phone')}*</label>
          <input type="number" placeholder={lang.t('auth:new.phone.placeholder')} name="phone" value={this.state.phone} onChange={this.onChange2}/>
          <label>{lang.t('auth:new.dob')}*</label>
          <input type="date" placeholder="Your date of birth" value={Moment(this.state.birth, 'DD/MM/YYYY').format('YYYY-MM-DD')} onChange={this.onChangeBirth}/>
          {this.renderError2()}
          <div className="sv-30"></div>   
          <button className="btn primary" onClick={() => this.checkUpdate()}>{lang.t('auth:button.letsgo')}</button>
        </div>
      )
    }
  }


  renderLoader() {
    if(this.state.type === 'loading') {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p>{lang.t('auth:new.settingyouup')}</p>
        </div>
      )
    }
  }


  renderLogo() {
    if(this.state.logo === '') {
      return (
        <img src="/img/logo.svg" alt="PT Mate Staff Sign Up"/>
      )
    } else {
      return (
        <div className="logo">
          <img src={this.state.logo} alt="Staff Sign Up"/>
        </div>
      )
    }
  }


  renderForm() {
    if(this.state.type === 'form') {
      return (
        <div className={'inner '+this.state.move1}>
          {this.renderLogo()}
          <h1>{lang.t('auth:staff.title')}<br/>{this.state.sname}</h1>
          <p className="mb-30">{lang.t('auth:staff.text1')} {this.state.sname} {lang.t('auth:staff.text2')} {this.state.dtypes[this.state.atype]} {lang.t('auth:staff.text3')}</p>
          <label>{lang.t('auth:login.email')}</label>
          <input type="text" placeholder={lang.t('auth:login.email.placeholder')} name="email" value={this.state.email} onChange={this.onChange}/>
          <label>{lang.t('auth:login.password')}</label>
          <input type="password" placeholder={lang.t('auth:new.password.placeholder')} name="password" value={this.state.password} onChange={this.onChange}/>
          <div className="terms">
             <input type="checkbox" value={this.state.checked} onChange={this.toggleChange}/>
             <p>{lang.t('auth:new.tc1')} <a href="http://www.ptmate.net/terms-conditions" target="_blank" rel="noopener noreferrer">{lang.t('auth:new.tc2')}</a> {lang.t('auth:new.tc3')} <a href="http://www.ptmate.net/privacy-policy" target="_blank" rel="noopener noreferrer">{lang.t('auth:new.tc4')}</a></p>
          </div>
          <div className="clear"></div>
          {this.renderError()}
          <p className="sv-10"></p>
          <button className="btn primary" onClick={() => this.createUser()}>{lang.t('auth:button.createyouraccount')}</button>
        </div>
      )
    }
  }


  renderBottom() {
    if(this.state.type === 'form') {
      return (
        <Link to={ROUTES.LANDING} className="link">{lang.t('auth:button.already')}</Link>
      )
    }
  }


  render() {
    return (
      <div className={'register theme-'+global.spaceTheme}>
        <div className="content hero1">
          {this.renderForm()}
          {this.renderLoader()}
          {this.renderForm2()}
        </div>
        <div className="hero">
          <div className="reg-hero hero2">
            <div className={'gradient theme-'+global.spaceTheme}></div>
          </div>
        </div>
        {this.renderBottom()}
        <Message/>
      </div>
    )
  }
}

const SignUpStaffForm = compose(
  withRouter,
)(SignUpStaffPage);

export default withTranslation(['auth','common'])(SignUpStaffForm);
