import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class SubscriptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: global.userSubPlan,
      planId: global.userSubPlanId,
      status: global.userSubStatus,
      end: global.userSubEnd,
      card: global.userCard,
      show: 'options',
      selType: '',
      selName: '',
      selBilling: '',
      selCard: false,
      values: ['', '', '', '', ''],
      error: [false, false, false, false, false],
      message: '',
      log: global.userLog,
      loading: false,
      showOverlay: false,
      hidden: 'hidden',
      overlayType: '',
      margin: 0,
      // user data
      userStripe: global.userStripe,
      userSubId: global.userSubId,
      userSubPlanId: global.userSubPlanId,
      coupon: global.coupon,
      invoices: [],
      showOverlayInvoices: false,
      hiddenInvoices: 'hidden',
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Subscription - PT Mate'
    window.Intercom("update");
    window.scrollTo(0, 0)

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.checkSub())
    EventEmitter.subscribe('emailUsageLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => {
      this.setState({
        log: global.userLog
      }, () => {
        this.checkLog()
      })
      
    })
    if(global.userPhone !== '') {
      this.checkSub()
    }
    
    this.configureData()
    this.checkLog()
    Connector.loadEmailUsage()
  }


  checkSub() {
    if(global.userStripe === '') {
      Connector.loadSubscriptions((result) => {
        var existing = ''
        var sub = ''
        for(var item of global.allSubs) {
          if(item.id === global.uidUser) {
            existing = item.stripe
            sub = item.sub
          }
        }
        if(existing === '') {
          var dt1 = Moment(global.userSubEnd, 'X')
          var dt2 = Moment()
          var days = parseInt(dt1.diff(dt2, 'days'))
          if(days < 0) {
            days = 0
          }
          var createStripe = Firebase.functions().httpsCallable('createStripeCustomerFailsafe');
          createStripe({email: global.userEmail, name: global.userName, days: days, user: Firebase.auth().currentUser.uid, currency: global.userCurrency}).then(function(result) {
            //done
          })
        } else {
          var callFunction = Firebase.functions().httpsCallable('retrieveSubscription');
          callFunction({uid: global.uidUser, sub: sub, customer: existing}).then(function(result) {});
        }
      })
    }
    this.configureData()
  }


  configureData() {
    var tmp = false
    if(global.userCard === undefined || global.userCard === '') {
      tmp = true
    }
    this.setState({
      plan: global.userSubPlan,
      planId: global.userSubPlanId,
      status: global.userSubStatus,
      end: global.userSubEnd,
      card: global.userCard,
      selCard: tmp,
      show: 'options',
      selType: '',
      selName: '',
      selBilling: '',
      values: ['', '', '', '', ''],
      log: global.userLog,
      // user data
      userStripe: global.userStripe,
      userSubId: global.userSubId,
      userSubPlanId: global.userSubPlanId,
      coupon: global.coupon,
    })
  }


  checkLog() {
    for(var item of this.state.log) {
      if(item.data.title === 'subupdateerror' || item.data.title === 'subbillingerror' || item.data.title === 'subcancelerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        this.setState({loading: false})
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
      }
      if(item.data.title === 'subupdate' || item.data.title === 'subbilling' || item.data.title === 'subupdate' || item.data.title === 'subcancel') {
        this.setState({
          loading: false,
          show: 'options'
        })
        EventEmitter.dispatch('showMessage', this.state.message);
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove() 
        setTimeout(() => {
          window.Intercom("update", {"Paying": true})
        }, 2000);
      }
      if(item.data.title === 'invoicesloaded') {
        this.setState({
          invoices: item.data.data
        })
      }
    }
  }



  // Manage plan ------------------------------------------------------------
  


  renderSubName() {
    if(this.state.planId.indexOf('found') !== -1) {
      return lang.t('account:sub.plan.foundation')
    } else if(this.state.planId.indexOf('basic') !== -1) {
      return lang.t('account:sub.plan.basic')
    } else if(this.state.planId.indexOf('pro') !== -1) {
      return lang.t('account:sub.plan.pro')
    } else {
      return lang.t('account:sub.plan.business')
    }
  }


  renderCycle(type) {
    if(type === 'simple') {
      if(this.state.plan.indexOf('monthly') !== -1) {
        return lang.t('account:sub.monthly')
      } else {
        return lang.t('account:sub.yearly2')
      }
    } else {
      var label = global.prices[0]+' '+lang.t('account:sub.permonth-s')
      if(this.state.plan === 'foundyearly') {
        label = global.prices[1]+' '+lang.t('account:sub.peryear-s')
      } else if(this.state.plan === 'basicmonthly') {
        label = global.prices[2]+' '+lang.t('account:sub.permonth-s')
      } else if(this.state.plan === 'basicyearly') {
        label = global.prices[3]+' '+lang.t('account:sub.peryear-s')
      } else if(this.state.plan === 'promonthly') {
        label = global.prices[4]+' '+lang.t('account:sub.permonth-s')
      } else if(this.state.plan === 'proyearly') {
        label = global.prices[5]+' '+lang.t('account:sub.peryear-s')
      } else if(this.state.plan === 'businessmonthly') {
        label = global.prices[6]+' '+lang.t('account:sub.permonth-s')
      } else if(this.state.plan === 'businessyearly') {
        label = global.prices[7]+' '+lang.t('account:sub.peryear-s')
      }
      return label
    }
  }


  showOverlayConfirm(type) {
    this.setState({
      showOverlay: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2,
        overlayType: type
      });
    }, 100);
  }


  hideOverlay() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showOverlay: false});
    }, 500);
  }


  renderOverlayButton() {
    if(this.state.overlayType === 'cycle') {
      return (
        <button className="btn primary" onClick={() => this.changeBilling()}>{lang.t('modals:billingcycle.button')}</button>
      )
    } else if(this.state.overlayType === 'cancel') {
      return (
        <button className="btn primary" onClick={() => this.cancelSubscription('cancel')}>{lang.t('modals:billingcycle.button.cancel')}</button>
      )
    } else if(this.state.overlayType === 'resume') {
      return (
        <button className="btn primary" onClick={() => this.cancelSubscription('resume')}>{lang.t('modals:billingcycle.button.resume')}</button>
      )
    }
  }


  renderOverlay() {
    var title = lang.t('modals:billingcycle.title')
    var snew = lang.t('modals:billingcycle.yearly')
    var price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[1]+lang.t('modals:billingcycle.peryear')+"\n\n"
    if(this.state.plan === 'basicmonthly') {
      price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[3]+lang.t('modals:billingcycle.peryear')+"\n\n"
    }
    if(this.state.plan === 'promonthly') {
      price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[5]+lang.t('modals:billingcycle.peryear')+"\n\n"
    }
    if(this.state.plan === 'businessmonthly') {
      price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[7]+lang.t('modals:billingcycle.peryear')+"\n\n"
    }
    var text = lang.t('modals:billingcycle.text1')+' '+snew+'?'+price+lang.t('modals:billingcycle.text2a')
    if(this.state.plan === 'foundyearly' || this.state.plan === 'basicyearly' || this.state.plan === 'proyearly' || this.state.plan === 'businessyearly') {
      snew = lang.t('modals:billingcycle.monthly')
      price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[0]+lang.t('modals:billingcycle.permonth')+"\n\n"
      if(this.state.plan === 'basicmonthly') {
        price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[2]+lang.t('modals:billingcycle.permonth')+"\n\n"
      }
      if(this.state.plan === 'promonthly') {
        price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[4]+lang.t('modals:billingcycle.permonth')+"\n\n"
      }
      if(this.state.plan === 'businessmonthly') {
        price = "\n\n"+lang.t('modals:billingcycle.price')+": "+global.prices[6]+lang.t('modals:billingcycle.permonth')+"\n\n"
      }
      text = lang.t('modals:billingcycle.text1')+' '+snew+'?'+price+lang.t('modals:billingcycle.text2')
    }
    if(this.state.overlayType === 'cancel') {
      title = lang.t('modals:billingcycle.title.cancel')
      text = lang.t('modals:billingcycle.text1.cancel')+" ("+(Moment(this.state.end, 'X').locale(lang.language).format('LL'))+"). "+lang.t('modals:billingcycle.text2.cancel')
    } else if(this.state.overlayType === 'resume') {
      title = lang.t('modals:billingcycle.title.resume')
      text = lang.t('modals:billingcycle.text.resume')+" "+Moment(this.state.userSubEnd, 'X').locale(lang.language).format('LL')+"."
    }
    if(this.state.showOverlay) {
      return (
        <div className={'overlay '+this.state.hidden}   >
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{title}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlay()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-30">{text}</p>
            {this.renderOverlayButton()}
          </div>
        </div>
      )
    }
  }


  changeBilling() {
    var type = 'foundyearly'
    var plan = ''
    if(this.state.plan === 'foundyearly') {
      type = 'foundmonthly'
    } else if(this.state.plan === 'basicmonthly') {
      type = 'basicyearly'
    } else if(this.state.plan === 'basicyearly') {
      type = 'basicmonthly'
    } else if(this.state.plan === 'promonthly') {
      type = 'proyearly'
    } else if(this.state.plan === 'proyearly') {
      type = 'promonthly'
    } else if(this.state.plan === 'businessmonthly') {
      type = 'businessyearly'
    } else if(this.state.plan === 'businessyearly') {
      type = 'businessmonthly'
    }
    for(var item of global.stripePlans) {
      if(item.type === type) {
        plan = item.plan
      }
    }
    var callFunction = Firebase.functions().httpsCallable('updateSubscription');
      callFunction({subscription: this.state.userSubId, plan: plan, id: global.userSubItemId, customer: this.state.userStripe, name: global.userName}).then(function(result) {
       //var sanitizedMessage = result.data.text;
    });
  }


  cancelSubscription(state) {
    var msg =  lang.t('messaging:subscription.text1')
    if(state === 'resume') {
      msg =  lang.t('messaging:subscription.text2')
    }
    this.setState({
      loading: true,
      message: msg
    })
    var callFunction = Firebase.functions().httpsCallable('cancelSubscription');
    callFunction({subscription: this.state.userSubId, state: state}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }



  // Confirmation ------------------------------------------------------------
  


  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  configurePayment() {
    var type = this.state.selType+this.state.selBilling
    var plan = ''
    for(var item of global.stripePlans) {
      if(item.type === type) {
        plan = item.plan
      }
    }
    this.setState({
      message: lang.t('messaging:message.subscriptionupdated'),
    })
    if(this.state.selCard) {
      this.payCard(plan)
    } else {
      var coupon = ''
      var callFunction = Firebase.functions().httpsCallable('updateSubscription');
      callFunction({subscription: this.state.userSubId, plan: plan, id: global.userSubItemId, customer: this.state.userStripe, name: global.userName, coupon: coupon}).then(function(result) {
       //var sanitizedMessage = result.data.text;
      });
    }
  }


  payCard(plan) {
    this.setState({error: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.values[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.values[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.values[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.values[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.values[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({error: tmp})
    if(passed) {
      // Execute payment
      this.setState({
        loading: true,
        message: lang.t('messaging:message.subscriptionupdated')
      })
      var coupon = ''
      // October promo
      if(global.userSubStatus === 'trialing' || global.userSubPlan === 'spark') {
        if(plan === 'price_1OOCCzAd6uNQtfqalqT5jeD6' || plan === 'price_1NlUnTAd6uNQtfqaccbmNsAb' || plan === 'price_1NV8F8Ad6uNQtfqa4yHBpG8o' || plan === 'price_1NV8ANAd6uNQtfqa6iAxEMIS') {
          coupon = 'Ktyx1Eqr'
        }
      }
      var callFunction = Firebase.functions().httpsCallable('createCard');
      callFunction({customer: this.state.userStripe, name: this.state.values[0], number: this.state.values[1], month: this.state.values[2], year: this.state.values[3], cvc: this.state.values[4], subscription: this.state.userSubId, plan: plan, subid: global.userSubItemId, coupon: coupon}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
  }


  showConfirm(type) {
    var name = 'Pro'
    if(type === 'found') {
      name = lang.t('account:sub.plan.foundation')
    }
    if(type === 'basic') {
      name = lang.t('account:sub.plan.basic')
    }
    if(type === 'business') {
      name = lang.t('account:sub.plan.business')
    }
    this.setState({
      selType: type,
      selName: name,
      selBilling: 'monthly',
      show: 'confirm'
    })
  }



  // Invoices ------------------------------------------------------------



  getInvoices() {
    var callFunction = Firebase.functions().httpsCallable('getInvoices');
    callFunction({customer: this.state.userStripe}).then(function(result) {});
    this.showOverlayInvoices()
  }


  showOverlayInvoices() {
    this.setState({
      showOverlayInvoices: true,
    })
    setTimeout(() => {
      this.setState({
        hiddenInvoices: '',
      });
    }, 100);
  }


  hideOverlayInvoices() {
    this.setState({
      hiddenInvoices: 'hidden',
    })
    setTimeout(() => {
      this.setState({showOverlayInvoices: false});
    }, 500);
  }


  renderInvoicesContent() {
    if(this.state.invoices.length === 0) {
      return (
        <div className="empty small pt-30">
          <p>{lang.t('modals:invoices.empty')}</p>
        </div>
      )
    } else {
      return (
        <div className="scroll">
          {this.state.invoices.map(item => (
            <a href={item.hosted_invoice_url} target="_blank" rel="noopener noreferrer" className="list bare static" key={item.id}>
              <div className="icon secondary">
                <div className="inner invoice"></div>
              </div>
              <div className="main">
                <h4>{global.curSym}{(item.total/100).toFixed(2)}</h4>
                <p>{item.lines.data[0].description}<br/>{Moment(item.created, 'X').locale(lang.language).format('lll')}</p>
              </div>
              <div className="clear"></div>
            </a>
          ))}
        </div>
      )
    }
  }


  renderOverlayInvoices() {
    if(this.state.showOverlayInvoices) {
      return (
        <div className={'overlay '+this.state.hiddenInvoices}>
          <div className="box clients">
            <h2 className="mb-20 lft">{lang.t('modals:invoices.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideOverlayInvoices()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            {this.renderInvoicesContent()}
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  getNutritionPrice() {
    var label = 0
    var now = Moment().format('w/YYYY')
    for(var item of global.clients) {
      if(item.data.nutritionEnd !== undefined) {
        var nw1 = Moment(item.data.nutritionStart, 'X').format('DD/MM/YYYY')
        var nw2 = Moment(item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
        var diff = Moment(nw2, 'DD/MM/YYYY').diff(Moment(nw1, 'DD/MM/YYYY'), 'days')+1
        for(var i=0; i<diff; i++) {
          var dt = Moment(nw1, 'DD/MM/YYYY').add(i, 'days').format('w/YYYY')
          if(dt === now) {
            label += 1.1
          }
        }
      }
    }
    return label.toFixed(2)
  }


  getNutritionPlans() {
    var num = 0
    var week = Moment().format('w')
    for(var item of global.clients) {
      if(item.data.nutritionEnd !== undefined) {
        var nw1 = Moment(item.data.nutritionStart, 'X').format('w')
        var nw2 = Moment(item.data.nutritionEnd, 'X').format('w')
        if(nw1 <= week && nw2 >= week) {
          num++
        }
      }
    }
    return num
  }


  renderChargeInfo() {
    if(global.userSubStatus === 'trialing') {
      if(this.state.selType === 'business' || this.state.selType === 'pro') {
        return (
          <div className="infobox green mb-30 clear">
            <p>{lang.t('account:sub.charge1')} ({Moment(global.userSubEnd, 'X').add(1, 'month').locale(lang.language).format('D MMM YYYY')}).</p>
          </div>
        )
      } else {
        return (
          <div className="infobox mb-30 clear">
            <p>{lang.t('account:sub.charge2')} ({Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMM YYYY')}).</p>
          </div>
        )
      }
    } else {
      if(global.userSubPlan !== 'spark') {
        return (
          <div className="infobox mb-30 clear">
            <p>{lang.t('account:sub.charge3a')} {this.renderPrice()} {lang.t('account:sub.charge3b')} ({Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMM YYYY')}).</p>
          </div>
        )
      }
      
    }
  }


  renderPrice() {
    var label = global.prices[0]
    if(this.state.selType === 'found' && this.state.selBilling === 'yearly') {
      label = global.prices[1]
    } else if(this.state.selType === 'basic' && this.state.selBilling === 'monthly') {
      label = global.prices[2]
    } else if(this.state.selType === 'basic' && this.state.selBilling === 'yearly') {
      label = global.prices[3]
    } else if(this.state.selType === 'pro' && this.state.selBilling === 'monthly') {
      label = global.prices[4]
    } else if(this.state.selType === 'pro' && this.state.selBilling === 'yearly') {
      label = global.prices[5]
    } else if(this.state.selType === 'business' && this.state.selBilling === 'monthly') {
      label = global.prices[6]
    } else if(this.state.selType === 'business' && this.state.selBilling === 'yearly') {
      label = global.prices[7]
    }
    if(this.state.selBilling === 'monthly') {
      label += ' '+lang.t('account:sub.permonth-s')
    } else {
      label += ' '+lang.t('account:sub.peryear-s')
    }
    return label
  }


  renderButtonLabel() {
    if(global.userSubStatus === 'trialing') {
      return lang.t('account:button.updatesubscription')
    } else {
      return lang.t('account:button.confirmandpay')
    }
  } 


  renderError(num) {
    var texts = [lang.t('account:sub.error1'), lang.t('account:sub.error2'), lang.t('account:sub.error3'), lang.t('account:sub.error4'), lang.t('account:sub.error5')]
    if(this.state.error[num]) {
      return (
        <p className="error">{texts[num]}</p>
      )
    }
  }


  renderSavedCard() {
    var label = ''
    for(var item of global.cards) {
      if(item.id === this.state.card) {
        label = '('+item.data.brand+' '+lang.t('account:label.ending')+' '+item.data.last4+')'
      }
    }
    return label
  }


  renderPayment() {
    if(!this.state.selCard) {
      return (
        <div>
          <div className="radios">
            <button className="radio col-6 active">{lang.t('account:sub.usesavedcard')}</button>
            <button className="radio col-6" onClick={() => this.setState({selCard: true})}>{lang.t('account:sub.usenewcard')}</button>
          </div>
          <div className="sv-40"></div>
          <p className="mb-20">{lang.t('account:sub.savedcard1')} {this.renderSavedCard()} {lang.t('account:sub.savedcard2')}</p>
          {this.renderChargeInfo()}
        </div>
      )
    } else {
      if(this.state.card !== '' && this.state.card !== undefined) {
        return (
          <div>
            <div className="form-item">
              <div className="radios">
                <button className="radio col-6" onClick={() => this.setState({selCard: false})}>{lang.t('account:sub.usesavedcard')}</button>
                <button className="radio col-6 active">{lang.t('account:sub.usenewcard')}</button>
              </div>
            </div>
            <div className="col-12 mb-20">
              <label>{lang.t('account:sub.nameoncard')}*</label>
              <input type="text" placeholder={lang.t('account:sub.nameoncard.placeholder')} value={this.state.values[0]} className={this.state.error[0] ? 'error': ''} name="0" onChange={this.onChange}/>
              {this.renderError(0)}
            </div>
            <div className="col-12 mb-20">
              <label>{lang.t('account:sub.cardnumber')}*</label>
              <input type="number" placeholder={lang.t('account:sub.cardnumber.placeholder')} value={this.state.values[1]} className={this.state.error[1] ? 'error': ''} name="1" onChange={this.onChange}/>
              {this.renderError(1)}
            </div>
            <div className="col-4 mb-20">
              <label>{lang.t('account:sub.expiry')}*</label>
              <input type="number" placeholder="MM" value={this.state.values[2]} className={this.state.error[2] ? 'error': ''} name="2" onChange={this.onChange}/>
              {this.renderError(2)}
            </div>
            <div className="col-4 mb-20">
              <label>&nbsp;</label>
              <input type="number" placeholder="YY" value={this.state.values[3]} className={this.state.error[3] ? 'error': ''} name="3" onChange={this.onChange}/>
              {this.renderError(3)}
            </div>
            <div className="col-1">&nbsp;</div>
            <div className="col-3 mb-20">
              <label>{lang.t('account:sub.cvc')}*</label>
              <input type="number" placeholder={lang.t('account:sub.cvc')} value={this.state.values[4]} className={this.state.error[4] ? 'error': ''} name="4" onChange={this.onChange}/>
              {this.renderError(4)}
            </div>
            <div className="clear sv-20"></div>
            {this.renderChargeInfo()}
          </div>
        )
      } else {
        return (
          <div className="form">
            <div className="col-12 mb-20">
              <label>{lang.t('account:sub.nameoncard')}*</label>
              <input type="text" placeholder={lang.t('account:sub.nameoncard.placeholder')} value={this.state.values[0]} className={this.state.error[0] ? 'error': ''} name="0" onChange={this.onChange}/>
              {this.renderError(0)}
            </div>
            <div className="col-12 mb-20">
              <label>{lang.t('account:sub.cardnumber')}*</label>
              <input type="number" placeholder={lang.t('account:sub.cardnumber.placeholder')} value={this.state.values[1]} className={this.state.error[1] ? 'error': ''} name="1" onChange={this.onChange}/>
              {this.renderError(1)}
            </div>
            <div className="col-4 mb-20">
              <label>{lang.t('account:sub.expiry')}*</label>
              <input type="number" placeholder="MM" value={this.state.values[2]} className={this.state.error[2] ? 'error': ''} name="2" onChange={this.onChange}/>
              {this.renderError(2)}
            </div>
            <div className="col-4 mb-20">
              <label>&nbsp;</label>
              <input type="number" placeholder="YY" value={this.state.values[3]} className={this.state.error[3] ? 'error': ''} name="3" onChange={this.onChange}/>
              {this.renderError(3)}
            </div>
            <div className="col-1">&nbsp;</div>
            <div className="col-3 mb-20">
              <label>{lang.t('account:sub.cvc')}*</label>
              <input type="number" placeholder={lang.t('account:sub.cvc')} value={this.state.values[4]} className={this.state.error[4] ? 'error': ''} name="4" onChange={this.onChange}/>
              {this.renderError(4)}
            </div>
            <div className="clear sv-20"></div>
            {this.renderChargeInfo()}
          </div>
        )
      }
    }
  }


  renderConfirm() {
    if(this.state.show === 'confirm') {
      return (
        <div className="content form-container">
          <div className="box">
            <h4 className="mb-30">{lang.t('account:sub.updateyoursubscription')}</h4>
            <div className="info clear">
              <label>{lang.t('account:sub.selectedplan')}</label>
              <p>{this.state.selName}</p>
            </div>
            <div className="form-item">
              <label>{lang.t('account:sub.billingcycle')}</label>
              <div className="radios">
                <button className={this.state.selBilling === 'monthly' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({selBilling: 'monthly'})}>{lang.t('account:sub.monthly')}</button>
                <button className={this.state.selBilling === 'yearly' ? 'radio col-6 active' : 'radio col-6'} onClick={() => this.setState({selBilling: 'yearly'})}>{lang.t('account:sub.yearly')}</button>
              </div>
            </div>
            <div className="info">
              <label>{lang.t('account:sub.price')}</label>
              <p>{this.renderPrice()}</p>
            </div>
            {this.renderPayment()}
            <button className="btn primary" onClick={() => this.configurePayment()}>{this.renderButtonLabel()}</button>
            <div className="sv-20"></div>
            <button className="btn tertiary close width-12" onClick={() => this.setState({show: 'options'})}>{lang.t('common:button.cancel')}</button>
          </div>
        </div>
      )
    }
  }



  // Display stuff ------------------------------------------------------------



  renderTrial() {
    if(this.state.status === 'trialing' && this.state.plan === 'spark') {
      return (
        <div className="infobox large green mb-30">
          <p>
            <strong>{lang.t('account:sub.expires1')}</strong><br/>
            {lang.t('account:sub.expires2')} {Moment(this.state.end, 'X').locale(lang.language).fromNow()}
          </p>
        </div>
      )
    } else {
      if(this.state.coupon !== '' && this.state.coupon !== undefined) {
        return (
          <div className="infobox large green mb-30">
            <p>
              <strong>{lang.t('account:sub.discount1')}</strong><br/>
              {this.state.plan === 'spark' ? lang.t('account:sub.discount2') : lang.t('account:sub.discount3')} {this.state.coupon}<br/>
            </p>
          </div>
        )
      }
    }
  }


  renderLoading() {
    if(this.state.loading) {
      return (
        <div className="loading">
          <div className="loader-box">
            <span className="loader"><span className="loader-inner"></span></span>
          </div>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('account:sub.connecting')}</p>
        </div>
      )
    }
  }


  renderInvoices() {
    if(this.state.status !== 'trialing' && this.state.show === 'options') {
      return (
        <div className="clear">
          <div className="sv-40"></div>
          <button className="btn tertiary width-12" onClick={() => this.getInvoices()}>{lang.t('account:button.viewinvoicehistory')}</button>
          <div className="sv-20 clear"></div>
        </div>
      )
    }
  }


  renderEmail() {
    var label = 0
    var label2 = 0
    var num = 0

    if(global.spaceEmailUsage[Moment().format('MM-YYYY')] !== undefined) {
      num = global.spaceEmailUsage[Moment().format('MM-YYYY')]
    }
    var calc = num/1000
    label = Math.ceil(calc)
    label2 = Math.ceil(calc)*5
    if(num > 0) {
      return (
        <div className="box grey clear mb-20">
          <div className="info large">
            <label>{lang.t('account:sub.addon')}</label>
            <p>{lang.t('account:sub.email')}</p>
            <span>{num} {lang.t('account:sub.emailssent')}<br/>
            {lang.t('account:sub.cost')}: {global.curSym}{label2} ({lang.t('account:sub.charged')} {Moment().startOf('month').add(1, 'month').locale(lang.language).format('D MMMM')})</span>
          </div>
        </div>
      )
    }
  }


  renderNutrition() {
    if(global.userNutritionId !== '' && this.getNutritionPlans() > 0) {
      return (
        <div className="box grey clear mb-20">
          <div className="info large">
            <label>{lang.t('account:sub.addon')}</label>
            <p>{lang.t('account:sub.nutrition')}</p>
            <span>{this.getNutritionPlans()} {this.getNutritionPlans() === 1 ? lang.t('account:sub.activeplan') : lang.t('account:sub.activeplans')}<br/>
            {lang.t('account:sub.estimatedcost')}: {global.curSym}{this.getNutritionPrice()} ({lang.t('account:sub.chargedsunday')})</span>
          </div>
        </div>
      )
    }
  }


  renderAddons() {
    return (
      <div className="mb-40">
        <div className="col-4">&nbsp;</div>
        <div className="col-4">
          {this.renderNutrition()}
          {this.renderEmail()}
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderPlanActiveTop() {
    if(this.state.plan === 'foundmonthly') {
      return (
        <div className="sub-top">
          <div className="ribbon green">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.foundation')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.permonth')}</label>
            <p>{global.prices[0]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'foundyearly') {
      return (
        <div className="sub-top">
          <div className="ribbon green">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.foundation')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.peryear')}</label>
            <p>{global.prices[1]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'basicmonthly') {
      return (
        <div className="sub-top">
          <div className="ribbon">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.basic')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.permonth')}</label>
            <p>{global.prices[2]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'basicyearly') {
      return (
        <div className="sub-top">
          <div className="ribbon">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.basic')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.peryear')}</label>
            <p>{global.prices[3]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'promonthly') {
      return (
        <div className="sub-top">
          <div className="ribbon purple">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.pro')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.permonth')}</label>
            <p>{global.prices[4]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'proyearly') {
      return (
        <div className="sub-top">
          <div className="ribbon purple">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.pro')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.peryear')}</label>
            <p>{global.prices[5]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'businessmonthly') {
      return (
        <div className="sub-top">
          <div className="ribbon black">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.business')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.permonth')}</label>
            <p>{global.prices[6]}</p>
          </div>
        </div>
      )
    } else if(this.state.plan === 'businessyearly') {
      return (
        <div className="sub-top">
          <div className="ribbon black">{lang.t('account:sub.yourplan')}: {lang.t('account:sub.plan.business')}</div>
          <div className="info large">
            <label>{lang.t('account:sub.peryear')}</label>
            <p>{global.prices[7]}</p>
          </div>
        </div>
      )
    }
  }


  renderButtonUpgrade(type) {
    if(this.state.plan.indexOf(type) === -1) {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn secondary width-12" onClick={() => this.showConfirm(type)}>{lang.t('account:button.switchtothisplan')}</button>
        </div>
      )
    } else {
      return <div className="pt-30" style={{height: 65, textAlign: 'center', fontSize: 14}}>{lang.t('account:sub.yourcurrentplan')}</div>
    }
  }


  renderPlanButtons() {
    if(global.userSubCancel) {
      return (
        <div className="clear">
          <div className="clear sv-30"></div>
          <button className="btn tertiary width-12 small close" onClick={() => this.showOverlayConfirm('resume')}>{lang.t('account:button.resumeyoursubscription')}</button>
          <div className="clear"></div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="col-6">
            <button className="btn secondary" onClick={() => this.showOverlayConfirm('cycle')}>{global.userSubPlan.indexOf('monthly') !== -1 ? lang.t('account:button.changetoyearly') : lang.t('account:button.changetomonthly')}</button>
          </div>
          <div className="col-6">
            <button className="btn secondary" onClick={() => window.scrollTo(0, 900)}>{lang.t('account:button.switchplan')}</button>
          </div>
          <div className="clear sv-30"></div>
          <button className="btn tertiary width-12 small close" onClick={() => this.showOverlayConfirm('cancel')}>{lang.t('account:button.cancelyoursubscription')}</button>
          <div className="clear"></div>
        </div>
      )
    }
  }


  renderPlanActive() {
    if(this.state.show === 'options' && this.state.plan !== 'spark') {
      return (
        <div>
          {this.renderTrial()}
          <div className="col-4 sub-left">
            <div className="sub-top">
              <h3>{lang.t('account:sub.yourcurrentplan')}</h3>
            </div>
          </div>
          <div className="col-4">
            <div className="box highlight subscription">
              {this.renderPlanActiveTop()}
              <div className="info">
                <label>{lang.t('account:sub.status')}</label>
                <p style={{fontSize: 16, textTransform: 'capitalize'}}>{this.state.status}</p>
              </div>
              <div className="clear sv-10"></div>
              {this.renderPlanButtons()}
            </div>
          </div>
          <div className="sv-60 clear"></div>
          {this.renderAddons()}

          <div className="col-4">
            <h2 className="mt-20" style={{marginBottom: 55}}>{lang.t('account:sub.switchtoanotherplan1')} <span className="hide-1024">{lang.t('account:sub.switchtoanotherplan2')} </span>{lang.t('account:sub.switchtoanotherplan3')}</h2>
            <div className="sv-20 clear"></div>
            <div className="sub-feature">{lang.t('account:sub.feature1')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature2')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature3')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature4')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature5')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature6')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature7')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature8')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature9')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature10')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature11')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature12')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature13')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature14')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature15')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature16')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature17')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature18')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature19')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature20')}</div>
            <div className="sub-feature end">{lang.t('account:sub.feature21')}</div>
          </div>
          <div className="col-2">
            <div className="box highlight subscription">
              <div className="ribbon green">{lang.t('account:sub.plan.foundation')}</div>
              <h4>{global.prices[0]}/{lang.t('account:sub.month')}</h4>
              <div className="sv-20 clear"></div>
              <div className="sub-feature">10</div>
              <div className="sub-feature">40</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature end">&nbsp;</div>
              {this.renderButtonUpgrade('found')}
            </div>
          </div>
          <div className="col-2">
            <div className="box highlight subscription">
              <div className="ribbon blue">{lang.t('account:sub.plan.basic')}</div>
              <h4>{global.prices[2]}/{lang.t('account:sub.month')}</h4>
              <div className="sv-20 clear"></div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">1</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature end">&nbsp;</div>
              {this.renderButtonUpgrade('basic')}
            </div>
          </div>
          <div className="col-2">
            <div className="box highlight subscription">
              <div className="ribbon purple">{lang.t('account:sub.plan.pro')}</div>
              <h4>{global.prices[4]}/{lang.t('account:sub.month')}</h4>
              <div className="sv-20 clear"></div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick end">&nbsp;</div>
              {this.renderButtonUpgrade('pro')}
            </div>
          </div>
          <div className="col-2">
            <div className="box highlight subscription">
              <div className="ribbon black">{lang.t('account:sub.plan.business')}</div>
              <h4>{global.prices[6]}/{lang.t('account:sub.month')}</h4>
              <div className="sv-20 clear"></div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick">&nbsp;</div>
              <div className="sub-feature tick end">&nbsp;</div>
              {this.renderButtonUpgrade('business')}
            </div>
          </div>
        </div>
      )
    }
  }


  renderChooseSpark() {
    if(this.state.status !== 'trialing') {
      return (
        <p>{lang.t('account:sub.spark1')}<br/>{lang.t('account:sub.spark2')}</p>
      )
    }
  }


  renderChoose() {
    if(this.state.show === 'options' && this.state.plan === 'spark') {
      return (
        <div>
          {this.renderTrial()}
          <div className="col-3 sub-left">
            <div className="sub-top">
              <h2>{this.state.status !== 'trialing' ? lang.t('account:sub.chooseaplan') : lang.t('account:sub.compareourplans')}</h2> 
              {this.renderChooseSpark()}
            </div>
            <div className="sub-feature">{lang.t('account:sub.feature1')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature2')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature3')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature4')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature5')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature6')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature7')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature8')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature9')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature10')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature11')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature12')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature13')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature14')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature15')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature16')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature17')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature18')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature19')}</div>
            <div className="sub-feature">{lang.t('account:sub.feature20')}</div>
            <div className="sub-feature end">{lang.t('account:sub.feature21')}</div>
          </div>
          <div className="col-9">
            <div className="col-3">
              <div className="box highlight subscription">
                <div className="sub-top">
                  <div className="ribbon green">{lang.t('account:sub.plan.foundation')}</div>
                  <div className="info large">
                    <label>{lang.t('account:sub.permonth')}</label>
                    <p>{global.prices[0]}</p>
                  </div>
                </div>
                <div className="sub-feature">10</div>
                <div className="sub-feature">40</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature end">&nbsp;</div>
                <div className="sv-30"></div>
                <button className="btn primary" onClick={() => this.showConfirm('found')}>{lang.t('account:button.choosethisplan')}</button>
              </div>
            </div>
            <div className="col-3">
              <div className="box highlight subscription">
                <div className="sub-top">
                  <div className="ribbon">{lang.t('account:sub.plan.basic')}</div>
                  <div className="info large">
                    <label>{lang.t('account:sub.permonth')}</label>
                    <p>{global.prices[2]}</p>
                  </div>
                </div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">1</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature end">&nbsp;</div>
                <div className="sv-30"></div>
                <button className="btn primary" onClick={() => this.showConfirm('basic')}>{lang.t('account:button.choosethisplan')}</button>
              </div>
            </div>
            <div className="col-3">
              <div className="box highlight subscription">
                <div className="sub-top">
                  <div className="ribbon purple">{lang.t('account:sub.plan.pro')}</div>
                  <div className="popular"></div>
                  <div className="info large">
                    <label>{lang.t('account:sub.permonth')}</label>
                    <p>{global.prices[4]}</p>
                  </div>
                </div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick end">&nbsp;</div>
                <div className="sv-30"></div>
                <button className="btn primary" onClick={() => this.showConfirm('pro')}>{lang.t('account:button.choosethisplan')}</button>
              </div>
            </div>
            <div className="col-3">
              <div className="box highlight subscription">
                <div className="sub-top">
                  <div className="ribbon black">{lang.t('account:sub.plan.business')}</div>
                  <div className="info large">
                    <label>{lang.t('account:sub.permonth')}</label>
                    <p>{global.prices[6]}</p>
                  </div>
                </div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature">{lang.t('account:sub.unlimited')}</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick">&nbsp;</div>
                <div className="sub-feature tick end">&nbsp;</div>
                <div className="sv-30"></div>
                <button className="btn primary" onClick={() => this.showConfirm('business')}>{lang.t('account:button.choosethisplan')}</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'content theme-'+global.spaceTheme}>
        {this.renderChoose()}
        {this.renderPlanActive()}
        {this.renderConfirm()}
        {this.renderInvoices()}
        <div className="clear sv-40"></div>
        <div className="header">
          <div className="content">
            <h2>{lang.t('account:sub.subscription')}</h2>
          </div>
        </div>
        <Navigation active=''/>
        {this.renderOverlay()}
        {this.renderOverlayInvoices()}
        {this.renderLoading()}
      </div>
    )
  }


  renderSwitch() {
    if(global.userSubStatus === 'unpaid' && global.userSubPlan === "spark" && HelperBusiness.getRoleIncl('')) {
      return (
        <div className="content">
          <div className="infobox big red">
            <p>{lang.t('account:sub.reactivate')}</p>
          </div>
          <div className="clear sv-40"></div>
          <div className="header">
            <div className="content">
              <h2>{lang.t('account:sub.subscription')}</h2>
            </div>
          </div>
          <Navigation active=''/>
          {this.renderOverlay()}
          {this.renderOverlayInvoices()}
          {this.renderLoading()}
        </div>
      )

    } else {
      return this.renderBase()
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderSwitch()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['account','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(SubscriptionPage)));