import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HelperBusiness from '../../helper/Business';
import HelperClients from '../../helper/Clients';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class HeaderClientDetails extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      client: props.client ?? null,
      active: props.active ?? '',
      link: props.link ?? global.currentPage,
      type: props.type ?? '',
      selector: props.selector ?? false,
      dateRange: global.dateRange,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      client: props.client ?? null,
      active: props.active ?? '',
      link: props.link ?? global.currentPage,
      type: props.type ?? '',
      selector: props.selector ?? false,
      dateRange: global.dateRange,
    }
  }


  setDateRange(event) {
    global.dateRange = event.target.value
    this.setState({dateRange: event.target.value})
    this.props.updateSelect()
  }


  renderSelect() {
    if(this.state.selector) {
      return (
        <div className="rgt mt-10">
          <label className="lft">{lang.t('nav:stats.label')}</label>
          <select className="light lft" onChange={event => this.setDateRange(event)} value={this.state.dateRange}>
            <option value='week'>{lang.t('nav:stats.week')}</option>
            <option value='7days'>{lang.t('nav:stats.7days')}</option>
            <option value='30days'>{lang.t('nav:stats.30days')}</option>
            <option value='month'>{lang.t('nav:stats.month')}</option>
            <option value='60days'>{lang.t('nav:stats.60days')}</option>
          </select>
        </div>
      )
    }
  }


  renderProgress() {
    if(HelperBusiness.getRoleIncl('assistant,assistant2,trainer') && this.state.client !== null) {
      return (
        <Link to={'/clients/'+this.state.client.id+'/progress'} className={this.state.active === 'progress' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:client.progress')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderHealth() {
    if(HelperBusiness.getRoleIncl('trainer,admin') && this.state.client !== null) {
      return (
        <Link to={'/clients/'+this.state.client.id+'/health'} className={this.state.active === 'health' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:client.health')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderTraining() {
    if(HelperBusiness.getRoleIncl('trainer') && this.state.client !== null) {
      return (
        <Link to={'/clients/'+this.state.client.id+'/plans'} className={this.state.active === 'plans' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:client.training')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  renderBilling() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs') && this.state.client !== null) {
      if(this.state.client.data.parent === undefined || this.state.client.data.parent === '') {
        return (
          <Link to={'/clients/'+this.state.client.id+'/billing'} className={this.state.active === 'billing' ? 'tab active' : 'tab'}>
            <p>{lang.t('nav:client.billing')}</p>
            <div className="bg"></div>
          </Link>
        )
      }
    }
  }


  renderSessions() {
    if(HelperBusiness.getRoleIncl('trainer,admin,cs') && this.state.client !== null) {
      return (
        <Link to={'/clients/'+this.state.client.id+'/sessions'} className={this.state.active === 'sessions' ? 'tab active' : 'tab'}>
          <p>{lang.t('nav:client.sessions')}</p>
          <div className="bg"></div>
        </Link>
      )
    }
  }


  render() {
    if(this.state.client !== null) {
      if(this.state.type === 'inactive') {
        return (
          <div className="header withtabs">
            <div className="content">
              <Link to={this.state.link} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              {HelperClients.getImage(this.state.client)}
              <h2 className="lft">{this.state.client.data.name}</h2>
              {this.renderSelect()}
              <div className="clear"></div>
              <Link to={'/clients/'+this.state.client.id+'/overview'} className={this.state.active === 'overview' ? 'tab active' : 'tab'}>
                <p>{lang.t('nav:client.overview')}</p>
                <div className="bg"></div>
              </Link>
              <Link to={'/clients/'+this.state.client.id+'/details'} className={this.state.active === 'details' ? 'tab active' : 'tab'}>
                <p>{lang.t('nav:client.details')}</p>
                <div className="bg"></div>
              </Link>
            </div>
          </div>
        )
      } else {
        return (
          <div className="header withtabs">
            <div className="content">
              <Link to={this.state.link} className="back lft mr-10">
                <div className="circle"></div>
                <div className="arrow"></div>
              </Link>
              <div className="lft mr-10">
                {HelperClients.getImage(this.state.client)}
              </div>
              <h2 className="lft">{this.state.client.data.name}</h2>
              {this.renderSelect()}
              <div className="clear"></div>
              <div className="header-tabs">
                <div className="track">
                  <Link to={'/clients/'+this.state.client.id+'/overview'} className={this.state.active === 'overview' ? 'tab active' : 'tab'}>
                    <p>{lang.t('nav:client.overview')}</p>
                    <div className="bg"></div>
                  </Link>
                  {this.renderSessions()}
                  {this.renderBilling()}
                  {this.renderTraining()}
                  {this.renderHealth()}
                  {this.renderProgress()}
                  <Link to={'/clients/'+this.state.client.id+'/details'} className={this.state.active === 'details' ? 'tab active' : 'tab'}>
                    <p>{lang.t('nav:client.details')}</p>
                    <div className="bg"></div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="header withtabs">
          <div className="content">
            <Link to={this.state.link} className="back lft mr-10">
              <div className="circle"></div>
              <div className="arrow"></div>
            </Link>
            <h2 className="lft">{lang.t('nav:client.client')}</h2>
            {this.renderSelect()}
            <div className="clear"></div>
            <Link to={'/clients/'} className={this.state.active === 'overview' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:client.overview')}</p>
              <div className="bg"></div>
            </Link>
            <Link to={'/clients/'} className={this.state.active === 'details' ? 'tab active' : 'tab'}>
              <p>{lang.t('nav:client.details')}</p>
              <div className="bg"></div>
            </Link>
          </div>
        </div>
      )
    }
  }
}


export default withTranslation(['nav'])(HeaderClientDetails);