import React, { Component } from 'react';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import EventEmitter from '../../helper/Emitter';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';

import HeaderOverview from '../Headers/overview';
import HeaderClients from '../Headers/clients';
import HeaderCalendar from '../Headers/calendar';
import HeaderProgramming from '../Headers/programming';
import HeaderHealth from '../Headers/health';
import HeaderBilling from '../Headers/billing';
import HeaderAdmin from '../Headers/admin';

import Location from '../Navigation/location';
import Message from '../Message';
import Chat from '../Message/chat';
import Badges from '../Message/badges';

import { withTranslation } from "react-i18next";
import lang from 'i18next';



class Navigation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      name: global.userName,
      role: global.userRole,
      class: 'submenu out none',
      class2: 'sub-container none',
      submenu: '',
      showing: false,
      showTrial: global.showTrial
    };
  }


  componentDidMount() {
    EventEmitter.subscribe('userLoaded', (event) => {
      this.setState({
        name: global.userName,
        role: global.userRole,
        showTrial: global.showTrial,
      })
    })
    EventEmitter.subscribe('assistantUpdated', (event) => {
      this.setState({
        role: global.userRole,
      })
    })
  }


  showSubmenu() {
    if(!this.state.showing) {
      this.setState({
        class: 'submenu out',
        class2: 'sub-container',
      })
      setTimeout(() => {
        this.setState({
          class: 'submenu',
          showing: true
        })
      }, 10);
    }
  }


  hideSubmenu() {
    if(this.state.showing) {
      this.setState({
        class: 'submenu out',
        showing: false,
      })
      setTimeout(() => {
        this.setState({
          class: 'submenu out none',
          class2: 'sub-container none',
          submenu: '',
        })
      }, 260);
    }
  }


  getImage() {
    if(global.userImage === '' && global.userName !== null) {
      var inits = ''
      let arr = global.userName.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
      )
    }
  }


  getSubscription() {
    var label = lang.t('nav:sub.spark')
    var d1 = Moment(global.userSubEnd, 'X')
      var days = d1.fromNow()
    if(global.userSubStatus === 'trialing') {
      label = lang.t('nav:sub.trial')+' '+days+')'
    } else if(global.userSubStatus === 'canceled') {
      label = lang.t('nav:sub.canceled')
    } else {
      if(global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        label = lang.t('nav:sub.foundation')
      } else if(global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') {
        label = lang.t('nav:sub.basic')
      } else if(global.userSubPlan === 'promonthly' || global.userSubPlan === 'proyearly') {
        label = lang.t('nav:sub.pro')
      } else {
        label = lang.t('nav:sub.business')
      }
    }
    return label
  }


  getRole() {
    var label = lang.t('nav:role.assistant')
    if(global.userRole === 'trainer' || global.userRole === 'trainer2') {
      label = lang.t('nav:role.trainer')
    }
    if(global.userRole === 'finance') {
      label = lang.t('nav:role.finance')
    }
    if(global.userRole === 'marketing') {
      label = lang.t('nav:role.marketing')
    }
    if(global.userRole === 'admin') {
      label = lang.t('nav:role.admin')
    }
    if(global.userRole === 'cs') {
      label = lang.t('nav:role.cs')
    }
    if(global.userRole === 'manager') {
      label = lang.t('nav:role.manager')
    }
    return label
  }


  logoutUser() {
    HelperPage.signOut()
  }


  closeTrialMessage() {
    global.showTrial = false
    this.setState({
      showTrial: false
    })
  }


  renderUpgrade() {
    if(global.userSubStatus === 'trialing' && global.userSubPlan === 'spark' && HelperBusiness.getRoleIncl('') && !global.signup) {
      var d1 = Moment(global.userSubEnd, 'X')
      var days = d1.fromNow()
      if(window.location.href.indexOf('/program/') === -1 && window.location.href.indexOf('/new-program') === -1 && window.location.href.indexOf('/run-session/') === -1 && window.location.href.indexOf('/results/') === -1 && this.state.showTrial) {
        return (
          <div className="infobox green sub-promo">
            <h4 className="lft">{lang.t('nav:trial.title')}</h4>
            <button className="btn tertiary rgt close small" onClick={() => this.closeTrialMessage()}>{lang.t('common:button.close')}</button>
            <p className="pt-10 mb-10 clear">{lang.t('nav:trial.message')}</p>
            <Link to={ROUTES.ACCOUNTTRIAL} className="btn tertiary small lft">{lang.t('nav:trial.button')}</Link>
          </div>
        )
      }
    }
  }


  renderMenuAffiliate() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <Link to={ROUTES.AFFILIATES} className="item">
          <div className="bg"></div>
          <div className="text">{lang.t('nav:account.affiliate')}</div>
        </Link>
      )
    }
  }


  renderMenuSub() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <Link to={ROUTES.SUBSCRIPTION} className="item">
          <div className="bg"></div>
          <div className="text">{lang.t('nav:account.subscription')}</div>
        </Link>
      )
    }
  }


  renderMenuBadges() {
    if(HelperBusiness.getRoleIncl('assistant2,trainer2')) {
      return (
        <Link to={ROUTES.ACCOUNTBADGES} className="item">
          <div className="bg"></div>
          <div className="text">{lang.t('nav:account.achievements')}</div>
        </Link>
      )
    }
  }


  renderMenuHome() {
    if(this.state.submenu === 'home') {
      return (
        <div className="menu">
          <h4>{lang.t('nav:main.home')}</h4>
          <HeaderOverview type='nav'/>
        </div>
      )
    }
  }


  renderMenuClients() {
    if(this.state.submenu === 'clients') {
      return (
        <div className="menu menu-1">
          <h4>{lang.t('nav:main.clients')}</h4>
          <HeaderClients type='nav'/>
        </div>
      )
    }
  }


  renderMenuCalendar() {
    if(this.state.submenu === 'calendar') {
      return (
        <div className="menu menu-2">
          <h4>{lang.t('nav:main.calendar')}</h4>
            <HeaderCalendar type='nav'/>
        </div>
      )
    }
  }


  renderMenuProgramming() {
    if(this.state.submenu === 'programming') {
      return (
        <div className="menu menu-3">
          <h4>{lang.t('nav:main.programming')}</h4>
            <HeaderProgramming type='nav'/>
        </div>
      )
    }
  }


  renderMenuHealth() {
    if(this.state.submenu === 'health') {
      return (
        <div className="menu menu-4">
          <h4>{lang.t('nav:main.health')}</h4>
            <HeaderHealth type='nav'/>
        </div>
      )
    }
  }


  renderMenuBilling() {
    if(this.state.submenu === 'billing') {
      return (
        <div className="menu menu-5">
          <h4>{lang.t('nav:main.billing')}</h4>
            <HeaderBilling type='nav'/>
        </div>
      )
    }
  }


  renderMenuAdmin() {
    if(this.state.submenu === 'admin') {
      return (
        <div className="menu menu-6">
          <h4>{lang.t('nav:main.admin')}</h4>
            <HeaderAdmin type='nav'/>
        </div>
      )
    }
  }


  renderMenuAccount() {
    if(this.state.submenu === 'account') {
      return (
        <div className="menu account">
          <h4>{global.userName}</h4>
          <Link to={ROUTES.ACCOUNT} className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:account.details')}</div>
          </Link>
          {this.renderMenuSub()}
          {this.renderMenuBadges()}
          {this.renderMenuAffiliate()}
          <a href="https://help.ptmate.net" target="_blank" rel="noopener noreferrer" className="item">
            <div className="bg"></div>
            <div className="text">{lang.t('nav:account.help')}</div>
          </a>
          <div className="item" onClick={() => this.logoutUser()}>
            <div className="bg"></div>
            <div className="text">{lang.t('nav:account.logout')}</div>
          </div>
        </div>
      )
    }
  }


  renderProgramming() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return <Link to={ROUTES.PROGRAMMING} className={this.state.active === 'programming' ? 'nav-item programming mb-10 active' : 'nav-item programming mb-10'} onMouseEnter={() => this.setState({submenu: 'programming'})}></Link>
    }
  }


  renderHealth() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return <Link to={ROUTES.NUTRITION} className={this.state.active === 'health' ? 'nav-item health mb-10 active' : 'nav-item health mb-10'} onMouseEnter={() => this.setState({submenu: 'health'})}></Link>
    }
  }


  renderBilling() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return <Link to={ROUTES.BILLING} className={this.state.active === 'billing' ? 'nav-item billing mb-10 active' : 'nav-item billing mb-10'} onMouseEnter={() => this.setState({submenu: 'billing'})}></Link>
    }
  }


  renderAdmin() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      return <Link to={ROUTES.ADMINASSISTANT} className={this.state.active === 'admin' ? 'nav-item admin mb-10 active' : 'nav-item admin mb-10'} onMouseEnter={() => this.setState({submenu: 'admin'})}></Link>
    }
  }


  renderBusinessInfo() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className="business">
          <h3><Link to={ROUTES.ADMINSETTINGS}>{global.userBusiness}</Link></h3>
          <Link className='sublink' to={ROUTES.SUBSCRIPTION}>{this.getSubscription()}</Link>
        </div>
      )
    } else {
      return (
        <div className="business">
          <h3>{global.userBusiness}</h3>
          <Link className='sublink' to={ROUTES.ACCOUNT}>{this.getRole()}</Link>
        </div>
      )
    }
  }


  renderLogo() {
    if(global.spaceImage !== '' && global.spaceImage !== global.userImage) {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <Link to={ROUTES.ADMINSETTINGS} className="logo" style={{backgroundImage: 'url('+global.spaceImage+')'}} onMouseEnter={() => {this.showSubmenu(); this.setState({submenu: ''})}}></Link>
        )
      } else {
        return (
          <div className="logo" style={{backgroundImage: 'url('+global.spaceImage+')'}}></div>
        )
      }
      
    } else {
      if(HelperBusiness.getRoleIncl('')) {
        return (
          <Link to={ROUTES.ADMINSETTINGS} className="logo" onMouseEnter={() => {this.showSubmenu(); this.setState({submenu: ''})}}>
            <img src="/img/ptmate.svg" alt="PT Mate"/>
          </Link>
        )
      } else {
        return (
          <div className="logo" onMouseEnter={() => {this.showSubmenu(); this.setState({submenu: ''})}}>
            <img src="/img/ptmate.svg" alt="PT Mate"/>
          </div>
        )
      }
    }
  }


  render() {
    return (
      <div onMouseLeave={() => this.hideSubmenu()}>
        {this.renderUpgrade()}

        <div className={this.state.class2}></div>

        <div className={this.state.class}>
          {this.renderBusinessInfo()}
          <div className="menus">
            {this.renderMenuHome()}
            {this.renderMenuClients()}
            {this.renderMenuCalendar()}
            {this.renderMenuProgramming()}
            {this.renderMenuHealth()}
            {this.renderMenuBilling()}
            {this.renderMenuAdmin()}
            {this.renderMenuAccount()}
          </div>
        </div>

        <div className="nav">
          {this.renderLogo()}
          <div className="nav-items" onMouseEnter={() => this.showSubmenu()}>
            <Link to={ROUTES.OVERVIEW} className={this.state.active === 'overview' ? 'nav-item home mb-10 active' : 'nav-item home mb-10'} onMouseEnter={() => this.setState({submenu: 'home'})}></Link>
            <Link to={ROUTES.CLIENTS} className={this.state.active === 'clients' ? 'nav-item clients mb-10 active' : 'nav-item clients mb-10'} onMouseEnter={() => this.setState({submenu: 'clients'})}></Link>
            <Link to={ROUTES.CALENDAR} className={this.state.active === 'calendar' ? 'nav-item calendar mb-10 active' : 'nav-item calendar mb-10'} onMouseEnter={() => this.setState({submenu: 'calendar'})}></Link>
            {this.renderProgramming()}
            {this.renderHealth()}
            {this.renderBilling()}
            {this.renderAdmin()}
          </div>

          <Link to={ROUTES.ACCOUNT} onMouseEnter={() => {this.showSubmenu(); this.setState({submenu: 'account'})}}>
            {this.getImage()}
          </Link>
        </div>
        <Chat/>
        <Location/>
        <Badges/>
        <Message/>
      </div>
    )
  }
}


export default  withTranslation(['nav','common'])(withFirebase(Navigation));