import React, { Component } from 'react';
import Moment from 'moment';
import InputText from '../../components/Form/input-text';
import Textarea from '../../components/Form/textarea';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputFile from '../../components/Form/input-file';
import Select from '../../components/Form/select';
import InputRadio from '../../components/Form/radio';
import { withTranslation } from "react-i18next";
import lang from 'i18next';


const DataForm = [
  {
    id: 'referral',
    title: 'modals:form.data1.title',
    text: "modals:form.data1.text",
    input1: 'text',
    label1: 'modals:form.data1.label1',
    button: 'modals:form.data1.button',
  },
  {
    id: 'editnote',
    title: 'modals:form.data2.title',
    text: "",
    input1: 'text',
    label1: 'modals:form.data2.label1',
    input2: 'textarea',
    label2: 'modals:form.data2.label2',
    button: 'modals:form.data2.button',
    secondary: 'modals:form.data2.secondary'
  },
  {
    id: 'leadspage',
    title: 'modals:form.data3.title',
    text: "modals:form.data3.text",
    input1: 'textarea',
    label1: 'modals:form.data3.label1',
    button: 'modals:form.data3.button',
  },
  {
    id: 'pausedebit',
    title: 'modals:form.data4.title',
    text: "modals:form.data4.text",
    input1: 'date',
    label1: 'modals:form.data4.label1',
    button: 'modals:form.data4.button',
  },
  {
    id: 'assignfamily',
    title: 'modals:form.data5.title',
    text: "modals:form.data5.text",
    input1: 'select',
    label1: '',
    button: 'modals:form.data5.button',
  },
  {
    id: 'newcategory',
    title: 'modals:form.data6.title',
    text: '',
    input1: 'text',
    label1: 'modals:form.data6.label1',
    button: 'modals:form.data6.button',
  },
  {
    id: 'prevsessions',
    title: 'modals:form.data7.title',
    text: 'modals:form.data7.text',
    input1: 'number',
    label1: 'modals:form.data7.label1',
    input2: 'number',
    label2: 'modals:form.data7.label2',
    button: 'modals:form.data7.button',
  },
  {
    id: 'habitday',
    title: '$vartitle',
    text: '$var',
    input1: 'radio',
    label1: 'modals:form.data8.label1',
    input2: 'textarea',
    label2: 'modals:form.data8.label2',
    button: 'modals:form.data8.button',
  },
  {
    id: 'createpost',
    title: 'modals:form.data9.title',
    text: '',
    input1: 'textarea',
    label1: 'modals:form.data9.label1',
    input2: 'file',
    label2: 'modals:form.data9.label2',
    button: 'modals:form.data9.button',
  },
  {
    id: 'editpost',
    title: 'modals:form.data10.title',
    text: '',
    input1: 'textarea',
    label1: 'modals:form.data10.label1',
    input2: 'file',
    label2: 'modals:form.data10.label2',
    button: 'modals:form.data10.button',
  },
  {
    id: 'createreply',
    title: 'modals:form.data11.title',
    text: '',
    input1: 'textarea',
    label1: '',
    button: 'modals:form.data11.button',
  },
  {
    id: 'editreply',
    title: 'modals:form.data12.title',
    text: '',
    input1: 'textarea',
    label1: '',
    button: 'modals:form.data12.button',
  },
  {
    id: 'blocknotes',
    title: 'modals:form.data13.title',
    text: '',
    input1: 'textarea',
    label1: 'modals:form.data13.label1',
    button: 'modals:form.data13.button',
  },
  {
    id: 'exnotes',
    title: 'modals:form.data14.title',
    text: '',
    input1: 'textarea',
    label1: 'modals:form.data14.label1',
    button: 'modals:form.data14.button',
  },
  {
    id: 'testemail',
    title: 'modals:form.data15.title',
    text: '',
    input1: 'text',
    label1: 'modals:form.data15.label1',
    button: 'modals:form.data15.button',
  },
]

class ModalForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.type,
      show: props.show,
      hidden: 'hidden',
      value1: props.value1 ?? '',
      values1: props.values1 ?? [],
      value2: props.value2 ?? '',
      values2: props.values2 ?? [],
      var: props.var ?? '',
      vartitle: props.vartitle ?? '',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      id: props.type,
      show: props.show,
      value1: props.value1 ?? '',
      values1: props.values1 ?? [],
      value2: props.value2 ?? '',
      values2: props.values2 ?? [],
      var: props.var ?? '',
      vartitle: props.vartitle ?? '',
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  clickMainButton() {
    this.hide()
    this.props.clickMainButton()
  }


  clickSecondary() {
    this.hide()
    this.props.clickSecondary()
  }


  renderText(text) {
    if(text !== '') {
      return (
        <p className="clear mb-20">{text}</p>
      )
    }
  }


  renderInput2(data) {
    if(data.input2 !== undefined) {
      if(data.input2 === 'text') {
        return <InputText label={lang.t(data.label2)} value={this.state.value2} onChange={(event) => this.props.updateValue2(event)}/>
      } else if(data.input2 === 'number') {
        return <InputNumber label={lang.t(data.label2)} value={this.state.value2} onChange={(event) => this.props.updateValue2(event)}/>
      } else if(data.input2 === 'date') {
        return <InputDate label={lang.t(data.label2)} value={this.state.value2} format='YYYY-MM-DD' onChange={(event) => this.props.updateValue2(Moment(event).format('YYYY-MM-DD'))}/>
      } else if(data.input2 === 'textarea') {
        return <Textarea label={lang.t(data.label2)} value={this.state.value2} onChange={(event) => this.props.updateValue2(event)}/>
      } else if(data.input2 === 'file') {
        return <InputFile label={lang.t(data.label2)} onChange={(event) => this.props.updateValue2(event)}/>
      }
    }
  }


  renderInput1(data) {
    if(data.input1 === 'text') {
      return <InputText label={lang.t(data.label1)} value={this.state.value1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'number') {
      return <InputNumber label={lang.t(data.label1)} value={this.state.value1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'date') {
      return <InputDate label={lang.t(data.label1)} value={this.state.value1} format='YYYY-MM-DD' onChange={(event) => this.props.updateValue1(Moment(event).format('YYYY-MM-DD'))}/>
    } else if(data.input1 === 'select') {
      return <Select label={lang.t(data.label1)} value={this.state.value1} values={this.state.values1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'textarea') {
      return <Textarea label={lang.t(data.label1)} value={this.state.value1} onChange={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'radio') {
      return <InputRadio label={lang.t(data.label1)} value={this.state.value1} values={this.state.values1} clickElement={(event) => this.props.updateValue1(event)}/>
    } else if(data.input1 === 'file') {
      return <InputFile label={lang.t(data.label1)} onChange={(event) => this.props.updateValue1(event)}/>
    }
  }


  renderSecondaryButton(data) {
    if(data.secondary !== undefined) {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.clickSecondary()}>{data.secondary}</button>
        </div>
      )
    }
  }


  render() {
    var data = null
    var text = ''
    for(var item of DataForm) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(this.state.show && data !== null) {
      var title = lang.t(data.title)
      title = title.replace('$vartitle', this.state.vartitle)
      text = lang.t(data.text.replace('$var', this.state.var))
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{title}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            {this.renderText(text)}
            {this.renderInput1(data)}
            {this.renderInput2(data)}
            <div className="sv-10"></div>
            <button className="btn primary" onClick={() => this.clickMainButton()}>{lang.t(data.button)}</button>
            {this.renderSecondaryButton(data)}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalForm);