import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/form';
import ListEmpty from '../../components/List/empty';
import InputClient from '../../components/Form/client';
import InputText from '../../components/Form/input-text';
import InputRadio from '../../components/Form/radio';
import InfoPrice from '../../components/Form/price';
import FormCard from '../../components/Form/card';
import Loader from '../../components/Form/loader';
import WidgetFormInfo from '../../components/Widgets/forminfo';
import WidgetCard from '../../components/Widgets/card';
import ModalClients from '../../components/Modals/clients';
import ModalMessageBasic from '../../components/Modals/message-basic';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      clients: global.clients,
      clientsList: global.clients,
      products: [],
      product: null,
      productId: '',
      client: null,
      group: false,
      type: 'sessions',
      log: global.userLog,
      loading: false,
      card: false,
      valuesCard: ['', '', '', '', ''],
      errorCard: [false, false, false, false, false],
      trial: 'notset',
      search: '',
      back: ROUTES.BILLING,
      preselected: false,
      method: 'card',
      token: '',
      family: '',
      notes: '',
      invoice: '',
      showModalClients: false,
      showModalBasic: false,
      showModalConfirm: false,
      showModalStock: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Process a Payment - PT Mate')
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('invoicesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('paymentsLoaded', (event) => {
      this.configureData()
      if(this.state.loading) {
        global.message = lang.t('messaging:message.paymentsuccessful')
        setTimeout(() => {
          EventEmitter.dispatch('updateBadges', 'payment');
        }, 1000);
        this.props.history.push(this.state.back)
        this.setState({
          loading: false
        })
      }
    })
    EventEmitter.subscribe('logLoaded', (event) => this.configureLog())
    EventEmitter.subscribe('productsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('tokenLoaded', (event) => this.setState({token: global.clientToken}))
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
    this.setState({
      loading: false
    })
  }


  configureData() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      var id = ''
      var pid = ''
      var product = null
      var invoice = ''
      var group = false
      var back = ROUTES.BILLING
      if(arr[arr.length-1] !== 'payment') {
        id = arr[arr.length-2]
        if(arr[arr.length-1] === 'group') {
          group = true
        }
      }
      if(arr[arr.length-1] !== 'pay-invoice') {
        for(var inv of global.invoices) {
          if(inv.id === arr[arr.length-1]) {
            invoice = inv.id
            id = inv.data.client
            pid = inv.data.product
          }
        }
      }

      back = global.currentPage
      if(global.linkCli !== '') {
        back = global.linkCli
      }

      var selected = null
      var preselected = false
      var preselectedProd = false
      if(id !== '') {
        for(var client of global.clients) {
          if(client.id === id) {
            selected = client
            preselected = true
            global.currentClient = client.id
            if(client.data.uid !== '') {
              Connector.getClientToken(client.data.uid)
            }
          }
        }
      }

      // Invoice product
      if(pid !== '') {
        for(var prod of global.products) {
          if(prod.id === pid) {
            product = prod
            preselectedProd = true
          }
        }
      }

      var tmp = []
      var tmp2 = []
      for(var item of global.products) {
        if(item.data.type === this.state.type) {
          tmp.push(item)
        }
      }
      for(var item2 of global.clients) {
        if(item2.data.parent === undefined || item2.data.parent === '') {
          tmp2.push(item2)
        }
      }
      this.setState({
        clients: global.clients,
        clientsList: tmp2,
        products: tmp,
        product: product,
        productId: pid,
        group: group,
        client: selected,
        preselected: preselected,
        preselectedProd: preselectedProd,
        invoice: invoice,
        back: back
      })
    }
  }


  configureLog() {
    if(this._isMounted) {
      this.setState({
        log: global.userLog
      }, () => {
        if(this.state.loading) {
          for(var item of this.state.log) {
            if(item.data.title === 'chargeerror') {
              EventEmitter.dispatch('showMessageError', item.data.message);
              Firebase.database().ref('/log/'+global.uid+'/'+item.id).remove()
              this.setState({
                loading: false,
              })
            }
            if(item.data.title === 'charge' || item.data.type === 'charge') {
              EventEmitter.dispatch('showMessage', "Payment successful");
              this.setState({
                loading: false,
              })
            }
          }
        }
      })
    }
  }



  // Interactive ------------------------------------------------------------



  toggleType(value) {
    this.setState({
      type: value,
    }, () => {
      var tmp = []
      for(var item of global.products) {
        if(item.data.type === value) {
          tmp.push(item)
        }
      }
      this.setState({
        products: tmp,
        product: null,
        productId: '',
      })
    })
  }


  selectProduct(event) {
    if(event.target.value !== '') {
      var tmp = null
      for(var item of this.state.products) {
        if(item.id === event.target.value) {
          tmp = item
        }
      }
      if(this.state.client !== null) {
        var card = true
        if(this.state.client.data.cardId !== '' && this.state.client.data.cardId !== undefined) {
          card = false
        }
        this.setState({
          card: card
        });
      }
      this.setState({
        productId: event.target.value,
        product: tmp,
        group: tmp.data.group
      });
    }
  }


  selectFamily(event) {
    this.setState({
      family: event.target.value
    });
  }


  getFamilyName(id) {
    var label = 'Client'
    for(var item of global.clients) {
      if(item.id === id) {
        label = item.data.name
      }
    }
    return label
  }


  checkMakePayment() {
    this.setState({errorCard: [false, false, false, false, false]})
    var tmp = [false, false, false, false, false]
    var passed = true
    if(this.state.valuesCard[0] === '') {
      tmp[0] = true
      passed = false
    }
    if(this.state.valuesCard[1] === '') {
      tmp[1] = true
      passed = false;
    }
    if(this.state.valuesCard[2] === '') {
      tmp[2] = true
      passed = false;
    }
    if(this.state.valuesCard[3] === '') {
      tmp[3] = true
      passed = false;
    }
    if(this.state.valuesCard[4] === '') {
      tmp[4] = true
      passed = false;
    }
    this.setState({errorCard: tmp})

    var passed2 = false
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      if(this.state.product.data.stock === 0) {
        this.setState({
          showModalStock: true
        })
      } else {
        passed2 = true
        stock = this.state.product.data.stock-1
      }
    } else {
      passed2 = true
    }

    if(passed && passed2) {
      // Execute payment
      this.setState({
        loading: true,
      })
      var fee = this.state.product.data.price*0.5
      var email = this.state.client.data.email
      if(email === '' || email === undefined) {
        email = 'noemail@ptmate.net'
      }

      var product = this.state.product.data.name
      if(product.length > 21) {
        product = this.state.product.data.name.slice(0, 20)
      }

      var idGS = ''
      var paidGS = this.state.product.data.sessions
      var id11 = ''
      var paid11 = this.state.product.data.sessions11
      var sessions11 = this.state.product.data.sessions11
      if(this.state.product.data.stype === '11') {
        paid11 = this.state.product.data.sessions
        sessions11 = this.state.product.data.sessions
      }

      var ppaidGS = 0
      var psessionsGS = 0
      var pidGS = ''
      var ppaid11 = 0
      var psessions11 = 0
      var pid11 = ''
      var expires = 0

      if(this.state.product.data.expires !== undefined) {
        expires = parseInt(Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('X'))
        for(var cred1 of this.state.client.credits) {
          if(cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
            if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
              psessionsGS = cred1.data.sessionsTotal-cred1.data.sessionsPaid
              if(psessionsGS > this.state.product.data.sessions) {
                psessionsGS = this.state.product.data.sessions
              }
              ppaidGS = cred1.data.sessionsPaid+psessionsGS
              pidGS = cred1.id
            }
          }
          if(!cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
            if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
              psessions11 = cred1.data.sessionsTotal-cred1.data.sessionsPaid
              if(psessions11 > sessions11) {
                psessions11 = sessions11
              }
              ppaid11 = cred1.data.sessionsPaid+psessions11
              pid11 = cred1.id
            }
          }
        }
      } else {
        if(this.state.client.credits !== undefined) {
          for(var cred of this.state.client.credits) {
            if(cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group')) {
              if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
                idGS = cred.id
                paidGS = this.state.product.data.sessions+cred.data.sessionsPaid
              }
            }
            if(!cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11')) {
              if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
                id11 = cred.id
                paid11 = this.state.product.data.sessions+cred.data.sessionsPaid
              }
            }
          }
        }
      }
      this.getPaymentFailsafe()

      //var fee = this.state.product.data.price*0.005
      if(this.state.client.data.cardId !== '' && this.state.client.data.cardId !== undefined) {
        var callFunction3 = Firebase.functions().httpsCallable('connectedManageClientCard');
        callFunction3({type: 'delete', account: global.userStripeConnect, customer: this.state.client.data.customer, card: this.state.client.data.cardId}).then(function(result) {});
      }

      if(this.state.client.data.customer !== '' && this.state.client.data.customer !== undefined) {
        var callFunction = Firebase.functions().httpsCallable('connectedPayment');
        callFunction({type: 'card', account: global.userStripeConnect, clientname: this.state.client.data.name, amount: parseInt(this.state.product.data.price*100), fee: fee, pack: product, email: email, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], customer: this.state.client.data.customer, client: this.state.client.id, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: this.state.product.data.stype, sessionsGS: this.state.product.data.sessions, sessions11: sessions11, creditsGS: idGS, credits11: id11, paidGS: paidGS, paid11: paid11, creditseGS: pidGS, creditse11: pid11, sessionseGS: psessionsGS, sessionse11: psessions11, paidnewGS: ppaidGS, paidnew11: ppaid11, expires: expires, ptype: this.state.product.data.type, user: this.state.family, uid: global.uid, invoice: this.state.invoice, product: this.state.product.id, stock: stock}).then(function(result) {});
      } else {
        var callFunction2 = Firebase.functions().httpsCallable('connectedPayment');
        callFunction2({type: 'account', account: global.userStripeConnect, amount: parseInt(this.state.product.data.price*100), fee: fee, pack: product, email: email, name: this.state.valuesCard[0], card: this.state.valuesCard[1], month: this.state.valuesCard[2], year: this.state.valuesCard[3], cvc: this.state.valuesCard[4], clientname: this.state.client.data.name, client: this.state.client.id, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: this.state.product.data.stype, sessionsGS: this.state.product.data.sessions, sessions11: sessions11, creditsGS: idGS, credits11: id11, paidGS: paidGS, paid11: paid11, creditseGS: pidGS, creditse11: pid11, sessionseGS: psessionsGS, sessionse11: psessions11, paidnewGS: ppaidGS, paidnew11: ppaid11, expires: expires, ptype: this.state.product.data.type, user: this.state.family, uid: global.uid, invoice: this.state.invoice, product: this.state.product.id, stock: stock}).then(function(result) {});
      }
    }
  }


  makePayment() {
    var passed = false
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      if(this.state.product.data.stock === 0) {
        this.setState({
          showModalStock: true
        })
      } else {
        passed = true
        stock = this.state.product.data.stock-1
      }
    } else {
      passed = true
    }

    if(passed) {
      global.badgeLocked = true
      this.hideModals()
      this.setState({
        loading: true,
      })
      var product = this.state.product.data.name
      if(product.length > 21) {
        product = this.state.product.data.name.slice(0, 20)
      }
      var email = this.state.client.data.email
      if(email === '' || email === undefined) {
        email = 'noemail@ptmate.net'
      }

      var idGS = ''
      var paidGS = this.state.product.data.sessions
      var id11 = ''
      var paid11 = this.state.product.data.sessions11
      var sessions11 = this.state.product.data.sessions11
      if(this.state.product.data.stype === '11') {
        paid11 = this.state.product.data.sessions
        sessions11 = this.state.product.data.sessions
      }

      var ppaidGS = 0
      var psessionsGS = 0
      var pidGS = ''
      var ppaid11 = 0
      var psessions11 = 0
      var pid11 = ''
      var expires = 0

      if(this.state.product.data.expires !== undefined) {
        expires = parseInt(Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('X'))
        for(var cred1 of this.state.client.credits) {
          if(cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
            if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
              psessionsGS = cred1.data.sessionsTotal-cred1.data.sessionsPaid
              if(psessionsGS > this.state.product.data.sessions) {
                psessionsGS = this.state.product.data.sessions
              }
              ppaidGS = cred1.data.sessionsPaid+psessionsGS
              pidGS = cred1.id
            }
          }
          if(!cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred1.data.sessionsTotal > cred1.data.sessionsPaid) {
            if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
              psessions11 = cred1.data.sessionsTotal-cred1.data.sessionsPaid
              if(psessions11 > sessions11) {
                psessions11 = sessions11
              }
              ppaid11 = cred1.data.sessionsPaid+psessions11
              pid11 = cred1.id
            }
          }
        }
      } else {
        if(this.state.client.credits !== undefined) {
          for(var cred of this.state.client.credits) {
            if(cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group')) {
              if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
                idGS = cred.id
                paidGS = this.state.product.data.sessions+cred.data.sessionsPaid
              }
            }
            if(!cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11')) {
              if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
                id11 = cred.id
                paid11 = this.state.product.data.sessions+cred.data.sessionsPaid
              }
            }
          }
        }
      }
      this.getPaymentFailsafe()

      var fee = this.state.product.data.price*0.5 //includes *100 calculation
      var callFunction = Firebase.functions().httpsCallable('connectedPayment');
      callFunction({type: 'pay', account: global.userStripeConnect, amount: parseInt(this.state.product.data.price*100), fee: fee, pack: product, customer: this.state.client.data.customer, client: this.state.client.id, date: Moment().format('DD/MM/YYYY HH:mm'), currency: global.userCurrency, stype: this.state.product.data.stype, sessionsGS: this.state.product.data.sessions, sessions11: sessions11, email: email, creditsGS: idGS, credits11: id11, paidGS: paidGS, paid11: paid11, creditseGS: pidGS, creditse11: pid11, sessionseGS: psessionsGS, sessionse11: psessions11, paidnewGS: ppaidGS, paidnew11: ppaid11, expires: expires, ptype: this.state.product.data.type, user: this.state.family, uid: global.uid, invoice: this.state.invoice, product: this.state.product.id, stock: stock}).then(function(result) {
        //var sanitizedMessage = result.data.text;
      });
    }
  }


  getPaymentFailsafe() {
    setTimeout(() => {
      if(this.state.client !== null && HelperBusiness.getRoleIncl('')) {
        if(this.state.client.id !== undefined && this.state.client.data.customer !== undefined) {
          var callFunction = Firebase.functions().httpsCallable('chargeBackup');
          callFunction({type: 'payment', account: global.userStripeConnect, customer: this.state.client.data.customer, client: this.state.client.id}).then(function(result) {});
        }
      }
    }, 100000);
  }


  topUpClient() {
    var hasCreditGS = false
    var hasCredit11 = false

    var sessions11 = this.state.product.data.sessions11
    if(this.state.product.data.stype === '11') {
      sessions11 = this.state.product.data.sessions
    }

    if(this.state.product.data.type !== 'other') {
      if(this.state.product.data.expires !== undefined) {
        var totalGS = 0
        var total11 = 0
        var num = 0
        for(var cred1 of this.state.client.credits) {
          if(cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') && cred1.data.sessionsTotal > cred1.data.sessionsPaid && cred1.data.expires === undefined) {
            if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
              totalGS = cred1.data.sessionsTotal-cred1.data.sessionsPaid
              if(totalGS > this.state.product.data.sessions) {
                totalGS = this.state.product.data.sessions
              }
              num = cred1.data.sessionsPaid+totalGS
              Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits/'+cred1.id).update({
                sessionsPaid: num
              })
            }
          }
          if(!cred1.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') && cred1.data.sessionsTotal > cred1.data.sessionsPaid && cred1.data.expires === undefined) {
            if(cred1.data.account === this.state.family || (cred1.data.account === undefined && this.state.family === '')) {
              total11 = cred1.data.sessionsTotal-cred1.data.sessionsPaid
              if(total11 > sessions11) {
                total11 = sessions11
              }
              num = cred1.data.sessionsPaid+total11
              Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits/'+cred1.id).update({
                sessionsPaid: num
              })
            }
          }
        }
        if(this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group') {
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits').push({
            sessionsTotal: totalGS,
            group: true,
            sessionsPaid: this.state.product.data.sessions,
            type: 'sessions',
            client: this.state.client.id,
            account: this.state.family,
            expires: parseInt(Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('X')),
            product: this.state.product.id,
            name: this.state.product.data.name
          })
        }
        if(this.state.product.data.stype === 'both' || this.state.product.data.stype === '11') {
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits').push({
            sessionsTotal: total11,
            group: false,
            sessionsPaid: sessions11,
            type: 'sessions',
            client: this.state.client.id,
            account: this.state.family,
            expires: parseInt(Moment().add(this.state.product.data.expires, this.state.product.data.expType).format('X')),
            product: this.state.product.id,
            name: this.state.product.data.name
          })
        }
        
      } else {
        // Check for existing credits
        for(var cred of this.state.client.credits) {
          if(cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group')) {
            if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
              hasCreditGS = true
              var num1 = cred.data.sessionsPaid+this.state.product.data.sessions
              Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits/'+cred.id).update({
                sessionsPaid: num1
              })
            }
          }
          if(!cred.data.group && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11')) {
            if(cred.data.account === this.state.family || (cred.data.account === undefined && this.state.family === '')) {
              hasCredit11 = true
              var num2 = cred.data.sessionsPaid+sessions11
              Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits/'+cred.id).update({
                sessionsPaid: num2
              })
            }
          }
        }

        // Create new credit entries
        if(!hasCreditGS && (this.state.product.data.stype === 'both' || this.state.product.data.stype === 'group')) {
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits').push({
            sessionsTotal: 0,
            group: true,
            sessionsPaid: this.state.product.data.sessions,
            type: 'sessions',
            client: this.state.client.id,
            account: this.state.family
          })
        }
        if(!hasCredit11 && (this.state.product.data.stype === 'both' || this.state.product.data.stype === '11')) {
          Firebase.database().ref('/clients/'+global.uid+'/'+this.state.client.id+'/credits').push({
            sessionsTotal: 0,
            group: false,
            sessionsPaid: sessions11,
            type: 'sessions',
            client: this.state.client.id,
            account: this.state.family
          })
        }
      }
    }

    global.message = lang.t('messaging:message.paymentsuccessful')
    var link = global.currentPage
    if(global.linkCli !== '') {
      link = global.linkCli
    }
    this.props.history.push(link)
  }


  logCashPayment() {
    var passed = false
    var stock = -1
    if(this.state.product.data.stock !== undefined && this.state.product.data.stock !== -1) {
      if(this.state.product.data.stock === 0) {
        this.setState({
          showModalStock: true
        })
      } else {
        passed = true
        stock = this.state.product.data.stock-1
      }
    } else {
      passed = true
    }

    if(passed) {
      global.badgeLocked = true
      var obj = Firebase.database().ref('/payments/'+global.uid).push()
      obj.set({
        amount: this.state.product.data.price*100,
        client: this.state.client.id,
        fee: 0,
        id: obj.key,
        date: Moment().format('DD/MM/YYYY HH:mm'),
        last4: '',
        name: this.state.product.data.name,
        receipt: '',
        refunds: '',
        type: 'Cash',
        notes: this.state.notes,
        uid: global.uidUser
      })

      if(stock !== -1) {
        Firebase.database().ref('/packs/'+global.uid+'/'+this.state.product.id).update({
          stock: stock
        })
      }

      if(global.showHabits) {
        var arr = []
        var address = ''
        if(global.spaceAddress !== undefined) {
          arr = global.spaceAddress.split('||')
          if(arr.length > 3) {
            address = arr[0]+'\n'+arr[1]+'\n'+arr[2]+', '+arr[3]
          }
        }
        if(global.spaceInvoice1 !== undefined && global.spaceInvoice1 !== '') {
          address = global.spaceInvoice1
        }
        var gst1 = ''
        var gst2 = ''
        if(global.spaceGST !== 0) {
          var mul = this.state.product.data.price/11
          var num = (mul/10)*global.spaceGST
          gst1 = lang.t('messaging:label.gstincluded')
          gst2 = global.curSym+num.toFixed(2)
        }
        var callFunction = Firebase.functions().httpsCallable('sendReceipt');
        callFunction({name: global.userBusiness, email: this.state.client.data.email, address: address, number: obj.key, date: Moment().format('D MMM YYYY - HH:mm'), businessemail: global.spaceEmail, phone: global.spacePhone, product: this.state.product.data.name, price: global.curSym+(this.state.product.data.price.toFixed(2)), method: 'Other payment method', gst1: gst1, gst2: gst2}).then(function(result) {});
      }
      if(this.state.preselectedProd && this.state.invoice !== '') {
        Firebase.database().ref('/invoices/'+global.uid+'/'+this.state.invoice).update({
          status: 'paid'
        })
      }
      
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'payment');
      }, 1000);
      this.topUpClient()
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalClients: false,
      showModalBasic: false,
      showModalConfirm: false,
      showModalStock: false,
    })
  }


  modalClick(item) {
    this.hideModals()
    var card = false
    if(item.data.cardId === '' || item.data.cardId === undefined) {
      card = true
    }
    this.setState({
      client: item,
      card: card
    })
    if(item.data.uid !== '') {
      Connector.getClientToken(item.data.uid)
    }
  }



  // Display stuff ------------------------------------------------------------



  renderFamily() {
    if(this.state.client !== null && this.state.product !== null) {
      if(this.state.client.data.linked !== undefined && this.state.product.data.type === 'sessions') {
        return (
          <div className="mb-20">
            <label>{lang.t('billing:form.familymember')}</label>
            <select onChange={event => this.selectFamily(event)} value={this.state.family}>
              <option value=''>{lang.t('billing:label.family')}</option>
              <option value={this.state.client.id} key={this.state.client.id}>{this.state.client.data.name}</option>
              {this.state.client.data.linked.map(item => (
                <option value={item} key={item}>{this.getFamilyName(item)}</option>
              ))}
            </select>
          </div>
        )
      }
    }
  }


  renderBottomButton() {
    if(global.userStripeConnect === undefined || global.userStripeConnect === '') {
      return (
        <div>
          <div className="sv-20"></div>
          <div className="infobox yellow">
            <h5>{lang.t('billing:enable.title')}</h5>
            <p className="mb-20">{lang.t('billing:enable.text')}</p>
            <Link to={ROUTES.ACCOUNTBILLING} className="btn tertiary width-12 small" style={{textDecoration: 'none'}}>{lang.t('billing:enable.button')}</Link>
          </div>
        </div>
      )
    } else {
      return (
        this.renderBottom()
      )
    }
  }


  renderBottom() {
    if(this.state.productId !== '' && this.state.client !== null) {
      if(this.state.card) {
        return (
          <div className="mt-20">
              <FormCard values={this.state.valuesCard} error={this.state.errorCard} onChange={(event) => this.setState({valuesCard: event})}/>
              <div className="sv-40 clear"></div>
              <button className="btn primary mb-10" onClick={() => this.checkMakePayment()}>{lang.t('billing:button.paywithcard')}</button>
              <p className="small center secondary">{lang.t('billing:form.cardsaved')}<br/>{lang.t('billing:form.paymentfee')}: {global.stripeFee}</p>
          </div>
        )
      } else {
        return (
          <div>
            <div className="paymethod mt-10 mb-30">
              <WidgetCard id={this.state.client.id}/>
              <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('billing:form.info.existingcard')}</p>
            </div>
            <button className="btn tertiary add width-12" onClick={() => this.setState({card: true})}>{lang.t('billing:button.useanothercard')}</button>
            <div className="sv-50"></div>
            <button className="btn primary mb-10" onClick={() => this.setState({showModalConfirm: true})}>{lang.t('billing:button.paywithcard')}</button>
            <p className="small center secondary">Payment fee: {global.stripeFee}</p>
          </div>
        )
      }
    }
  }


  renderSelect() {
    if(this.state.method === 'card') {
      return (
        <div>
          <div className="space-40"></div>
          <InputRadio
            value={this.state.method}
            clickElement={(event) => this.setState({method: event})}
            values={[{name: lang.t('billing:form.cardpayment'), value: 'card'}, {name: lang.t('billing:form.othermethod'), value: 'cash'}]}
          />
          {this.renderBottomButton()}
        </div>
      )
    } else {
      return (
        <div>
          <div className="space-40"></div>
          <InputRadio
            value={this.state.method}
            clickElement={(event) => this.setState({method: event})}
            values={[{name: lang.t('billing:form.cardpayment'), value: 'card'}, {name: lang.t('billing:form.othermethod'), value: 'cash'}]}
          />
          <div className="paymethod mt-10 mb-10">
            <p>{lang.t('billing:form.info.logpayment')}</p>
          </div>
          <div className="sv-20"></div>
          <InputText label={lang.t('billing:form.notes')} value={this.state.notes} onChange={(event) => this.setState({notes: event})}/>
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.logCashPayment()}>{lang.t('billing:button.logpayment')}</button>
        </div>
      )
    }
  }


  renderBottomCheck() {
    if(this.state.productId !== '' && this.state.client !== null) {
      if(this.state.product.data.price === 0) {
        return (
          <div>
            <div className="paymethod mt-10">
              <p>C{lang.t('billing:form.info.topup1')} {global.curSym}0. {lang.t('billing:form.info.topup2')}</p>
            </div>
            <div className="sv-50"></div>
            <button className="btn primary mb-10" onClick={() => this.topUpClient()}>{lang.t('billing:button.topupclient')}</button>
          </div>
        )
      } else {
        return (
          this.renderSelect()
        )
      }
    }
  }


  renderProductGroup(item) {
    if(this.state.type === 'sessions' && item.data.stype === '11') {
      return lang.t('billing:label.form11')
    } else if(this.state.type === 'sessions' && item.data.stype === 'group') {
      return lang.t('billing:label.formgroup')
    } else if(this.state.type === 'sessions' && item.data.stype === 'both') {
      return lang.t('billing:label.formgroup11')
    }
  }


  renderDesc() {
    var show = false
    if(this.state.productId !== '') {
      for(var item of global.products) {
        if(item.id === this.state.productId && item.data.desc !== undefined && item.data.desc !== '') {
          show = true
        }
      }
    }
    if(show) {
      return (
        <button className="btn tertiary small rgt" style={{fontSize:'11px'}} onClick={() => this.setState({showModalBasic: true})}>{lang.t('common:button.viewdetails')}</button>
      )
    }
  }


  renderProductSelection() {
    if(this.state.preselectedProd) {
      return (
        <div className="info">
          <label style={{float:'left'}}>{lang.t('billing:form.product')}</label>
          <p className="clear">{this.state.product.data.name}</p>
        </div>
      )
    } else {
      return (
        <div className="mb-20">
          <InputRadio
            label={lang.t('billing:form.producttype')}
            value={this.state.type}
            clickElement={(event) => this.toggleType(event)}
            values={[{name: lang.t('billing:form.packofsessions'), value: 'sessions'}, {name: lang.t('billing:form.other'), value: 'other'}]}
          />
          <label style={{float:'left'}}>Product</label>{this.renderDesc()}
          <select onChange={event => this.selectProduct(event)} value={this.state.productId}>
            <option value={''}>- Please select -</option>
            {this.state.products.map(item => (
              <option value={item.id} key={item.id}>{item.data.name} {this.renderProductGroup(item)}</option>
            ))}
          </select>
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content form-sidebar">
            <div className="col-6 mt-20">
              <div className="box">
                <InputClient id='payment' client={this.state.client} clickElement={() => this.setState({showModalClients: true})}/>
                <div className="sv-20"></div>
                {this.renderProductSelection()}
                {this.renderFamily()}
                <div className="sv-10"></div>
                <InfoPrice id={this.state.productId} product={this.state.product} type={this.state.client === null ? 'client' : 'payment'}/>
                <div className="sv-10"></div>
                {this.renderBottomCheck()}
              </div>
            </div>
            <div className="col-6 mt-20">
              <div className="sidebar">
                <WidgetFormInfo id={this.state.preselectedProd ? 'payinvoice' : 'newpayment'}/>
              </div>
            </div>
            <div className="clear sv-20"></div>
          </div>
          <Header title={lang.t('billing:title.newpayment')} link={this.state.back}/>
          <Navigation active='billing' />
          <ModalClients clients={this.state.clientsList} show={this.state.showModalClients} onHide={() => this.hideModals()} clickElement={(event) => this.modalClick(event)}/>
          <ModalMessageBasic show={this.state.showModalBasic} onHide={() => this.hideModals()} text={this.state.product === null ? '' : this.state.product.data.desc}/>
          <ModalMessageSimple type='confirmpay' show={this.state.showModalConfirm} var={this.state.client === null ? '' : this.state.client.data.name} onHide={() => this.hideModals()} clickMainButton={() => this.makePayment()}/>
          <ModalMessageSimple type='nostock' show={this.state.showModalStock} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
          <Loader show={this.state.loading}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='payment-locked' type='simple'/>
          </div>
          <Header title={lang.t('billing:title.newpayment')} link={this.state.back}/>
          <Navigation active='billing' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['billing','common','header','messaging'])(withRouter(withAuthorization(condition)(PaymentPage)));