import React, { Component } from 'react';



class Select extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      values: props.values,
      value: props.value,
      info: props.info ?? '',
      error: props.error ?? false,
      errormsg: props.errormsg ?? ''
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      values: props.values,
      value: props.value,
      info: props.info ?? '',
      error: props.error ?? false,
      errormsg: props.errormsg ?? ''
    }
  }


  onChange = event => {
    this.props.onChange(event.target.value)
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">{this.state.errormsg}</p>
      )
    }
  }


  renderInfo() {
    if(this.state.info !== '') {
      return (
        <p className="info">{this.state.info}</p>
      )
    }
  }


  render() {
    return (
      <div className="form-item">
        <label>{this.state.label}</label>
        <select value={this.state.value} className={this.state.error ? 'error': ''} onChange={this.onChange}>
          {this.state.values.map(item => (
            <option value={item.value} key={item.value}>{item.name}</option>
          ))}
        </select>
        {this.renderError()}
        {this.renderInfo()}
      </div>
    )
  }
}


export default Select;