import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalItems from '../../components/Modals/items';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsPlansPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      plans: global.clientPlans,
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      show: 'all',
      programs: global.programs,
      programsClient: [],
      margin: 0,
      del: '',
      showModalDelete: false,
      showModalDeleteAll: false,
      showModalPrograms: false,
      showModalPlans: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Training - Client Details - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.getClient()
      if(this.state.id === '') {
        this.getEmpty()
      }
    })
    this.getEmpty()
    
    EventEmitter.subscribe('programsLoaded', (event) => {
      this.setState({
        programs: global.programs
      })
    })
    EventEmitter.subscribe('clientProgramsLoaded', (event) => {
      this.setState({
        programsClient: global.programsClient
      })
    })
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.getClient()
    }
  }


  updateTraining() {
    this.setState({
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
    })
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
  }


  getClient() {
    if(this._isMounted) {
      var id = ''
      var item = null
      var name = ''
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          id = client.id
          item = client
          name = client.data.name
        }
      }

      if(item !== null) {
        this.setState({
          id: id,
          item: item,
          name: name,
        }, () => {
          if(this.state.item.data.uid === '') {
            global.clientPlans = []
            this.setState({
              plans: []
            })
          }
          if(global.plansUid !== this.state.item.data.uid && this.state.item.data.uid !== '') {
            global.plansUid = this.state.item.data.uid
            Connector.loadPlansClient((result) => {
              this.setState({
                plans: global.clientPlans
              })
            })
          }
          this.configureData()
          if(this.state.item.data.uid !== '') {
            Connector.loadProgramsClient(this.state.item.data.uid)
          }
          global.linkCli = '/clients/'+id+'/plans';
        });
      }
    }
  }


  configureData() {  
    if(this._isMounted) {  
      this.setState({
        sessionsTraining: global.sessionsTraining,
        archiveTraining: global.archiveTraining
      })
    }
  }


  calculateCompliance(item) {
    var done = 0
    var total = 0
    var color = ' green'
    var ids = []
    var pids = []

    var start = Moment(item.data.date, 'DD/MM/YYYY HH:mm').startOf('day')
    for(var i=0; i<item.weeks.length; i++) {
      var d1 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i, 'days')
      var d2 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+1, 'days')
      var d3 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+2, 'days')
      var d4 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+3, 'days')
      var d5 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+4, 'days')
      var d6 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+5, 'days')
      var d7 = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(7*i+6, 'days')

      var ar1 = item.weeks[i].data.day1.split(',')
      var ar2 = item.weeks[i].data.day2.split(',')
      var ar3 = item.weeks[i].data.day3.split(',')
      var ar4 = item.weeks[i].data.day4.split(',')
      var ar5 = item.weeks[i].data.day5.split(',')
      var ar6 = item.weeks[i].data.day6.split(',')
      var ar7 = item.weeks[i].data.day7.split(',')
      ar1.splice(0, 1)
      ar2.splice(0, 1)
      ar3.splice(0, 1)
      ar4.splice(0, 1)
      ar5.splice(0, 1)
      ar6.splice(0, 1)
      ar7.splice(0, 1)

      if(d1 < Moment()) {
        total += ar1.length
      }
      if(d2 < Moment()) {
        total += ar2.length
      }
      if(d3 < Moment()) {
        total += ar3.length
      }
      if(d4 < Moment()) {
        total += ar4.length
      }
      if(d5 < Moment()) {
        total += ar5.length
      }
      if(d6 < Moment()) {
        total += ar6.length
      }
      if(d7 < Moment()) {
        total += ar7.length
      }

      var art = []
      for(var a1 of ar1) { art.push(a1) }
      for(var a2 of ar2) { art.push(a2) }
      for(var a3 of ar3) { art.push(a3) }
      for(var a4 of ar4) { art.push(a4) }
      for(var a5 of ar5) { art.push(a5) }
      for(var a6 of ar6) { art.push(a6) }
      for(var a7 of ar7) { art.push(a7) }

      for(var session of this.state.sessionsTraining) {
        
        var date = Moment(session.data.date, 'DD/MM/YYYY HH:mm')
        if(date > start && art.indexOf(session.program[0].id) !== -1 && date < Moment().add(1, 'day')) {
          if(session.data.attendance === 3 && ids.indexOf(session.id) === -1 && pids.indexOf(session.program[0].id) === -1 && session.data.uid === this.state.item.data.uid) {
            done++
            ids.push(session.id)
            pids.push(session.program[0].id)
          }
        }
      }
    }

    var val = parseInt((done/total)*100)
    if(val < 85 && val > 50) {
      color = ' yellow'
    } else if(val < 51 && val > 25) {
      color = ' yellow'
    } else if(val < 26) {
      color = ' red'
    }
    return [val, color]
  }



  // Modals ------------------------------------------------------------



  showModalDelete(id) {
    if(id === '') {
      this.setState({
        showModalDeleteAll: true,
        del: id,
      })
    } else {
      this.setState({
        showModalDelete: true,
        del: id,
      })
    }
  }


  hideModals() {
    this.setState({
      showModalDelete: false,
      showModalDeleteAll: false,
      showModalPrograms: false,
      showModalPlans: false,
    })
  }


  sendProgram(item) {
    Firebase.database().ref('/workouts/'+this.state.item.data.uid+'/'+item.id).update(
      item.data
    ).then((data)=>{
      //
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    for(var block of item.blocks) {
      Firebase.database().ref('/workouts/'+this.state.item.data.uid+'/'+item.id+'/blocks/'+block.id).push(
        block.data
      )
    }
    if(this.state.item.data.pushToken !== undefined && this.state.item.data.pushToken !== '') {
      Connector.sendPushNotification(this.state.item.data.pushToken,lang.t('messaging:push.program.title'), global.userName+lang.t('messaging:push.program.text'), 'program', item.id)
    }
    this.hideModals()
    EventEmitter.dispatch('showMessage', lang.t('messaging:message.programsent'));
  }


  sendPlan(item) {
    var obj = Firebase.database().ref('/plans/'+this.state.item.data.uid).push()
    this.updateClientData(item, obj.key)
    obj.update({
      name: item.data.name,
      description: item.data.description,
      video: item.data.video,
      sessions: item.data.sessions,
      uid: global.uidUser
    }).then((data)=>{
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.plansenttoclients'))
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    for(var week of item.weeks) {
      Firebase.database().ref('/plans/'+this.state.item.data.uid+'/'+obj.key+'/weeks/'+week.id).update(
        week.data
      )
    }
    for(var prog of item.programs) {
      Firebase.database().ref('/plans/'+this.state.item.data.uid+'/'+obj.key+'/workouts/'+prog.id).update(
        prog.data
      )
    }
    var num = 1
    if(item.data.clients !== null && item.data.clients !== undefined) {
      num = item.data.clients+1
    }
    Firebase.database().ref('/plans/'+global.uid+'/'+item.id).update({
      clients: num
    })
    if(this.state.item.data.pushToken !== undefined && this.state.item.data.pushToken !== '') {
      Connector.sendPushNotification(this.state.item.data.pushToken,lang.t('messaging:push.plan.title'), global.userName+lang.t('messaging:push.plan.text'), 'plan', item.id)
    }
    this.hideModals()
  }


  updateClientData(item, id) {
    var plans = []
    if(this.state.item.data.plans !== undefined && this.state.item.data.plans !== null) {
      plans = this.state.item.data.plans
    }
    plans.push(id)
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({plans: plans})
    var hasCredit = false
    if(global.userChargeSessions) {
      if(this.state.item.credits.length > 0) {
        for(var cred of this.state.item.credits) {
          if(!cred.data.group) {
            hasCredit = true
            var paid = cred.data.sessionsPaid+item.data.sessions
            Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits/'+cred.id).update({sessionsPaid: paid})
          }
        }
      }
      if(!hasCredit && item.data.sessions > 0) {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id+'/credits').push({
          sessionsPaid: item.data.sessions,
          sessionsTotal: 0,
          group: false,
          type: 'sessions'
        })
      }
    }
  }


  deleteProgram() {
    this.hideModals()
    if(this.state.del !== '') {
      Firebase.database().ref('/workouts/'+this.state.item.data.uid+'/'+this.state.del).remove()
      .then((data)=>{
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.programdeleted'));
        var tmp1 = this.state.programsClient
        for(var i=0; i<tmp1.length; i++) {
          if(tmp1[i].id === this.state.del) {
            tmp1.splice(i, 1)
          }
        }
        this.setState({
          programsClient: tmp1
        })
      }).catch((error)=>{
        EventEmitter.dispatch('showMessageError', error.message);
      })
    } else {
      var tmp2 = []
      for(var item of this.state.programsClient) {
        if(item.data.uid === global.uid) {
          Firebase.database().ref('/workouts/'+this.state.item.data.uid+'/'+item.id).remove()
          .then((data)=>{
            //global.message = 'Program successfully deleted'
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        } else {
          tmp2.push(item)
        }
      }
      this.setState({
        programsClient: tmp2
      })
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.programsdeleted'));
    }
  }



  // Display stuff ------------------------------------------------------------



  renderColor(item) {
    var label = ''
    if(item.data.date !== undefined) {
      var add = 7*item.weeks.length
      var end = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(add, 'days')
      label = this.calculateCompliance(item)[1]
    }
    return label
  }


  renderCompliance(item) {
    if(item.data.date === undefined || item.data.date === null) {
      return (
        <div className="icon grey mb-20"><p>-</p></div>
      )
    } else {
      return (
        <div className={'icon mb-20 '+this.renderColor(item)}><p>{this.calculateCompliance(item)[0]}%</p></div>
      )
    }
  }


  renderStatus(item) {
    var label = lang.t('clients:label.notstartedyet')
    if(item.data.date !== undefined) {
      var add = 7*item.weeks.length
      var start = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      var end = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(add, 'days')
      if(end < Moment()) {
        label = lang.t('clients:label.finished')+' '+Moment(end).locale(lang.language).format('D MMM YYYY')
      } else {
        label = lang.t('clients:label.activeweek')+' '+(Moment().diff(start, 'weeks')+1)+')'
      }
    }
    return label
  }


  renderPlans() {
    if(this.state.item !== null) {
      if(this.state.plans === undefined || this.state.plans.length === 0) {
        if(this.state.item.data.uid === '') {
          return (
            <ListEmpty id='noplansclient1' type='simple'/>
          )
        } else {
          return (
            <ListEmpty id='noplansclient2' clickMainButton={() => this.setState({showModalPlans: true})}/>
          )
        }
        
      } else {
        return (
          <div>
            {this.state.plans.map(item => (
              <div className="col-2 col-3-1280" key={item.id}>
                <div className="box card medium highlight mb-20">
                  <Link to={'/clients/'+this.state.id+'/plan/'+item.id}>
                    {this.renderCompliance(item)}
                    <h4 className="mb-10 clear">{item.data.name}</h4>
                    <p className="mb-10">{item.weeks.length} {item.weeks.length === 1 ? lang.t('clients:label.week') : lang.t('clients:label.weeks')}<br/>{this.renderStatus(item)}</p>
                    <div className="sv-30"></div>
                    <button className="btn tertiary small width-12">{lang.t('common:button.viewdetails')}</button>
                    <div className="clear"></div>
                  </Link>
                </div>
              </div>
            ))} 
            <div className="col-2 col-3-1280">
              <button className="inline-add card medium mb-20" onClick={() => this.setState({showModalPlans: true})}></button>
            </div>
          </div>
        )
      }
    }
  }


  renderProgram(item) {
    if(item.data.uid === global.uid) {
      return (
        <div className="box list simple highlight mb-10" key={item.id} onClick={() => this.showModalDelete(item.id)}>
          <div className="col-5 col-7-1280">
            <div className={"icon "+HelperCal.getColor(item.data.time)}>
              <p className="lft">{item.data.time}'</p>
              <div className="clear"></div>
            </div>
            <p className="lft"><strong>{item.data.name}</strong></p>
          </div>
          <div className="col-2">
            <p>{HelperCal.getDuration(item.data.time)}</p>
          </div>
          <div className="col-2 hide-1280">
            <p>{item.blocks.length} {item.blocks.length === 1 ? lang.t('clients:label.block') : lang.t('clients:label.blocks')}</p>
          </div>
          <div className="col-3">
            <button className="btn tertiary small close rgt">{lang.t('common:button.delete')}</button>
          </div>
        </div>
      )
    } else {
      return (
        <div className="box list simple highlight mb-10" key={item.id}>
          <div className="col-5 col-7-1280">
            <div className={"icon "+HelperCal.getColor(item.data.time)}>
              <p>{item.data.time}'</p>
            </div>
            <p className="lft"><strong>{item.data.name}</strong> ({lang.t('clients:label.notsentbyyou')})</p>
            <div className="clear"></div>
          </div>
          <div className="col-2">
            <p>{HelperCal.getDuration(item.data.time)}</p>
          </div>
          <div className="col-5 hide-1280">
            <p>{item.blocks.length} {item.blocks.length === 1 ? lang.t('clients:label.block') : lang.t('clients:label.blocks')}</p>
          </div>
        </div>
      )
    }
  }


  renderProgramsDelete() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <button className="btn tertiary width-12 close" onClick={() => this.showModalDelete('')}>{lang.t('clients:button.deleteallprograms')}</button>
      )
    }
  }


  renderPrograms() {
    if(this.state.item !== null) {
      if(this.state.item.data.uid !== "" && this.state.programsClient.length > 0) {
        var list = this.state.programsClient
        list.sort((a, b) => a.data.name.localeCompare(b.data.name))
        return (
          <div className="col-12">
            {list.map(item => (
              <div key={item.id}>{this.renderProgram(item)}</div>
            ))}
            <button className="inline-add list mb-10" onClick={() => this.setState({showModalPrograms: true})}>{lang.t('clients:button.sendanotherprogram')}</button>
            <div className="sv-20"></div>
            {this.renderProgramsDelete()}
          </div>
        )
      } else {
        if(this.state.item.data.uid === "") {
          return <ListEmpty id='noprogramsyet1' type='simple'/>
        } else {
          return <ListEmpty id='noprogramsyet2' clickMainButton={() => this.setState({showModalPrograms: true})}/>
        }
      }
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            {HelperClients.getFamily(this.state.item)}
            <div className="col-9">
              <WidgetOverview
                item1={{id: 'planssent', value: this.state.plans.length, prev: ''}}
                item2={{id: 'plansavail', value: global.plans.length, prev: ''}}
                item3={{id: 'programssent', value: this.state.programsClient.length, prev: ''}}
                item4={{id: 'programsavail', value: global.programs.length, prev: ''}}
              />
            </div>
            <WidgetActions
              item1={{id: 'sendplan', link: ''}} clickItem1={() => this.setState({showModalPlans: true})}
              item2={{id: 'sendprogram', link: ''}} clickItem2={() => this.setState({showModalPrograms: true})}
              item3={{id: '', link: ''}}
            />
            <div className="listheader clear">
              <ListHeader id='planssent' number={this.state.plans.length}/>
              <div className="clear"></div>
            </div>
            {this.renderPlans()}
            <div className="listheader clear">
              <ListHeader id='programssent' number={this.state.programsClient.length}/>
              <div className="clear"></div>
            </div>
            {this.renderPrograms()}
            <div className="sv-40 clear"></div>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='plans'/>
          <Navigation active='clients' />
          <ModalMessageSimple type='deleteprogram' show={this.state.showModalDelete} onHide={() => this.hideModals()} clickMainButton={() => this.deleteProgram()}/>
          <ModalMessageSimple type='deleteprograms' show={this.state.showModalDeleteAll} onHide={() => this.hideModals()} clickMainButton={() => this.deleteProgram()}/>
          <ModalItems show={this.state.showModalPrograms} type={'programs'} items={global.programs} onHide={() => this.hideModals()} clickElement={(event) => this.sendProgram(event)}/>
          <ModalItems show={this.state.showModalPlans} type={'plans'} items={global.plans} onHide={() => this.hideModals()} clickElement={(event) => this.sendPlan(event)}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='trainingplans-locked' type='simple'/>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='plans'/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsPlansPage)));