import Moment from 'moment';
import Firebase from 'firebase';



class DemoContent {

  createContent() {
    this.createClients()
    this.createAssessments()
    this.createCredits()
    this.createProducts()
    this.createPayments()
    this.createRecurring()
    this.createSessions()
    this.createInvoice()
    this.createHabit()
    this.createForms()
    this.createPrograms()
    this.createActivity()
  }


  // Clients

  createClients() {
    var random = parseInt(Math.random()*global.avatars.length)
    var av = global.avatars[random]
    Firebase.database().ref("clients/"+global.uidUser+"/demo1").update({
      name: "Peetie [Demo]",
      birth: "05/07/1990",
      phone: "0412345678",
      height: 182,
      country: "au",
      pushToken: "",
      image: "",
      imageDate: "01/01/2024 00:00",
      email: "democlient1@ptmate.net",
      goal: "Get fit",
      active: true,
      avatar: av,
      uid: "bsuJMlTNd7eufBoo3qiOHT7N7v92",
      nutritionId: "1256",
      nutritionStart: 1708239600,
      nutritionEnd: 1735628400,
      nutritionStatus: "active",
      ecName: "Peetunia",
      ecPhone: "0412345679",
      ecType: 0,
    });
          
    random = parseInt(Math.random()*global.avatars.length)
    av = global.avatars[random]
    Firebase.database().ref("clients/"+global.uidUser+"/demo2").update({
      name: "Peetunia [Demo]",
      birth: "12/09/1993",
      phone: "0412345679",
      height: 171,
      country: "au",
      pushToken: "",
      image: "",
      imageDate: "01/01/2024 00:00",
      email: "democlient2@ptmate.net",
      goal: "Get fit",
      active: true,
      avatar: av,
      uid: "",
      ecName: "Peetie",
      ecPhone: "0412345678",
      ecType: 0,
    });
  }


  // Health log

  createAssessments() {
    Firebase.database().ref("clients/"+global.uidUser+"/demo1/assessments/log1").update({
      weight: 83.5,
      fat: 23.1,
      heart: 58,
      notes: "",
      image: "",
      image2: "",
      image3: "",
      image4: "",
      neck: 0,
      chest: 0,
      abdomen: 0,
      hip: 0,
      armL: 0,
      armR: 0,
      thighL: 0,
      thighR: 0,
      date: Moment().add(-2, 'days').format('DD/MM/YYYY HH:mm'),
      blood1: "120",
      blood2: "75",
      custom: ""
    });
  }


  // Credits
  
  createCredits() {
    Firebase.database().ref("clients/"+global.uidUser+"/demo1/credits/static1").update({
      account: "",
      group: false,
      sessionsPaid: 5,
      sessionsTotal: 4,
      type: "sessions"
    });
    Firebase.database().ref("clients/"+global.uidUser+"/demo1/credits/static2").update({
      account: "",
      group: true,
      sessionsPaid: 10,
      sessionsTotal: 10,
      type: "sessions"
    });
    Firebase.database().ref("clients/"+global.uidUser+"/demo1/credits/expire1").update({
      account: "",
      expires: parseInt(Moment().add(2, 'days').format('X')),
      group: true,
      sessionsPaid: 5,
      sessionsTotal: 3,
      type: "sessions"
    });
  }


  //Products
  
  createProducts() {
    Firebase.database().ref("packs/"+global.uidUser+"/demo1").update({
      billing: "",
      name: "Pack of 5 1:1 sessions",
      price: 250,
      product: "",
      public: true,
      sessions: 2,
      sessions11: 0,
      sku: "",
      stype: "11",
      type: "sessions",
      uid: global.uidUser
    });
    Firebase.database().ref("packs/"+global.uidUser+"/demo2").update({
      billing: "",
      name: "Pack of 5 group sessions",
      price: 100,
      product: "",
      public: true,
      sessions: 5,
      sessions11: 0,
      sku: "",
      stype: "group",
      type: "sessions",
      uid: global.uidUser
    });
    Firebase.database().ref("packs/"+global.uidUser+"/demo3").update({
      billing: "",
      name: "5 group sessions fortnightly",
      desc: "The sessions of this pack expire after two weeks. Credits won't roll over.",
      expType: "days",
      expires: 14,
      price: 100,
      product: "",
      public: false,
      sessions: 5,
      sessions11: 0,
      sku: "",
      stype: "group",
      type: "sessions",
      uid: global.uidUser
    });
    Firebase.database().ref("packs/"+global.uidUser+"/demo4").update({
      billing: "",
      name: "Boxing gloves",
      price: 79.95,
      product: "",
      public: true,
      sessions: 0,
      sku: "",
      type: "other",
      uid: global.uidUser
    });
  }


  // Payments
  
  createPayments() {
    Firebase.database().ref("payments/"+global.uidUser+"/demo1").update({
      amount: 10000,
      client: "demo1",
      date: Moment().add(-40, 'days').format('DD/MM/YYYY HH:mm'),
      fee: 0,
      id: "demo1",
      last: "",
      name: "Pack of 5 group sessions",
      notes: "",
      receipt: "",
      refunds: "",
      type: "Cash",
      uid: global.uidUser
    });
    Firebase.database().ref("payments/"+global.uidUser+"/demo2").update({
      amount: 10000,
      client: "demo1",
      date: Moment().add(-20, 'days').format('DD/MM/YYYY HH:mm'),
      fee: 0,
      id: "demo1",
      last: "",
      name: "Pack of 5 group sessions",
      notes: "",
      receipt: "",
      refunds: "",
      type: "Cash",
      uid: global.uidUser
    });
    Firebase.database().ref("payments/"+global.uidUser+"/demo3").update({
      amount: 10000,
      client: "demo1",
      date: Moment().add(-11, 'days').format('DD/MM/YYYY HH:mm'),
      fee: 0,
      id: "demo1",
      last: "",
      name: "5 group sessions fortnightly",
      notes: "Topping up because of low credits",
      receipt: "",
      refunds: "",
      type: "Cash",
      uid: global.uidUser
    });
    Firebase.database().ref("payments/"+global.uidUser+"/demo4").update({
      amount: 25000,
      client: "demo1",
      date: Moment().add(-35, 'days').format('DD/MM/YYYY HH:mm'),
      fee: 0,
      id: "demo1",
      last: "",
      name: "Pack of 5 1:1 sessions",
      notes: "Trying out 1:1 sessions",
      receipt: "",
      refunds: "",
      type: "Cash",
      uid: global.uidUser
    });
  }


  // Recurring template
  
  createRecurring() {
    var date = Moment().add(-18, 'days')
    var dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    var fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("recurring/"+global.uidUser+"/demo").update({
      client: "HIIT Morning",
      current: 4,
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      interval: 7,
      link: "",
      locked: 10000,
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: 9999
    });
  }


  // Sessions
  
  createSessions() {
    var date = Moment().add(-18, 'days')
    var dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    var fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo1").update({
      attendance: 3,
      client: "HIIT Morning",
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      templateSeq: 1,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
      clients: ["demo1", "demo2"],
      confirmed: ["demo1", "demo2"],
    });
    date = Moment().add(-11, 'days')
    dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo2").update({
      attendance: 3,
      client: "HIIT Morning",
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      templateSeq: 2,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
      clients: ["demo2"],
      confirmed: ["demo2"],
    });
    date = Moment().add(-4, 'days')
    dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo3").update({
      attendance: 3,
      client: "HIIT Morning",
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      templateSeq: 3,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
      clients: ["demo1"],
      confirmed: ["demo1", "demo2"],
      noshows: ["demo2"]
    });
    date = Moment().add(3, 'days')
    dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo4").update({
      attendance: 2,
      client: "HIIT Morning",
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      clients: ["demo2"],
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      templateSeq: 4,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
      confirmed: [],
    });
    date = Moment().add(10, 'days')
    dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo5").update({
      attendance: 2,
      client: "HIIT Morning",
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      templateSeq: 5,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
      clients: [],
      confirmed: [],
    });
    date = Moment().add(17, 'days')
    dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo6").update({
      attendance: 2,
      client: "HIIT Morning",
      date: dstr,
      desc: "",
      duration: 45,
      group: true,
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      templateSeq: 6,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
      clients: [],
      confirmed: [],
    });
    date = Moment().add(5, 'days')
    dstr = Moment(date).format('DD/MM/YYYY')+" 06:00"
    fd = parseInt(Moment(dstr, 'DD/MM/YYYY HH:mm').format('X'))
    Firebase.database().ref("sessions/"+global.uidUser+"/demo7").update({
      attendance: 3,
      client: "demo1",
      date: dstr,
      desc: "",
      duration: 60,
      group: false,
      link: "",
      locked: 5763053200,
      notes: "",
      max: 0,
      plan: true,
      preview: true,
      timestamp: fd,
      trainerId: global.uidUser,
      trainerName: global.userName,
      uid: global.uidUser,
      unlocked: "01/01/1900 00:00",
    });
  }


  // Invoice
  
  createInvoice() {
    Firebase.database().ref("invoices/"+global.uidUser+"/demo").update({
      client: "demo1",
      date: parseInt(Moment().add(-2, 'days').format('X')),
      due: parseInt(Moment().add(12, 'days').format('X')),
      number: "Demo",
      price: 250,
      product: "demo4",
      status: "open"
    });
  }
  
    
  // Habit
  
  createHabit() {
    Firebase.database().ref("habits/"+global.uidUser+"/demo").update({
      amount: 3,
      client: "demo1",
      interval: 1,
      name: "Drink enough water",
      start: parseInt(Moment().add(-2, 'days').format('X')),
      unit: "l"
    });
  }


  // Forms
  
  createForms() {
    Firebase.database().ref("forms/"+global.uidUser+"/demo1").update({
      name: "Health & Fitness questionnaire",
      pre: true,
      uid: global.uidUser,
      version: 1,
      lock: false,
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section1").update({
      label: "Welcome to our community!! Take a couple of minutes to work through this questionnaire to help me to learn all about you, your training routine and exercise requirements.",
      response: "",
      seq: 0,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section2").update({
      label: "Training History",
      response: "",
      seq: 1,
      type: "header"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section3").update({
      label: "Do you currently have a training routine? Give me a breakdown of your training routine ",
      detail: "",
      seq: 2,
      type: "yesno",
      answer1: true,
      answer2: true
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section4").update({
      label: "From 1 - 10 how committed are you to achieving you health and fitness goals? ",
      response: "",
      num: 10,
      seq: 3,
      type: "rating"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section5").update({
      label: "Pre-Existing Illnesses",
      response: "",
      seq: 4,
      type: "header"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section6").update({
      label: "Have you had or do you currently have any illness. That effect you in any way? ",
      detail: "",
      seq: 5,
      type: "yesno",
      answer1: true,
      answer2: true
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section7").update({
      label: "What would you say your energy is at the beginning of the day? 10% - 100% ",
      response: "",
      num: 10,
      seq: 6,
      type: "rating"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section8").update({
      label: "Let's talk about your food",
      response: "",
      seq: 7,
      type: "header"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section9").update({
      label: "Give me a run down of your nutrition, add your breakfast, lunch, dinner and any snacks.",
      response: "",
      seq: 8,
      type: "text"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section10").update({
      label: "Breakfast (type/time)",
      response: "",
      seq: 9,
      type: "text"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section11").update({
      label: "Lunch (type/time)",
      response: "",
      seq: 10,
      type: "text"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/sections/section12").update({
      label: "Dinner (type/time)",
      response: "",
      seq: 11,
      type: "text"
    });
    
    Firebase.database().ref("forms/"+global.uidUser+"/demo2").update({
      name: "Training Consent Form",
      pre: false,
      uid: global.uidUser,
      version: 1,
      lock: true,
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section1").update({
      label: "I understand that I am entering into a personal training agreement with my Melony at Beachside barbells. ",
      response: "",
      seq: 0,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section2").update({
      label: "My training is intended to enhance my physical and mental wellbeing including body composition, cardiovascular strength and endurance, muscle power and strength, flexibility, and other intended purposes such as;",
      response: "",
      seq: 1,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section3").update({
      label: "The general benefits and risks of training have been explained to me. I understand that my trainer is covered with valid Professional Indemnity, Public Liability and Personal Accident insurance.",
      response: "",
      seq: 2,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section4").update({
      label: "I have informed my trainer of all known physical conditions, medical conditions and medications, and will keep them updated of any changes to my health that may occur in the future.",
      response: "",
      seq: 3,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section5").update({
      label: "You agree that by participating in these physical exercise sessions or personal training activities, you do so entirely at your own risk.",
      response: "",
      seq: 4,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section6").update({
      label: "E. By agreeing to this document you acknowledge that you have carefully read this waiver and release and fully understand that it is a release of liability of any personal injury or property damage or loss action except in the case of proven negligence.",
      detail: "",
      seq: 5,
      type: "yesno",
      answer1: false,
      answer2: true,
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo2/sections/section7").update({
      label: "Date",
      response: "",
      seq: 6,
      type: "text"
    });
    
    Firebase.database().ref("clients/"+global.uidUser+"/demo1/forms/demo2").update({
      name: "Training Consent Form",
      pre: false,
      uid: global.uidUser,
      version: 1,
      lock: true,
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section1").update({
      label: "I understand that I am entering into a personal training agreement with my Melony at Beachside barbells. ",
      response: "",
      seq: 0,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section2").update({
      label: "My training is intended to enhance my physical and mental wellbeing including body composition, cardiovascular strength and endurance, muscle power and strength, flexibility, and other intended purposes such as;",
      response: "",
      seq: 1,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section3").update({
      label: "The general benefits and risks of training have been explained to me. I understand that my trainer is covered with valid Professional Indemnity, Public Liability and Personal Accident insurance.",
      response: "",
      seq: 2,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section4").update({
      label: "I have informed my trainer of all known physical conditions, medical conditions and medications, and will keep them updated of any changes to my health that may occur in the future.",
      response: "",
      seq: 3,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section5").update({
      label: "You agree that by participating in these physical exercise sessions or personal training activities, you do so entirely at your own risk.",
      response: "",
      seq: 4,
      type: "paragraph"
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section6").update({
      label: "E. By agreeing to this document you acknowledge that you have carefully read this waiver and release and fully understand that it is a release of liability of any personal injury or property damage or loss action except in the case of proven negligence.",
      detail: "",
      seq: 5,
      type: "yesno",
      response: "0",
      answer1: false,
      answer2: true,
    });
    Firebase.database().ref("forms/"+global.uidUser+"/demo1/forms/demo2/sections/section7").update({
      label: "Date",
      response: "12 Jun 2024",
      seq: 6,
      type: "text"
    });
  }


  // Programs
  
  createPrograms() {
    Firebase.database().ref("workouts/"+global.uidUser+"/demo").update({
      benchmark: false,
      desc: "",
      exercises: 14,
      name: "Functional Strength Session [Demo]",
      session: "",
      time: 37,
      uid: global.uidUser,
      video: "",
    });
    Firebase.database().ref("workouts/"+global.uidUser+"/demo/blocks/block1").update({
      cat: 3,
      cycles: 0,
      emom: false,
      logResults: false,
      name: "Mobility / Stretch",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 2,
      type: 1,
      exCat: [3, 3, 3, 3],
      exId: ["1909202118250422011", "190920211825042203", "2608202114074580130", "2608202114074580016"],
      exImage: ["adm-myo-quadriceps", "adm-myo-gluteals", "adm-mob-quadstretch", "adm-mob-hamstringstretch"],
      exName: ["Quads (Quadriceps)", "Glutes (Gluteals)", "Standing Quad Stretch", "Seated Hamstring Stretch"],
      exNotes: ["", "", "", ""],
      exReps: [0, 0, 0, 0],
      exRepsRounds: ["0", "0", "0", "0"],
      exResReps: [0, 0, 0, 0],
      exResRepsGroup: ["", "", "", ""],
      exResWeight: [0, 0, 0, 0],
      exResWeightGroup: ["", "", "", ""],
      exRest: [0, 0, 0, 0],
      exTool: [20, 20, 0, 0],
      exType: [2, 2, 2, 2],
      exUnits: ["reps", "reps", "reps", "reps"],
      exWeight: [0, 0, 0, 0],
      exWeightRounds: ["0", "0", "0", "0"],
      exWeightType: ["kg", "kg", "kg", "kg"],
      exWork: [60, 60, 60, 60],
    });
    Firebase.database().ref("workouts/"+global.uidUser+"/demo/blocks/block2").update({
      cat: 4,
      cycles: 0,
      emom: false,
      logResults: false,
      name: "Activation / Warm up",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 1,
      type: 4,
      exCat: [10, 3, 2, 2, 4],
      exId: ["1111202112392039529", "04052022153657971", "1706202109191821821", "050520211359349331", "1811202116524128326"],
      exImage: ["adm-func-run", "adm-lunge-thorrot", "", "adm-altankletaps", "adm-act-latglutewalk"],
      exName: ["Run", "Lunge With Thoracic Rotation", "Wall Squat", "Alternating A Frame Ankle Taps", "Lateral Glute Walks"],
      exNotes: ["", "", "", "", ""],
      exReps: [400, 20, 10, 20, 20],
      exRepsRounds: ["400", "20", "10", "20", "20"],
      exResReps: [0, 0, 0, 0, 0],
      exResRepsGroup: ["", "", "", "", ""],
      exResWeight: [0, 0, 0, 0, 0],
      exResWeightGroup: ["", "", "", "", ""],
      exRest: [0, 0, 0, 0, 0],
      exTool: [6, 0, 0, 0, 16],
      exType: [1, 5, 0, 2, 5],
      exUnits: ["dist", "reps", "reps", "reps", "reps"],
      exWeight: [0, 0, 0, 0, 0],
      exWeightRounds: ["0", "0", "0", "0", "0"],
      exWeightType: ["kg", "kg", "kg", "kg", "kg"],
      exWork: [0, 0, 0, 0, 0],
    });
    Firebase.database().ref("workouts/"+global.uidUser+"/demo/blocks/block3").update({
      cat: 2,
      cycles: 0,
      emom: true,
      logResults: true,
      name: "Strength Compound",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 6,
      type: 1,
      exCat: [2],
      exId: ["-LkHr8o3uwUMaWvrgl9z"],
      exImage: ["adm-dl-traditional"],
      exName: ["Deadlift"],
      exNotes: [""],
      exReps: [2],
      exRepsRounds: ["2"],
      exResReps: [0],
      exResRepsGroup: [""],
      exResWeight: [0],
      exResWeightGroup: [""],
      exRest: [0],
      exTool: [3],
      exType: [0],
      exUnits: ["reps"],
      exWeight: [80],
      exWeightRounds: ["80"],
      exWeightType: ["per"],
      exWork: [120],
    });
    Firebase.database().ref("workouts/"+global.uidUser+"/demo/blocks/block4").update({
      cat: 6,
      cycles: 0,
      emom: false,
      logResults: true,
      name: "Metabolic Conditioning",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 720,
      type: 0,
      exCat: [0, 2, 2, 2],
      exId: ["170620210914598142", "-LkHr8p452B3aiA0gXCL", "111120211228549369", "170620210919182170"],
      exImage: ["", "adm-pushup-pushup", "adm-abs-butterfly", "adm-squat-air"],
      exName: ["Rower", "Push Up", "Sit Up", "Air Squat"],
      exNotes: ["", "", "", ""],
      exReps: [500, 10, 20, 30],
      exRepsRounds: ["500", "10", "20", "30"],
      exResReps: [0, 0, 0, 0],
      exResRepsGroup: ["", "", "", ""],
      exResWeight: [0, 0, 0, 0],
      exResWeightGroup: ["", "", "", ""],
      exRest: [0, 0, 0, 0],
      exTool: [27, 0, 0, 0],
      exType: [2, 2, 5, 0],
      exUnits: ["dist", "reps", "reps", "reps"],
      exWeight: [0, 0, 0, 0],
      exWeightRounds: ["0", "0", "0", "0"],
      exWeightType: ["kg", "kg", "kg", "kg"],
      exWork: [0, 0, 0, 0],
    });
    
    Firebase.database().ref("sessions/"+global.uidUser+"/demo3/workout/demo").update({
      benchmark: false,
      desc: "",
      exercises: 14,
      name: "Functional Strength Session [Demo]",
      session: "",
      time: 37,
      uid: global.uidUser,
      video: "",
    });
    Firebase.database().ref("sessions/"+global.uidUser+"/demo3/workout/demo/blocks/block1").update({
      cat: 3,
      cycles: 0,
      emom: false,
      logResults: false,
      name: "Mobility / Stretch",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 2,
      type: 1,
      exCat: [3, 3, 3, 3],
      exId: ["1909202118250422011", "190920211825042203", "2608202114074580130", "2608202114074580016"],
      exImage: ["adm-myo-quadriceps", "adm-myo-gluteals", "adm-mob-quadstretch", "adm-mob-hamstringstretch"],
      exName: ["Quads (Quadriceps)", "Glutes (Gluteals)", "Standing Quad Stretch", "Seated Hamstring Stretch"],
      exNotes: ["", "", "", ""],
      exReps: [0, 0, 0, 0],
      exRepsRounds: ["0", "0", "0", "0"],
      exResReps: [0, 0, 0, 0],
      exResRepsGroup: ["", "", "", ""],
      exResWeight: [0, 0, 0, 0],
      exResWeightGroup: ["", "", "", ""],
      exRest: [0, 0, 0, 0],
      exTool: [20, 20, 0, 0],
      exType: [2, 2, 2, 2],
      exUnits: ["reps", "reps", "reps", "reps"],
      exWeight: [0, 0, 0, 0],
      exWeightRounds: ["0", "0", "0", "0"],
      exWeightType: ["kg", "kg", "kg", "kg"],
      exWork: [60, 60, 60, 60],
    });
    Firebase.database().ref("sessions/"+global.uidUser+"/demo3/workout/demo/blocks/block2").update({
      cat: 4,
      cycles: 0,
      emom: false,
      logResults: false,
      name: "Activation / Warm up",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 1,
      type: 4,
      exCat: [10, 3, 2, 2, 4],
      exId: ["1111202112392039529", "04052022153657971", "1706202109191821821", "050520211359349331", "1811202116524128326"],
      exImage: ["adm-func-run", "adm-lunge-thorrot", "", "adm-altankletaps", "adm-act-latglutewalk"],
      exName: ["Run", "Lunge With Thoracic Rotation", "Wall Squat", "Alternating A Frame Ankle Taps", "Lateral Glute Walks"],
      exNotes: ["", "", "", "", ""],
      exReps: [400, 20, 10, 20, 20],
      exRepsRounds: ["400", "20", "10", "20", "20"],
      exResReps: [0, 0, 0, 0, 0],
      exResRepsGroup: ["", "", "", "", ""],
      exResWeight: [0, 0, 0, 0, 0],
      exResWeightGroup: ["", "", "", "", ""],
      exRest: [0, 0, 0, 0, 0],
      exTool: [6, 0, 0, 0, 16],
      exType: [1, 5, 0, 2, 5],
      exUnits: ["dist", "reps", "reps", "reps", "reps"],
      exWeight: [0, 0, 0, 0, 0],
      exWeightRounds: ["0", "0", "0", "0", "0"],
      exWeightType: ["kg", "kg", "kg", "kg", "kg"],
      exWork: [0, 0, 0, 0, 0],
    });
    Firebase.database().ref("sessions/"+global.uidUser+"/demo3/workout/demo/blocks/block3").update({
      cat: 2,
      cycles: 0,
      emom: true,
      logResults: true,
      name: "Strength Compound",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 6,
      type: 1,
      exCat: [2],
      exId: ["-LkHr8o3uwUMaWvrgl9z"],
      exImage: ["adm-dl-traditional"],
      exName: ["Deadlift"],
      exNotes: [""],
      exReps: [2],
      exRepsRounds: ["2"],
      exResReps: [0],
      exResRepsGroup: [""],
      exResWeight: [0],
      exResWeightGroup: [""],
      exRest: [0],
      exTool: [3],
      exType: [0],
      exUnits: ["reps"],
      exWeight: [80],
      exWeightRounds: ["80"],
      exWeightType: ["per"],
      exWork: [120],
    });
    Firebase.database().ref("sessions/"+global.uidUser+"/demo3/workout/demo/blocks/block4").update({
      cat: 6,
      cycles: 0,
      emom: false,
      logResults: true,
      name: "Metabolic Conditioning",
      notes: "",
      notesRes: "",
      preset: "",
      results: false,
      rounds: 720,
      type: 0,
      exCat: [0, 2, 2, 2],
      exId: ["170620210914598142", "-LkHr8p452B3aiA0gXCL", "111120211228549369", "170620210919182170"],
      exImage: ["", "adm-pushup-pushup", "adm-abs-butterfly", "adm-squat-air"],
      exName: ["Rower", "Push Up", "Sit Up", "Air Squat"],
      exNotes: ["", "", "", ""],
      exReps: [500, 10, 20, 30],
      exRepsRounds: ["500", "10", "20", "30"],
      exResReps: [0, 0, 0, 0],
      exResRepsGroup: ["", "", "", ""],
      exResWeight: [0, 0, 0, 0],
      exResWeightGroup: ["", "", "", ""],
      exRest: [0, 0, 0, 0],
      exTool: [27, 0, 0, 0],
      exType: [2, 2, 5, 0],
      exUnits: ["dist", "reps", "reps", "reps"],
      exWeight: [0, 0, 0, 0],
      exWeightRounds: ["0", "0", "0", "0"],
      exWeightType: ["kg", "kg", "kg", "kg"],
      exWork: [0, 0, 0, 0],
    });
  }


  // Activity
  
  createActivity() {
    Firebase.database().ref("activity/"+global.uidUser+"/demo1").update({
      data: "demo1,demo4",
      date: Moment().add(-9, 'days').format('DD/MM/YYYY HH:mm'),
      type: "booking",
    });
    Firebase.database().ref("activity/"+global.uidUser+"/demo2").update({
      data: "demo1,demo3",
      date: Moment().add(-2, 'days').format('DD/MM/YYYY HH:mm'),
      type: "booking",
    });
    Firebase.database().ref("activity/"+global.uidUser+"/demo3").update({
      data: "bsuJMlTNd7eufBoo3qiOHT7N7v92,demo3",
      date: Moment().add(-4, 'days').format('DD/MM/YYYY HH:mm'),
      type: "booking",
    });
  }

}


const b = new DemoContent();
export default b;