import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class ModalBilling extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      hidden: 'hidden',
      type: props.type ?? 'info',
      cycle: props.cycle ?? 'week',
    };
    if(props.show) {
      this.show()
    }
  }


  componentWillReceiveProps(props) {
    this.setState({
      show: props.show,
      type: props.type ?? 'info',
      cycle: props.cycle ?? 'week',
    }, () => {
      if(this.state.show) {
        this.show()
      }
    })
  }


  show() {
    this.setState({
      show: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hide() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({show: false});
      this.props.onHide(false);
    }, 500);
  }


  render() {
    if(this.state.show && this.state.type === 'info') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:payoutschedule.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-20">{lang.t('modals:billing.payoutschedule.text1')}</p>
            <img src="/img/payouts.png" alt="Payout schedule" style={{width: '100%', marginBottom: '10px'}}/>
            <p className="mb-40">{lang.t('modals:billing.payoutschedule.text2')}</p>
            <button className="btn primary" onClick={() => this.hide()}>{lang.t('common:button.gotit')}</button>
          </div>
        </div>
      )
    } else if(this.state.show && this.state.type === 'locked') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:billing.noproductsyet.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-30">{lang.t('modals:billing.noproductsyet.text1')}</p>
            <Link to={'/billing/products/new'} className="btn primary">{lang.t('modals:billing.noproductsyet.button')}</Link>
          </div>
        </div>
      )
    } else if(this.state.show && this.state.type === 'locked2') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:billing.noproductsyet.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-30">{lang.t('modals:billing.noproductsyet.text2')}</p>
            <Link to={'/billing/products/new'} className="btn primary">{lang.t('modals:billing.noproductsyet.button')}</Link>
          </div>
        </div>
      )
    } else if(this.state.show && this.state.type === 'debit') {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{lang.t('modals:billing.membership.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hide()}>{lang.t('common:button.close')}</button>
            <p className="clear mb-20">{lang.t('modals:billing.membership.text')}</p>
            <img src={'/img/debits-'+this.state.cycle+'.png'} alt="Payout schedule" style={{width: '100%', marginBottom: '30px'}} className="show-light"/>
            <img src={'/img/debits-'+this.state.cycle+'-dark.png'} alt="Payout schedule" style={{width: '100%', marginBottom: '30px'}} className="show-dark"/>
            <button className="btn primary" onClick={() => this.hide()}>{lang.t('common:button.gotit')}</button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}


export default withTranslation(['modals','common'])(ModalBilling);