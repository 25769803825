import React, { Component } from 'react';
import HelperEmpty from '../../helper/EmptyLarge';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



const DataFormInfo  = [
  {
    id: 'newclient',
    title: 'Add a new client',
    text: "forminfo:newclient.text",
    image: 'newclient'
  },
  {
    id: 'newclient-noemail',
    title: 'Add a new client',
    text: "forminfo:newclientnoemail.text",
    image: 'newclient',
    notes: 'forminfo:newclientnoemail.notes',
    notestext: "forminfo:newclientnoemail.notestext",
  },
  {
    id: 'clientimport',
    title: 'Import your existing client list',
    text: "forminfo:clientimport.text",
    image: 'importclients'
  },
  {
    id: 'newnutrition',
    title: 'New Nutrition Plan',
    text: "forminfo:newnutrition.text",
    image: 'newnutrition'
  },
  {
    id: 'newhabit',
    title: 'Create a new habit',
    text: "forminfo:newhabit.text",
    image: 'newhabit',
    notes: 'forminfo:newhabit.notes',
    notestext: "forminfo:newhabit.notestext",
  },
  {
    id: 'edithabit',
    title: 'Update habit',
    text: "forminfo:edithabit.text",
    image: 'edithabit',
  },
  {
    id: 'newinvoice',
    title: 'Create an invoice',
    text: "forminfo:newinvoice.text",
    image: 'newinvoice',
  },
  {
    id: 'newexpense',
    title: 'Log an expense',
    text: "forminfo:newexpense.text",
    image: 'newexpense',
  },
  {
    id: 'editexpense',
    title: 'Edit expense',
    text: "forminfo:editexpense.text",
    image: 'editexpense',
  },
  {
    id: 'newpayment',
    title: 'Process a payment',
    text: "forminfo:payment.text",
    image: 'newpayment',
  },
  {
    id: 'payinvoice',
    title: 'Pay an invoice',
    text: "forminfo:payinvoice.text",
    image: 'newpayment',
  },
  {
    id: 'directdebit',
    title: 'Set up a membership',
    text: "forminfo:membership.text",
    image: 'newdebit',
  },
  {
    id: 'prodnewsessions',
    title: 'New pack of sessions',
    text: "forminfo:prodnewsessions.text",
    image: 'newproduct',
    notes: 'forminfo:prodnewsessions.notes',
    notestext: "forminfo:prodnewsessions.notestext",
  },
  {
    id: 'prodeditsessions',
    title: 'Edit pack of sessions',
    text: "forminfo:prodeditsessions.text",
    image: 'editproduct',
    notes: 'forminfo:prodeditsessions.notes',
    notestext: "forminfo:prodeditsessions.notestext",
  },
  {
    id: 'prodnewdebit',
    title: 'New membership',
    text: "forminfo:prodnewdebit.text",
    image: 'newproduct',
    notes: 'forminfo:prodnewdebit.notes',
    notestext: "forminfo:prodnewdebit.notestext",
  },
  {
    id: 'prodeditdebit',
    title: 'Edit membership',
    text: "forminfo:prodeditdebit.text",
    image: 'editproduct',
    notes: 'forminfo:prodeditdebit.notes',
    notestext: "forminfo:prodeditdebit.notestext",
  },
  {
    id: 'prodnewother',
    title: 'New other product',
    text: "forminfo:prodnewother.text",
    image: 'newproduct',
  },
  {
    id: 'prodeditother',
    title: 'Edit product',
    text: "forminfo:prodeditother.text",
    image: 'editproduct',
  },
  {
    id: 'addstaff',
    title: 'Add a staff member',
    text: "forminfo:addstaff.text",
    image: 'newclient',
    notes: 'forminfo:addstaff.notes',
    notestext: "forminfo:addstaff.notestext",
  },
  {
    id: 'rec11new',
    title: 'New 1:1 recurring session template',
    text: "forminfo:rec11new.text",
    image: 'newsession',
    notes: 'forminfo:rec11new.notes',
    notestext: "forminfo:rec11new.notestext",
  },
  {
    id: 'rec11edit',
    title: 'Edit 1:1 recurring session template',
    text: "forminfo:rec11edit.text",
    image: 'editsession',
    notes: 'forminfo:rec11edit.notes',
    notestext: "forminfo:rec11edit.notestext",
  },
  {
    id: 'recgroup1',
    title: 'New public group recurring session template',
    text: "forminfo:recgroup1.text",
    image: 'newsession',
    notes: 'forminfo:recgroup1.notes',
    notestext: "forminfo:recgroup1.notestext",
  },
  {
    id: 'recgroup2',
    title: 'New restricted group recurring session template',
    text: "forminfo:recgroup2.text",
    image: 'newsessionexclusive',
    notes: 'forminfo:recgroup2.notes',
    notestext: "forminfo:recgroup2.notestext",
  },
  {
    id: 'recgroup3',
    title: 'New private group recurring session template',
    text: "forminfo:recgroup3.text",
    image: 'newsessionprivate',
    notes: 'forminfo:recgroup3.notes',
    notestext: "forminfo:recgroup.notestext",
  },
  {
    id: 'recavail',
    title: 'New recurring available 1:1 session template',
    text: "forminfo:recavail.text",
    image: 'newsession',
    notes: 'forminfo:recavail.notes',
    notestext: "forminfo:recavail.notestext",
  },

  {
    id: 'recgroup1edit',
    title: 'Edit public group recurring session template',
    text: "forminfo:recgroup1edit.text",
    image: 'editsession',
    notes: 'forminfo:recgroup1edit.notes',
    notestext: "forminfo:recgroup1edit.notestext",
  },
  {
    id: 'recgroup2edit',
    title: 'Edit restricted group recurring session template',
    text: "forminfo:recgroup2edit.text",
    image: 'editsessionexclusive',
    notes: 'forminfo:recgroup2edit.notes',
    notestext: "forminfo:recgroup2edit.notestext",
  },
  {
    id: 'recgroup3edit',
    title: 'Edit private group recurring session template',
    text: "forminfo:recgroup3edit.text",
    image: 'editsessionprivate',
    notes: 'forminfo:recgroup3edit.notes',
    notestext: "forminfo:recgroup3edit.notestext",
  },
  {
    id: 'recavailedit',
    title: 'Edit recurring available 1:1 session template',
    text: "forminfo:recavailedit.text",
    image: 'editsession',
    notes: 'forminfo:recavailedit.notes',
    notestext: "forminfo:recavailedit.notestext",
  },
  {
    id: 'recevent',
    title: 'New recurring event template',
    text: "forminfo:recevent.text",
    image: 'newsession',
  },
  {
    id: 'receventedit',
    title: 'Edit recurring event template',
    text: "forminfo:receventedit.text",
    image: 'editsession',
  },
  {
    id: 'cal11',
    title: 'New 1:1 session',
    text: "forminfo:cal11.text",
    image: 'newsession',
    notes: 'forminfo:cal11.notes',
    notestext: "forminfo:cal11.notestext"
  },
  {
    id: 'cal11edit',
    title: 'Edit 1:1 session',
    text: "forminfo:cal11edit.text",
    image: 'editsession',
  },
  {
    id: 'calavail',
    title: 'New bookable 1:1 time slot',
    text: "forminfo:calavail.text",
    image: 'newsession',
    notes: 'forminfo:calavail.notes',
    notestext: 'forminfo:calavail.notestext',
  },
  {
    id: 'calgroup1',
    title: 'New public group session',
    text: "forminfo:calgroup1.text",
    image: 'newsession',
    notes: 'forminfo:calgroup1.notes',
    notestext: "forminfo:calgroup1.notestext",
  },
  {
    id: 'calgroup2',
    title: 'New restricted group session',
    text: "forminfo:calgroup2.text",
    image: 'newsessionexclusive',
    notes: 'forminfo:calgroup2.notes',
    notestext: "forminfo:calgroup2.notestext",
  },
  {
    id: 'calgroup3',
    title: 'New private group session',
    text: "forminfo:calgroup3.text",
    image: 'newsessionprivate',
    notes: 'forminfo:calgroup3.notes',
    notestext: "forminfo:calgroup3.notestext",
  },
  {
    id: 'calavailedit',
    title: 'Edit available 1:1 session',
    text: "forminfo:calavailedit.text",
    image: 'editsession',
  },
  {
    id: 'calgroup1edit',
    title: 'Edit public group session',
    text: "forminfo:calgroup1edit.text",
    image: 'editsession',
  },
  {
    id: 'calgroup2edit',
    title: 'Edit restricted group session',
    text: "forminfo:calgroup2edit.text",
    image: 'editsessionexclusive',
  },
  {
    id: 'calgroup3edit',
    title: 'Edit private group session',
    text: "forminfo:calgroup3edit.text",
    image: 'editsessionprivate',
  },
  {
    id: 'calevent',
    title: 'Edit private group session',
    text: "forminfo:calevent.text",
    image: 'newsession',
  },
  {
    id: 'caleventedit',
    title: 'Edit bookable event',
    text: "forminfo:caleventedit.text",
    image: 'editsession',
  },
  {
    id: 'caleventedit2',
    title: 'Edit internal event',
    text: "forminfo:caleventedit2.text",
    image: 'editsession',
  },
  {
    id: 'newclientgroup',
    title: 'New client group',
    text: "forminfo:newclientgroup.text",
    image: 'newgroup',
  },
  {
    id: 'editclientgroup',
    title: 'Edit client group',
    text: "forminfo:editclientgroup.text",
    image: 'editgroup',
  },{
    id: 'newlocation',
    title: 'New location',
    text: "forminfo:newlocation.text",
    image: 'newlocation',
    notes: 'forminfo:newlocation.notes',
    notestext: "forminfo:newlocation.notestext",
  },
  {
    id: 'editlocation',
    title: 'Edit location',
    text: "forminfo:editlocation.text",
    image: 'editlocation',
    notes: 'forminfo:editlocation.notes',
    notestext: "forminfo:editlocation.notestext",
  },
]



class WidgetFormInfo extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      id: props.id,
    }
  }


  renderLink(data) {
    if(data.link !== undefined && data.link !== '') {
      return (
        <div className="mb-30">
          <p className="small"><a href="" target="_blank" rel="noreferrer no" className="help">{lang.t('forminfo:button.helpcenter')}</a></p>
        </div>
      )
    }
  }


  renderNotes(data) {
    if(data.notes !== undefined && data.notes !== '') {
      return (
        <div className="infobox mb-30">
          <h5>{lang.t(data.notes)}</h5>
          <p>{lang.t(data.notestext)}</p>
        </div>
      )
    }
  }


  render() {
    var data = null
    for(var item of DataFormInfo) {
      if(item.id === this.state.id) {
        data = item
      }
    }
    if(data !== null) {
      return (
        <div>
          <div className="image empty large mb-30">
            <div className="illustration">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmpty.renderImage(data.image)}
            </div>
          </div>
          <p style={{marginBottom: 30, whiteSpace: 'pre-wrap'}}>{lang.t(data.text)}</p>
          {this.renderNotes(data)}
          {this.renderLink(data)}
        </div>
      )
    }
  }
}


export default withTranslation(['forminfo'])(WidgetFormInfo);