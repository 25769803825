import React, { Component } from 'react';
import Connector from '../../data/Connector';
import HelperCal from '../../helper/Calendar';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class PrintProgramPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      session: '',
      plan: '',
      program: null,
      benchmark: false,
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Print Program - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.updateExercises())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    this.configureData()

    setTimeout(() => {
      //window.print()
    }, 2000);
  }


  configureData() {
    if(!this.state.editing) {
      var tmp3 = []

      var id = ''
      var session = ''
      var group = false
      var plan = ''
      var arr = this.props.location.pathname.split('/')
      id = arr[arr.length-1]
      if(arr.length > 5) {
        if(arr.indexOf('session') !== -1) {
          session = arr[arr.length-3]
        } else if(arr.indexOf('group-session') !== -1) {
          session = arr[arr.length-3]
          group = true
        } else {
          plan = arr[arr.length-3]
          if(arr.indexOf('plan-program') !== -1) {
            plan = arr[arr.length-2]
          }
        }
      }

      var benchmark = false
      var program = null
      var list = global.programs
      if(session !== '') {
        global.backLink = '/calendar/session/'+session
        if(group) {
          global.backLink = '/calendar/group-session/'+session
        }
        for(var sess of global.sessions) {
          if(sess.id === session) {
            list = sess.program
          }
        }
      }
      if(plan !== '') {
        global.backLink = '/programming/plan/'+plan
        for(var pln of global.plans) {
          if(pln.id === plan) {
            list = pln.programs 
          }
        }
        for(var pln2 of global.clientPlans) {
          if(pln2.id === plan) {
            list = pln2.programs
            global.backLink = '/clients/'+arr[arr.length-4]+'/plan/'+pln2.id
          }
        }
      }

      for(var iteme of global.exercises) {
        if(iteme.data.user === global.uidUser) {
          tmp3.push({id: iteme.id, name: iteme.data.name})
        }
      }

      for(var item of list) {
        if(item.id === id) {
          program = item

          if(item.data.benchmark !== undefined) {
            benchmark = item.data.benchmark
          }
        }
      }

      this.setState({
        id: id,
        session: session,
        plan: plan,
        exercises: global.exercises,
        program: program,
        benchmark: benchmark,
      })
    }
  }


  updateExercises() {
    this.setState({
      exercises: global.exercises
    })
  }



  // Display stuff ------------------------------------------------------------



  renderSetsNameStatic(item) {
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    var label = lang.t('program:label.set'+s)
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('program:label.superset'+s)
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('program:label.dropset'+s)
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('program:label.dropset'+s)
      } else {
        label = lang.t('program:label.superset'+s)
      }
    }
    return label
  }


  renderBlockTypeStatic(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)
      if(item.emom) {
        label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('program:label.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.worknorest')
        } else {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMin(parseInt(item.exWork[0]))+' '+lang.t('program:label.workand')+' '+HelperCal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('program:label.rest')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.renderSetsNameStatic(item)+'s'
      if(item.rounds === 1) {
        label = ' - 1 '+this.renderSetsNameStatic(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('program:label.rounds')+' - '+lang.t('program:label.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderRepsStatic(item, index) {
    var label = item.exReps[index]
    if(item.exRepsRounds !== undefined && item.exRepsRounds !== '') {
      if(item.exRepsRounds.length > index) {
        if(item.exRepsRounds[index] !== '') {
          label = item.exRepsRounds[index].replaceAll('-', ' - ')
        }
      }
    }
    if(item.exUnits !== undefined) {
      if(item.exUnits.length > index) {
        if(item.exUnits[index] === 'dist') {
          label += 'm'
        }
        if(item.exUnits[index] === 'cals') {
          label += 'cal'
        }
        if(item.exUnits[index] === 'time') {
          label += 's'
        }
      }
    } else {
      if(item.exTool[index] === 6 || item.exTool[index] === 7) {
        label += 'm'
      }
      if(item.exTool[index] === 27) {
        label += 'cal'
      }
    }
    if(item.exReps[index] === 0) {
      label = ''
    }
    return label
  }


  renderWeightStatic(item, index) {
    if(item.exWeight[index] !== 0) {
      var label = lang.t('program:label.at')+' '+item.exWeight[index]+'% '+lang.t('program:label.of1rm')
      if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
        label = lang.t('program:label.at')+' '+item.exWeightRounds[index].replaceAll('-', ' - ')+'% '+lang.t('program:label.of1rm')
      }
      if(item.exWeightType !== undefined) {
        if(item.exWeightType.length > index && (item.exWeightType[index] === 'kg' || item.exWeightType[index] === 'lb')) {
          label = lang.t('program:label.with')+' '+item.exWeight[index]+' '+item.exWeightType[index]
          if(item.exWeightRounds !== undefined && item.exWeightRounds !== '') {
            label = lang.t('program:label.with')+' '+item.exWeightRounds[index].replaceAll('-', ' - ')+' '+item.exWeightType[index]
          }
        }
      }
      return label
    }
  }


  renderIntervalStatic(item, index) {
    var label = ''
    if(item.type === 2 && !item.emom) {
      label = '('+HelperCal.getDurationMin(item.exWork[index])+' '+lang.t('program:label.work')+' - '+HelperCal.getDurationMin(item.exRest[index])+' '+lang.t('program:label.rest-s')+')'
      if(item.exRest[index] === 0 || item.exRest[index] === undefined) {
        label = '('+HelperCal.getDurationMin(item.exWork[index])+' '+lang.t('program:label.work')+')'
      }
    }
    return label
  }


  renderNotesStatic(item, index) {
    if(item.exNotes !== undefined) {
      if(item.exNotes.length > index) {
        if(item.exNotes[index] !== '') {
          return (
            <p className="clear col-12 small">{lang.t('program:button.notes')}: {item.exNotes[index]}</p>
          )
        }
      }
    }
  }


  renderStaticNotes(item) {
    if(item.notes !== '') {
      return <p className="small mb-20 col-12" style={{whiteSpace: 'pre-wrap'}}>{item.data.notes}</p>
    }
  }


  renderResults(block) {
    if(block.logResults) {
      return (
        <div className="clear col-12 mb-10 small">{lang.t('program:label.results')}:</div>
      )
    }
  }


  renderExercisesStatic(num) {
    var block = this.state.program.blocks[num].data
    return (
      <div>
        {block.exName.map((item, index) => (
          <div className="print-movement">
            <strong>{this.renderRepsStatic(block, index)} {item}</strong> {this.renderWeightStatic(block, index)} {this.renderIntervalStatic(block, index)}
            {this.renderResults(block)}
            {this.renderNotesStatic(block, index)}
            <div className="clear"></div>
          </div>
        ))}
      </div>
      
    )
  }


  renderBlockTitleStatic(item) {
    if(item.data.name !== '' && item.data.name !== undefined) {
      return item.data.name
    } else {
      return lang.t(global.exCats[item.data.cat])
    }
  }


  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }


  renderContent() {
    if(this.state.program !== null) {
      return (
        <div>
          <div className="print-header">
            {this.renderLogo()}
            <h2>{global.userBusiness}</h2>
            <h3>{this.state.program.data.name}<br/>{lang.t('program:label.createdwithptmate')}</h3>
          </div>
          <p>{this.state.program.data.desc}</p>
          {this.state.program.blocks.map((item, index) => (
            <div key={index} className="mb-40">
              <h3>{this.renderBlockTitleStatic(item)}</h3>
              <h4 className="clear mb-10">{lang.t(global.proTypes[item.data.type])} {this.renderBlockTypeStatic(item.data)}</h4>
              {this.renderExercisesStatic(index)}
              {this.renderStaticNotes(item)}
            </div>
          ))}
          <div className="sv-40"></div>
          <p style={{fontSize: '11px'}}>{lang.t('program:label.sessionnotes')}:</p>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content print-content">
          {this.renderContent()}
          <div className="noprint">
            <button className="btn tertiary width-12" onClick={() => window.print()}>{lang.t('program:button.print')}</button>
          </div>
        </div>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['program','common','header'])(withRouter(withAuthorization(condition)(PrintProgramPage)));