import React from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import HelperBusiness from './Business';
import { Link } from 'react-router-dom';
import lang from 'i18next';



class HelperClients {


  // Avatar image

  getImage(client) {
    if(client !== null && client !== undefined) {
      if(client.data.image === '') {
        var inits = ''
        let arr = client.data.name.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        if(client.data.avatar !== undefined && client.data.avatar !== '') {
          return (
            <div className={'avatar '+client.data.avatar}></div>
          )
        } else {
          return (
            <div className="avatar">
              <p>{inits}</p>
            </div>
          )
        }
        
      } else {
        var img = ''
        for(var item of global.clientsImages) {
          if(item.id === client.id) {
            img = item.image
          }
        }
        return (
          <div className="avatar" style={{backgroundImage: 'url('+img+')'}}> </div>
        )
      }
    } else {
      return (
        <div className="avatar"><p>?</p></div>
      )
    }
  }


  getImageEdit(client) {
    if(client !== null && client !== undefined) {
      if(client.data.image === '') {
        var inits = ''
        let arr = client.data.name.split(' ')
        if(arr.length > 1) {
          inits = arr[0].charAt(0)+arr[1].charAt(0)
        } else {
          inits = arr[0].charAt(0)
        }
        return (
          <div className="avatar"><p>✎</p></div>
        )
      } else {
        var img = ''
        for(var item of global.clientsImages) {
          if(item.id === client.id) {
            img = item.image
          }
        }
        return (
          <div className="avatar" style={{backgroundImage: 'url('+img+')'}}> </div>
        )
      }
    } else {
      return (
        <div className="avatar"><p>?</p></div>
      )
    }
  }


  // Form status

  getForm(client, form, type, pre, list) {
    var notsent = "Not sent yet"
    var notcompleted = "Not completed yet"
    var version = "Version"
    var alreadysent = "Already sent"
    var completedon = "Completed on"
    if(lang.language === 'es') {
      notsent = "Aún no enviado"
      notcompleted = "Aún no rellenado"
      version = "Versión"
      alreadysent = "Ya enviado"
      completedon = "Rellenado el"
    }

    var label = notsent
    if(pre || list === 'response') {
      label = notcompleted
    }
    if(type === 'version') {
      label = ' '
    }
    for(var item of client.forms) {
      if(item.id === form) {
        label = alreadysent
        
        if(item.data.date !== undefined) {
          label = completedon+' '+Moment(item.data.date, 'X').locale(lang.language).format('D MMM YYYY')
        } else {
          if(pre || list === 'response') {
            label = notcompleted
          }
        }
        if(type === 'version') {
          label = version+' '+item.data.version
        }
      }
    }
    return label
  }


  // Notification badges

  getDotBirthday(client) {
    var show = false
    let day = Moment(client.data.birth, 'DD/MM/YYYY').format('DD/MM')
    let yr = Moment().format('YYYY')
    let date = Moment(day+'/'+yr, 'DD/MM/YYYY').format('X')
    let advance = Moment().add(5, 'days').format('X')
    if((date < advance && date >= Moment().format('X')) || day === Moment().format('DD/MM')) {
      show = true
    }
    var label = "Birthday on"
    if(lang.language === 'es') {
      label = "Cumpleaños el"
    }
    if(show) {
      return (
        <div className="bullet quarternary" title={label+' '+Moment(client.data.birth, 'DD/MM/YYYY').locale(lang.language).format('D MMMM')}></div>
      )
    }
  }


  renderButton(item, active) {
    if(active || active === undefined) {
      var label = "Manage account"
      if(lang.language === 'es') {
        label = "Gestionar cuenta"
      }
      return (
        <Link to={'/clients/'+item+'/details'} className="btn tertiary small rgt">{label}</Link>
      )
    }
  }


  // Format phone number

  getPhone(number) {
    var label = number
    if(number.indexOf('04') !== -1) {
      var l1 = number.substring(0, 4)
      var l2 = number.substring(4, 7)
      var l3 = number.substring(7, number.length)
      label = l1+" "+l2+" "+l3
    }
    return label
  }


  // Family account banner

  getFamily(item) {
    if(item !== null && HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      var label1 = "This is the main profile for a family account"
      var label2 = "Part of a family account managed by"
      var label3 = "another client"
      if(lang.language === 'es') {
        label1 = "Este es el perfil principal para una cuenta de familia"
        label2 = "Parte de una cuenta de familia gestionada por"
        label3 = "otro cliente"
      }
      if(item.data.parent !== undefined && item.data.parent !== '') {
        var name = label3
        for(var cl of global.clients) {
          if(cl.id === item.data.parent) {
            name = cl.data.name
          }
        }
        return (
          <div className="infobox large primary mb-30">
            <p>{label2} {name}{this.renderButton(item.data.parent, item.data.active)}</p>
          </div>
        )
      } else if(item.data.linked !== undefined) { 
        return (
          <div className="infobox large green mb-30">
            <p>{label1}{this.renderButton(item.id, item.data.active)}</p>
          </div>
        )
      }
    }
  }

}


const b = new HelperClients();
export default b;