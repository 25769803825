import React, { Component } from 'react';
import { ResponsiveLine } from '@nivo/line';



class WidgetChartGraph extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      curve: props.curve ?? 'cardinal',
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      axis: props.axis === '' ? null : {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Dates',
        legendOffset: 36,
        legendPosition: 'center'
      },
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      curve: props.curve ?? 'cardinal',
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      axis: props.axis === '' ? null : {
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Dates',
        legendOffset: 36,
        legendPosition: 'center'
      },
    }
  }


  renderLight() {
    return (
      <ResponsiveLine
          data={this.state.data}
          margin={{ top: 5, right: 20, bottom: 25, left: 28 }}
          xScale={{ type: 'point', format: '%Y-%m-%d', precision: 'day', }}
          yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: false,
              reverse: false
          }}
          axisTop={null}
          curve={this.state.curve}
          axisRight={null}
          colors= {global.themeColors}
          colorBy={d => d.color}
          enableGridX={false}
          enableGridY={true}
          enableSlices="x"
          axisBottom={this.state.axis}
          axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'center'
          }}
          //colors={{ scheme: 'nivo' }}
          pointSize={5}
          pointColor={{ from: 'color', modifiers: [] }}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'color', modifiers: [] }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh={true}
          enableCrosshair={false}
          theme={{
            tooltip: {
              container: {
                background: "#ffffff",
                fontSize: 12
              },
            },
          }}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemBackground: 'rgba(0, 0, 0, .03)',
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
      />
    )
  }


  renderDark() {
    return (
      <ResponsiveLine
        data={this.state.data}
        margin={{ top: 5, right: 20, bottom: 25, left: 28 }}
        xScale={{ type: 'point', format: '%Y-%m-%d', precision: 'day', }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        curve={this.state.curve}
        axisRight={null}
        colors= {global.themeColors}
        colorBy={d => d.color}
        enableGridX={false}
        enableGridY={true}
        enableSlices="x"
        axisBottom={this.state.axis}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'center'
        }}
        pointSize={5}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color', modifiers: [] }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        enableCrosshair={false}
        theme={{
          annotations: {
            text: {
              color: '#999C9F',
              fontSize: 11
            },
          },
          "axis": {
            domain: {
              line: {
                stroke: '#13131b',
              },
            },
            legend: {
              text: {
                color: '#999C9F',
              },
            },
            "ticks": {
              line: {
                  stroke: "#999C9F",
                  strokeWidth: 1,
              },
              "text": {
                  "fill": "#999C9F",
              }
            }
          },
          "grid": {
            "line": {
                "stroke": "#323439",
                "strokeWidth": "1",
            }
            
          },
          tooltip: {
            container: {
              background: "#000011",
              fontSize: 12
            },
          },
        }}
        legends={[
          {
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    )
  }


  render() {
    return (
      <div className="chart">
        <div className="show-light chart">
          {this.renderLight()}
        </div>
        <div className="show-dark chart solid">
          {this.renderDark()}
        </div>
      </div>
    )
  }
}


export default WidgetChartGraph;