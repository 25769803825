export const LANDING = '/';
export const PASSWORD = '/reset-password';
export const SIGN_UP = '/signup';
export const SIGN_UP2 = '/complete-signup';
export const PROMO = '/planahead22';
export const STAFFINACTIVE = '/staff/inactive';
export const STAFFSEARCH = '/staff/search';

export const OVERVIEW = '/overview';
export const COMMUNITY = '/community';
export const CLIENTS = '/clients';
export const CLIENTSGROUPS = '/clients/groups';
export const CLIENTSGROUPSNEW = '/clients/groups/new';
export const NEWCLIENT = '/clients/new';
export const NEWCLIENTIMPORT = '/clients/import';
export const CLIENTSREPORT = '/clients/report';

export const CALENDAR = '/calendar';
export const RECURRING = '/calendar/recurring-templates';
export const NEWSESSION = '/calendar/new-session';
export const NEWGROUP = '/calendar/new-group';
export const NEWEVENT = '/calendar/new-event';
export const NEWAVAILABILITY = '/calendar/new-availability';
export const NEWRECSESSION = '/calendar/new-recurring-session';
export const NEWRECGROUP = '/calendar/new-recurring-group';
export const NEWRECAVAILABILITY = '/calendar/new-recurring-availability';
export const NEWRECEVENT = '/calendar/new-recurring-event';
export const CALINSIGHTS = '/calendar/insights';
export const CALREPORT = '/calendar/report';
export const ROSTER = '/calendar/roster';
export const CALARCHIVE = '/calendar/archive';

export const PROGRAMMING = '/programming';
export const NEWPROGRAM = '/programming/new-program';
export const PLANS = '/programming/plans';
export const EXERCISES = '/programming/movements';
export const NEWEXERCISE = '/programming/new-movement';

export const NUTRITION = '/health';
export const NEWNUTRITION = '/health/new-plan';
export const HABITS = '/health/habits';
export const NEWHABIT = '/health/new-habit';

export const BILLING = '/billing';
export const DEBITS = '/billing/direct-debits';
export const PRODUCTS = '/billing/products';
export const NEWPRODUCT = '/billing/products/new';
export const INVOICES = '/billing/invoices';
export const PAYMENT = '/billing/payment';
export const DEBITNEW = '/billing/direct-debits/new';
export const INVOICENEW = '/billing/invoices/new';
export const EXPENSES = '/billing/expenses';
export const NEWEXPENSE = '/billing/expenses/new';
export const REPORT = '/billing/report';
export const BILINSIGHTS = '/billing/insights';

export const SUBSCRIPTION = '/subscription';
export const ACCOUNT = '/account';
export const ACCOUNTSPACES = '/account/spaces';
export const ACCOUNTBILLING = '/stripe-payments';
export const ACCOUNTBADGES = '/account/achievements';
export const ACCOUNTTRIAL = '/subscribe-now';
export const AFFILIATES = '/account/affiliates';
export const EMAILUSAGE = '/email-usage';

export const ADMINASSISTANT = '/admin';
export const ADMINSTAFF = '/admin/staff';
export const ADMINSTAFFNEW = '/admin/staff/new';
export const ADMINDOC = '/admin/documents';
export const ADMINNEWDOC = '/admin/documents/new';
export const ADMINPUBLIC = '/admin/prospects';
export const ADMINEMAILS = '/admin/emails';
export const ADMINNEWEMAIL = '/admin/emails/new';
export const ADMINWELCOMEEMAIL = '/admin/emails/welcome';
export const LOCATIONS = '/admin/locations';
export const NEWLOCATION = '/admin/locations/new';
export const ADMINSETTINGS = '/admin/settings';

// MEMBER AREA

export const MEMBERLOGIN = '/member/login';
export const MEMBERPASSWORD = '/member/reset-password';
export const MEMBERSIGNUP = '/member/signup';
export const MEMBERCONFIRM = '/member/confirm';
export const MEMBERLOADING = '/member/loading';