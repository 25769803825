import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class HeaderForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      title: props.title ?? '',
      link: props.link ?? '/overview'
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      title: props.title ?? '',
      link: props.link ?? '/overview'
    }
  }
  
  
  render() {
    return (
      <div className="header">
        <div className="content">
          <Link to={this.state.link} className="back lft mr-10">
            <div className="circle"></div>
            <div className="arrow"></div>
          </Link>
          <h2>{this.state.title}</h2>
        </div>
      </div>
    )
  }
}


export default HeaderForm;