import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperCal from '../../helper/Calendar';
import HelperEmpty from '../../helper/Empty';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import WidgetChartBar from '../../components/Widgets/chart-bar';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';




class ClientsProgressPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      sessions: global.sessions,
      archive: global.archive,
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
      best: [],
      dateRange: global.dateRange,
      showModalDay: false,
      hiddenDay: 'hidden',
      cbest: null,
      margin: 0,
      ltitle: 'Weights (kg)',
      dataLeft: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      currentLeft: '',
      dataRight: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
      currentRight: 'fat',
      showModalBeep: false,
      hiddenBeep: 'hidden',
      beep: [],
      beep1: '00',
      beep2: '00',
      dataEnergy: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
      benchmark: [],
      energy1: '-',
      energy2: '-',
      month: Moment().format('MM/YYYY'),
      day: Moment().format('DD/MM/YYYY'),
      days: [],
      selected: [],
      dsessions: [],
      // Theme
      gc: "#1DC5C9",
      showWeight: true,
      showReps: false,
    };
  }


  componentDidMount() { 
    HelperPage.initialize('Progress - Client Details - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('clientsLoaded', (event) => {
      this.getClient()
      if(this.state.id === '') {
        this.getEmpty()
      }
    })
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    EventEmitter.subscribe('bestLoaded', (event) => this.configureData())
    this.getEmpty()

    var tmp = []
    for(var i=0; i<20; i++) {
      if(i < 10) {
        tmp.push('0'+i)
      } else {
        tmp.push(i)
      }
    }
    this.setState({
      beep: tmp
    })

    if(global.archive.length === 0 && global.uidUser !== '') {
      Connector.loadArchive((result) => {})
    }

    if(global.spaceTheme === 'red') {
      this.setState({
        gc: "#DE1053"
      })
    }
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.getClient()
    }
  }


  updateTraining() {
    this.setState({
      sessionsTraining: global.sessionsTraining,
      archiveTraining: global.archiveTraining,
    })
    if(this.state.sessionsTraining.length === 0) {
      setTimeout(() => {
        this.updateTraining();
      }, 5000);
    }
  }


  getClient() {
    if(this._isMounted) {
      var id = ''
      var name = ''
      var item = null
      global.clientBest = []
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          if(client.data.uid !== '') {
            Connector.loadClientBest(client.data.uid)
          } else {
            Connector.loadClientBest(client.id)
          }
          
          id = client.id
          item = client
          name = item.data.name
        }
      }

      if(item !== null) {
        this.setState({
          id: id,
          item: item,
          name: name,
        }, () => {
            this.configureData()
            global.linkCli = '/clients/'+id+'/progress';
        });
      }
    }    
  }


  configureData() {
    if(this._isMounted) {
      var tmp = []
      var tmp4 = []
      var ids = []

      // Sessions with trainer
      for(var item of global.sessions) {
        var isGroup = false
        if(item.data.group && item.data.clients !== undefined) {
          if(item.data.clients.indexOf(global.currentClient) !== -1) {
            isGroup = true
          }
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment().format('X')) {
          if(item.data.client === this.state.id) {
            tmp.push(item)
          }
          if(isGroup) {
            tmp.push(item)
          }
        }
        if(item.program.length > 0 && (isGroup || item.data.client === this.state.id)) {
          if(item.program[0].data.benchmark === true) {
            if(ids.indexOf(item.program[0].id) === -1) {
              ids.push(item.program[0].id)
              tmp4.push(item.program[0])
            }
          }
        }
      }
      for(var item2 of global.archive) {
        if(item2.data.client === this.state.id) {
          tmp.push(item2)
        }
        var isGroup2 = false
        if(item2.data.group && item2.data.clients !== undefined) {
          if(item2.data.clients.indexOf(global.currentClient) !== -1) {
            isGroup2 = true
          }
        }
        if(item2.program.length > 0 && (isGroup2 || item2.data.client === this.state.id)) {
          if(item2.program[0].data.benchmark === true) {
            if(ids.indexOf(item2.program[0].id) === -1) {
              ids.push(item2.program[0].id)
              tmp4.push(item2.program[0])
            }
          }
        }
      }

      // Training sessions
      if(this.state.item !== null && (this.state.show === 'all' || this.state.show === 'training')) {
        for(var item3 of global.sessionsTraining) {
          if(item3.data.client === this.state.item.data.uid) {
            tmp.push(item3)
            if(item3.program[0].data.benchmark === true) {
              if(ids.indexOf(item3.program[0].id) === -1) {
                ids.push(item3.program[0].id)
                tmp4.push(item3.program[0])
              }
            }
          }
        }
        for(var item4 of global.archiveTraining) {
          if(item4.data.client === this.state.item.data.uid) {
            tmp.push(item4)
            if(item4.program[0].data.benchmark === true) {
              if(ids.indexOf(item4.program[0].id) === -1) {
                ids.push(item4.program[0].id)
                tmp4.push(item4.program[0])
              }
            }
          }
        }
      }

      var bp = '00:00'
      if(this.state.item !== null) {
        if(this.state.item.data.beep !== undefined) {
          bp = this.state.item.data.beep
        }
      }
      
      var ar = bp.split(':')

      var tmp2 = []
      for(var item5 of global.clientBest) {
        for(var ex of global.exercises) {
          if(ex.data.subIds.indexOf(item5.id) !== -1 && ids.indexOf(item5.id) === -1 && global.exToolsWeight.indexOf(item5.data.tool) !== -1) {
            tmp2.push(item5)
            ids.push(item5.id)
          }
        }
      }
      
      this.setState({
        sessions: tmp,
        sessionsTraining: global.sessionsTraining,
        archiveTraining: global.archiveTraining,
        best: tmp2,
        beep1: ar[0],
        beep2: ar[1],
        benchmark: tmp4,
        ltitle: this.state.cbest === null ? lang.t('clients:label.weights') : lang.t('clients:label.weights')+' ('+this.renderExType(this.state.cbest.data.type)+')',
      }, () => {
        if(tmp2.length > 0) {
          this.setState({
            currentLeft: tmp2.id
          })
          var id = tmp2[0].id
          if(this.state.cbest === null) {
            for(var it of tmp2) {
              if(it.id === this.state.cbest) {
                id = this.state.cbest
              }
            }
          }
          this.createGraphWeights(id)
          this.configureDetails(id)
          this.createGraphEnergy()
        }
      })
    }
  }


  configureBestName(item) {
    var label = item.data.name
    if(item.data.tool === 6 || item.data.tool === 7) {
      var ar = item.id.split('|')
      if(ar.length > 1) {
        label = item.data.name+' '+ar[1]+' m'
      }
    }
    return label
  }


  configureDetails(ex) {
    var best = null
    for(var item of this.state.best) {
      if(item.id === ex) {
        best = item
      }
    }
    this.setState({
      cbest: best,
    })
  }



  // Graph Calculations ------------------------------------------------------------



  setDateRange(event) {
    global.dateRange = event.target.value
    this.setState({
        dateRange: event.target.value
    }, () => {
        this.createGraphEnergy()
    });
  }


  showSessions(event) {
     this.setState({
        show: event.target.value
    }, () => {
        this.configureData()
    });
  }


  setMonth(type) {
    var dt = Moment('01/'+this.state.month, 'DD/MM/YYYY').add(1, 'month')
    if(type === 'prev') {
      dt = Moment('01/'+this.state.month, 'DD/MM/YYYY').add(-1, 'month')
    }
    this.setState({
      month: Moment(dt).format('MM/YYYY')
    })
  }


  updateLeft(event) {
    var ltitle = this.state.cbest === null ? lang.t('clients:label.weight') : lang.t('clients:label.weight')+' ('+this.renderExType(this.state.cbest.data.type)+')'
    for(var item of global.clientBest) {
      if(item.id === event.target.value && (item.data.tool === 6 || item.data.tool === 7)) {
        ltitle = lang.t('clients:label.timesec')
      }
    }
    this.setState({
      currentLeft: event.target.value,
      ltitle: ltitle,
      dataLeft: [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
    })
    this.createGraphWeights(event.target.value)
    this.configureDetails(event.target.value)
  }


  toggleGraph(type) {
    if(type === 'weight') {
      this.setState({
        showWeight: !this.state.showWeight
      }, () => {
        this.createGraphWeights(this.state.cbest.id)
      })
    } else {
      this.setState({
        showReps: !this.state.showReps
      }, () => {
        this.createGraphWeights(this.state.cbest.id)
      })
    }
  }


  createGraphWeights(id) {
    var tmp = []
    var tmpr = []
    var days = []
    var sessions = []
    var list = this.state.sessions
    list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
    
    for(var item of list) {
      var numbr = 0
      if(item.program[0] !== undefined) {
        if(item.program[0].id !== '') {
          
          for(var block of item.program[0].blocks) {
            for(var i=0; i<block.data.exId.length; i++) {
              if(block.data.exId[i] === id) {
                days.push(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY'))
                numbr++
                var label = ''
                var valr = 0
                if(!item.data.group) {
                  var val = block.data.exResWeight[i]
                  var wval = val
                  var reps = 0
                  if(block.data.exResReps !== undefined) {
                    reps = block.data.exResReps[i]
                    valr = block.data.exResReps[i]
                  }
                  if(block.data.exResWeightRounds !== undefined) {
                    wval = block.data.exResWeightRounds[i]
                  }
                  if(block.data.exResRepsRounds !== undefined) {
                    reps = block.data.exResRepsRounds[i]
                  }
                  sessions.push({
                    id: item.id,
                    name: lang.t('clients:label.11session'),
                    type: block.data.type,
                    date: item.data.date,
                    weight: wval,
                    reps: reps,
                    block: block,
                    weightType: block.data.exWeightType[i]
                  })
                  if(val === undefined) {
                    val = 0
                  }
                  
                  if(numbr > 1) {
                    label = ' ('+numbr+')'
                  }
                  if(val !== 0) {
                    tmp.push({
                      "x": Moment(item.data.timestamp, 'X').format('X')+label,
                      "y": val,
                    })
                    tmpr.push({
                      "x": Moment(item.data.timestamp, 'X').format('X')+label,
                      "y": valr,
                    })
                  }
                } else {
                  var num = 0
                  if(item.data.clients !== undefined && item.data.clients.length > 0) {
                    for(var j=0; j<item.data.clients.length; j++) {
                      if(item.data.clients[j] === this.state.id) {
                        num = j
                      }
                    }
                  }
                  var wgts = []
                  if(block.data.exResWeightGroup[i] !== undefined && block.data.exResWeightGroup[i] !== '') {
                    wgts = String(block.data.exResWeightGroup[i]).split('-')
                  }
                  var val2 = 0
                  var wnum = num
                  if(wgts[0] === '') {
                    wnum = num+1
                  }
                  if(wgts[wnum] !== '' && wgts[wnum] !== undefined) {
                    val2 = parseFloat(wgts[wnum])
                  }

                  var rps = []
                  if(block.data.exResRepsGroup[i] !== undefined && block.data.exResRepsGroup[i] !== '') {
                    rps = String(block.data.exResRepsGroup[i]).split('-')
                  }
                  var rps2 = 0
                  var rnum = num
                  if(rps[0] === '') {
                    rnum = num+1
                  }
                  if(rps[rnum] !== '' && rps[rnum] !== undefined) {
                    rps2 = parseFloat(rps[rnum])
                    valr = parseFloat(rps[rnum])
                  }

                  var name = lang.t('clients:label.groupsession')
                  if(item.data.client !== '') {
                    name = item.data.client
                  }
                  sessions.push({
                    id: item.id,
                    name: name,
                    type: block.data.type,
                    date: item.data.date,
                    weight: val2,
                    reps: rps2,
                    block: block,
                    weightType: block.data.exWeightType[i]
                  })
                  if(numbr > 1) {
                    label = ' ('+numbr+')'
                  }
                  var add = true
                  if(val === 0 && val2 === 0) {
                    add = false
                  }
                  if(add) {
                    tmp.push({
                      "x": Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM')+label,
                      "y": val2,
                    })
                    tmpr.push({
                      "x": Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM')+label,
                      "y": rps2,
                    })
                  }
                  
                }
              }
            }
          }
        }
      }
    }
    var tmp2 = [{
      "id": "W:",
      "color": "#1DC5C9",
      data: tmp
    },
    {
      "id": "R:",
      "color": "#81DB24",
      data: tmpr
    }]

    if(this.state.showWeight && !this.state.showReps) {
      tmp2 = [{
        "id": "W:",
        "color": "#1DC5C9",
        data: tmp
      }]
    } else if(!this.state.showWeight && this.state.showReps) {
      tmp2 = [{
        "id": "R:",
        "color": "#81DB24",
        data: tmpr
      }]
    } else if(!this.state.showWeight && !this.state.showReps) {
      tmp2 = [{
        "id": "japan",
        "color": "#1DC5C9",
        "data": []
      }]
    }

    setTimeout(() => {
      this.setState({
        dataLeft: tmp2,
        days: days,
        selected: sessions
      });
      EventEmitter.dispatch('graphLoaded', 'loaded');
    }, 100);

    global.dataWeight = tmp

  }


  updateRight(event) {
    this.setState({
      currentRight: event.target.value,
      dataRight: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
    })
  }


  createGraphEnergy() {
    if(this.state.item !== null) {
      var tmp = []
      var tmpb1 = 0
      var tmpb2 = 0
      var tmpa1 = 0
      var tmpa2 = 0
      var list = this.state.sessions
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
      for(var item of list) {
        var val = 0
        var val2 = 0
        if(item.data.energyStart !== undefined) {
          var ar = item.data.energyStart.split('-')
          ar.splice(0,1)
          if(item.data.group) {
            if(item.data.clients.length > 0) {
              for(var i1=0; i1<item.data.clients.length; i1++) {
                if(item.data.clients[i1] === this.state.id) {
                  val = parseInt(ar[i1])
                }
              }
            }
          } else {
            val = parseInt(ar[0])
          }
        }

        var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        var min = Moment().add(-30, 'days')
        if(this.state.dateRange === '7days') {
          min = Moment().add(-7, 'days')
        }
        if(this.state.dateRange === '60days') {
          min = Moment().add(-60, 'days')
        }
        if(this.state.dateRange === 'month') {
          var mon = Moment().format('MM/YYYY')
          min = Moment('01/'+mon, 'DD/MM/YYYY')
        }
        if(this.state.dateRange === 'all') {
          min = Moment('01/01/1900', 'DD/MM/YYYY')
        }
        if(item.data.energyEnd !== undefined && date >= min) {
          var ar2 = item.data.energyEnd.split('-')
          ar2.splice(0,1)
          if(item.data.group) {
            if(item.data.clients.length > 0) {
              for(var i2=0; i2<item.data.clients.length; i2++) {
                if(item.data.clients[i2] === this.state.id) {
                  val2 = parseInt(ar2[i2])
                }
              }
            }
          } else {
            val2 = parseInt(ar2[0])
          }
        }
        
        if(val !== 0 && val2 !== 0) {
          tmp.push({
            "date": Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM'),
            "before": val,
            "after": val2
          })
          tmpb1 += val
          tmpb2++
          tmpa1 += val2
          tmpa2++
        }
      }
      var tmp2 = [{
        "id": "Before",
        "color": "#1DC5C9",
        data: tmp
      }]

      var e1 = '-'
      var e2 = '-'
      if(tmpb1 !== 0 && tmpb2 !== 0) {
        e1 = (tmpb1/tmpb2).toFixed(1)
      }
      if(tmpa1 !== 0 && tmpa2 !== 0) {
        e2 = (tmpa1/tmpa2).toFixed(1)
      }

      setTimeout(() => {
        this.setState({
          dataEnergy: tmp2,
          energy1: e1,
          energy2: e2
        });
      }, 100);
    }
  }


  configureStats(type) {
    var label = '-'
    var date = Moment('01/01/2000', 'DD/MM/YYYY').format('X')
    var tmp11 = 0
    var tmp12 = 0
    var tmp21 = 0
    var tmp22 = 0
    if(this.state.cbest !== null) {
      for(var item of this.state.sessions) {
        var sdate = Moment(item.data.date, 'DD/MM/YYYY').format('X')
        var cls = []
        if(item.data.clients !== undefined) { cls = item.data.clients }
        if(item.program.length > 0 && (item.data.client === this.state.item.id || cls.indexOf(this.state.item.id) !== -1)) {
          for(var bl of item.program[0].blocks) {
            if(bl.data.exId.length > 0) {
              for(var i=0; i<bl.data.exId.length; i++) {
                if(bl.data.exId[i] === this.state.cbest.id && parseInt(sdate) >= parseInt(date) && !item.data.group) {
                  if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && bl.data.exResWeight[i] > 0)) {
                    if(type === 'lastw') {
                      label = bl.data.exResWeight[i].toFixed(1)
                    }
                    if(type === 'lastd') {
                      label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                      if(item.data.timestamp !== '') {
                        label = Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM YYYY')
                      }
                      if(bl.data.exResReps[i] !== 0) {
                        label += ' ('+bl.data.exResReps[i]+' reps)'
                      }
                    }
                    date = sdate
                  }
                }

                if(bl.data.exId[i] === this.state.cbest.id && parseInt(sdate) >= parseInt(date) && item.data.group) {
                  var val = bl.data.exResWeightGroup[i].split('-')
                  //val.splice(0,1)
                  if(val[0] === '') {
                    val.splice(0,1)
                  }
                  var val2 = bl.data.exResRepsGroup[i].split('-')
                  if(val2[0] === '') {
                    val2.splice(0,1)
                  }
                  //val2.splice(0,1)
                  for(var j=0; j<cls.length; j++) {
                    if(cls[j] === this.state.item.id && val.length > j) {
                      if(parseInt(sdate) !== parseInt(date) || (parseInt(sdate) === parseInt(date) && val[j] > 0)) {
                        if(type === 'lastw') {
                          label = val[j]
                        }
                        if(type === 'lastd') {
                          label = Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')
                          if(item.data.timestamp !== '') {
                            label = Moment(item.data.timestamp, 'X').locale(lang.language).format('ddd, D MMM YYYY')
                          }
                          if(val2[j] !== 0 && val2[j] !== undefined) {
                            label += ' ('+val2[j]+' reps)'
                          }
                        }
                        date = sdate
                      }
                    }
                  }
                }

                if(bl.data.exId[i] === this.state.cbest.id) {
                  if(!item.data.group) {
                    if(bl.data.exResWeight[i] > 0) {
                      tmp11 += bl.data.exResWeight[i]
                      tmp12++
                    }
                    if(bl.data.exResReps[i] !== 0) {
                      tmp21 += bl.data.exResReps[i]
                      tmp22++
                    }
                  } else if(item.data.group) {

                  }

                }
              }
            }
          }
        }
      }
    }

    if(type === 'lastw' && label !== '-') {
      return <span>{label}{this.renderExType(this.state.cbest.data.type)}</span>
    } else if(type === 'lastw' && label === '-') {
      return '-'
    } else if(type === 'lastd' && label !== '-') {
      return label
    } else if(type === 'avg') {
      if(tmp11 !== 0 && tmp12 !== 0) {
        return <span>{(tmp11/tmp12).toFixed(1)}{this.renderExType(this.state.cbest.data.type)}</span>
      } else {
        return <p>-</p>
      }
    } else if(type === 'reps') {
      if(tmp21 !== 0 && tmp22 !== 0) {
        return (tmp21/tmp22).toFixed(1)
      } else {
        return '-'
      }
    }
  }



  // Day modal ------------------------------------------------------------



  showModalDay(item) {
    var tmp = []
    for(var sess of this.state.selected) {
      if(Moment(sess.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY') === item) {
        tmp.push(sess)
      }
    }
    this.setState({
      showModalDay: true,
      day: item,
      dsessions: tmp,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenDay: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalDay() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenDay: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalDay: false});
    }, 500);
  }


  renderSetsName(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    var label = lang.t('program:label.set'+s)
    if(item.exId.length > 1) {
      var prev = ''
      var multiple = true
      for(var ex of item.exId) {
        if(ex !== prev && prev !== '') {
          label = lang.t('program:label.superset'+s)
          multiple = false
        }
        if(ex === prev && prev !== '') {
          label = lang.t('program:label.dropset'+s)
        }
        prev = ex
      }
      if(multiple) {
        label = lang.t('program:label.dropset'+s)
      } else {
        label = lang.t('program:label.superset'+s)
      }
    }
    return label
  }


  renderBlockType(item) {
    var label = ''
    var s = ''
    if(item.rounds > 1) {
      s = 's'
    }
    if(item.type === 0) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
    }
    if(item.type === 1) {
      label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMinAlt(parseInt(item.exWork[0]))
      if(item.emom) {
        label = ' - '+item.rounds+' '+lang.t('program:label.round'+s)+' '+lang.t('program:label.of')+' '+HelperCal.getDurationMinAlt(parseInt(item.exWork[0]))+' ('+lang.t('program:label.allatthesametime')+')'
      }
    }
    if(item.type === 2 || item.type === 3) {
      label = ' - '+item.rounds+' '+lang.t('program:label.rounds')
      if(item.rounds === 1) {
        label = ' - 1 '+lang.t('program:label.round')
      }
      if(item.type === 2 && item.emom) { 
        if(item.exRest[0] === 0) {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMinAlt(parseInt(item.exWork[0]))+' '+lang.t('program:label.worknorest')
        } else {
          label += ' '+lang.t('program:label.of')+' '+HelperCal.getDurationMinAlt(parseInt(item.exWork[0]))+' '+lang.t('program:label.workand')+' '+HelperCal.getDurationMin(parseInt(item.exRest[0]))+' '+lang.t('program:label.rest-s')
        }
      }
    }
    if(item.type === 4) {
      label = ' - '+item.rounds+' '+this.renderSetsName(item)
      if(item.rounds === 1) {
        label = ' - 1 '+this.renderSetsName(item)
      }
    }
    if(item.type === 5) {
      label = ' - '+HelperCal.getDurationMin(item.rounds)
      if(item.cycles > 1) {
        label = ' - '+item.cycles+' '+lang.t('program:label.rounds')+' - '+lang.t('program:label.cap')+': '+HelperCal.getDurationMin(item.rounds)
      }
    }
    return label
  }


  renderDayContent() {
    if(this.state.dsessions.length === 0) {
      return (
        <div className="empty small">
          {HelperEmpty.renderImage('graph')}
          <h4>{lang.t('modals:beep.text1')}<br/>{lang.t('modals:beep.text2')} {Moment(this.state.day, 'DD/MM/YYYY').locale(lang.language).format('D MMM YYYY')}</h4>
        </div>
      )
    } else {
      var list = this.state.dsessions
      return (
        <div className="clear">
          {list.map(item => (
            <div key={item.id} className="mb-20">
              <h4 className="mb-10">{item.name}</h4>
              <p><strong>{lang.t(global.proTypes[item.type])} {this.renderBlockType(item.block.data)}</strong><br/>{item.weight} {item.weightType !== 'per' ? item.weightType : (global.spaceLbs ? 'lb' : 'kg')}<br/>{item.reps} reps<br/></p>
              <div className="clear"></div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderModalDay() {
    if(this.state.showModalDay) {
      return (
        <div className={'overlay '+this.state.hiddenDay}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{Moment(this.state.day, 'DD/MM/YYYY').locale(lang.language).format('D MMM YYYY')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalDay()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            {this.renderDayContent()}
          </div>
        </div>
      )
    }
  }



  // Beep modal ------------------------------------------------------------



  showModalBeep() {
    this.setState({
      showModalBeep: true,
    })
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hiddenBeep: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModalBeep() {
    const height = this.divElement.clientHeight
    this.setState({
      hiddenBeep: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({showModalBeep: false});
    }, 500);
  }


  renderModalBeep() {
    if(this.state.showModalBeep) {
      return (
        <div className={'overlay '+this.state.hiddenBeep}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="mb-20 lft">{lang.t('modals:beep.title')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModalBeep()}>{lang.t('common:button.close')}</button>
            <div className="clear"></div>
            <div className="col-5">
              <select value={this.state.beep1} onChange={event => this.updateBeep(0, event)}>
                {this.state.beep.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="col-2" style={{textAlign: 'center', paddingTop: '4px', fontSize: '24px'}}>:</div>
            <div className="col-5">
              <select value={this.state.beep2} onChange={event => this.updateBeep(1, event)}>
                {this.state.beep.map(item => (
                  <option key={item} value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="sv-40 clear"></div>
            <button className="btn primary" onClick={() => this.saveBeep()}>{lang.t('common:button.savechanges')}</button>
          </div>
        </div>
      )
    }
  }


  updateBeep(num, event) {
    var tmp1 = this.state.beep1
    var tmp2 = this.state.beep2
    if(num === 0) {
      tmp1 = event.target.value
    } else {
      tmp2 = event.target.value
    }
    this.setState({
      beep1: tmp1,
      beep2: tmp2
    })
  }


  saveBeep() {
    this.hideModalBeep()
    Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
      beep: this.state.beep1+':'+this.state.beep2
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.beeptestupdated'));
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }



  // Display stuff ------------------------------------------------------------



  renderGraph() {
    if(this.state.dataLeft[0].id === 'japan') {
      return (
        <p className="empty">{lang.t('clients:empty.nodata')}</p>
      )
    } else if(this.state.dataLeft[0].data.length === 0 && this.state.dataLeft[0].id !== 'japan') {
      return (
        <div className="empty small">
          {HelperEmpty.renderImage('graph')}
          <h4>{lang.t('clients:empty.nodata.title')}</h4>
          <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nodata.text1')}</p>
        </div>
      )
    } else if(this.state.dataLeft[0].data.length > 0 && this.state.dataLeft[0].id !== 'japan') {
      return (
        <WidgetChartGraph data={this.state.dataLeft} curve='linear' axis=''/>
      )
    }
  }


  renderGraphEnergy() {
    return <WidgetChartBar data={this.state.dataEnergy[0].data}/>
  }


  configureWeightId(item) {
    var label = item.id
    if(item.data.tool === 6 || item.data.tool === 7) {
      var ar = item.id.split('|')
      if(ar.length > 1) {
        label = ar[0]
      }
    }
    return label
  }


  renderHeaviest() {
    if(this.state.cbest !== null) {
      if(this.state.cbest.data.actual !== undefined && this.state.cbest.data.percent !== undefined) {
        if( this.state.cbest.data.percent === 0) {
          return (
            <div className="info large col-4">
              <label>{lang.t('clients:label.heaviestweightdone')}</label>
              <p>{this.state.cbest.data.actual.toFixed(1)}{this.renderExType(this.state.cbest.data.type)}</p>
              <span style={{fontSize: 11}}>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')}</span>
            </div>
          )
        } else {
          return (
            <div className="info large col-4">
              <label>{lang.t('clients:label.heaviestweightdone')}</label>
              <p>{this.state.cbest.data.actual.toFixed(1)}{this.renderExType(this.state.cbest.data.type)} at {this.state.cbest.data.percent}%</p>
              <span style={{fontSize: 11}}>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')}</span>
            </div>
          )
        }
      } else {
        return (
          <div className="info large col-4">
            <label>{lang.t('clients:label.heaviestweightdone')}</label>
            <p>{this.state.cbest.data.value.toFixed(1)}{this.renderExType(this.state.cbest.data.type)}</p>
            <span style={{fontSize: 11}}>{Moment(this.state.cbest.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('ddd, D MMM YYYY')}</span>
          </div>
        )
      }
    } else {
      return (
        <div className="info large col-4">
          <label>{lang.t('clients:label.heaviestweightdone')}</label>
          <p>-</p>
        </div>
      )
    }
  }


  renderExType(type) {
    var label = "kg"
    if(type !== undefined) {
      label = type
    }
    if(type === 'per') {
      label = (global.spaceLbs ? 'lb' : 'kg')
    }
    return label
  }


  render1RM() {
    if(this.state.cbest !== null) {
      if(this.state.cbest.data.actual !== undefined && this.state.cbest.data.percent !== undefined) {
        if( this.state.cbest.data.percent === 0) {
          return (
            <p>{this.state.cbest.data.actual.toFixed(1)}<span>{this.renderExType(this.state.cbest.data.type)}</span></p>
          )
        } else {
          return (
            <p>{this.state.cbest.data.value.toFixed(1)}<span>{this.renderExType(this.state.cbest.data.type)}</span></p>
          )
        }
      } else {
        return (
          <p>-</p>
        )
      }
    } else {
      return (
        <p>-</p>
      )
    }
  }


  renderWeights() {
    if(this.state.best.length === 0) {
      return (
        <div>
          <h3 className="mb-20">{lang.t('clients:label.weights')}</h3>
          <div className="chart clear">
            <div className="empty small">
              {HelperEmpty.renderImage('graph')}
              <h4>{lang.t('clients:empty.nodata.title')}</h4>
              <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nodata.text1')}</p>
            </div>
          </div>
        </div>
      )
    } else {
      var list = this.state.best
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="col-8">
            <h3 className="mt-5 mb-20 lft">{lang.t('header:title.progress')}</h3>
            <div className="rgt">
              <div style={{marginTop: 15}} onClick={() => this.toggleGraph('weight')} className={this.state.showWeight ? 'checkbox mr-20 lft active' : 'checkbox lft mr-20'}>
                <div className="check mr-10">
                  <div className="inner"></div>
                </div>
                <p>{lang.t('clients:label.weight')}</p>
              </div>
              <div style={{marginTop: 15}} onClick={() => this.toggleGraph('reps')} className={this.state.showReps ?  'checkbox mt-5 lft mr-20 active' : 'checkbox lft mr-20'}>
                <div className="check mr-10">
                  <div className="inner"></div>
                </div>
                <p>{lang.t('clients:label.reps')}</p>
              </div>
            </div>
          </div>
          <div className="col-4 rgt">
          <select className="light rgt" value={this.state.currentLeft} onChange={event => this.updateLeft(event)}>
            {list.map(item => (
              <option key={item.id} value={this.configureWeightId(item)}>{this.configureBestName(item)}</option>
            ))}
          </select>
          </div>
          <div className="chart col-8 clear mb-40">
            {this.renderGraph()}
          </div>
          <div className="col-4">
            {this.renderCalendar()}
          </div>
          <div className="sv-20 clear"></div>
          {this.renderHeaviest()}
          <div className="info large col-4">
            <label>{lang.t('clients:label.calculated1rm')}</label>
            {this.render1RM()}
            <span style={{fontSize: 11}}>{lang.t('clients:label.basedonheaviestweight')}</span>
          </div>
          <div className="info large col-4">
            <label>{lang.t('clients:label.lasttimedone')}</label>
            <p>{this.configureStats('lastw')}</p>
            <span style={{fontSize: 11}}>{this.configureStats('lastd')}</span>
          </div>
          <div className="sv-20 clear"></div>
          <div className="info large col-4 clear">
            <label>{lang.t('clients:label.averageweight')}</label>
            <p>{this.configureStats('avg')}</p>
          </div>
          <div className="info large col-4">
            <label>{lang.t('clients:label.averagerepsblock')}</label>
            <p>{this.configureStats('reps')}</p>
          </div>
          <div className="clear"></div>
        </div>
      )
    }
    
  }


  renderEnergy() {
    if(this.state.item !== null) {
      if(this.state.dataEnergy[0].data.length === 0 || this.state.dataEnergy[0].id === 'japan') {
        return (
          <div className="chart clear">
            <div className="empty small">
              {HelperEmpty.renderImage('graph')}
              <h4>{lang.t('clients:empty.nodata.title')}</h4>
              <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nodata.text2')}</p>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="chart clear">
              {this.renderGraphEnergy()}
            </div>
            <div className="sv-40"></div>
            <div className="col-6">
              <div className="info">
                <label>{lang.t('clients:label.avgbefore')}</label>
                <p>{this.state.energy1}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="info">
                <label>{lang.t('clients:label.avgafter')}</label>
                <p>{this.state.energy2}</p>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    } else {
      return (
        <div className="mb-50 clear">
          <div className="empty small">
            {HelperEmpty.renderImage('graph')}
            <h4>{lang.t('clients:empty.nodata.title')}</h4>
            <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nodata.text2')}</p>
          </div>
        </div>
      )
    }
  }


  renderBeepText() {
    if(this.state.item !== null) {
      if(this.state.item.data.beep === undefined) {
        return '-'
      } else {
        return this.state.item.data.beep
      }
    } else {
      return '-'
    }
  }


  renderBeepButton() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <button className="btn tertiary edit rgt small" onClick={() => this.showModalBeep()}>{lang.t('common:button.edit')}</button>
      )
    }
  }


  getDayClass(item) {
    var label = 'weekday interactive pending lft'
    if(this.state.days.indexOf(item) !== -1) {
      label = 'weekday interactive active lft'
    }
    return label
  }


  renderCalendar() {
    var d1 = Moment('01/'+this.state.month, 'DD/MM/YYYY').format('d')
    var d2 = Moment('01/'+this.state.month, 'DD/MM/YYYY').endOf('month').format('D')
    var list1 = []
    var list2 = []
    if(parseInt(d1) === 0) {
      d1 = 7
    }
    for(var i1=1; i1<parseInt(d1); i1++) {
      list1.push('')
    }
    for(var i2=1; i2<(parseInt(d2)+1); i2++) {
      list2.push(Moment(i2+'/'+this.state.month, 'D/MM/YYYY').format('DD/MM/YYYY'))
    }
    return (
      <div className="calmini">
        <div className="calmini-header">
          <div className="arrow lft" onClick={() => this.setMonth('prev')}>‹</div>
          <h3 className="lft mb-20">{Moment('01/'+this.state.month, 'DD/MM/YYYY').locale(lang.language).format('MMMM YYYY')}</h3>
          <div className="arrow lft" onClick={() => this.setMonth('next')}>›</div>
          <div className="clear"></div>
        </div>
        {list1.map(item => (
          <div>
            <div className="weekday lft" key={item}></div>
          </div>
        ))}
        {list2.map((item,index) => (
          <div>
            <div className={this.getDayClass(item)} key={item} onClick={() => this.showModalDay(item)}>{index+1}</div>
          </div>
        ))}
      </div>
    )
  }


  renderBenchmark() {
    if(global.maxClients === 0) {
      if(this.state.benchmark.length > 0) {
        var list = this.state.benchmark
        list.sort((a, b) => a.data.name.localeCompare(b.data.name))
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <ListHeader id='benchmark' number={this.state.benchmark.length}/>
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <div className="box list simple highlight mb-10" key={item.id}>
                <Link to={'/clients/'+this.state.id+'/benchmark/'+item.id}>
                  <div className="col-7 col-12-768">
                    <div className={'icon lft '+HelperCal.getColor(item.data.time)}>
                      <p>{item.data.time}'</p>
                    </div>
                    <p className="lft"><strong>{item.data.name}</strong></p>
                    <div className="clear"></div>
                  </div>
                  <div className="col-3">
                    <p>{HelperCal.getDuration(item.data.time)}</p>
                  </div>
                  <div className="col-2">
                    <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                  </div>
                  <div className="clear"></div>
                </Link>
              </div>
            ))}
          </div>
        )
      } else {
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <h3>{lang.t('header:title.benchmark')}</h3>
              <div className="clear"></div>
            </div>
            <ListEmpty id='nobenchmark' type='simple'/>
          </div>
        )
      }
    } else {
      if(this.state.benchmark.length > 0) {
        return (
          <div className="col-12 clear">
            <div className="listheader">
              <h3>{lang.t('header:title.benchmark')}</h3>
              <div className="clear"></div>
            </div>
            <ListEmpty id='nobenchmark2' type='simple'/>
          </div>
        )
      }
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          {HelperClients.getFamily(this.state.item)}
          <div className="col-9">
            {this.renderWeights()}
          </div>
          <div className="col-3">
            <div className="mb-50">
              <h3 className="mb-20 mt-5 lft">{lang.t('header:title.energy')}</h3>
              <select className="small light rgt" style={{width:120}} onChange={event => this.setDateRange(event)} value={this.state.dateRange}>
                <option value='7days'>{lang.t('nav:stats.7days')}</option>
                <option value='30days'>{lang.t('nav:stats.30days')}</option>
                <option value='month'>{lang.t('nav:stats.month')}</option>
                <option value='60days'>{lang.t('nav:stats.60days')}</option>
              </select>
              {this.renderEnergy()}
              <div className="clear"></div>
            </div>
            <div className="clear mb-50">
              <h3 className="mb-20">{lang.t('clients:label.beeptest')}</h3>
              <div className="clear info">
                <label>{lang.t('clients:label.beeptest')}{this.renderBeepButton()}</label>
                <p>{this.renderBeepText()}</p>
              </div>
            </div>
          </div>
          {this.renderBenchmark()}
          <div className="sv-40 clear"></div>
        </div>
        <Header client={this.state.item} link={global.currentPage} active='progress'/>
        <Navigation active='clients' />
        {this.renderModalBeep()}
        {this.renderModalDay()}
      </div>
      
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','program','common','nav','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsProgressPage)));