import React, { Component } from 'react';
import Connector from '../../data/Connector';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';


class AccountBadgesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      badges: global.userBadges,
    };
  }


  componentDidMount() {
    document.title = 'Achievements - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    Connector.setUser()
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('updateBadges', (event) => this.configureData())
    this.configureData()
  }


  configureData() { 
    this.setState({
      badges: global.userBadges
    })
  }



  // Display stuff ------------------------------------------------------------



  renderBox1() {
    return (
      <div className="box achievements mt-10">
        <h3 className="mb-20">{lang.t('account:badge.spark.title')}</h3>
        <p className="clear mb-40">{lang.t('account:badge.spark.text')}</p>
        <div className="col-2 achievement">
          <img src="/img/badges/account.svg" alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.spark.title1')}</h4>
          <p>{lang.t('account:badge.spark.text1')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-client') !== -1 ? '/img/badges/create-client.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.spark.title2')}</h4>
          <p>{lang.t('account:badge.spark.text2')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-import') !== -1 ? '/img/badges/import-clients.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.spark.title3')}</h4>
          <p>{lang.t('account:badge.spark.text3')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-session') !== -1 ? '/img/badges/first-session.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.spark.title4')}</h4>
          <p>{lang.t('account:badge.spark.text4')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l1-program') !== -1 ? '/img/badges/create-program.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.spark.title5')}</h4>
          <p>{lang.t('account:badge.spark.text5')}</p>
        </div>
        <div className="col-2 achievement">
          <img src="/img/badges/portal.svg" alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.spark.title6')}</h4>
          <p>{lang.t('account:badge.spark.text6')}</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderBox2() {
    return (
      <div className="box achievements">
        <h3 className="mb-20">{lang.t('account:badge.basics.title')}</h3>
        <p className="clear mb-40">{lang.t('account:badge.basics.text')}</p>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-billing') !== -1 ? '/img/badges/enable-billing.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.basics.title1')}</h4>
          <p>{lang.t('account:badge.basics.text1')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-attendance') !== -1 ? '/img/badges/run-session.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.basics.title2')}</h4>
          <p>{lang.t('account:badge.basics.text2')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-product') !== -1 ? '/img/badges/create-product.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.basics.title3')}</h4>
          <p>{lang.t('account:badge.basics.text3')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-payment') !== -1 ? '/img/badges/payment.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.basics.title4')}</h4>
          <p>{lang.t('account:badge.basics.text4')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-report') !== -1 ? '/img/badges/create-report.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.basics.title5')}</h4>
          <p>{lang.t('account:badge.basics.text5')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l2-nutrition') !== -1 ? '/img/badges/enable-nutrition.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.basics.title6')}</h4>
          <p>{lang.t('account:badge.basics.text6')}</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderBox3() {
    return (
      <div className="box achievements">
        <h3 className="mb-20">{lang.t('account:badge.unlimited.title')}</h3>
        <p className="clear mb-40">{lang.t('account:badge.unlimited.text')}</p>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-exercise') !== -1 ? '/img/badges/exercise.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.unlimited.title1')}</h4>
          <p>{lang.t('account:badge.unlimited.text1')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-plan') !== -1 ? '/img/badges/plan.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.unlimited.title2')}</h4>
          <p>{lang.t('account:badge.unlimited.text2')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-sessions') !== -1 ? '/img/badges/sessions-100.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.unlimited.title3')}</h4>
          <p>{lang.t('account:badge.unlimited.text3')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-programs') !== -1 ? '/img/badges/programs-10.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.unlimited.title4')}</h4>
          <p>{lang.t('account:badge.unlimited.text4')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-run') !== -1 ? '/img/badges/run-sessions-20.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.unlimited.title5')}</h4>
          <p>{lang.t('account:badge.unlimited.text5')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l3-nutrition') !== -1 ? '/img/badges/nutrition-10.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.unlimited.title6')}</h4>
          <p>{lang.t('account:badge.unlimited.text6')}</p>
        </div>
        
        <div className="clear"></div>
      </div>
    )
  }


  renderBox4() {
    return (
      <div className="box achievements">
        <h3 className="mb-20">{lang.t('account:badge.pro.title')}</h3>
        <p className="clear mb-40">{lang.t('account:badge.pro.text')}</p>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-sessions') !== -1 ? '/img/badges/sessions-500.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.pro.title1')}</h4>
          <p>{lang.t('account:badge.pro.text1')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-payments') !== -1 ? '/img/badges/clients-charged-500.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.pro.title2')}</h4>
          <p>{lang.t('account:badge.pro.text2')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-programs') !== -1 ? '/img/badges/programs-60.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.pro.title3')}</h4>
          <p>{lang.t('account:badge.pro.text3')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-plans') !== -1 ? '/img/badges/plans-20.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.pro.title4')}</h4>
          <p>{lang.t('account:badge.pro.text4')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-run') !== -1 ? '/img/badges/run-sessions-200.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.pro.title5')}</h4>
          <p>{lang.t('account:badge.pro.text5')}</p>
        </div>
        <div className="col-2 achievement">
          <img src={this.state.badges.indexOf('l4-public') !== -1 ? '/img/badges/public.svg' : '/img/badges/empty.svg'} alt="badge" className="mb-20"/>
          <h4 className="mb-20">{lang.t('account:badge.pro.title6')}</h4>
          <p>{lang.t('account:badge.pro.text6')}</p>
        </div>
        <div className="clear"></div>
      </div>
    )
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          {this.renderBox1()}
          <div className="sv-30"></div>
          {this.renderBox2()}
          <div className="sv-30"></div>
          {this.renderBox3()}
          <div className="sv-30"></div>
          {this.renderBox4()}
          <div className="sv-30"></div>
        </div>
        <Header type='account' active='badges'/>
        <Navigation active='' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['account'])(withRouter(withAuthorization(condition)(AccountBadgesPage)));