import React, { Component } from 'react';
import { ResponsivePie } from '@nivo/pie';



class WidgetChartPie extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      colors: props.colors ?? global.themeColors,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      data: props.data ?? [
        {
          "id": "japan",
          "color": "#1DC5C9",
          "data": []
        },
      ],
      colors: props.colors ?? global.themeColors,
    }
  }


  renderLight() {
    return (
      <ResponsivePie
        data={this.state.data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        colors={this.state.colors}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#323439"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#000"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={{
          tooltip: {
            container: {
              background: "#ffffff",
              fontSize: 12
            },
          },
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            translateY: 56,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999C9F',
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000'
                }
              }
            ]
          }
        ]}
      />
    )
  }


  renderDark() {
    return (
      <ResponsivePie
        data={this.state.data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={0.6}
        padAngle={0.7}
        cornerRadius={3}
        colors={this.state.colors}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#dee0e2"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#000"
        arcLinkLabelsTextColor="#999C9F"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        theme={{
          tooltip: {
            container: {
              background: "#000011",
              fontSize: 12
            },
          },
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            translateY: 56,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#545a5f',
            symbolSize: 18,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#fff'
                }
              }
            ]
          }
        ]}
      />
    )
  }


  render() {
    return (
      <div className="chart">
        <div className="show-light chart">
          {this.renderLight()}
        </div>
        <div className="show-dark chart solid">
          {this.renderDark()}
        </div>
      </div>
    )
  }
}


export default WidgetChartPie;