import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListSimple from '../../components/List/simple';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ExercisesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exercises: global.exercises,
      exercisesList: [],
      search: '',
      maxPrograms: global.maxPrograms,
      tools: [],
      toolList: 0,
      showModalMovement: false
    };
  }


  componentDidMount() {
    HelperPage.initialize('Movements - PT Mate', ROUTES.EXERCISES)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.configureList('')
      this.setState({
        exercises: global.exercises,
        maxPrograms: global.maxPrograms
      })
    }
  }



  // Interactive stuff ------------------------------------------------------------



  filterExercises = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList(this.state.search)
    });
  }


  selectTool(items, list) {
    this.setState({
      tools: items,
      toolList: list
    }, () => {
      this.configureList(this.state.search)
    })
  }


  configureList(value) {
    var tmp = []
    if(value === '') {
      for(var item of this.state.exercises) {
        for(var i=0; i<item.data.subTitles.length; i++) {
          if(this.state.tools.indexOf(item.data.subTools[i]) !== -1 || this.state.tools.length === 0) {
            tmp.push({
              id: item.data.subIds[i],
              name: item.data.subTitles[i],
              tool: item.data.subTools[i],
              image: item.data.subImages[i],
              video: item.data.subVideos[i],
              parent: item.data.name,
              cat: item.data.cat,
              type: item.data.type
            })
          }
        }
      }
      this.setState({
        exercisesList: tmp
      })
    } else {
      var str = value.toLowerCase()
      for(var item2 of this.state.exercises) {
        for(var i2=0; i2<item2.data.subTitles.length; i2++) {
          if(item2.data.subTitles[i2].toLowerCase().indexOf(str) !== -1) {
            if(this.state.tools.indexOf(item2.data.subTools[i2]) !== -1 || this.state.tools.length === 0) {
              tmp.push({
                id: item2.data.subIds[i2],
                name: item2.data.subTitles[i2],
                tool: item2.data.subTools[i2],
                image: item2.data.subImages[i2],
                video: item2.data.subVideos[i2],
                parent: item2.data.name,
                cat: item2.data.cat,
                type: item2.data.type
              })
            }
          }
        }
      }
      this.setState({
        exercisesList: tmp
      })
    }
  }



  // Modal ------------------------------------------------------------



  hideModal() {
    this.setState({
      showModalMovement: false
    })
  }


  modalClick() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }



  // Display stuff ------------------------------------------------------------



  renderExercises() {
    var list = this.state.exercisesList
    list.sort((a, b) => a.name.localeCompare(b.name))
    return (
      <div>
        {list.map(item => (
          <ListSimple
            key={item.id}
            link={'/programming/exercise/'+item.id}
            columns={[{text: item.name, width: 6}, {text: lang.t(global.exTools[item.tool]), width: 4}]}
          />
        ))}
      </div>
    )
  }
  
  
  renderNewExercise() {
    if(this.state.maxPrograms === 0 || global.userSubStatus === 'trialing') {
      return (
        <Link to={ROUTES.NEWEXERCISE} className="btn tertiary add">{lang.t('program:button.createamovement')}</Link>
      )
    } else {
      return (
        <div className="btn tertiary add" onClick={() => this.setState({showModalMovement: true})}>{lang.t('program:button.createamovement')}</div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              <div className="listheader">
                <h3 className="mt-5 lft">{lang.t('header:title.movements')}</h3>
                <input type="text" className="search light large rgt" placeholder={lang.t('program:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterExercises(val)} />
                <div className="clear"></div>
              </div>
              <div className="col-2 hide-992">
                <div className={this.state.toolList === 0 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([], 0)}>{lang.t('program:tool.any')}</div>
                <div className={this.state.toolList === 1 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([19, 20, 21], 1)}>{lang.t('program:tool.accessories')}</div>
                <div className={this.state.toolList === 2 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([3], 2)}>{lang.t('program:tool.barbell')}</div>
                <div className={this.state.toolList === 3 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([22], 3)}>{lang.t('program:tool.battleropes')}</div>
                <div className={this.state.toolList === 4 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([0], 4)}>{lang.t('program:tool.bodyweight')}</div>
                <div className={this.state.toolList === 5 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([4, 5], 5)}>{lang.t('program:tool.dumbbells')}</div>
                <div className={this.state.toolList === 6 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([6, 7], 6)}>{lang.t('program:tool.cardio')}</div>
                <div className={this.state.toolList === 7 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([12, 13, 14], 7)}>{lang.t('program:tool.exerciseball')}</div>
                <div className={this.state.toolList === 8 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([1, 2], 8)}>{lang.t('program:tool.kettlebells')}</div>
                <div className={this.state.toolList === 9 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([18], 9)}>{lang.t('program:tool.rings')}</div>
                <div className={this.state.toolList === 10 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([8], 10)}>{lang.t('program:tool.pinloadedcables')}</div>
                <div className={this.state.toolList === 11 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([23], 11)}>{lang.t('program:tool.plyobox')}</div>
                <div className={this.state.toolList === 12 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([16], 12)}>{lang.t('program:tool.resistanceband')}</div>
                <div className={this.state.toolList === 13 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([17], 13)}>{lang.t('program:tool.rig')}</div>
                <div className={this.state.toolList === 14 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([9], 14)}>{lang.t('program:tool.skippingrope')}</div>
                <div className={this.state.toolList === 15 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([24, 25], 15)}>{lang.t('program:tool.strongman')}</div>
                <div className={this.state.toolList === 16 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([15], 16)}>{lang.t('program:tool.suspensiontrainer')}</div>
                <div className={this.state.toolList === 17 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([10], 17)}>{lang.t('program:tool.weightplate')}</div>
                <div className={this.state.toolList === 18 ? 'list bare active' : 'list bare'} onClick={() => this.selectTool([11], 18)}>{lang.t('program:tool.other')}</div>
              </div>
              <div className="col-10">
                {this.renderExercises()}
              </div>
              <div className="clear sv-20"></div>
              {this.renderNewExercise()}
              <div className="sv-40"></div>
            </div>
            <Header type='programming' active='movements'/>
            <Navigation active='programming' />
            <ModalMessageSimple type='movement' show={this.state.showModalMovement} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()}/>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['program','common','header'])(withRouter(withAuthorization(condition)(ExercisesPage)));