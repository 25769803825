import React, { Component } from 'react';
import HelperCal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import ListEmptySearch from '../../components/List/empty-search';
import ListTraining from '../../components/List/training';
import ModalMessageSimple from '../../components/Modals/message-simple';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ProgrammingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      programs: global.programs,
      programsList: global.programs,
      benchList: global.programs,
      exercises: global.exercises,
      show: 'all',
      search: '',
      maxPrograms: global.maxPrograms,
      showModalMessage: false,
      showModalMovement: false,
      showModalBenchmark: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Programs - PT Mate', ROUTES.PROGRAMMING)
    HelperPage.checkMessage()
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('programsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var tmp1 = []
      var tmp2 = []
      for(var item of global.programs) {
        if(item.data.benchmark === true) {
          tmp2.push(item)
        } else {
          tmp1.push(item)
        }
      }
      this.setState({
        clients: global.clients,
        programs: global.programs,
        exercises: global.exercises,
        programsList: tmp1,
        benchList: tmp2,
        maxPrograms: global.maxPrograms
      })
    }
  }


  getBlocks() {
    var total = 0
    var count = 0
    for(var item of this.state.programs) {
      total += item.blocks.length
    }
    if(total > 0) {
      count = total/this.state.programs.length
    }
    return count.toFixed(0)
  }


  getTime() {
    var total = 0
    var count = 0
    for(var item of this.state.programs) {
      total += item.data.time
    }
    if(total > 0) {
      count = total/this.state.programs.length
    }
    return count.toFixed(0)
  }



  // Interactive stuff ------------------------------------------------------------



  filterPrograms = event => {
    this.setState({
      search: event.target.value
    }, () => {
      this.configureList()
    });
  }


  configureList() {
    var tmp = []
    for(var item of global.programs) {
      if((item.data.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1 || this.state.search === '') && item.data.benchmark !== true) {
        tmp.push(item)
      }
    }
    this.setState({
      programsList: tmp
    })
  }


  checkBenchmark() {
    if(global.maxPrograms === 0) {
      this.newBenchmark()
    } else {
      this.setState({
        showModalBenchmark: true
      })
    }
  }


  newBenchmark() {
    if(global.maxClients === 0) {
      global.benchmark = true
      this.props.history.push('/programming/new-program')
    } else {
      this.showOverlayMessage('benchmark')
    }
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalMessage: false,
      showModalBenchmark: false,
      showModalMovement: false
    })
  }


  modalClick() {
    this.hideModals()
    this.props.history.push(ROUTES.SUBSCRIPTION)
  }



  // Display stuff ------------------------------------------------------------



  renderMaxMessage() {
    if(global.maxPrograms !== 0) {
      var plan = lang.t('program:label.spark')
      for(var item of global.stripePlans) {
        if(item.type === global.userSubPlan) {
          plan = item.name
        }
      }
      return (
        <div className="clear">
          <div className="sv-40"></div>
          <p className="small center secondary">{lang.t('program:label.max1')} {plan} {lang.t('program:label.max2')} ({global.maxClients} {lang.t('program:label.max3')} {global.maxPrograms} {lang.t('program:label.max4')}).<br/><Link to={ROUTES.SUBSCRIPTION}>{lang.t('program:button.checkyoursubscriptionnow')}</Link></p>
        </div>
      )
    }
  }


  renderProgramsBenchmark() {
    if(this.state.benchList.length !== 0) {
      var list = this.state.benchList
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <ListHeader id='benchmark' number={this.state.benchList.length}/>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <ListTraining
              key={item.id}
              value={item.data.time}
              unit="'"
              color={HelperCal.getColor(item.data.time)}
              link={'/programming/program/'+item.id}
              columns={[{text: item.data.name, width: 5}, {text: HelperCal.getDuration(item.data.time), width: 2}, {text: item.blocks.length+(item.blocks.length === 1 ? ' '+lang.t('program:label.block') : ' '+lang.t('program:label.blocks')), width: 3}]}
            />
          ))}
          <div className="space-60"></div>
        </div>
      )
    }
  }


  renderPrograms() {
    if(this.state.programs.length === 0) {
      return (
        <ListEmpty id='programs'/>
      )
    } else {
      var list = this.state.programsList
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      if(list.length === 0) {
        return (
          <div>
            <div className="listheader">
              <div className="lft mt-5">
                <ListHeader id='programs' number={this.state.programsList.length}/>
              </div>
              <input type="text" className="light search large rgt" placeholder={lang.t('program:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterPrograms(val)} />
              <div className="clear"></div>
            </div>
            <ListEmptySearch id='programs'/>
            {this.renderMaxMessage()}
            <div className="sv-80 clear"></div>
          </div>
        )
      } else {
        return (
          <div>
            <div className="listheader">
              <div className="lft mt-5">
                <ListHeader id='programs' number={this.state.programsList.length}/>
              </div>
              <input type="text" className="light search large rgt" placeholder={lang.t('program:label.filterbyname')} value={this.state.search} onChange={(val) => this.filterPrograms(val)} />
              <div className="clear"></div>
            </div>
            {list.map(item => (
              <ListTraining
                key={item.id}
                value={item.data.time}
                unit="'"
                color={HelperCal.getColor(item.data.time)}
                link={'/programming/program/'+item.id}
                columns={[{text: item.data.name, width: 5}, {text: HelperCal.getDuration(item.data.time), width: 2}, {text: item.blocks.length+(item.blocks.length === 1 ? ' '+lang.t('program:label.block') : ' '+lang.t('program:label.blocks')), width: 3}]}
              />
            ))}
            <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.NEWPROGRAM)}>{lang.t('program:button.addanotherprogram')}</button>
            {this.renderMaxMessage()}
            <div className="sv-80 clear"></div>
          </div>
        )
      }
      
    }
  }


  renderBottom() {
    if(HelperBusiness.getRole('assistant,assistant2')) {
      return (
        <ListEmpty id='programs-locked' type='simple'/>
      )
    } else {
      return (
        <div className="col-12 clear">
          {this.renderProgramsBenchmark()}
          {this.renderPrograms()}
        </div>
      )
    }
  }


  renderOverview() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <div className="col-9">
          <WidgetOverview showSelect={false}
            item1={{id: 'progtime', value: this.getTime(), prev: ''}}
            item2={{id: 'progblocks', value: this.getBlocks(), prev: ''}}
            item3={{id: 'progtotal', value: this.state.programs.length, prev: ''}}
            item4={{id: '', value: ''}}
          />
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: 'newprogram', link: ROUTES.NEWPROGRAM}
    var item2 = {id: 'newbenchmark', link: ''}
    var item3 = {id: 'newmovement', link: ROUTES.NEWEXERCISE}
    if(HelperBusiness.getRoleIncl('')) {
      if(this.state.programs.length > global.maxPrograms-1 && global.maxPrograms !== 0) {
        item1 = {id: 'newprogram', link: ''}
      }
      if(this.state.maxPrograms !== 0 && global.userSubStatus !== 'trialing') {
        item3 = {id: 'newmovement', link: ''}
      }
      return (
        <WidgetActions
          item1={item1} clickItem1={() => this.setState({showModalMessage: true})}
          item2={item2} clickItem2={() => this.checkBenchmark()}
          item3={item3} clickItem3={() => this.setState({showModalMovement: true})}
        />
      )
    } else if(HelperBusiness.getRole('trainer')) {
      item2 = {id: 'newmovement', link: ROUTES.NEWEXERCISE}
      item3 = {id: '', link: ''}
      if(this.state.programs.length > this.state.maxPrograms-1 && this.state.maxPrograms !== 0) {
        item1 = {id: 'newprogram', link: ''}
      }
      return (
        <WidgetActions
          item1={item1} clickItem1={() => this.setState({showModalMessage: true})}
          item2={item2} clickItem2={() => this.setState({showModalMovement: true})}
          item3={item3}
        />
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content withtabs">
              {this.renderOverview()}
              {this.renderActions()}
              {this.renderBottom()}
            </div>
            <Header type='programming' active='programs'/>
            <Navigation active='programming' />
            <ModalMessageSimple type='programlimit' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()} var={global.maxPrograms}/>
            <ModalMessageSimple type='movement' show={this.state.showModalMovement} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()}/>
            <ModalMessageSimple type='benchmark' show={this.state.showModalBenchmark} onHide={() => this.hideModals()} clickMainButton={() => this.modalClick()}/>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['program','common','header'])(withRouter(withAuthorization(condition)(ProgrammingPage)));