import React, { Component } from 'react';



class InputNumber extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      label: props.label,
      value: props.value,
      placeholder: props.placeholder ?? '',
      info: props.info ?? '',
      error: props.error ?? false,
      errormsg: props.errormsg ?? ''
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      label: props.label,
      value: props.value,
      placeholder: props.placeholder ?? '',
      info: props.info ?? '',
      error: props.error ?? false,
      errormsg: props.errormsg ?? ''
    }
  }


  onChange = event => {
    this.props.onChange(event.target.value)
  }


  renderError() {
    if(this.state.error) {
      return (
        <p className="error">{this.state.errormsg}</p>
      )
    }
  }


  renderInfo() {
    if(this.state.info !== '') {
      return (
        <p className="info">{this.state.info}</p>
      )
    }
  }


  render() {
    return (
      <div className="form-item">
        <label>{this.state.label}</label>
        <input type="number" className={this.state.error ? 'error': ''} value={this.state.value} placeholder={this.state.placeholder} onChange={this.onChange}/>
        {this.renderError()}
        {this.renderInfo()}
      </div>
    )
  }
}


export default InputNumber;