import React, { Component } from 'react';
import Firebase from 'firebase';
import Connector from '../../data/Connector';

import Navigation from '../../components/Navigation';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class AdminNewExercisePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat: 0,
      type: 0,
      muscles: [],
      values: ['', ''],
      variations: [{
        name: '',
        desc: '',
        tool: 0,
        image: '',
        video: ''
      }],
      files: [null],
      error: false,
      errorText: ''
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'New Movement Type - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)

    global.currentClient = '';
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('exercisesLoaded', (event) => this.configureData())
    this.configureData()
  }


  configureData() {
    
  }


  setImage(file, index) {
    var tmp = this.state.files
    var tmp2 = this.state.variations
    tmp[index] = file
    tmp2[index].image = URL.createObjectURL(file)
    this.setState({
      files: tmp,
      variations: tmp2
    })
  }


  displayImage(index) {
    if(this.state.files[index] === null) {
      return (
       <div className="ex-image"></div>
      )
    } else {
      return (
        <div className="ex-image" style={{backgroundImage: 'url('+this.state.variations[index].image+')'}}></div>
      )
    }
  }



  // Interactive stuff ------------------------------------------------------------



  onChange = event => {
    var tmp = this.state.values
    tmp[parseInt(event.target.name)] = event.target.value
    this.setState({values: tmp});
  };


  setCat(event) {
    this.setState({
      cat: event.target.value
    })
  }


  setType(event) {
    this.setState({
      type: event.target.value
    })
  }


  addVariation() {
    var tmp = this.state.variations
    var tmp2 = this.state.files
    tmp.push({
      name: '',
      desc: '',
      tool: 0,
      image: '',
      video: '',
    })
    tmp2.push(null)
    this.setState({
      variations: tmp,
      files: tmp2,
    })
  }


  deleteVariation(index) {
    var tmp = this.state.variations
    var tmp2 = this.state.files
    tmp.splice(index, 1)
    tmp2.splice(index, 1)
    this.setState({
      variations: tmp,
      files: tmp2,
    })
  }


  onChangeName(event, index) {
    var tmp = this.state.variations
    tmp[index].name = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeDesc(event, index) {
    var tmp = this.state.variations
    tmp[index].desc = event.target.value
    this.setState({
      variations: tmp
    })
  }


  onChangeVideo(event, index) {
    var tmp = this.state.variations
    tmp[index].video = event.target.value
    this.setState({
      variations: tmp
    })
  }


  setTool(event, index) {
    var tmp = this.state.variations
    tmp[index].tool = event.target.value
    this.setState({
      variations: tmp
    })
  }


  getMuscles(index) {
    if(this.state.muscles.indexOf(index) !== -1) {
      return "ex-muscle active"
    } else {
      return "ex-muscle"
    }
  }


  setMuscles(index) {
    var add = true
    var tmp = this.state.muscles
    for(var i=tmp.length-1; i>=0; i--) {
      if(tmp[i] === index) {
        add = false
        tmp.splice(i, 1)
      }
    }
    if(add) {
      tmp.push(index)
    }
    this.setState({
      muscles: tmp
    })
  }



  // Create exercises ------------------------------------------------------------



  checkCreateExercise() {
    var passed = true
    if(this.state.values[0] === '' || this.state.values[1] === '' || this.state.muscles.length === 0) {
      passed = false
    }
    for(var item of this.state.variations) {
      if(item.name === '') {
        passed = false
      }
    }
    if(passed) {
      this.createExercise()
    } else {
      this.showError()
    }
  }


  createExercise() {
    global.badgeLocked = true
    var user = global.uid
    var ids = []
    var names = []
    var tools = []
    var descs = []
    var images = []
    var videos = []
    var obj = Firebase.database().ref('/exercises/').push()
    var msc = ""
    for(var m of this.state.muscles) {
      msc += ","+m
    }
    var mmm = [msc]
    for(let item of this.state.variations) {
      var img = item.image
      var key = Firebase.database().ref('/exercises/'+this.state.id).push()
      if(item.image !== '') {
        img = obj.key+'-'+key.key+'.jpg'
      }
      ids.push(key.key)
      //ids.push(Moment().format('DDMMYYYYHHmmssSSS')+c)
      names.push(item.name)
      tools.push(parseInt(item.tool))
      descs.push(item.desc)
      images.push(img)
      videos.push(item.video)
    }
    this.uploadImages(obj.key, ids)
    obj.set({
      name: this.state.values[0],
      desc: this.state.values[1],
      muscles: this.state.muscles,
      cat: parseInt(this.state.cat),
      type: parseInt(this.state.type),
      user: user,
      subIds: ids,
      subTitles: names,
      subDescs: descs,
      subTools: tools,
      subImages: images,
      subVideos: videos,
      subMuscles: mmm
    }).then((data)=>{
      global.message = 'Movement type successfully created'
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'exercise');
      }, 1000);
      this.props.history.push(ROUTES.EXERCISES)
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
  }


  showError() {
    var txt = ''
    if(this.state.values[0] === '') {
      txt = 'Movement type name'
    }
    if(this.state.values[1] === '') {
      if(txt !== '') { txt += ', ' }
      txt += 'Movement type description'
    }
    if(this.state.muscles.length === 0) {
      if(txt !== '') { txt += ', ' }
      txt += 'Select at least 1 muscle'
    }
    for(var item of this.state.variations) {
      if(item.name === '') {
        if(txt !== '') { txt += ', ' }
        txt += 'Name all your movements'
      }
    }
    this.setState({
      error: true,
      errorText: txt
    })
  }


  uploadImages(key, ids) {
    for(var i=0; i<ids.length; i++) {
      if(this.state.files[i] !== null) {
        const sr = Firebase.storage().ref()
        const fr = sr.child('images/exercises/'+key+'-'+ids[i]+'.jpg')
        fr.put(this.state.files[i]).then(() => {
          //
        }).catch((error)=>{
          EventEmitter.dispatch('showMessageError', error.message);
        })
      }
    } 
  }



  // Display stuff ------------------------------------------------------------



  renderError() {
    if(this.state.error) {
      return (
        <div className="inline-message red">
          <strong>Please review the following</strong><br/>{this.state.errorText}
        </div>
      )
    }
  }


  renderDelete(index) {
    if(index !== 0) {
      return (
        <button className="btn tertiary close rgt" onClick={() => this.deleteVariation(index)}>Delete</button>
      )
    }
  }


  renderExercises() {
    return (
      <div>
        {this.state.variations.map((item, index) => (
          <div key={index}>
            <div className="box exercise">
              <div className="col-3">
                {this.displayImage(index)}
                <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0], index);}}/>
              </div>
              <div className="col-4 form">
                <label>Name*</label>
                <input type="text" value={this.state.variations[index].name} onChange={(val) => this.onChangeName(val, index)}/>
                <label>Video</label>
                <input type="text" value={this.state.variations[index].video} onChange={(val) => this.onChangeVideo(val, index)}/>
                <label>Equipment</label>
                <select value={this.state.variations[index].tool} onChange={event => this.setTool(event, index)}>
                  {global.exTools.map((item, index) => (
                    <option key={index} value={index}>{item} ({global.exToolsCount[index]})</option>
                  ))}
                </select>
              </div>
              <div className="col-4 form">
                <label>Description</label>
                <textarea className="big" value={this.state.variations[index].desc} onChange={(val) => this.onChangeDesc(val, index)}></textarea>
              </div>
              <div className="col-1">
                {this.renderDelete(index)}
              </div>
              <div className="clear"></div>
            </div>
            <div className="space-20"></div>
          </div>
        ))}
      </div>
    )
  }


  renderBase() {
    var list1 = global.exCats
    var list2 = global.exTypes
    return (
      <div>
        <div className="content">
          {this.renderError()}
          <div className="box">
            <div className="col-6">
              <div className="form">
                <label>Name*</label>
                <input type="text" value={this.state.values[0]} name="0" onChange={this.onChange}/>
                <label>Description*</label>
                <input type="text" value={this.state.values[1]} name="1" onChange={this.onChange}/>
              </div>
            </div>
            <div className="col-6">
              <div className="form">
                <label>Category</label>
                <select value={this.state.cat} onChange={event => this.setCat(event)}>
                  {list1.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
                <label>Type</label>
                <select value={this.state.type} onChange={event => this.setType(event)}>
                  {list2.map((item, index) => (
                    <option key={index} value={index}>{item}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="clear space-20"></div>
            <div className="col-12">
              <label>Muscles*</label>
              <div className="ex-muscles clear">
                {global.exMuscles.map((item, index) => (
                  <div className={this.getMuscles(index)} key={index} onClick={() => this.setMuscles(index)}>
                    <img src={global.exMusclesImages[index]} alt={item}/>
                    <p>{item} </p>
                  </div>
                ))}
                <div className="clear"></div>
              </div>
            </div>
            <div className="clear"></div>
          </div>
          <div className="filters">
            <h2>Movements</h2>
            <div className="clear"> </div>
          </div>
          {this.renderExercises()}
          <div className="space-10"></div>
          <button className="btn tertiary add width-12" onClick={() => this.addVariation()}>Add movement</button>
          <div className="space-60"></div>
          <div className="width-6">
            <button className="btn primary" onClick={() => this.checkCreateExercise()}>Create movement type</button>
          </div>
          <div className="space-20"></div>
        </div>
        <div className="header">
          <Link to={'/admin/movements'} className="back"></Link>
          <h1>New Movement Type</h1>
        </div>
        <Navigation active='admin' />
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(AdminNewExercisePage));


