import React, { Component } from 'react';
import Firebase from 'firebase';
import 'firebase/functions';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import Resizer from "react-image-file-resizer";
import HelperBusiness from '../../helper/Business';
import HelperEmptyImg from '../../helper/EmptyLarge';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import InputText from '../../components/Form/input-text';
import InputNumber from '../../components/Form/input-number';
import InputDate from '../../components/Form/input-date';
import InputRadio from '../../components/Form/radio';
import InputToggle from '../../components/Form/toggle';
import InfoData from '../../components/Form/info';
import ModalCard from '../../components/Modals/card';
import ModalPassword from '../../components/Modals/password';
import Loader from '../../components/Form/loader';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: global.userName,
      email: global.userEmail,
      phone: global.userPhone,
      birth: global.userBday,
      image: global.userImage,
      values: [global.userName, global.userEmail, global.userPhone, Moment(global.userBday, 'DD/MM/YYYY').format('DD/MM/YYYY')],
      file: null,
      fileDisplay: null,
      error: [false, false, false, false],
      editing: false,
      bname: [],
      card: global.userCard,
      cards: global.cards,
      templates: [],
      stripe: global.userStripeConnect,
      userStripe: global.userStripe,
      loading: false,
      message: '',
      log: global.userLog,
      lbs: global.userLbs,
      reminder: global.userReminder,
      valueReminder: global.userSessionReminder,
      showModalCard: false,
      showModalPassword: false,
    };
  }


  componentDidMount() {
    HelperPage.initializeForm('Account - PT Mate')
    HelperPage.checkMessage()
    this._isMounted = true

    global.currentClient = '';
    global.currentPage = ROUTES.ACCOUNT;
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('logLoaded', (event) => this.configureData())
    this.configureData() 
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      var bnm = []
      if(global.userStripeAddress !== '' && global.userStripeAddress !== undefined) {
        bnm = global.userStripeAddress.split('||')
      }

      this.setState({
        name: global.userName,
        email: global.userEmail,
        phone: global.userPhone,
        birth: global.userBday,
        image: global.userImage,
        values: [global.userName, global.userEmail, global.userPhone, Moment(global.userBday, 'DD/MM/YYYY').format('DD/MM/YYYY')],
        bname: bnm,
        card: global.userCard,
        cards: global.cards,
        stripe: global.userStripeConnect,
        userStripe: global.userStripe,
        log: global.userLog,
        lbs: global.userLbs,
        reminder: global.userSessionReminder,
        valueReminder: global.userSessionReminder,
      });
      this.getImage()
      this.checkLog()
    }
  }


  getImage() {
    if(global.userImage === '') {
      var inits = ''
      let arr = global.userName.split(' ')
      if(arr.length > 1) {
        inits = arr[0].charAt(0)+arr[1].charAt(0)
      } else {
        inits = arr[0].charAt(0)
      }
      return (
        <div className="avatar">
          <p>{inits}</p>
        </div>
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+global.userImage+')'}}> </div>
      )
    }
  }


  checkLog() {
    for(var item of this.state.log) {
      if(item.data.title === 'cardnewerror' || item.data.title === 'carddeleteerror') {
        EventEmitter.dispatch('showMessageError', item.data.message);
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
        this.setState({
          loading: false,
          card: global.userCard,
          cards: global.cards,
        })
      }
      if(item.data.title === 'cardnew' || item.data.title === 'carddelete') {
        EventEmitter.dispatch('showMessage', this.state.message);
        Firebase.database().ref('/log/'+global.uidUser+'/'+item.id).remove()
        this.setState({
          loading: false,
          card: global.userCard,
          cards: global.cards,
        })
        Connector.loadUser((result) => {})
      }
    }
  }



  // Update user details ------------------------------------------------------------



  toggleEditing(show) {
    this.setState({
      editing: show,
      values: [global.userName, global.userEmail, global.userPhone, Moment(global.userBday, 'DD/MM/YYYY').format('DD/MM/YYYY')],
      lbs: global.userLbs,
      file: null,
      fileDisplay: null,
      valueReminder: this.state.reminder,
    });
  }


  updateValues(val, num) {
    var tmp = this.state.values
    tmp[num] = val
    this.setState({
      values: tmp
    });
  }


  setImage(file) {
    this.setState({
      file: file,
      fileDisplay:  URL.createObjectURL(file)
    })
  }


  displayImage() {
    if(this.state.file === null) {
      return (
        this.getImage()
      )
    } else {
      return (
        <div className="avatar" style={{backgroundImage: 'url('+this.state.fileDisplay+')'}}> </div>
      )
    }
  }


  checkUpdate() {
    this.setState({error: [false, false, false, false]})
    var tmp = [false, false, false, false]
    if(this.state.values[0] === '') { tmp[0] = true }
    if(this.state.values[1] === '') { tmp[1] = true }
    if(this.state.values[2] === '') { tmp[2] = true }
    if(this.state.values[3] === '') { tmp[3] = true }
    this.setState({error: tmp})
    var passed = true
    for(var item of this.state.values) {
      if(item === '') {
        passed = false
      }
    }
    if(passed) {
      this.updateDetails()
    }
  }


  updateDetails() {
    var user = Firebase.auth().currentUser
    if(this.state.values[0] !== this.state.name) {
      user.updateProfile({
        displayName: this.state.values[0],
      })
    }
    var ref = '/users/'
    if(global.userRole === '') {
      Firebase.database().ref('/spaces/'+global.uidUser).update({
        owner: this.state.values[0],
      })
    } else {
      ref = '/usersStaff/'
    }
    this.updateLbs()
    Firebase.database().ref(ref+global.uidUser).update({
      email: this.state.values[1],
      phone: this.state.values[2],
      name: this.state.values[0],
      birth: this.state.values[3],
      lbs: this.state.lbs,
      reminder: parseInt(this.state.valueReminder),
    }).then(() => {
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.detailsupdated'));
      this.setState({
        editing: false,
      });
    }).catch((error)=>{
      EventEmitter.dispatch('showMessageError', error.message);
    })
    if(this.state.file !== null) {
      const sr = Firebase.storage().ref()
      const fr = sr.child('images/users/'+global.uidUser+'.jpg')
      user.updateProfile({
        imageDate: Moment().format('DD/MM/YYYY HH:mm'),
        photoURL: 'images/users/'+global.uidUser+'.jpg'
      })
      Resizer.imageFileResizer(
        this.state.file,
        800,
        800,
        "JPEG",
        70,
        0,
        (uri) => {
          var ar = uri.split(',')
          fr.putString(ar[1], "base64", {contentType: 'image/jpg'}).then(() => {
            Firebase.database().ref('/users/'+global.uidUser).update({
              image: 'images/users/'+global.uidUser+'.jpg',
              imageDate: Moment().format('DD/MM/YYYY HH:mm')
            })
          }).catch((error)=>{
            EventEmitter.dispatch('showMessageError', error.message);
          })
        },
        "base64",
        200,
        200
      );
    }
  }


  updateLbs() {
    if(HelperBusiness.getRoleIncl('')) {
      Firebase.database().ref('users/'+global.uidUser).update({
        lbs: this.state.lbs
      })
      Firebase.database().ref('spaces/'+global.uidUser).update({
        lbs: this.state.lbs
      })
    }
  }


  getSubscription(type) {
    var label = '-'
    if(type === 'name') {
      if(global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        label = lang.t('account:label.subscribedto')+' '+lang.t('account:sub.plan.foundation')
      }
      if(global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') {
        label = lang.t('account:label.subscribedto')+' '+lang.t('account:sub.plan.basic')
      }
      if(global.userSubPlan === 'promonthly' || global.userSubPlan === 'proyearly') {
        label = lang.t('account:label.subscribedto')+' '+lang.t('account:sub.plan.pro')
      }
      if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly') {
        label = lang.t('account:label.subscribedto')+' '+lang.t('account:sub.plan.business')
      }
      if(global.userSubStatus === 'trialing') {
        label = lang.t('account:sub.plan.trial')
      }
    }
    if(type === 'date') {
      label = lang.t('account:label.nextpayment')+': '+global.prices[4]+' on '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      if(global.userSubPlan === 'foundmonthly' || global.userSubPlan === 'foundyearly') {
        label = lang.t('account:label.nextpayment')+': '+global.prices[0]+' on '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
      if(global.userSubPlan === 'basicmonthly' || global.userSubPlan === 'basicyearly') {
        label = lang.t('account:label.nextpayment')+': '+global.prices[2]+' on '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
      if(global.userSubPlan === 'businessmonthly' || global.userSubPlan === 'businessyearly') {
        label = lang.t('account:label.nextpayment')+': '+global.prices[6]+' on '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
      if(global.userSubPlan === 'spark') {
        label = lang.t('account:label.nextpayment')+': -'
      }
      if(global.userSubStatus === 'trialing') {
        label = lang.t('account:label.ends')+' '+Moment(global.userSubEnd, 'X').locale(lang.language).format('D MMMM YYYY')
      }
    }
    if(type === 'card') {
      label = lang.t('account:label.nopaymentmethod')
      if(global.cards.length > 0 && global.userCard !== '' && global.userCard !== undefined) {
        for(var item of global.cards) {
          if(item.id === global.userCard) {
            label = item.data.brand+' '+lang.t('account:label.ending')+' '+item.data.last4
          }
        }
      }
    }
    return label
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalCard: false,
      showModalPassword: false,
    })
  }


  createCard(values) {
    this.hideModals()
    this.setState({
      loading: true,
      message: lang.t('messaging:message.cardadded')
    })
    var current = ''
    if(this.state.card !== undefined && this.state.card !== '') {
      current = this.state.card
    }
    var callFunction = Firebase.functions().httpsCallable('replaceCard');
    callFunction({customer: this.state.userStripe, name: values[0], number: values[1], month: values[2], year: values[3], cvc: values[4], current: current}).then(function(result) {
      //var sanitizedMessage = result.data.text;
    });
  }


  deleteCard(card) {
    this.hideModals()
    this.setState({
      loading: true,
      message: lang.t('messaging:message.carddeleted'),
    })
    var callFunction = Firebase.functions().httpsCallable('deleteCard');
      callFunction({card: card, customer: this.state.userStripe, primary: 'remove'}).then(function(result) {
       //var sanitizedMessage = result.data.text;
    });
  }



  // Display stuff ------------------------------------------------------------



  renderReminder() {
    if(this.state.valueReminder === 99999) {
      return <InputToggle label={lang.t('account:label.receivesessionreminders')} value={this.state.valueReminder !== 99999 ? true : false} onChange={() => this.setState({valueReminder: 12})} text={lang.t('account:form.receive.info')}/>
    } else {
      return (
        <div>
          <InputToggle label={lang.t('account:label.receivesessionreminders')} value={this.state.valueReminder !== 99999 ? true : false} onChange={() => this.setState({valueReminder: 99999})} text={lang.t('account:form.receive.info')}/>
          <InputNumber label={lang.t('account:form.receive.label')} value={this.state.valueReminder} onChange={(event) => this.setState({valueReminder: event})}/>
        </div>
      )
    }
  }


  renderDetails() {
    if(this.state.editing) {
      return (
        <div>
          <div className="avatar-container clear">
            {this.displayImage()}
          </div>
          <div className="form-item" style={{textAlign: 'center'}}>
            <label>{lang.t('account:form.image')}</label>
            <input type="file" placeholder="Select an image" onChange={(e: any) => {this.setImage(e.target.files[0]);}}/>
          </div>
          <InfoData label={lang.t('account:form.emaillocked')} value={this.state.email} small={true}/>
          <InputText label={lang.t('account:label.name')+"*"} placeholder={lang.t('account:form.name.placeholder')} value={this.state.values[0]} error={this.state.error[0]} errormsg={lang.t('account:form.name.error')} onChange={(event) => this.updateValues(event, 0)}/>
          <InputNumber label={lang.t('account:label.mobile')+"*"} placeholder={lang.t('account:form.phone.placeholder')} value={this.state.values[2]} error={this.state.error[2]} errormsg={lang.t('account:form.phone.error')} onChange={(event) => this.updateValues(event, 2)}/>
          <InputDate label={lang.t('account:form.dob')+"*"} value={Moment(this.state.values[3], 'DD/MM/YYYY').format('YYYY-MM-DD')} format='YYYY-MM-DD' error={this.state.error[3]} errormsg={lang.t('account:form.dob.error')} onChange={(event) => this.updateValues(Moment(event).format('DD/MM/YYYY'), 3)}/>
          <InputRadio
            label={lang.t('account:label.units')}
            value={this.state.lbs}
            clickElement={(event) => this.setState({lbs: event})}
            values={[{name: 'kg/cm', value: false}, {name: 'lb/in', value: true}]}
            info={HelperBusiness.getRoleIncl('') ? lang.t('account:form.units.info') : ''}
          />
          {this.renderReminder()}
          <div className="clear sv-30"></div>
          <button className="btn primary" onClick={() => this.checkUpdate()}>{lang.t('account:button.updateyourdetails')}</button>
          <div className="sv-20"></div>
          <button className="btn tertiary close width-12" onClick={() => this.toggleEditing(false)}>{lang.t('common:button.cancel')}</button>
          <div className="sv-40"></div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="avatar-container clear">
            {this.getImage()}
          </div>
          <InfoData label={lang.t('account:label.name')} value={this.state.name}/>
          <InfoData label={lang.t('account:label.email')} value={this.state.email}/>
          <InfoData label={lang.t('account:label.mobile')} value={this.state.phone}/>
          <InfoData label={lang.t('account:label.receivesessionreminders')} value={this.state.reminder === 99999 ? lang.t('account:label.off') : this.state.reminder+'h '+lang.t('account:label.beforethesession')}/>
          <InfoData label={lang.t('account:label.units')} value={this.state.lbs ? 'lb/in' : 'kg/cm'}/>
          <div className="sv-20"></div>
          <button className="btn primary" onClick={() => this.toggleEditing(true)}>{lang.t('account:button.updatedetails')}</button>
          <div className="sv-20"></div>
          <button className="btn tertiary edit width-12" onClick={() => this.setState({showModalPassword: true})}>{lang.t('account:button.changepassword')}</button>
          <div className="sv-40"></div>
        </div>
      )
    }
  }


  renderCard() {
    if(HelperBusiness.getRoleIncl('assistant2,trainer2')) {
      var label = lang.t('account:label.nocardprovided')
      for(var item of global.cards) {
        if(item.id === this.state.card) {
          label = item.data.brand+' **** **** **** '+item.data.last4
        }
      }
      if((global.userSubPlanId.indexOf('basic') !== -1 || global.userSubPlanId.indexOf('unlimited') !== -1) && (this.state.card === '' || this.state.card === undefined)) {
        label = '- '+lang.t('account:label.addcardasap')+' -'
      }
      if(label === lang.t('account:label.nocardprovided')) {
        return (
          <div className="clear">
            <h4>{lang.t('account:label.paymentmethod')}</h4>
            <div className="sv-20"></div>
            <InfoData label={this.state.status === 'trialing' ? lang.t('account:label.freeaccess') : lang.t('account:label.cardtopayforsubscription')} value={label}/>
            <button className="btn tertiary width-12 edit" onClick={() => this.setState({showModalCard: true})}>{lang.t('account:button.addacard')}</button>
          </div>
        )
      } else {
        return (
          <div className="clear">
            <h4>{lang.t('account:label.paymentmethod')}</h4>
            <div className="sv-20"></div>
            <InfoData label={lang.t('account:label.cardtopayforsubscription')} value={label}/>
            <button className="btn tertiary width-12 edit" onClick={() => this.setState({showModalCard: true})}>{lang.t('account:button.updatepaymentmethod')}</button>
          </div>
        )
      }
    }
  }


  renderSubscription() {
    var uname = Firebase.auth().currentUser.displayName
    if(uname !== "staff") {
      return (
        <div className="infobox small mb-20">
          <h5>{lang.t('account:label.yourptmatesubscription')}</h5>
          <p>&nbsp;<br/>{this.getSubscription('name')}</p>
          <p>{this.getSubscription('date')}</p>
          <p>{this.getSubscription('card')}<br/>&nbsp;</p>
          <p><Link to={ROUTES.SUBSCRIPTION} className="btn tertiary small edit" style={{textDecoration: 'none'}}>{lang.t('account:button.managesubscription')}</Link></p>
        </div>
      )
    }
  }


  renderBusinessInfo() {
    if(HelperBusiness.getRoleIncl('')) {
      return (
        <div className="infobox primary large mb-20">
          <p>{lang.t('account:label.accountinfo')} <Link className="btn tertiary small rgt" to={ROUTES.ADMINSETTINGS}>{lang.t('account:button.businesssettings')}</Link></p>
        </div>
      )
    }
  }


  renderBase() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className={HelperBusiness.getRoleIncl('assistant2,trainer2') ? 'content form-sidebar withtabs' : 'content form-sidebar'}>
          {this.renderBusinessInfo()}
          <div className="col-6">
            <div className="box">
              {this.renderDetails()}
              <div className="clear sv-20"></div>
              {this.renderCard()}
            </div>
          </div>
          
          <div className="col-6 sidebar"> 
            <h2 className="mb-20">{lang.t('account:label.youraccount')}</h2>
            <div className="image empty large mb-30">
              <div className="illustration">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('user')}
              </div>
            </div>
            <p style={{marginBottom: 30, whiteSpace: 'pre-wrap'}}>{lang.t('account:label.youraccount.text')}</p>
            {this.renderSubscription()}
            <h3 className="mb-20">{lang.t('header:title.links')}</h3>
            <ul className="links">
              <li><a href="http://www.ptmate.net/terms-conditions" target="_blank" rel="noopener noreferrer">{lang.t('account:link.tcs')}</a></li>
              <li><a href="http://www.ptmate.net/privacy-policy" target="_blank" rel="noopener noreferrer">{lang.t('account:link.privacy')}</a></li>
              <li><a href="https://help.ptmate.net" target="_blank" rel="noopener noreferrer">{lang.t('account:link.helpcenter')}</a></li>
              <li><a href="https://apps.apple.com/us/app/pt-mate/id1465347277?ls=1" target="_blank" rel="noopener noreferrer">{lang.t('account:link.downloadios')}</a></li>
              <li><a href="https://play.google.com/store/apps/details?id=net.ptmate.ptmate" target="_blank" rel="noopener noreferrer">{lang.t('account:link.downloadandroid')}</a></li>
            </ul>
          </div>
          <div className="clear sv-20"></div>
          <button className="btn tertiary small width-12" onClick={() => HelperPage.signOut()}>{lang.t('account:button.logout')}</button>
          <div className="clear sv-40"></div>
        </div>
        <Header active='details' title={lang.t('nav:main.account')} type={HelperBusiness.getRoleIncl('assistant2,trainer2') ? 'account' : 'simple'}/>     
        <Navigation active=''/>
        <ModalCard show={this.state.showModalCard} card={this.state.card} cards={this.state.cards} onHide={() => this.hideModals()} deleteCard={(event) => this.deleteCard(event)} clickMainButton={(event) => this.createCard(event)}/>
        <ModalPassword show={this.state.showModalPassword} onHide={() => this.hideModals()} clickMainButton={() => this.hideModals()}/>
        <Loader show={this.state.loading}/>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['account','common','header','nav','messaging'])(withRouter(withAuthorization(condition)(AccountPage)));