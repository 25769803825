import React, { Component } from 'react';
import Firebase from 'firebase';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import { gql } from "@apollo/client";
import HelperClients from '../../helper/Clients';
import HelperBusiness from '../../helper/Business';
import HelperCal from '../../helper/Calendar';
import HelperPro from '../../helper/Programming';
import HelperEmptyImg from '../../helper/EmptyLarge';
import HelperEmpty from '../../helper/Empty';
import * as ROUTES from '../../constants/routes';
import { Link, withRouter } from 'react-router-dom';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/clientdetails';
import ListHeader from '../../components/List/header';
import ListEmpty from '../../components/List/empty';
import InfoBar from '../../components/Form/info-bar';
import ModalMessageSimple from '../../components/Modals/message-simple';
import ModalForm from '../../components/Modals/form';
import WidgetChartGraph from '../../components/Widgets/chart-graph';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';





class ClientsHealthPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      item: null,
      name: 'Client',
      nutrition: null,
      nutritionWeek: null,
      nutritionMeal: null,
      showModal: false,
      hidden: 'hidden',
      showModalSettings: false,
      start: Moment().format('YYYY-MM-DD'),
      end: Moment().format('YYYY-MM-DD'),
      showBoth: false,
      weeks: [],
      currentWeek: 0,
      meals: [],
      loading: true,
      startNew: Moment().format('YYYY-MM-DD'),
      dates: [],
      weekday: 0,
      habits: global.habits,
      dataGraph: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
      currentGraph: 'weight',
      showModalMessage: false,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Health - Client Details - PT Mate', global.currentPage)
    HelperPage.checkMessage()
    this._isMounted = true
    global.clientToken = ''
    global.nutritionData = null

    EventEmitter.subscribe('clientsLoaded', (event) => {
      if(this.state.id === '') {
        this.getEmpty()
      } else {
        this.getClient()
      }
    })
    EventEmitter.subscribe('habitsLoaded', (event) => { this.getHabits() })
    this.getEmpty()
    this.getDates()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  getDates() {
    if(this._isMounted) {
      var tmpd = [
        Moment().endOf('week').format('YYYY-MM-DD'),
        Moment().endOf('week').add(7, 'days').format('YYYY-MM-DD'),
        Moment().endOf('week').add(14, 'days').format('YYYY-MM-DD'),
        Moment().endOf('week').add(21, 'days').format('YYYY-MM-DD'),
      ]
      this.setState({
        dates: tmpd,
        startNew: tmpd[0],
      })
    }
  }


  getEmpty() {
    if(this._isMounted) {
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      this.setState({
        id: arr[arr.length-2]
      }, () => {
        this.getClient()
      })
    }
  }


  getClient() {
    if(this._isMounted) {
      var id = ''
      var item = null
      var name = ''
      var arr = this.props.location.pathname.split('/')
      global.currentClient = arr[arr.length-2]
      for(var client of global.clients) {
        if(client.id === global.currentClient) {
          id = client.id
          item = client
          name = client.data.name
        }
      }
  
      if(item !== null) {
        this.setState({
          id: id,
          item: item,
          name: name,
          start: Moment(item.data.nutritionStart, 'X').format('YYYY-MM-DD'),
          startNew: Moment(item.data.nutritionStart, 'X').format('YYYY-MM-DD'),
          end: Moment(item.data.nutritionEnd, 'X').format('YYYY-MM-DD'),
        }, () => {
          global.linkCli = '/clients/'+id+'/nutrition';
          if(this.state.item.data.nutritionId !== undefined) {
            this.getNutritionData()
          }
          this.getHabits()
          this.createGraph('weight')
        });
      }
    }
  }


  getHabits() {
    if(this._isMounted) {
      if(this.state.item !== null) {
        var habits = []
        for(var item of global.habits) {
          if(item.data.client === this.state.item.id) {
            habits.push(item)
          }
        }
        this.setState({
          habits: habits
        })
      }
    }
  }


  getNutritionData() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getUserData($id: Int!) {
      users(where: { id: { _eq: $id} }) {
        id
        weight
        current_weight
        goal_weight
        sex
        weights {
          date_recorded
          weight
        }
        form_status
        current_period_start
        current_period_end
        plans(limit: 3, order_by: {created_at: desc}) {
          id
          start_date
          stop_date
          daily_cals
          starting_weight
          bmr
          target_fat
          target_carbs
          target_protein
        }
      }
    }`;

    client.query({
      variables: { id: parseInt(this.state.item.data.nutritionId) },
      query
    }).then((result) => {
      this.processNutritionData(result.data)
    }).catch((error) => {
      console.log(error);
    });
  }


  processNutritionData(data) {
    var weeks = []
    var weights = []
    for(var week of data.users[0].plans) {
      weeks.push({id: week.id, date: Moment(week.start_date, 'YYYY-MM-DD')})
    }
    
    weeks.sort((a,b) => Moment(b.date) - Moment(a.date));
    if(data.users[0].weights !== null) {
      for(var wgt of data.users[0].weights) {
        weights.push({"y": wgt.weight, "x": Moment(wgt.date_recorded, 'YYYY-MM-DD').format('DD/MM')})
      }
    }
    var tmp = [{
      "id": "",
      "color": "#81DB24",
      data: weights
    }]
    this.setState({
      userPassed: true,
      nutrition: data,
      weeks: weeks,
      graph: tmp
    }, () => {
      if(this.state.nutrition.users[0].form_status !== this.state.item.data.nutritionStatus && this.state.nutrition.users[0].form_status !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
          nutritionStatus: this.state.nutrition.users[0].form_status
        })
      }
      var cps = Moment(this.state.nutrition.users[0].current_period_start, 'YYYY-MM-DDTHH:mm:ss.SSS')
      var cpe = Moment(this.state.nutrition.users[0].current_period_end, 'YYYY-MM-DDTHH:mm:ss.SSS')
      if(cps !== Moment(this.state.item.data.nutritionStart, 'X') && this.state.nutrition.users[0].current_period_start !== null && this.state.nutrition.users[0].current_period_end !== null) {
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
          nutritionStart: parseInt(cps.format('X')),
          nutritionEnd: parseInt(cpe.format('X'))
        })
      }
    })
    if(weeks.length === 0) {
      this.setState({
        loading: false
      })
    } else {
      this.setState({
        currentWeek: weeks[0].id
      })
    }
    this.getNutritionWeek()
  }


  getNutritionWeek() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getWeek($id: Int!) {
      days(where: { plan_id: { _eq: $id} }) {
        meals {
          id
          recipe_id
          breakfast
          snack_morning
          lunch
          snack_afternoon
          dinner
          snack_evening
          dessert
          checked
        }
        id
        scheduled_date
      }
    }`;

    client.query({
      variables: { id: parseInt(this.state.currentWeek) },
      query
    }).then((result) => {
      this.processWeekData(result.data)
    }).catch((error) => {
      console.log(error);
    });
  }


  processWeekData(data) {
    var meals = []
    var days = []
    
    for(var dy of data.days) {
      var day = []
      for(var item of dy.meals) {  
        meals.push(item.recipe_id)
        var seq = 0
        if(item.snack_morning) { seq = 1 }
        if(item.lunch) { seq = 2 }
        if(item.snack_afternoon) { seq = 3 }
        if(item.dinner) { seq = 4 }
        if(item.snack_evening) { seq = 5 }
        if(item.dessert) { seq = 6 }
        day.push({data: item, seq: seq})
      }
      day.sort((a, b) => a.seq - b.seq)
      days.push({data: dy, meals: day})
    }
    days.sort((a,b) => Moment(a.data.scheduled_date, 'YYYY-MM-DD') - Moment(b.data.scheduled_date, 'YYYY-MM-DD'));
    this.setState({
      nutritionWeek: days,
      meals: meals
    }, () => {
      this.getNutritionRecipes()
    })
  }


  getNutritionRecipes() {
    const client = Connector.createApolloClient()
    const query = gql`
    query getWeek($id: [Int!]) {
      recipes(where: { id: { _in: $id} })  {
        name
        id
        photo_file_name
      }
    }`;

    client.query({
      variables: { id: this.state.meals },
      query
    }).then((result) => {
      this.setState({
        loading: false,
        nutritionMeal: result.data,
      })
    }).catch((error) => {
      console.log(error);
    });
  }


  updateGraph(event) {
    this.setState({
      currentGraph: event.target.value,
      dataGraph: [
        {
          "id": "japan",
          "color": "#81DB24",
          "data": []
        },
      ],
    })
    this.createGraph(event.target.value)
  }


  createGraph(value) {
    if(this.state.item !== null) {
      var tmp = []
      var tmpb = []
      var list = this.state.item.assessments
      list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
      for(var ass of list) {
        var val = 0
        var val2 = 0
        if(ass.data.weight !== undefined) {
          val = HelperPro.showWeight(ass.data.weight.toFixed(1))
        }
        if(value === 'fat') {
          console.log(ass.data.fat)
          val = ass.data.fat.toFixed(1)
        } else if(value === 'neck') {
          val = ass.data.neck.toFixed(1)
        } else if(value === 'abdomen') {
          val = ass.data.abdomen.toFixed(1)
        } else if(value === 'hip') {
          val = ass.data.hip.toFixed(1)
        } else if(value === 'chest') {
          val = ass.data.chest.toFixed(1)
        } else if(value === 'rarm') {
          val = ass.data.armR.toFixed(1)
        } else if(value === 'larm') {
          val = ass.data.armL.toFixed(1)
        } else if(value === 'rthigh') {
          val = ass.data.thighR.toFixed(1)
        } else if(value === 'lthigh') {
          val = ass.data.thighL.toFixed(1)
        } else if(value === 'rhr') {
          if(ass.data.heart !== undefined) {
            val = ass.data.heart.toFixed(0)
          } else {
            val = 0
          }
        } else if(value === 'bpr') {
          if(ass.data.blood1 !== undefined) {
            val = parseInt(ass.data.blood1)
          } else {
            val = 0
          }
          if(ass.data.blood2 !== undefined) {
            val2 = parseInt(ass.data.blood2)
          } else {
            val2 = 0
          }
          tmpb.push({
            "x": Moment(ass.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YY'),
            "y": val2,
          })
        } else if(value === 'cust') {
          if(ass.data.custom !== undefined) {
            val = parseFloat(ass.data.custom)
          } else {
            val = 0
          }
        }

        tmp.push({
          "x": Moment(ass.data.date, 'DD/MM/YYYY HH:mm').format('DD/MM/YY'),
          "y": val,
        })
      }

      var tmp2 = [{
        "id": "Value",
        "color": global.themeColors[0],
        data: tmp
      }]
      if(value === 'bpr') {
        tmp2 = [{
          "id": "S",
          "color": global.themeColors[0],
          data: tmp
        },
        {
          "id": "D",
          "color": global.themeColors[1],
          data: tmpb
        }]
      }

      setTimeout(() => {
        this.setState({dataGraph: tmp2});
      }, 100);
    }
  }



  // Interface related ------------------------------------------------------------



  getDate(date, type) {
    var label = Moment(date, 'X').format('D MMM YYYY')
    if(type === 'day') {
      label = Moment(date, 'X').locale(lang.language).format('dddd')
      var dt = Moment(date, 'X').format('DD/MM/YYYY')
      if(dt === Moment().format('DD/MM/YYYY')) {
        label = lang.t('calendar:label.today-s')
      }
      if(dt === Moment().add(-1, 'days').format('DD/MM/YYYY')) {
        label = lang.t('calendar:label.yesterday-s')
      }
      if(dt === Moment().add(1, 'days').format('DD/MM/YYYY')) {
        label = lang.t('calendar:label.tomorrow-s')
      }
    }
    return label
  }


  getDateBar() {
    var label = '0px'
    if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment()) {
      label = '100%'
    }
    if(Moment(this.state.item.data.nutritionEnd, 'X') > Moment() && Moment(this.state.item.data.nutritionStart, 'X') < Moment()) {
      var num1 = Moment(this.state.item.data.nutritionEnd, 'X').diff(Moment(this.state.item.data.nutritionStart, 'X'), 'days')+1
      var num2 = Moment().diff(Moment(this.state.item.data.nutritionStart, 'X'), 'days')+1
      label = (num2/num1*100)+'%'
    }
    return label
  }


  getDays(type, value) {
    var label = '-'
    var d1 = Moment().format('DD/MM/YYYY')
    var ds = Moment(this.state.item.data.nutritionStart, 'X').format('DD/MM/YYYY')
    var de = Moment(this.state.item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
    var diff = 0
    var cval = lang.t('clients:label.costsofar')+': '
    if(type === 'start') {
      diff = Moment(d1, 'DD/MM/YYYY').diff(Moment(ds, 'DD/MM/YYYY'), 'days')+1
    } else if(type === 'end') {
      diff = Moment(de, 'DD/MM/YYYY').diff(Moment(d1, 'DD/MM/YYYY'), 'days')
      cval = lang.t('clients:label.expectedcost')+': '
    } else if(type === 'summary') {
      diff = Moment(de, 'DD/MM/YYYY').diff(Moment(ds, 'DD/MM/YYYY'), 'days')+1
      cval = lang.t('clients:label.cost')+'Cost: '
    }

    if(diff > 0 && value === 'cost') {
      label = cval+global.curSym+(1.1*diff).toFixed(2)
    }
    if(diff === 1 && value === 'days' && type === 'end') {
      label = lang.t('clients:label.day')
    }
    if(diff === 1 && value === 'days' && type !== 'end') {
      label = '1 '+lang.t('clients:label.day')
    }
    if(diff > 1 && value === 'days') {
      label = diff+' '+lang.t('clients:label.days')
    }
    
    return label
  }


  getDaysSmall() {
    if(Moment(this.state.item.data.nutritionStart, 'X') < Moment() && Moment(this.state.item.data.nutritionEnd, 'X') > Moment()) {
      return <p style={{fontSize: '10px', margin: 0}}>{lang.t('clients:label.maximumweeklycharge')}: {global.curSym}7.70</p>
    }
  }


  getChange() {
    if(this.state.nutrition !== null) {
      var weight = this.state.nutrition.users[0].weight
      if(weight === null && this.state.nutrition.users[0].plans.length > 0) {
        weight = this.state.nutrition.users[0].plans[0].starting_weight
      }
      if(weight > this.state.nutrition.users[0].current_weight) {
        return (
          <div>
            <p className="label">{lang.t('clients:label.lost')}</p>
            <div className="badge green">{(this.state.nutrition.users[0].weight-this.state.nutrition.users[0].current_weight).toFixed(1)} kg</div>
          </div>
        )
      } else if(this.state.nutrition.users[0].current_weight === null || this.state.nutrition.users[0].weight === null || this.state.nutrition.users[0].weight === this.state.nutrition.users[0].current_weight) {
        return (
          <div>
            <p className="label">{lang.t('clients:label.lost')}</p>
            <div className="badge grey">-</div>
          </div>
        )
      } else {
        return (
          <div>
            <p className="label">{lang.t('clients:label.gained')}</p>
            <div className="badge red">{HelperPro.showWeight((this.state.nutrition.users[0].current_weight-this.state.nutrition.users[0].weight).toFixed(1))} {global.userLbs ? 'lb' : 'kg'}</div>
          </div>
        )
      }
    }
  }


  getFatColor(value) {
    var label = 'green'
    var sex = 'f'
    if(this.state.nutrition !== null) {
      sex = this.state.nutrition.users[0].sex
    }
    if((value < 14 && sex === 'f') || (value > 25 && sex === 'f')) {
      label = 'orange'
    }
    if((value < 10 && sex === 'f') || (value > 32 && sex === 'f')) {
      label = 'red'
    }
    if((value < 6 && sex === 'm') || (value > 18 && sex === 'm')) {
      label = 'orange'
    }
    if((value < 2 && sex === 'm') || (value > 25 && sex === 'm')) {
      label = 'red'
    }
    return label
  }


  getFat(type) {
    var label = '-'
    if(type === 'bar') {
      label = '0px'
    } else if(type === 'color') {
      label = ''
    }
    var date = Moment('01/01/1900', 'DD/MM/YYYY')
    for(var item of this.state.item.assessments) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.fat !== undefined && item.data.fat !== 0) {
        label = item.data.fat.toFixed(1)
        date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(type === 'bar') {
          label = (item.data.fat.toFixed(1)*2)+'%'
        }
        if(type === 'color') {
          label = this.getFatColor(item.data.fat)
        }
      }
    }
    return label
  }


  getActivity(type) {
    var label = '-'
    if(type === 'color') {
      label = 'grey'
    }
    var num = 0
    for(var item of global.sessions) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
        if(item.data.group) {
          if(item.data.clients !== undefined) {
            if(item.data.clients.indexOf(this.state.id) !== -1) {
              num += item.data.duration
            }
          }
        } else {
          if(item.data.client === this.state.id) {
            num += item.data.duration
          }
        }
      }
      
    }
    label = HelperCal.getDuration(num)
    if(type === 'color') {
      if(num < 46) {
        label = 'red'
      }
      if(num > 45 && num < 91) {
        label = 'yellow'
      }
      if(num > 90 && num < 181) {
        label = 'yellow'
      }
      if(num > 180) {
        label = 'green'
      }
    }
    return label
  }



  // Interactive ------------------------------------------------------------



  selectWeek(event) {
    this.setState({
      currentWeek: event.target.value,
      loading: true
    }, () => {
      this.getNutritionWeek()
    })
  }


  getMeal(id) {
    var label = lang.t('clients:empty.loading')
    if(this.state.nutritionMeal !== null) {
      for(var item of this.state.nutritionMeal.recipes) {
        if(item.id === id) {
          label = item.name
        }
      }
    }
    return label
  }


  getMealPhoto(id) {
    var label = ''
    if(this.state.nutritionMeal !== null) {
      for(var item of this.state.nutritionMeal.recipes) {
        if(item.id === id) {
          label = item.photo_file_name
        }
      }
    }
    return label
  }


  getMealLink(id) {
    var start = '000000000'+String(id)
    var total = start.substr(String(id).length, start.length)
    var label = total.substr(0, 3)+'/'+total.substr(3, 3)+'/'+total.substr(6, 3)
    return label
  }


  getMealType(meal) {
    var label = '-'
    if(meal.breakfast) {
      label = lang.t('clients:label.breakfast')
    }
    if(meal.lunch) {
      label = lang.t('clients:label.lunch')
    }
    if(meal.dinner) {
      label = lang.t('clients:label.dinner')
    }
    if(meal.snack_morning) {
      label = lang.t('clients:label.morningsnack')
    }
    if(meal.snack_afternoon) {
      label = lang.t('clients:label.afternoonsnack')
    }
    if(meal.snack_evening) {
      label = lang.t('clients:label.eveningsnack')
    }
    if(meal.dessert) {
      label = lang.t('clients:label.dessert')
    }
    return label
  }


  getMealChecked(date, meal) {
    if(meal.checked) {
      return (
        <span className="pill green">{lang.t('clients:label.checked')}</span>
      )
    } else if(!meal.checked && Moment(date, 'YYYY-MM-DD') < Moment()) {
      return (
        <span className="pill red">{lang.t('clients:label.notchecked')}</span>
      )
    } else {
      return (
        <span className="pill grey">- {lang.t('clients:label.pending')} -</span>
      )
    }
  }


  getWeekName(date) {
    var label = lang.t('clients:label.weekstarting')+' '+Moment(date).format('D MMM YYYY')
    if(Moment(date).startOf('week').format('DD/MM/YYYY') === Moment().startOf('week').format('DD/MM/YYYY')) {
      label = lang.t('clients:label.thisweek')
    }
    if(Moment(date).startOf('week').format('DD/MM/YYYY') === Moment().add(7, 'days').startOf('week').format('DD/MM/YYYY')) {
      label = lang.t('clients:label.nextweek')
    }
    if(Moment(date).startOf('week').format('DD/MM/YYYY') === Moment().add(-7, 'days').startOf('week').format('DD/MM/YYYY')) {
      label = lang.t('clients:label.lastweek')
    }
    return label
  }


  getBmr() {
    var label = '-'
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[this.state.nutrition.users[0].plans.length-1].bmr === undefined) {
            label = '-'
          } else {
            label = this.state.nutrition.users[0].plans[this.state.nutrition.users[0].plans.length-1].bmr.toFixed(0)+'cal'
          }
        }
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment()) {
        label = '-'
      }
    }
    return label
  }


  getDayProtein(type) {
    var label = '-'
    if(type === 'bar') {
      label = '100%'
    }
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].target_protein === undefined) {
            label = '-'
            if(type === 'bar') {
              label = '100%'
            }
          } else {
            if(type === 'bar') {
              label = (100-this.state.nutrition.users[0].plans[0].target_protein*100)+'%'
            } else {
              label = (this.state.nutrition.users[0].plans[0].target_protein*100)+'%'
            }
          }
        }
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment() && type !== 'bar') {
        label = '-'
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment() && type === 'bar') {
        label = '100%'
      }
    }
    return label
  }


  getDayCarbs(type) {
    var label = '-'
    if(type === 'bar') {
      label = '100%'
    }
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].target_carbs === undefined) {
            label = '-'
            if(type === 'bar') {
              label = '100%'
            }
          } else {
            if(type === 'bar') {
              label = (100-this.state.nutrition.users[0].plans[0].target_carbs*100)+'%'
            } else {
              label = (this.state.nutrition.users[0].plans[0].target_carbs*100)+'%'
            }
            
          }
        }
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment() && type !== 'bar') {
        label = '-'
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment() && type === 'bar') {
        label = '100%'
      }
    }
    return label
  }


  getDayFat(type) {
    var label = '-'
    if(type === 'bar') {
      label = '100%'
    }
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users.length > 0) {
        if(this.state.nutrition.users[0].plans.length > 0) {
          if(this.state.nutrition.users[0].plans[0].target_fat === undefined) {
            label = '-'
            if(type === 'bar') {
              label = '100%'
            }
          } else {
            if(type === 'bar') {
              label = (100-this.state.nutrition.users[0].plans[0].target_fat*100)+'%'
            } else {
              label = (this.state.nutrition.users[0].plans[0].target_fat*100)+'%'
            }
          }
        }
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment() && type !== 'bar') {
        label = '-'
      }
      if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment() && type === 'bar') {
        label = '100%'
      }
    }
    return label
  }


  getBpm(type) {
    var label = '-'
    if(type === 'bar') {
      label = '0px'
    } else if(type === 'color') {
      label = ''
    }
    var date = Moment('01/01/1900', 'DD/MM/YYYY')
    for(var item of this.state.item.assessments) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.heart !== undefined && item.data.heart !== 0) {
        label = item.data.heart.toFixed(0)
        date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        if(type === 'bar') {
          label += '%'
        }
        if(type === 'color' && item.data.heart < 61) {
          label = 'green'
        }
        if(type === 'color' && item.data.heart > 60 && item.data.heart < 66) {
          label = 'yellow'
        }
        if(type === 'color' && item.data.heart > 65 && item.data.heart < 76) {
          label = 'orange'
        }
        if(type === 'color' && item.data.heart > 75) {
          label = 'red'
        }
      }
    }
    return label
  }


  getBlood1(type) {
    var label = '-'
    if(type === 'bar') {
      label = '0px'
    } else if(type === 'color') {
      label = ''
    }
    var date = Moment('01/01/1900', 'DD/MM/YYYY')
    for(var item of this.state.item.assessments) {
      if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.blood1 !== undefined) {
        if(item.data.blood1 !== '') {
          label = parseInt(item.data.blood1)
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
          if(type === 'bar') {
            label = label*0.7
            label += '%'
          }
          if(type === 'color' && item.data.blood1 < 121) {
            label = 'green'
          }
          if(type === 'color' && item.data.blood1 > 120 && item.data.blood1 < 130) {
            label = 'yellow'
          }
          if(type === 'color' && item.data.blood1 > 129 && item.data.blood1 < 140) {
            label = 'orange'
          }
          if(type === 'color' && item.data.blood1 > 140) {
            label = 'red'
          }
        }
        if(type === 'value' && label) {
          if(item.data.blood2 !== undefined) {
            if(item.data.blood2 !== '') {
              label += '/'+item.data.blood2
            } else {
              label += '/-'
            }
          } else {
            label += '/-'
          }
        }
      }
    }
    
    return label
  }


  getHabitCompliance(item, type) {
    var label = 'grey'
    var num = 0
    var tmp1 = []
    var start = Moment(item.data.start, 'X').startOf('day')
    var end = Moment().add(1, 'days')
    if( Moment(item.data.end, 'X') < Moment()) {
      end = Moment(item.data.end, 'X')
    }
    var d = Moment.duration(end.diff(start))
    var dys = d.asDays()
    for(var i=0; i<dys.toFixed(0); i++) {
      if(item.data.days !== undefined) {
        var dt = Moment(item.data.start, 'X').add(i, 'days').format('DD/MM/YYYY')
        for(var day of item.data.days) {
          if(day.indexOf(dt) !== -1 && day.indexOf('||1||') !== -1) {
            tmp1++
          }
        }
      }
    }
    var per = tmp1/dys.toFixed(0)*100
    num = per.toFixed(0)
    if(per > 0 && per < 30) {
      label = 'red'
    }
    if(per > 29 && per < 80) {
      label = 'yellow'
    }
    if(per > 79) {
      label = 'green'
    }
    if(isNaN(per)) {
      num = 0
    }

    if(type === 'class') {
      return label
    } else {
      return num
    }
  }



  // Modal ------------------------------------------------------------



  showModal() {
    var both = false
    if(Moment(this.state.item.data.nutritionStart, 'X') > Moment()) {
      both = true
    }
    this.setState({
      showModal: true,
      showBoth: both,
    })
    if(Moment(this.state.item.data.nutritionStart, 'X') < Moment()) {
      this.setState({
        startNew: Moment(this.state.item.data.nutritionStart, 'X').format('YYYY-MM-DD')
      })
    }
    setTimeout(() => {
      const height = this.divElement.clientHeight
      this.setState({
        hidden: '',
        margin: -height/2
      });
    }, 100);
  }


  hideModal() {
    const height = this.divElement.clientHeight
    this.setState({
      hidden: 'hidden',
      margin: -height/2+100
    })
    setTimeout(() => {
      this.setState({
        showModal: false, 
      });
      this.getDates()
    }, 500);
  }


  renderStartDate() {
    if(Moment(this.state.item.data.nutritionStart, 'X') > Moment()) {
      return (
        <div>
          <label>{lang.t('modals:dates.startdate')}</label>
          <select value={this.state.startNew} onChange={this.onChangeStart}>
            <option value={this.state.dates[0]}>{lang.t('modals:dates.thissunday')} ({Moment(this.state.dates[0], 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')})</option>
            <option value={this.state.dates[1]}>{lang.t('modals:dates.nextsunday')} ({Moment(this.state.dates[1], 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')})</option>
            <option value={this.state.dates[2]}>{lang.t('modals:dates.sunday')} {Moment(this.state.dates[2], 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')}</option>
            <option value={this.state.dates[3]}>{lang.t('modals:dates.sunday')} {Moment(this.state.dates[3], 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')}</option>
          </select>
        </div>
      )
    }
  }


  renderButtonUpdate() {
    var label = lang.t('modals:dates.button.enddate')
    if(this.state.showBoth) {
      label = lang.t('modals:dates.button')
    }
    if(Moment(this.state.end, 'YYYY-MM-DD') > Moment(this.state.startNew, 'YYYY-MM-DD')) {
      return (
        <button className="btn primary" onClick={() => this.updateDates()}>{label}</button>
      )
    }
  }


  renderButtonCancel() {
    if(Moment(this.state.start, 'YYYY-MM-DD') > Moment()) {
      return (
        <div>
          <div className="sv-20"></div>
          <button className="btn tertiary width-12" onClick={() => this.cancelPlan()}>{lang.t('modals:dates.button.cancel')}</button>
        </div>
      )
    }
  }


  renderModal() {
    if(this.state.showModal) {
      return (
        <div className={'overlay '+this.state.hidden}>
          <div className="box auto" ref={ (divElement) => { this.divElement = divElement }} style={{marginTop: this.state.margin}}>
            <h2 className="lft mb-20">{this.state.showBoth ? lang.t('modals:dates.title') : lang.t('modals:dates.title.enddate')}</h2>
            <button className="btn tertiary small close rgt" onClick={() => this.hideModal()}>{lang.t('common:button.close')}</button>
            <div className="form clear">
              {this.renderStartDate()}
              <label>{lang.t('modals:dates.enddate')}</label>
              <input type="date" value={this.state.end} onChange={this.onChangeEnd} min={Moment().format('YYYY-MM-DD')}/>
            </div>
            <div className="sv-50"></div>
            {this.renderButtonUpdate()}
            {this.renderButtonCancel()}
          </div>
        </div>
      )
    }
  }


  onChangeStart = event => {
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    var dt = Moment(event.target.value, 'YYYY-MM-DD')
    if(valid) {
      this.setState({
        startNew: Moment(dt).format('YYYY-MM-DD'),
      });
    }
  }


  onChangeEnd = event => {
    var valid = Moment(event.target.value, 'YYYY-MM-DD').isValid()
    var dt = Moment(event.target.value, 'YYYY-MM-DD')
    if(valid) {
      this.setState({
        end: Moment(dt).format('YYYY-MM-DD'),
      });
    }
  }


  updateDates() {
    if(Moment(this.state.end, 'YYYY-MM-DD') > Moment(this.state.startNew, 'YYYY-MM-DD')) {
      this.hideModal()

      const client = Connector.createApolloClient()
      const mutation = gql`
        mutation UpdateDates($id: Int!, $start: timestamp!, $end: timestamp!) {
          update_users(where: { id: { _eq: $id} }, _set: { current_period_start: $start, current_period_end: $end }) {
            returning {
              current_period_start
              current_period_end
            }
          }
        }
      `;
      var start = Moment(this.state.item.data.nutritionStart, 'X')
      if(this.state.showBoth) {
        start = Moment(this.state.startNew, 'YYYY-MM-DD')
      }
      return client.mutate({
        variables: { id: parseInt(this.state.item.data.nutritionId), start: start, end: Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss') },
        mutation
      }).then((result) => {
        var cps = Moment(result.data.update_users.returning[0].current_period_start, 'YYYY-MM-DDTHH:mm:ss.SSS')
        var cpe = Moment(result.data.update_users.returning[0].current_period_end, 'YYYY-MM-DDTHH:mm:ss.SSS')
        Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
          nutritionStart: parseInt(cps.format('X')),
          nutritionEnd: parseInt(cpe.format('X'))
        })
        EventEmitter.dispatch('showMessage', lang.t('messaging:message.nutritionplanupdated'));
      }).catch((error) => {
        EventEmitter.dispatch('showMessageError', error);
      });
    }
  }


  cancelPlan() {
    this.hideModal()
    const client = Connector.createApolloClient()
    const mutation = gql`
      mutation UpdateDates($id: Int!, $start: timestamp!, $end: timestamp!) {
        update_users(where: { id: { _eq: $id} }, _set: { current_period_start: $start, current_period_end: $end }) {
          returning {
            current_period_start
            current_period_end
          }
        }
      }
    `;
    return client.mutate({
      variables: { id: parseInt(this.state.item.data.nutritionId), start: Moment().add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss'), end: Moment().add(-1, 'days').format('YYYY-MM-DDTHH:mm:ss') },
      mutation
    }).then((result) => {
      var cps = Moment(result.data.update_users.returning[0].current_period_start, 'YYYY-MM-DDTHH:mm:ss.SSS')
      var cpe = Moment(result.data.update_users.returning[0].current_period_end, 'YYYY-MM-DDTHH:mm:ss.SSS')
      Firebase.database().ref('/clients/'+global.uid+'/'+this.state.item.id).update({
        nutritionStart: parseInt(cps.format('X')),
        nutritionEnd: parseInt(cpe.format('X'))
      })
      EventEmitter.dispatch('showMessage', lang.t('messaging:message.nutritionplancanceled'));
    }).catch((error) => {
      EventEmitter.dispatch('showMessageError', error);
    });
  }



  // Modals ------------------------------------------------------------



  hideModals() {
    this.setState({
      showModalMessage: false,
      showModalSettings: false,
    })
  }



  // Display stuff ------------------------------------------------------------



  renderStatus(item) {
    var label = lang.t('clients:label.notstartedyet')
    if(item.data.date !== undefined) {
      var add = 7*item.weeks.length
      var start = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
      var end = Moment(item.data.date, 'DD/MM/YYYY HH:mm').add(add, 'days')
      if(end < Moment()) {
        label = lang.t('clients:label.finished')+' '+Moment(end).locale(lang.language).format('D MMM YYYY')
      } else {
        label = lang.t('clients:label.activeweek')+' '+(Moment().diff(start, 'weeks')+1)+')'
      }
    }
    return label
  }


  renderCompliance(type) {
    var num = 0
    var num2 = 0
    var total = 0
    var color = 'grey'
    var label = '-'
    if(this.state.nutritionWeek !== null && this.state.nutritionWeek !== undefined && this.state.nutritionWeek.length > 0) {
      for(var item of this.state.nutritionWeek) {
        for(var meal of item.meals) {
          num++
          if(meal.data.checked) {
            num2++
          }
        }
      }
      total = parseInt((num2/num)*100)
      color = 'red'
      if(total > 30 && total < 41) {
        color = 'yellow'
      } else if(total > 40 && total < 75) {
        color = 'yellow'
      } else if(total > 74) {
        color = 'green'
      }
    }
    if(type === 'color') {
      label = color
    } else {
      label = total+'%'
    }
    if(Moment(this.state.item.data.nutritionEnd, 'X').add(5, 'days') < Moment()) {
      label = '-'
      if(type === 'color') {
        label = 'grey'
      }
    }
    return label
  }


  renderContent() {
    if(this.state.item.data.nutritionStatus === 'registered') {
      return (
        <div>
          <div className="filters">
            <h3>{lang.t('header:title.meals')}</h3>
            <div className="clear"></div>
          </div>
          <div className="empty large">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('nutrition')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('clients:empty.meal.title')}</h3>
              <p className="mb-20">{lang.t('clients:empty.meal.text')}</p>
              <a href="https://www.ptmate.net/help/nutrition/preferences" target="_blank" rel="noopener noreferrer" className="btn tertiary width-12 add">{lang.t('clients:button.learnmore')}</a>
            </div>
          </div>
        </div>
      )
    } else if(this.state.item.data.nutritionStatus !== 'registered' && this.state.loading) {
      return (
        <div>
          <div className="listheader">
            <h3>{lang.t('header:title.meals')}</h3>
            <div className="clear"></div>
          </div>
          <div className="empty large">
            <h3>{lang.t('clients:empty.loadingdata')}</h3>
          </div>
        </div>
      )
    } else {
      if(this.state.nutritionWeek !== null && this.state.nutritionWeek !== undefined && this.state.nutritionWeek.length > 0) {
        if(Moment(this.state.item.data.nutritionEnd, 'X').add(1, 'days') < Moment()) {
          return (
            <div>
              <div className="listheader">
                <h3>{lang.t('header:title.meals')}</h3>
                <div className="clear"></div>
              </div>
              <div className="empty large">
                <div className="illustration mb-30">
                  <div className="img1"></div>
                  <div className="img2"></div>
                  <div className="img3"></div>
                  <div className="img4"></div>
                  {HelperEmptyImg.renderImage('nutrition')}
                </div>
                <div className="data">
                  <h3 className="mb-20">{lang.t('clients:empty.nutrition2.title')}</h3>
                  <p className="mb-20">{lang.t('clients:empty.nutrition2.text')}</p>
                  {this.renderButtonNutritionMain()}
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div className="listheader">
                <h3 className="lft mb-20 mt-5">{lang.t('header:title.meals')}</h3>
                <select className="light rgt" onChange={event => this.selectWeek(event)} value={this.state.currentWeek}>
                {this.state.weeks.map(item => (
                  <option value={item.id} key={item.id}>{this.getWeekName(item.date)}</option>
                ))}
                </select>
                <div className="clear"></div>
              </div>
              <div className="fom-item">
              <div className="radios mb-20 clear">
                <button className={'radio weekday lft '+(this.state.weekday === 0 ? 'active' : '')} onClick={() => this.setState({weekday: 0})}>{lang.t('calendar:label.monday')}</button>
                <button className={'radio weekday lft '+(this.state.weekday === 1 ? 'active' : '')} onClick={() => this.setState({weekday: 1})}>{lang.t('calendar:label.tuesday')}</button>
                <button className={'radio weekday lft '+(this.state.weekday === 2 ? 'active' : '')} onClick={() => this.setState({weekday: 2})}>{lang.t('calendar:label.wednesday')}</button>
                <button className={'radio weekday lft '+(this.state.weekday === 3 ? 'active' : '')} onClick={() => this.setState({weekday: 3})}>{lang.t('calendar:label.thursday')}</button>
                <button className={'radio weekday lft '+(this.state.weekday === 4 ? 'active' : '')} onClick={() => this.setState({weekday: 4})}>{lang.t('calendar:label.friday')}</button>
                <button className={'radio weekday lft '+(this.state.weekday === 5 ? 'active' : '')} onClick={() => this.setState({weekday: 5})}>{lang.t('calendar:label.saturday')}</button>
                <button className={'radio weekday lft '+(this.state.weekday === 6 ? 'active' : '')} onClick={() => this.setState({weekday: 6})}>{lang.t('calendar:label.sunday')}</button>
              </div>
              </div>
              {this.state.nutritionWeek.map((item, index) => (
                <div key={item.data.id} className={this.state.weekday === index ? '' : 'none'}>
                  <h4 className="mb-20">{Moment(item.data.scheduled_date, 'YYYY-MM-DD').locale(lang.language).format('dddd, D MMM YYYY')}</h4>
                  {item.meals.map(meal => (
                    <div className="col-3" key={meal.data.id}>
                      <div className="box meal highlight mb-20">
                        <div className="image mb-20" style={{backgroundImage: 'url(https://backtomybody-dev.s3.amazonaws.com/recipes/photos/'+this.getMealLink(meal.data.recipe_id)+'/medium/'+this.getMealPhoto(meal.data.recipe_id)+')'}}></div>
                        <div className="meal-info">
                          {this.getMealChecked(item.data.scheduled_date, meal.data)}
                          <div className="info mt-20">
                            <label>{this.getMealType(meal.data)}</label>
                            {this.getMeal(meal.data.recipe_id)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )
        }
      } else {
        return (
          <div className="empty large">
            <h3>{lang.t('header:empty.nodata')}</h3>
          </div>
        )
      }
      
    }
  }


  renderButtonNutritionMain() {
    if(HelperBusiness.getRoleIncl('admin')) {
      return (
        <Link to={'/nutrition/new-plan/'+this.state.item.id} className="btn tertiary width-12 add">{lang.t('clients:button.createanutritionplan')}</Link>
      )
    }
  }


  renderMeals() {
    if(this.state.item !== null) {
      if(this.state.item.data.nutritionId === undefined) {
        if(this.state.item.data.uid === '') {
          return (
            <div>
              <div className="listheader">
                <h3>{lang.t('header:title.meals')}</h3>
                <div className="clear"></div>
              </div>
              <div className="empty large">
                <div className="illustration mb-30">
                  <div className="img1"></div>
                  <div className="img2"></div>
                  <div className="img3"></div>
                  <div className="img4"></div>
                  {HelperEmptyImg.renderImage('nutrition')}
                </div>
                <div className="data">
                  <h3 className="mb-20">{lang.t('clients:empty.nutrition.title')}</h3>
                  <p className="mb-20" style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nutrition.text3')}</p>
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div className="listheader">
                <h3>{lang.t('header:title.meals')}</h3>
                <div className="clear"></div>
              </div>
              <div className="empty large">
                <div className="illustration mb-30">
                  <div className="img1"></div>
                  <div className="img2"></div>
                  <div className="img3"></div>
                  <div className="img4"></div>
                  {HelperEmptyImg.renderImage('nutrition')}
                </div>
                <div className="data">
                  <h3 className="mb-20">{lang.t('clients:empty.nutrition.title')}</h3>
                  <p className="mb-20" style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nutrition.text2')}</p>
                  {this.renderButtonNutritionMain()}
                </div>
              </div>
            </div>
          )
        }
      } else {
        return (<div>{this.renderContent()}</div>)
      }
    }
  }


  renderNewLog() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <Link to={'/clients/'+this.state.item.id+'/new-assessment'} className="btn tertiary add width-12">{lang.t('clients:button.newlogentry')}</Link>
      )
    }
  }


  renderHistory() {
    if(this.state.item !== null) {
      if(this.state.item.assessments.length === 0) {
        return (
          <div>
            <div className="listheader clear">
              <h3>{lang.t('header:title.healthlog')}</h3>
            </div>
            <div className="clear empty large">
              <div className="illustration mb-30">
                <div className="img1"></div>
                <div className="img2"></div>
                <div className="img3"></div>
                <div className="img4"></div>
                {HelperEmptyImg.renderImage('health')}
              </div>
              <div className="data">
                <h3 className="mb-20">{lang.t('clients:empty.log.title')}</h3>
                <p className="mb-20">{lang.t('clients:empty.log.text')}</p>
                {this.renderNewLog()}
              </div>
            </div>
          </div>
        )
      } else {
        var list = this.state.item.assessments
        list.sort((a,b) => Moment(a.data.date, 'DD/MM/YYYY HH:mm') - Moment(b.data.date, 'DD/MM/YYYY HH:mm'));
        return (
          <div>
            <div className="clear space-20"></div>
            <div className="listheader clear">
              <h3>{lang.t('header:title.healthlog')}</h3>
            </div>
            <div className="clear"></div>
            {list.map(item => (
              <div className="col-2 col-3-1280" key={item.id}>
                <div className="box card small highlight mb-20">
                  <Link to={'/clients/'+this.state.item.id+'/assessments/'+item.id}>
                    <div className="icon secondary mb-10">
                      <div className="inner healthlog"></div>
                    </div>
                    <h4 className="mb-10 clear">{Moment(item.data.date, 'DD/MM/YYYY HH:mm').locale(lang.language).format('D MMM YYYY')}</h4>
                    <p>{item.data.weight !== undefined ? HelperPro.showWeight(item.data.weight.toFixed(1)) : '-'} {global.userLbs ? 'lb' : 'kg'}</p>
                    <div className="sv-20"></div>
                    <button className="btn tertiary small width-12">{lang.t('common:button.viewdetails')}</button>
                    <div className="clear"></div>
                  </Link>
                </div>
              </div>
            ))}
            <div className="col-2 col-3-1280">
              <button className="inline-add card small mb-20" onClick={() => this.props.history.push('/clients/'+this.state.id+'/new-assessment')}></button>
            </div>
            <div className="clear"></div>
          </div>
        )
      }
    }
  }


  renderDateButton() {
    if(HelperBusiness.getRoleIncl('')) {
      var d1 = Moment().format('DD/MM/YYYY')
      var d2 = Moment(this.state.item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
      if(Moment(d2, 'DD/MM/YYYY') >= Moment(d1, 'DD/MM/YYYY')) {
        if(Moment(this.state.item.data.nutritionStart, 'X') > Moment()) {
          return (
            <div>
              <div className="space-20"></div>
              <button className="btn tertiary edit width-12" onClick={() => this.showModal()}>{lang.t('clients:button.updatedates')}</button>
            </div>
          )
        } else {
          return (
            <div>
              <div className="space-20"></div>
              <button className="btn tertiary edit width-12" onClick={() => this.showModal()}>{lang.t('clients:button.updateenddate')}</button>
            </div>
          )
        }
      } else {
        return (
          <div>
            <div className="space-20"></div>
            <Link to={'/nutrition/new-plan/'+this.state.item.id} className="btn tertiary edit width-12">{lang.t('clients:button.createanotherplan')}</Link>
          </div>
        )
      }
    }
  }


  renderDays() {
    var d1 = Moment().format('DD/MM/YYYY')
    var d2 = Moment(this.state.item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
    if(Moment(this.state.item.data.nutritionStart, 'X') <= Moment()) {
      if(HelperBusiness.getRoleIncl('')) {
        if(Moment(d2, 'DD/MM/YYYY') >= Moment(d1, 'DD/MM/YYYY')) {
          return (
            <div>
              <div className="info">
                <label>{this.getDays('start', 'days')} {lang.t('clients:label.activeincludingtoday')}</label>
                <p>{this.getDays('start', 'cost')}</p>
              </div>
              <div className="info">
                <label>{lang.t('clients:label.activeforanother')} {this.getDays('end', 'days')}</label>
                <p>{this.getDays('end', 'cost')}</p>
              </div>
            </div>
          )
        } else {
          return (
            <div className="info">
              <label>{lang.t('clients:label.wasactivefor')} {this.getDays('summary', 'days')}</label>
              <p>{this.getDays('summary', 'cost')}</p>
            </div>
          )
        }
      } else {
        if(Moment(d2, 'DD/MM/YYYY') >= Moment(d1, 'DD/MM/YYYY')) {
          return (
            <div>
              <div className="info">
                <label>{lang.t('clients:label.activeincludingtoday')}</label>
                <p>{this.getDays('start', 'days')}</p>
              </div>
              <div className="info">
                <label>>{lang.t('clients:label.activeforanother')}</label>
                <p>{this.getDays('end', 'days')}</p>
              </div>
            </div>
          )
        } else {
          return (
            <div className="info">
              <label>{lang.t('clients:label.wasactivefor')}</label>
              <p>{this.getDays('summary', 'days')}</p>
            </div>
          )
        }
      }
      
    }
  }


  renderWeightBar() {
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users[0].goal_weight !== null) {
        var w1 = 0
        var w2 = this.state.nutrition.users[0].goal_weight
        var w3 = 0
        var date = Moment('01/01/1900', 'DD/MM/YYYY')
        for(var item of this.state.item.assessments) {
          if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.weight !== undefined && item.data.weight !== 0) {
            w1 = item.data.weight
            date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
          }
        }
        if(this.state.item.assessments.length > 0) {
          w3 = this.state.item.assessments[0].data.weight
        }
        var range = w3-w2
        var current = w3-w1
        var per = current/range*100
        if(per < 0 || w3 === 0) {
          per = 0
        }
        return (
          <div className="inner default" style={{width: per+'%'}}></div>
        )
      }
    }
  }


  renderWeight(type) {
    if(this.state.nutrition !== null && type === 'goal') {
      if(type === 'goal') {
        if(this.state.nutrition.users[0].goal_weight !== null) {
          return (
            <p>{HelperPro.showWeight(this.state.nutrition.users[0].goal_weight)}<span>{global.userLbs ? 'lb' : 'kg'}</span></p>
          )
        } else {
          return <p>-</p>
        }
      }
    } else {
      var label = '-'
      var date = Moment('01/01/1900', 'DD/MM/YYYY')
      for(var item of this.state.item.assessments) {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > date && item.data.weight !== undefined && item.data.weight !== 0) {
          label = item.data.weight.toFixed(1)
          date = Moment(item.data.date, 'DD/MM/YYYY HH:mm')
        }
      }
      if(label === '-') {
        return <p>-</p>
      } else {
        return <p>{HelperPro.showWeight(label)}<span>{global.userLbs ? 'lb' : 'kg'}</span></p>
      }
      
    }
    
  }


  renderCalories() {
    if(this.state.nutrition !== null) {
      if(this.state.nutrition.users[0].plans.length > 0) {
        if(this.state.nutrition.users[0].plans[this.state.nutrition.users[0].plans.length-1].daily_cals !== null) {
          if(Moment(this.state.item.data.nutritionEnd, 'X') < Moment()) {
            return <p>-</p>
          } else {
            return (
              <p>{this.state.nutrition.users[0].plans[this.state.nutrition.users[0].plans.length-1].daily_cals}<span>cal</span></p>
            )
          }
        } else {
          return <p>-</p>
        }
      } else {
        return <p>-</p>
      }
    } else {
      return <p>-</p>
    }
  }


  renderNutrition() {
    if(this.state.item !== null) {
      if(this.state.item.data.nutritionId !== undefined) {
        return (
          <div>
            <div className="mt-5 mb-20">
              <h3>{lang.t('header:title.nutritionlog')}</h3>
            </div>
            <div className="info clear">
              <label className="lft">{Moment(this.state.item.data.nutritionStart, 'X') < Moment() ? lang.t('clients:label.started') : lang.t('clients:label.starts')} {this.getDate(this.state.item.data.nutritionStart, 'day')}</label>
              <label className="rgt">{Moment(this.state.item.data.nutritionEnd, 'X').add(1, 'days') < Moment() ? lang.t('clients:label.ended') : lang.t('clients:label.ends')} {this.getDate(this.state.item.data.nutritionEnd, 'day')}</label>
              <div className="clear"></div>
              <div className="bar">
                <div className="inner default" style={{width: this.getDateBar()}}></div>
              </div>
              <p className="lft">{this.getDate(this.state.item.data.nutritionStart, 'date')}</p>
              <p className="rgt">{this.getDate(this.state.item.data.nutritionEnd, 'date')}</p>
            </div>
            <div className="clear sv-20"></div>
            {this.renderDays()}
            {this.renderDateButton()}
          </div>
        )
      } else {
        return (
          <div>
            <div className="mt-5 mb-20">
              <h3>{lang.t('header:title.nutritionlog')}</h3>
            </div>
            <div className="clear empty small">
              {HelperEmpty.renderImage('calendar')}
              <h4>{lang.t('clients:empty.nutrition.title')}</h4>
              <p style={{whiteSpace: 'pre-wrap'}}>{lang.t('clients:empty.nutrition.text')}</p>
            </div>
          </div>
        )
      }
    }
  }


  renderTop() {
    if(this.state.item !== null) {
      return (
        <div>
          <div className="col-8 withsidebar mb-20">
            <div className="lft mt-5">
              <h3 className="mb-20">{lang.t('header:title.progress')}</h3>
            </div>
            <select className="light rgt" value={this.state.currentGraph} onChange={event => this.updateGraph(event)} style={{width: 150}}>
              <option value='weight'>{lang.t('clients:label.weight')}</option>
              <option value='fat'>{lang.t('clients:label.bodyfat')}</option>
              <option value='rhr'>{lang.t('clients:label.restingheartrate')}</option>
              <option value='bpr'>{lang.t('clients:label.bloodpressure')}</option>
              <option value='neck'>{lang.t('clients:label.neck')}</option>
              <option value='chest'>{lang.t('clients:label.chest')}</option>
              <option value='abdomen'>{lang.t('clients:label.abdomen')}</option>
              <option value='hip'>{lang.t('clients:label.hip')}</option>
              <option value='rarm'>{lang.t('clients:label.rightarm')}</option>
              <option value='larm'>{lang.t('clients:label.leftarm')}</option>
              <option value='lthigh'>{lang.t('clients:label.rightthigh')}</option>
              <option value='rthigh'>{lang.t('clients:label.leftthigh')}</option>
              <option value='cust'>{lang.t('clients:form.customvalue')}</option>
            </select>
            <div className="clear">
              <WidgetChartGraph data={this.state.dataGraph} curve='linear'/>
            </div>
            <div className="clear"></div>
          </div>
          <div className="col-4">
            {this.renderNutrition()}
          </div>
        </div>
      )
    }
  }


  renderButtonNutrition() {
    var d1 = Moment().format('DD/MM/YYYY')
    var d2 = Moment(this.state.item.data.nutritionEnd, 'X').format('DD/MM/YYYY')
    if(HelperBusiness.getRoleIncl('admin')) {
      if(Moment(d2, 'DD/MM/YYYY') >= Moment(d1, 'DD/MM/YYYY')) {
        if(Moment(this.state.item.data.nutritionStart, 'X') > Moment()) {
          return (
            <div className="action" onClick={() => this.showModal()}>{lang.t('clients:button.updateplandates')}</div>
          )
        } else {
          return (
            <div className="action" onClick={() => this.showModal()}>{lang.t('clients:button.updateplanenddate')}</div>
          )
        }
      } else {
        return (
          <Link to={'/nutrition/new-plan/'+this.state.item.id} className="action">{lang.t('clients:button.createanutritionplan')}</Link>
        )
      }
    }
  }


  renderButtonLog() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      return (
        <Link to={'/clients/'+this.state.id+'/new-assessment'} className="action">{lang.t('clients:button.newhealthlog')}</Link>
      )
    }
  }


  renderButtonHabit() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      if(global.showHabits && this.state.item !== null) {
        return (
          <Link to={'/clients/'+this.state.item.id+'/new-habit'} className="action">{lang.t('clients:button.createanewhabit')}</Link>
        )
      } else {
        return (
          <div className="action" onClick={() => this.setState({showModalMessage: true})}>{lang.t('clients:button.createanewhabit')}</div>
        )
      }
    }
  }


  renderButtonEmptyHabit() {
    if(HelperBusiness.getRoleIncl('trainer')) {
      if(global.showHabits && this.state.item !== null) {
        return (
          <Link to={'/clients/'+this.state.item.id+'/new-habit'} className="btn tertiary width-12 add">{lang.t('clients:button.createyourclientsfirsthabit')}</Link>
        )
      } else {
        return (
          <button className="btn tertiary width-12 add" onClick={() => this.setState({showModalMessage: true})}>{lang.t('clients:button.createyourclientsfirsthabit')}</button>
        )
      }
    }
  }


  renderHabits() {
    if(this.state.habits.length > 0) {
      var list = this.state.habits
      list.sort((a,b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          <div className="listheader">
            <ListHeader id='habits' number={this.state.habits.length}/>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/clients/'+this.state.item.id+'/habit/'+item.id}>
                <div className="col-7">
                  <div className={"icon "+this.getHabitCompliance(item, 'class')}><p>{this.getHabitCompliance(item, 'number')}%</p></div>
                  <p className="lft"><strong>{item.data.name}</strong></p>
                </div>
                <div className="col-3">
                  <p>{item.data.amount} {item.data.unit} {lang.t('clients:label.per')} {item.data.interval === 1 ? lang.t('clients:label.day') : lang.t('clients:label.week')}</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push('/clients/'+this.state.item.id+'/new-habit')}>{lang.t('clients:button.addanotherhabit')}</button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="listheader">
            <h3>{lang.t('header:title.habits')}</h3>
            <div className="clear"></div>
          </div>
          <div className="empty large">
            <div className="illustration mb-30">
              <div className="img1"></div>
              <div className="img2"></div>
              <div className="img3"></div>
              <div className="img4"></div>
              {HelperEmptyImg.renderImage('habits')}
            </div>
            <div className="data">
              <h3 className="mb-20">{lang.t('clients:empty.habits.title')}</h3>
              <p className="mb-20">{lang.t('clients:empty.habits.text')}</p>
              {this.renderButtonEmptyHabit()}
            </div>
          </div>
        </div>
      )
    }
  }


  renderContentBase() {
    if(this.state.item !== null) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
          {HelperClients.getFamily(this.state.item)}
            <div className="col-9 withsidebar">
              {this.renderTop()}
              {this.renderHistory()}
              {this.renderHabits()}
              {this.renderMeals()}
            </div>
            <div className="col-3">
              {this.renderButtonNutrition()}
              {this.renderButtonLog()}
              {this.renderButtonHabit()}
              <div className="clear sv-50"></div>
              <h3 className="mb-20">{lang.t('clients:label.weight')}</h3>
              <div className="clear info">
                <label className="lft">{lang.t('clients:label.current')}</label>
                <label className="rgt">{lang.t('clients:label.goal')}</label>
                <div className="clear"></div>
                <div className="bar">
                  {this.renderWeightBar()}
                </div>
                <p className="lft">{this.renderWeight('current')}</p>
                <p className="rgt">{this.renderWeight('goal')}</p>
                <div className="clear"></div>
              </div>
              <div className="clear sv-30"></div>

              <div className="mb-50">
                <h3 className="mb-20">{lang.t('header:title.health')}</h3>
                <InfoBar label='Resting heart rate' value={this.getBpm('value')+(this.getBpm('value') === '-' ? '' : 'bpm')} color={this.getBpm('color')} width={this.getBpm('bar')}/>
                <InfoBar label='Body fat' value={this.getFat('value')+(this.getFat('value') === '-' ? '' : '%')} color={this.getFat('color')} width={this.getFat('bar')}/>
                <InfoBar label='Blood pressure' value={this.getBlood1('value')} color={this.getBlood1('color')} width={this.getBlood1('bar')}/>
              </div>

              <div className="mb-50">
                <h3 className="mb-20">{lang.t('header:title.dailytargets')}</h3>
                <div className="info">
                  <div className="icon primary lft mr-10">
                    <div className="inner cal"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.totalcalories')}</label>
                    <p>{this.renderCalories()}</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="info">
                  <div className="icon primary lft mr-10">
                    <div className="inner training"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.basalmetabolicrate')}</label>
                    <p>{this.getBmr()}</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="info">
                  <div className="icon quarternary lft mr-10">
                    <div className="inner protein"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.protein')}</label>
                    <p>{this.getDayProtein('%')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="info">
                  <div className="icon quarternary lft mr-10">
                    <div className="inner carbs"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.carbs')}</label>
                    <p>{this.getDayCarbs('%')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="info">
                  <div className="icon quarternary lft mr-10">
                    <div className="inner fat"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.fat')}</label>
                    <p>{this.getDayFat('%')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="mb-50">
                <h3 className="mb-20">{lang.t('header:title.stats')}</h3>
                <div className="info">
                  <div className={'icon lft mr-10 '+this.renderCompliance('color')}>
                    <div className="inner compliance"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.compliance')}</label>
                    <p>{this.renderCompliance('value')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
                <div className="info">
                  <div className={'icon lft mr-10 '+this.getActivity('color')}>
                    <div className="inner training"></div>
                  </div>
                  <div className="main lft">
                    <label>{lang.t('clients:label.exerciselast7days')}</label>
                    <p>{this.getActivity('value')}</p>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>
            </div>
            <div className="clear sv-40"></div>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='health'/>
          <Navigation active='clients' />
          {this.renderModal()}
          <ModalMessageSimple type='habitslocked' show={this.state.showModalMessage} onHide={() => this.hideModals()} clickMainButton={() => this.props.history.push(ROUTES.SUBSCRIPTION)}/>
          <ModalForm type={'nutrition'} show={this.state.showModalSettings} onHide={() => this.hideModals()} value1={this.state.valueReferral} updateValue1={(event) => this.setState({valueReferral: event})} clickMainButton={() => this.sendReferral()}/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs"></div>
          <Header client={this.state.item} link={global.currentPage} active='health'/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div>
          {this.renderContentBase()}    
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <ListEmpty id='health-locked' type='simple'/>
          </div>
          <Header client={this.state.item} link={global.currentPage} active='health'/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','calendar','modals','messaging'])(withRouter(withAuthorization(condition)(ClientsHealthPage)));