import React, { Component } from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import Connector from '../../data/Connector';
import HelperBusiness from '../../helper/Business';
import HelperClients from '../../helper/Clients';
import HelperPage from '../../helper/Page';
import { CSVLink } from "react-csv";
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import ListEmpty from '../../components/List/empty';
import HelperEmptyImg from '../../helper/EmptyLarge';
import InputDate from '../../components/Form/input-date';
import Select from '../../components/Form/select';
import InputRadio from '../../components/Form/radio';
import * as ROUTES from '../../constants/routes';
import { withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientReportPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: global.clients,
      clientsBase: global.clients,
      clientsList: [],
      sessions: global.sessions,
      archive: global.archive,
      start: Moment('01/01/2020', 'DD/MM/YYYY').format('YYYY-MM-DD'),
      end: Moment().format('YYYY-MM-DD'),
      currentClient: '',
      served: [],
      created: false,
      group: '',
      location: global.loc,
      type: 'active',
      sum1: 0,
      sum2: 0,
      sum3: 0,
      sum4: 0,
      // CSV stuff
      csvheaders: [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Phone", key: "phone" },
        { label: "Date of birth", key: "dob" },
      ],
      csvdata: [],
    };
  }


  componentDidMount() {  
    HelperPage.initialize('Client Reports - PT Mate', ROUTES.CLIENTSREPORT)
    this._isMounted = true

    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      if(global.archive.length === 0 && global.uidUser !== '') {
        Connector.loadArchive((result) => {})
      }
      var tmp1 = []
      var tmp2 = []
      var tmp3 = []
      for(var item1 of global.clients) {
        tmp1.push(item1)
      }
      for(var item2 of global.sessions) {
        tmp2.push(item2)
      }
      for(var item3 of global.archive) {
        tmp3.push(item3)
      }
      this.setState({
        clients: tmp1,
        clientsBase: tmp1,
        sessions: tmp2,
        archive: tmp3,
      })
    }
  }


  configureList() {
    var tmp = []
    var csv = []
    var ids = []
    var pt = 0
    var group = 0
    var ptn = 0
    var groupn = 0
    var startStr = this.state.start+' 00:00'
    var endStr = this.state.end+' 23:59'
    var start = Moment(startStr, 'YYYY-MM-DD HH:mm').format('X')
    var end = Moment(endStr, 'YYYY-MM-DD HH:mm').format('X')

    for(var client of this.state.clients) {
      if(this.state.currentClient !== '') {
        if(client.id === this.state.currentClient) {
          tmp.push(client)
          ids.push(client.id)
          csv.push({name: client.data.name, email: client.data.email, phone: client.data.phone, dob: Moment(client.data.birth, 'DD/MM/YYYY').locale(lang.language).format('D MMM YYYY')})
        }
      } else {
        tmp.push(client)
        ids.push(client.id)
        csv.push({name: client.data.name, email: client.data.email, phone: client.data.phone, dob: Moment(client.data.birth, 'DD/MM/YYYY').locale(lang.language).format('D MMM YYYY')})
      }
    }

    for(var item1 of this.state.sessions) {
      var date = Moment(item1.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item1.data.timestamp !== undefined) {
        Moment(item1.data.timestamp, 'X').format('X')
      }
      if(parseInt(date) > parseInt(start) && parseInt(date) < parseInt(end)) {
        if(!item1.data.group) {
          if(ids.indexOf(item1.data.client) !== -1 && item1.data.attendance === 3) {
            pt++
          }
          if(ids.indexOf(item1.data.client) !== -1 && item1.data.attendance > 3) {
            ptn++
          }
        } else {
          if(item1.data.clients !== undefined) {
            for(var cl of item1.data.clients) {
              if(ids.indexOf(cl) !== -1) {
                group++
              }
            }
          }
          if(item1.data.noshows !== undefined) {
            for(var cl1 of item1.data.noshows) {
              if(ids.indexOf(cl1) !== -1) {
                groupn++
              }
            }
          }
        }
      }
    }

    for(var item2 of this.state.archive) {
      var date2 = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item2.data.timestamp !== undefined) {
        Moment(item2.data.timestamp, 'X').format('X')
      }
      if(parseInt(date2) > parseInt(start) && parseInt(date2) < parseInt(end)) {
        if(!item2.data.group) {
          if(ids.indexOf(item2.data.client) !== -1 && item2.data.attendance === 3) {
            pt++
          }
          if(ids.indexOf(item2.data.client) !== -1 && item2.data.attendance > 3) {
            ptn++
          }
        } else {
          if(item2.data.clients !== undefined) {
            for(var cl2 of item2.data.clients) {
              if(ids.indexOf(cl2) !== -1) {
                group++
              }
            }
          }
          if(item2.data.noshows !== undefined) {
            for(var cl3 of item2.data.noshows) {
              if(ids.indexOf(cl3) !== -1) {
                groupn++
              }
            }
          }
        }
      }
    }

    this.setState({
      clientsList: tmp,
      sum1: pt,
      sum2: group,
      sum3: ptn,
      sum4: groupn,
      csvdata: csv,
    })
  }


  updateClients(event) {
    var tmp = global.clients
    if(event === 'inactive') {
      tmp = global.clientsInactive
    }
    this.setState({
      type: event,
      clients: tmp,
      clientsBase: tmp,
    }, () => {

    })
  }


  setLocation(event) {
    this.setState({
      location: event,
      currentClient: '',
    }, () => {
      this.setClientList()
    })
  }


  setGroup(event) {
    this.setState({
      group: event,
      currentClient: '',
    }, () => {
      this.setClientList()
    })
  }


  setClientList() {
    var tmp = []
    var list1 = []
    var list2 = []
    var cl = []
    var ids = []
    for(var c1 of this.state.clientsBase) {
      cl.push(c1)
      ids.push(c1.id)
    }

    if(this.state.group === '') {
      list1 = ids
    } else {
      for(var gr of global.clientGroups) {
        if(gr.id === this.state.group && gr.data.clients !== undefined) {
          list1 = gr.data.clients
        }
      }
    }
    
    if(this.state.location === '') {
      list2 = list1
    } else {
      for(var loc of global.locations) {
        if(loc.id === this.state.location && loc.data.clients !== undefined) {
          list2 = loc.data.clients
        }
      }
    }
    
    for(var client of cl) {
      if(list1.indexOf(client.id) !== -1 && list2.indexOf(client.id) !== -1) {
        tmp.push(client)
      }
    }

    this.setState({
      clients: tmp,
    })
  }


  getSessions(client, group) {
    var num = 0
    var endStr = this.state.end+' 23:59'
    var start = Moment(this.state.start+' 00:00', 'YYYY-MM-DD HH:mm').format('X')
    var end = Moment(endStr, 'YYYY-MM-DD HH:mm').format('X')
    
    for(var item of this.state.sessions) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item.data.timestamp !== undefined) {
        Moment(item.data.timestamp, 'X').format('X')
      }
      if(item.data.group === group && parseInt(date) > parseInt(start) && parseInt(date) < parseInt(end)) {
        if(item.data.client === client && item.data.attendance === 3) {
          num++
        }
        if(item.data.clients !== undefined) {
          if(item.data.clients.indexOf(client) !== -1) {
            num++
          }
        }
      }
    }

    for(var item2 of this.state.archive) {
      var date2 = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item2.data.timestamp !== undefined) {
        Moment(item2.data.timestamp, 'X').format('X')
      }
      if(item2.data.group === group && parseInt(date2) > parseInt(start) && parseInt(date2) < parseInt(end)) {
        if(item2.data.client === client && item.data.attendance === 3) {
          num++
        }
        if(item2.data.clients !== undefined) {
          if(item2.data.clients.indexOf(client) !== -1) {
            num++
          }
        }
      }
    }

    return num
  }


  getSessionsNoshow(client, group) {
    var num = 0
    var endStr = this.state.end+' 23:59'
    var start = Moment(this.state.start+' 00:00', 'YYYY-MM-DD HH:mm').format('X')
    var end = Moment(endStr, 'YYYY-MM-DD HH:mm').format('X')
    
    for(var item of this.state.sessions) {
      var date = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item.data.timestamp !== undefined) {
        Moment(item.data.timestamp, 'X').format('X')
      }
      if(item.data.group === group && parseInt(date) > parseInt(start) && parseInt(date) < parseInt(end)) {
        if(item.data.client === client && item.data.attendance > 3) {
          num++
        }
        if(item.data.noshows !== undefined) {
          if(item.data.noshows.indexOf(client) !== -1) {
            num++
          }
        }
      }
    }

    for(var item2 of this.state.archive) {
      var date2 = Moment(item2.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(item2.data.timestamp !== undefined) {
        Moment(item2.data.timestamp, 'X').format('X')
      }
      if(item2.data.group === group && parseInt(date2) > parseInt(start) && parseInt(date2) < parseInt(end)) {
        if(item2.data.client === client && item.data.attendance > 3) {
          num++
        }
        if(item2.data.noshows !== undefined) {
          if(item2.data.noshows.indexOf(client) !== -1) {
            num++
          }
        }
      }
    }

    return num
  }



  // Interactive stuff ------------------------------------------------------------



  createReport() {
    if(this.state.start !== '' && this.state.end !== '') {
      this.setState({
        created: true
      })
      this.configureList()
      setTimeout(() => {
        EventEmitter.dispatch('updateBadges', 'report');
      }, 1000);
    }
  }


  gotoClient(item) {
    this.props.history.push('/clients/'+item.id+'/overview')
  }


  getSessionsTotal(item) {
    var label = '-'
    var num1 = this.getSessions(item.id, false)
    var num2 = this.getSessionsNoshow(item.id, false)
    if(num1 > 0 && num2 === 0) {
      label = num1+' '+lang.t('clients:label.attended-s')
    } else if(num2 > 0 && num1 === 0) {
      label = num1+' '+lang.t('clients:report.noshow')
    } else if(num1 > 0 && num2 > 0) {
      label = num1+' '+lang.t('clients:label.attended-s')+' / '+num2+' '+lang.t('clients:report.noshow')
    }
    return label
  }


  getGroupTotal(item) {
    var label = '-'
    var num1 = this.getSessions(item.id, true)
    var num2 = this.getSessionsNoshow(item.id, true)
    if(num1 > 0 && num2 === 0) {
      label = num1+' '+lang.t('clients:label.attended-s')
    } else if(num2 > 0 && num1 === 0) {
      label = num1+' '+lang.t('clients:report.noshow')
    } else if(num1 > 0 && num2 > 0) {
      label = num1+' '+lang.t('clients:label.attended-s')+' / '+num2+' '+lang.t('clients:report.noshow')
    }
    return label
  }



  // Display stuff ------------------------------------------------------------



  renderBday(item) {
    if(item !== '01/01/1900' && item !== '' && item !== undefined) {
      var start = Moment(this.state.start, 'YYYY-MM-DD')
      var year1 =  Moment(this.state.start, 'YYYY-MM-DD').format('YYYY')
      var end = Moment(this.state.end, 'YYYY-MM-DD')
      var year2 =  Moment(this.state.end, 'YYYY-MM-DD').format('YYYY')
      var bd1 = Moment(item, 'DD/MM/YYYY').format('DD/MM')+'/'+year1
      var bd2 = Moment(item, 'DD/MM/YYYY').format('DD/MM')+'/'+year2
      var show = false
      if(Moment(bd1, 'DD/MM/YYYY') > start && Moment(bd1, 'DD/MM/YYYY') < end) {
        show = true
      }
      if(Moment(bd2, 'DD/MM/YYYY') > start && Moment(bd2, 'DD/MM/YYYY') < end) {
        show = true
      }
      if(show) {
        return "("+lang.t('clients:report.birthday')+")"
      }
    }
  }


  renderList() {
    if(this.state.clientsList.length === 0) {
      return (
        <div className="empty large pt-60 clear">
          <div className="illustration mb-30">
            <div className="img1 static"></div>
            <div className="img2 static"></div>
            <div className="img3 static"></div>
            <div className="img4 static"></div>
            {HelperEmptyImg.renderImage('reports')}
          </div>
          <h3 className="mb-20">{lang.t('clients:report.nothing')}</h3>
          <p className="mb-20">{lang.t('clients:report.nothing.text')}</p>
        </div>
      )
    } else {
      var list = this.state.clientsList
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div>
          {list.map(item => (
            <div className="list bare" key={item.id} onClick={() => this.props.history.push('/clients/'+item.id+'/overview')}>
              <div className="col-4">
                {HelperClients.getImage(item)}
                <p className="lft"><strong>{item.data.name}</strong><br/>{Moment(item.data.birth, 'DD/MM/YYYY').locale(lang.language).format('D MMM YYYY')} {this.renderBday(item.data.birth)}</p>
              </div>
              <div className="col-2">
                <p>{(item.data.email === '' || item.data.email === ' ') ? '-' : item.data.email}</p>
              </div>
              <div className="col-2">
                <p>{HelperClients.getPhone(item.data.phone)}</p>
              </div>
              <div className="col-2">
                <p>{this.getSessionsTotal(item)}</p>
              </div>
              <div className="col-2">
              <p>{this.getGroupTotal(item)}</p>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderCSV() {
    if(this.state.created && this.state.currentType !== 'inv') {
      return (
        <CSVLink className="btn secondary" data={this.state.csvdata} headers={this.state.csvheaders} filename={"ptmate-"+Moment().format('YYYY-MM-DD')+".csv"}>{lang.t('clients:button.exportascsv')}</CSVLink>
      )
    }
  }


  renderPrint() {
    if(this.state.created) {
      return (
        <button className="btn secondary" onClick={() => window.print()}>{lang.t('clients:button.print')}</button>
      )
    }
  }


  renderClients() {
    if(global.clients.length > 0 || global.clientsInactive.length > 0) {
      var list = [{value: '', name: 'All clients'}]
      this.state.clients.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var cl of this.state.clients) {
        list.push({value: cl.id, name: cl.data.name})
      }
      return (
        <div className="col-2">
          <Select label={lang.t('clients:report.client')} value={this.state.currentClient} values={list} onChange={(event) => this.setState({currentClient: event})}/>
        </div>
      )
    }
  }


  renderLogo() {
    if(global.spaceImage !== '') {
      return (
        <img src={global.spaceImage} alt={global.userBusiness}/>
      )
    } else {
      return (
        <img src="/img/logo.svg" alt="PT Mate"/>
      )
    }
  }


  renderReport() {
    if(this.state.created) {
      return (
        <div>
          <div className="col-12">
            <div className="print-header">
              {this.renderLogo()}
              <h2>{global.userBusiness}</h2>
              <h3>{lang.t('clients:report.clientreport')} {Moment(this.state.start, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')} - {Moment(this.state.end, 'YYYY-MM-DD').locale(lang.language).format('D MMM YYYY')}</h3>
            </div>
            <div className="print-box mb-40">
              <h3 className="mb-30">Summary</h3>
              <div className="info large col-4 clear">
                <label>{lang.t('clients:report.totalclients')}</label>
                <p>{this.state.clientsList.length}</p>
              </div>
              <div className="info large col-2">
                <label>{lang.t('clients:label.11sessions')}</label>
                <p>{this.state.sum1}</p>
                <span className="pill green">{lang.t('clients:label.attended')}</span>
              </div>
              <div className="info large col-2">
                <label>&nbsp;</label>
                <p>{this.state.sum3}</p>
                <span className="pill red">{lang.t('clients:report.noshow')}</span>
              </div>
              <div className="info large col-2">
                <label>{lang.t('clients:label.groupsessions')}</label>
                <p>{this.state.sum2}</p>
                <span className="pill green">{lang.t('clients:label.attended')}</span>
              </div>
              <div className="info large col-2">
                <label>&nbsp;</label>
                <p>{this.state.sum4}</p>
                <span className="pill red">{lang.t('clients:report.noshow')}</span>
              </div>
            </div>
          </div>
          <div className="col-12 clear print-data mt-20">
            <h3 className="mb-10">{lang.t('clients:report.clients')}</h3>
            <div className="list bare" style={{background: 'none', cursor: 'default'}}>
              <div className="col-4"><h5>{lang.t('clients:label.name')}</h5></div>
              <div className="col-2"><h5>{lang.t('clients:label.email')}</h5></div>
              <div className="col-2"><h5>{lang.t('clients:label.phone')}</h5></div>
              <div className="col-2"><h5>{lang.t('clients:label.11sessions')}</h5></div>
              <div className="col-2"><h5>{lang.t('clients:label.groupsessions')}</h5></div>
              <div className="clear"></div>
            </div>
            {this.renderList()}
          </div>
          <div className="sv-20"></div>
        </div>
      )
    }
  }


  renderLocations() {
    if(global.locations.length > 0) {
      var list = [{value: '', name: lang.t('clients:report.all')}]
      global.locations.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var loc of global.locations) {
        list.push({value: loc.id, name: loc.data.name})
      }
      return (
        <div className="col-2">
          <Select label={lang.t('clients:report.location')} value={this.state.location} values={list} onChange={(event) => this.setLocation(event)}/>
        </div>
      )
    }
  }


  renderGroups() {
    if(global.clientGroups.length > 0) {
      var list = [{value: '', name: 'All'}]
      global.clientGroups.sort((a, b) => a.data.name.localeCompare(b.data.name))
      for(var clg of global.clientGroups) {
        list.push({value: clg.id, name: clg.data.name})
      }
      return (
        <div className="col-2">
          <Select label={lang.t('clients:report.clientgroup')} value={this.state.group} values={list} onChange={(event) => this.setGroup(event)}/>
        </div>
      )
    }
  }


  renderEmpty() {
    if(global.clientGroups.length === 0) {
      return (
        <div className="col-2">&nbsp;</div>
      )
    }
  }


  renderEnabled() {
    return (
      <div className={'theme-'+global.spaceTheme}>
        <div className="content withtabs">
          <div className="col-12 noprint mt-20">
            <div className="box">
              <h2 className="mb-20">{lang.t('clients:report.createareport')}</h2>
              <div className="col-2 clear">
                <InputRadio
                  label='Client type'
                  value={this.state.type}
                  clickElement={(event) => this.updateClients(event)}
                  values={[{name: lang.t('clients:label.active'), value: 'active'}, {name: lang.t('clients:label.inactive'), value: 'inactive'}]}
                />
              </div>
              {this.renderGroups()}
              {this.renderLocations()}
              {this.renderClients()}
              <div className="col-2">
                <InputDate label={lang.t('clients:report.sessionsfrom')} value={Moment(this.state.start, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(event) => this.setState({start: event})}/>
              </div>
              <div className="col-2">
                <InputDate label={lang.t('clients:report.sessionstill')} value={Moment(this.state.end, 'YYYY-MM-DD').format('YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(event) => this.setState({end: event})}/>
              </div>
              {this.renderEmpty()}
              <div className="col-2 clear mt-10">
                <button className="btn primary" onClick={() => this.createReport()}>{lang.t('clients:button.createreport')}</button>
              </div>
              <div className="col-2 mt-10">
                {this.renderPrint()}
              </div>
              <div className="col-2 mt-10">
                {this.renderCSV()}
              </div>
              <div className="clear"></div>
            </div>
            <div className="sv-40"></div>
          </div>
          {this.renderReport()}
        </div>
        <div className="noprint">
          <Header type='clients' active='reports'/>
          <Navigation active='clients' />
        </div>
      </div>
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin,finance,cs')) {
      if(global.maxClients !== 0) {
        return (
          <div className={'theme-'+global.spaceTheme}>
            <div className="content form-container withtabs">
              <ListEmpty id='clientreports'/>
            </div> 
            <Header type='clients' active='reports'/>
            <Navigation active='clients' />
          </div>
        )
      } else {
        return (
          this.renderEnabled()
        )
      }
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='reports-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('clients:page.clients')}/>
          <Navigation active='clients' />
        </div>
      )
    }
    
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','messaging','nav','calendar'])(withRouter(withAuthorization(condition)(ClientReportPage)));