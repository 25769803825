import React, { Component } from 'react';
import 'firebase/functions';
import Connector from '../../data/Connector';
import { QRCodeCanvas } from "qrcode.react";

import { Link, withRouter } from 'react-router-dom';
import EventEmitter from '../../helper/Emitter';

import { AuthUserContext, withAuthorization } from '../../components/Session';



class QRPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: global.uid
    };
  }


  componentDidMount() {
    Connector.setUser()
    document.title = 'Signup QR Code - PT Mate'
    window.Intercom("update")
    window.scrollTo(0, 0)
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
  }


  configureData() {
    this.setState({
      uid: global.uid
    })
  }


  renderBase() {
    return (
      <div className={'content form theme-'+global.spaceTheme} style={{width: 500}}>
        <Link to={'/clients/new'} className="btn tertiary close" style={{float: 'left'}}>Close</Link>
        <div className="sv-100"></div>
        <QRCodeCanvas
          id="qrCode"
          value={'https://ptmate.me/'+this.state.uid+'/signup'}
          size={500}
          bgColor={"#ffffff"}
          level={"H"}
        />
        <div className="sv-100"></div>
        <a href={'https://ptmate.me/'+this.state.uid+'/signup'} target="_blank" rel="noopener noreferrer">{'https://ptmate.me/'+this.state.uid+'/signup'}</a>
      </div>
    )
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }
}



const condition = authUser => !!authUser;

export default withRouter(withAuthorization(condition)(QRPage));

