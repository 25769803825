import React, { Component } from 'react';
import Moment from 'moment';
import * as ROUTES from '../../constants/routes';
import Cal from '../../helper/Calendar';
import HelperBusiness from '../../helper/Business';
import HelperPage from '../../helper/Page';
import EventEmitter from '../../helper/Emitter';
import WidgetOverview from '../../components/Widgets/overview';
import WidgetActions from '../../components/Widgets/actions';
import ListEmpty from '../../components/List/empty';
import Navigation from '../../components/Navigation';
import Header from '../../components/Headers/header';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import lang from 'i18next';
import { AuthUserContext, withAuthorization } from '../../components/Session';



class ClientsGroupsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: global.sessions,
      archive: global.archive,
      clients: global.clients,
      groups: [],
      clientsInactive: global.clientsInactive,
      dateRange: global.dateRange,
    };
  }


  componentDidMount() {
    HelperPage.initialize('Client Groups - PT Mate', ROUTES.CLIENTSGROUPS)
    HelperPage.checkMessage()
    this._isMounted = true
    
    EventEmitter.subscribe('sessionsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('archiveLoaded', (event) => this.configureData())
    EventEmitter.subscribe('clientsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('userLoaded', (event) => this.configureData())
    EventEmitter.subscribe('groupsLoaded', (event) => this.configureData())
    EventEmitter.subscribe('locationSet', (event) => this.configureData())

    this.configureData()
  }


  componentWillUnmount() {
    this._isMounted = false
  }


  configureData() {
    if(this._isMounted) {
      this.setState({
        groups: global.clientGroups,
        clients: global.clients,
        sessions: global.sessions,
        archive: global.archive
      })
    }
  }



  // Overview Calculations ------------------------------------------------------------



  getActiveClients() {
    var minDate = Moment().add(-31, 'days')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days')
    }
    var clients = []
    for(var item of this.state.sessions) {
      var add = false
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > minDate && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add = true
        }
      } else if(this.state.dateRange === 'week') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
          add = true
        }
      } else {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X') < Moment()) {
          add = true
        }
      }
      if(global.loc !== '' && item.data.location !== global.loc) {
        add = false
      }
      if(add && clients.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients.indexOf(client) === -1) {
                clients.push(client)
              } 
            }
          }
        }
        
      }
    }
    return clients.length
  }


  getActiveDifference(type) {
    var today = Moment().format('X')
    var minDate = Moment().add(-31, 'days').format('X')
    var minDate2 = Moment().add(-30, 'days').format('X')
    var prevDate = Moment().add(-61, 'days').format('X')
    if(this.state.dateRange === '7days') {
      minDate = Moment().add(-8, 'days').format('X')
      minDate = Moment().add(-7, 'days').format('X')
      prevDate = Moment().add(-15, 'days').format('X')
    }
    if(this.state.dateRange === '60days') {
      minDate = Moment().add(-61, 'days').format('X')
      minDate = Moment().add(-60, 'days').format('X')
      prevDate = Moment().add(-121, 'days').format('X')
    }
    if(this.state.dateRange === 'week') {
      minDate = Moment().startOf('week').format('X')
      minDate = Moment().startOf('week').format('X')
      prevDate = Moment().startOf('week').add(-7, 'days').format('X')
    }

    var clients1 = []
    var clients2 = []

    for(var item of this.state.sessions) {
      var add1 = false
      var add2 = false
      var idate = Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('X')
      if(this.state.dateRange === '7days' || this.state.dateRange === '30days' || this.state.dateRange === '60days') {
        if(idate > minDate && idate < today) {
          add1 = true
        }
        if(idate > prevDate && idate < minDate2) {
          add2 = true
        }
      } else if(this.state.dateRange === 'month') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().format('MM/YYYY') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment()) {
          add1 = true
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm').format('MM/YYYY') === Moment().add(-1, 'month').format('MM/YYYY')) {
          add2 = true
        }
      } else if(this.state.dateRange === 'week') {
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().add(1, 'days').startOf('day')) {
          add1 = true
        }
        if(Moment(item.data.date, 'DD/MM/YYYY HH:mm') > Moment().startOf('week').add(-7, 'days') && Moment(item.data.date, 'DD/MM/YYYY HH:mm') < Moment().startOf('week')) {
          add2 = true
        }
      }

      if(global.loc !== '' && item.data.location !== global.loc) {
        add1 = false
        add2 = false
      }

      if(add1 && clients1.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients1.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client of item.data.clients) {
              if(clients1.indexOf(client) === -1) {
                clients1.push(client)
              } 
            }
          }
        }
      }
      if(add2 && clients2.indexOf(item.data.client) === -1) {
        if(!item.data.group) {
          clients2.push(item.data.client)
        } else {
          if(item.data.clients !== undefined) {
            for(var client2 of item.data.clients) {
              if(clients2.indexOf(client2) === -1) {
                clients2.push(client2)
              } 
            }
          }
        }
      }
    }

    return clients2.length
  }



  // Display stuff ------------------------------------------------------------



  renderColor(item) {
    if(item.data.color !== undefined && item.data.color !== '') {
      return (
        <div className="groups">
          <div className={'bullet lft group '+item.data.color}></div>
        </div>
      )
    }
  }


  renderBottom() {
    if(this.state.groups.length === 0) {
      return (
        <ListEmpty id='clientgroups'/>
      )
    } else {
      var list = this.state.groups
      list.sort((a, b) => a.data.name.localeCompare(b.data.name))
      return (
        <div className="col-12">
          <div className="listheader clear">
            <h3>{this.state.groups.length} {this.state.groups.length === 1 ? lang.t('header:title.clientgroups1') : lang.t('header:title.clientgroups')}</h3>
            <div className="clear"></div>
          </div>
          {list.map(item => (
            <div className="box list simple highlight mb-10" key={item.id}>
              <Link to={'/clients/groups/group/'+item.id}>
                <div className="col-6">
                  <div className="icon primary">
                    <div className="inner group"></div>
                  </div>
                  <p className="lft"><strong>{item.data.name}</strong></p>
                  {this.renderColor(item)}
                  <div className="clear"></div>
                </div>
                <div className="col-4">
                  <p>{item.data.clients.length+' '+(item.data.clients.length === 1 ? lang.t('clients:label.client-s') : lang.t('clients:label.clients-s'))}</p>
                </div>
                <div className="col-2">
                  <button className="btn tertiary small rgt">{lang.t('common:button.viewdetails')}</button>
                </div>
                <div className="clear"></div>
              </Link>
            </div>
          ))}
          <button className="inline-add list mb-10" onClick={() => this.props.history.push(ROUTES.CLIENTSGROUPSNEW)}>{lang.t('clients:button.addanotherclientgroup')}</button>
          <div className="sv-80 clear"></div>
        </div>
      )
    }
  }


  renderActions() {
    var item1 = {id: '', link: ''}
    if(HelperBusiness.getRoleIncl('')) {
      item1 = {id: 'newclientgroup', link: ROUTES.CLIENTSGROUPSNEW}
    } else if(HelperBusiness.getRole('trainer,admin')) {
      item1 = {id: 'newclientgroup', link: ROUTES.CLIENTSGROUPSNEW}
    }
    return (
      <WidgetActions
        item1={item1}
      />
    )
  }


  renderBase() {
    if(HelperBusiness.getRoleIncl('trainer,admin')) {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content withtabs">
            <div className="col-9">
              <WidgetOverview showSelect={true} updateSelect={() => this.setState({dateRange: global.dateRange})}
                item1={{id: 'clientgroups', value: this.state.groups.length, prev: ''}}
                item2={{id: 'activeclients', value: this.getActiveClients(), prev: this.getActiveDifference()}}
                item3={{id: 'group', value: Cal.getAmount(this.state.sessions, this.state.archive, 'group', this.state.dateRange), prev: Cal.getDifference(this.state.sessions, true, this.state.dateRange, 'num')}}
                item4={{id: '', value: ''}}
              />
            </div>
            {this.renderActions()}
            {this.renderBottom()}
          </div>
          <Header type='clients' active='groups'/>
          <Navigation active='clients'/>
        </div>
      )
    } else {
      return (
        <div className={'theme-'+global.spaceTheme}>
          <div className="content">
            <ListEmpty id='clientgroups-locked' type='simple'/>
          </div>
          <Header type='simple' title={lang.t('nav:main.clients')}/>
          <Navigation active='clients' />
        </div>
      )
    }
  }


  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          this.renderBase()
        )}
      </AuthUserContext.Consumer>
    )
  }

}



const condition = authUser => !!authUser;
export default withTranslation(['clients','common','header','nav','actions','messaging','empty','overview'])(withAuthorization(condition)(ClientsGroupsPage));