import React, { Component } from 'react';
import Firebase from 'firebase';
import { withRouter } from 'react-router-dom';



class MemberPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: global.uid
    };
  }


  componentDidMount() {
    document.title = 'Member Area - PT Mate'
    window.scrollTo(0, 0)
    if(Firebase.auth().currentUser !== undefined) {
      Firebase.auth().signOut()
    }
  }


  render() {
    return (
      <div className='content form theme-blue'>
        <h2>Member Area</h2>
        <p className="mt-40 mb-40">The member area has moved to a new location. You can find it now at <a href="https://ptmate.me" rel="noopener noreferrer">http://ptmate.me</a></p>
        <div className="col-4">
          <a className="btn primary" href="https://ptmate.me" rel="noopener noreferrer">Go to Member Portal</a>
        </div>
      </div>
    )
  }
}



export default withRouter(MemberPage);