import React, { Component } from 'react';
import HelperClients from '../../helper/Clients';
import HelperEmpty from '../../helper/Empty';
import { withTranslation } from "react-i18next";
import lang from 'i18next';



class InputInvited extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      type: props.type ?? '',
      rec: props.recurring ?? false,
      clients: props.clients ?? [],
      groups: props.groups ?? [],
      memberships: props.memberships ?? [],
      event: props.event ?? false,
      error: props.error ?? false,
    };
  }


  static getDerivedStateFromProps(props) {
    return {
      type: props.type ?? '',
      rec: props.recurring ?? false,
      clients: props.clients ?? [],
      groups: props.groups ?? [],
      memberships: props.memberships ?? [],
      event: props.event ?? false,
      error: props.error ?? false,
    }
  }


  getName(item, type) {
    var label = lang.t('widgets:form.invited.name')
    if(type === 'group') {
      for(var gr of global.clientGroups) {
        if(gr.id === item.id) {
          label = gr.data.name
        }
      }
    } else if(type === 'membership') {
      for(var pr of global.products) {
        if(pr.id === item.id) {
          label = pr.data.name
        }
      }
    } else {
      for(var cl of global.clients) {
        if(cl.id === item) {
          label = cl.data.name
        }
      }
    }
    for(var staff of global.userStaff) {
      if(staff.id === item) {
        label = staff.data.name
      }
    }
    return label
  }


  getClient(id) {
    var client = null
    for(var item of global.clients) {
      if(item.id === id) {
        client = item
      }
    }
    return client
  }


  renderClientImage(item) {
    if(this.state.type === 'staff') {
      return (
        <div className="avatar">
          <img src='/img/list-staff.svg' className='staff' alt="Staff member" style={{width: 16, height: 16}}/>
        </div>
      )
    } else {
      return HelperClients.getImage(this.getClient(item))
    }
  }


  renderClients() {
    var empty = this.state.rec ? lang.t('widgets:form.invited.clients.label1') : lang.t('widgets:form.invited.clients.label1')
    if(this.state.type === 'location') {
      empty = lang.t('widgets:form.invited.location.label1')
    }
    if(this.state.type === 'staff') {
      empty = lang.t('widgets:form.invited.location.label2')
    }
    if(this.state.clients.length === 0) {
      return <div className="list-item empty-item static" style={{whiteSpace: 'pre-wrap'}}>{empty}</div>
    } else {
      return (
        <div>
          {this.state.clients.map(item => (
            <div className="list-item" key={item}>
              {this.renderClientImage(item)}
              {this.getName(item, 'client')}
              <div className="btn-action" title={lang.t('common:button.remove')} onClick={() => this.props.clickRemove(item)}>{HelperEmpty.renderImage('action-delete')}</div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderGroups() {
    if(this.state.groups.length === 0) {
      return <div className="list-item empty-item static" style={{whiteSpace: 'pre-wrap'}}>{this.state.rec ? lang.t('widgets:form.invited.groups.label1') : lang.t('widgets:form.invited.groups.label2')}</div>
    } else {
      return (
        <div>
          {this.state.groups.map(item => (
            <div className="list-item" key={item}>
              {this.getName(item, 'group')}
              <div className="btn-action" title={lang.t('common:button.remove')} onClick={() => this.props.clickRemove(item)}>{HelperEmpty.renderImage('action-delete')}</div>
            </div>
          ))}
        </div>
      )
    }
  }


  renderMemberships() {
    if(!this.state.event) {
      if(this.state.memberships.length === 0) {
        return (
          <div>
            <div className="list-item empty-item static" style={{whiteSpace: 'pre-wrap'}}>{this.state.rec ? lang.t('widgets:form.invited.membership.label1') : lang.t('widgets:form.invited.membership.label2')}</div>
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickMemberships()}>{lang.t('widgets:form.invited.membership.button')}</button>
            </div>
          </div>
        )
      } else {
        return (
          <div>
            {this.state.memberships.map(item => (
              <div className="list-item" key={item}>
                {this.getName(item, 'membership')}
                <div className="btn-action" title={lang.t('common:button.remove')} onClick={() => this.props.clickRemove(item)}>{HelperEmpty.renderImage('action-delete')}</div>
              </div>
            ))}
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickMemberships()}>{lang.t('widgets:form.invited.membership.button')}</button>
            </div>
          </div>
        )
      }
    }
  }


  renderError(message) {
    if(this.state.error) {
      return (
        <p className="error">{message}</p>
      )
    }
  }


  renderLabel() {
    if(this.state.event) {
      return (
        <div>
          <label>{lang.t('widgets:form.invited.radio.label')}</label>
          <div className="radios">
            <button className={this.state.type === '' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.props.clickType('')}>{lang.t('widgets:form.invited.radio1')}</button>
            <button className={this.state.type === 'group' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.props.clickType('group')}>{lang.t('widgets:form.invited.radio2')}</button>
            <button className={this.state.type === 'client' ? 'radio col-4 active' : 'radio col-4'} onClick={() => this.props.clickType('client')}>{lang.t('widgets:form.invited.radio3')}</button>
          </div>
        </div>
      )
    } else if(this.state.type === 'location') {
      return <label>{lang.t('widgets:form.invited.label.clients')}</label>
    } else if(this.state.type === 'staff') {
      return <label>{lang.t('widgets:form.invited.label.staff')}</label>
    } else {
      return <label>{lang.t('widgets:form.invited.label.access')}</label>
    }
  }


  render() {
    if(this.state.type === '') {
      return (
        <div>
          {this.renderLabel()}
          <div className="form-list">
            <div className="list-item empty-item">{this.state.rec ? lang.t('widgets:form.invited.label.empty1') : lang.t('widgets:form.invited.label.empty2')}</div>
          </div>
        </div>
      )
    } else if(this.state.type === 'group') {
      return (
        <div>
          {this.renderLabel()}
          <div className={this.state.error ? 'form-list error': 'form-list'}>
            {this.renderGroups()}
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickGroups()}>{lang.t('widgets:form.invited.button.selectmembergroups')}</button>
            </div>
            {this.renderMemberships()}
          </div>
          {this.renderError(this.state.event ? lang.t('widgets:form.invited.error.clientgroup') : lang.t('widgets:form.invited.error.membership'))}
        </div>
      )
    } else if(this.state.type === 'private' || this.state.type === 'client' || this.state.type === 'location' || this.state.type === 'staff') {
      return (
        <div>
          {this.renderLabel()}
          <div className={this.state.error ? 'form-list error': 'form-list'}>
            {this.renderClients()}
            <div className="list-item static">
              <button className="btn tertiary small width-12" onClick={() => this.props.clickClients()}>{this.state.type === 'staff' ? lang.t('widgets:form.invited.selectstaff') : lang.t('widgets:form.invited.selectclients')}</button>
            </div>
          </div>
          {this.renderError(lang.t('widgets:form.invited.error.client'))}
        </div>
      )
    }
  }
}


export default withTranslation(['widgets','common'])(InputInvited);